import { PointEvent } from "~~/constant/gtag"
import { useUser } from "@/store/user"
import { SubStatus } from "@/composables/api/common"

export enum IOfficialSiginStatus {
  signin_1_app = "signin_1_app", //已登录-app跳转
  signin_1_web = "signin_1_web", //已登录-网页登录
  signin_0 = "signin_0" // 未登录
}

export enum IOfficialPricingSource {
  click_pricing_tab = "click_pricing_tab", //点击官网pricing tab
  click_app_upgrade = "click_app_upgrade", //点击evoto内的upgrade按钮
  click_bill_upgrade = "click_bill_upgrade" //点击我的账单的upgrade按钮
}

export enum IOfficialPaymentStatus {
  paid_user_subscribed = "paid_user_subscribed", //付费用户当前是在订阅期间
  paid_user = "paid_user", //付费用户当前不在订阅期间
  unpaid_user = "unpaid_user" //未付费用户
}

export enum IOfficialPricingSwitchOperate {
  tab_montly = "tab_montly", // 月
  tab_yearly = "tab_yearly", // 年
  click_monthly_custom = "click_monthly_custom", // 点击月自定义档位
  click_yearly_custom = "click_yearly_custom" // 点击年自定义档位
}

export enum IOfficialPricingCustomOperate {
  click_contact_sales = "click_contact_sales",
  click_close = "click_close"
}
export enum IOfficialPricingCustomPosition {
  professionl_monthly = "professionl_monthly",
  professional_yearly = "professional_yearly",
  team_contact = "team_contact"
}

export enum IOfficialPricingPurchaseOperate {
  click_buy_now = "click_buy_now",
  click_signin_close = "click_signin_close",
  click_pay_close = "click_pay_close", //关闭正常支付弹窗
  click_upgrade_close = "click_upgrade_close", //关闭升级弹窗
  click_downgrade_close = "click_downgrade_close", //关闭降级弹窗
  click_addon_close = "click_addon_close", //关闭流量包弹窗
  click_continue = "click_continue",
  click_subscribe = "click_subscribe",
  pay_result = "pay_result"
}

export enum IOfficialPricingPurchaseValue {
  prompt_please_signin = "prompt_please_signin",
  prompt_pay = "prompt_pay", //非订阅用户正常购买时，需填写支付信息的弹窗
  prompt_upgrade = "prompt_upgrade", //升级弹窗
  prompt_downgrade = "prompt_downgrade", //降级弹窗
  prompt_addon = "prompt_addon", //流量包弹窗
  prompt_subscribe_success = "prompt_subscribe_success",
  prompt_upgrade_success = "prompt_upgrade_success",
  prompt_downgrade_success = "prompt_downgrade_success",
  prompt_buy_success = "prompt_buy_success",
  prompt_subscribe_failed = "prompt_subscribe_failed",
  prompt_upgrade_failed = "prompt_upgrade_failed",
  prompt_downgrade_failed = "prompt_downgrade_failed",
  prompt_buy_failed = "prompt_buy_failed"
}

export enum IOfficialPricingPurchasePosition {
  country_choose = "country_choose",
  card_input = "card_input",
  prompt_pay = "prompt_pay",
  prompt_upgrade = "prompt_upgrade",
  prompt_downgrade = "prompt_downgrade",
  prompt_addon = "prompt_addon"
}

export enum IOfficialUndoAndReactiveOperate {
  undo_cancellation = "undo_cancellation",
  undo_downgrading = "undo_downgrading",
  click_reactivate = "click_reactivate"
}
export enum IOfficialUndoAndReactivePosition {
  billing_page = "billing_page",
  pricing_page = "pricing_page"
}

// 进入定价页面埋点参数
interface IOfficialPricingParams {
  official_sigin_status: IOfficialSiginStatus
  official_source: IOfficialPricingSource
  official_payment_status: IOfficialPaymentStatus
}

// 定价界面tab切换参数
interface IOfficialPricingSwitch {
  official_operate: IOfficialPricingSwitchOperate | string
  official_payment_status?: IOfficialPaymentStatus
}

// 定价界面联系商务
interface IOfficialPricingCustom {
  official_operate: IOfficialPricingCustomOperate
  official_position?: IOfficialPricingCustomPosition
  official_sigin_status?: IOfficialSiginStatus
  official_payment_status?: IOfficialPaymentStatus
}

// 定价页面点击购买
interface IOfficialPricingPurchase {
  official_operate?: IOfficialPricingPurchaseOperate
  official_sigin_status?: IOfficialSiginStatus
  official_payment_status?: IOfficialPaymentStatus
  official_purchase_plan?: string //订阅：professional_张数_month或year，例：professional_750_month   补充包：Ondemand_张数,例：Ondemand_10
  official_value?: IOfficialPricingPurchaseValue
  official_position?: IOfficialPricingPurchasePosition
}

interface IOfficialUndoAndReactive {
  official_operate: IOfficialUndoAndReactiveOperate
  official_position: IOfficialUndoAndReactivePosition
}

export function usePaymentGTag() {
  const { $gtag } = useNuxtApp()
  const { setEvent } = $gtag
  const _PointOfficePricing = (params: IOfficialPricingParams) => setEvent(PointEvent.OfficialPricing, params)
  const _PointOfficePricingSwitch = (params: IOfficialPricingSwitch) =>
    setEvent(PointEvent.OfficialPricingSwitch, params)
  const _PointOfficePricingCustom = (params: IOfficialPricingCustom) =>
    setEvent(PointEvent.OfficialPricingCustom, params)
  const _PointOfficePricingPurchase = (params: IOfficialPricingPurchase) =>
    setEvent(PointEvent.OfficialPricingPurchase, params)
  const _PointOfficeUndoCancel = (params: IOfficialUndoAndReactive) => setEvent(PointEvent.OfficialUndoCancel, params)
  const _PointOfficeUndoDowngrade = (params: IOfficialUndoAndReactive) =>
    setEvent(PointEvent.OfficialUndoDowngrade, params)
  const _PointOfficeReactive = (params: IOfficialUndoAndReactive) => setEvent(PointEvent.OfficialReactivate, params)

  const _PointOfficePricingCustomMixin = (official_position: IOfficialPricingCustomPosition) => {
    _PointOfficePricingCustom({
      official_operate: IOfficialPricingCustomOperate.click_contact_sales,
      official_position,
      official_sigin_status: getOfficialSignStatusParams(),
      official_payment_status: getOfficialPaymentStatus()
    })
  }

  const _PointOfficePricingPurchaseMixin = (official_purchase_plan: string) => {
    _PointOfficePricingPurchase({
      official_operate: IOfficialPricingPurchaseOperate.click_buy_now,
      official_sigin_status: getOfficialSignStatusParams(),
      official_payment_status: getOfficialPaymentStatus(),
      official_purchase_plan
    })
  }

  const _PointOfficePricingMixin = (official_source: IOfficialPricingSource) => {
    _PointOfficePricing({
      official_sigin_status: getOfficialSignStatusParams(),
      official_payment_status: getOfficialPaymentStatus(),
      official_source
    })
  }

  // 获取official_sigin_status参数
  const getOfficialSignStatusParams = () => {
    const use_user = useUser()
    return use_user.isLogin
      ? use_user.isAppLogin()
        ? IOfficialSiginStatus.signin_1_app
        : IOfficialSiginStatus.signin_1_web
      : IOfficialSiginStatus.signin_0
  }
  // 获取official_payment_status参数
  const getOfficialPaymentStatus = () => {
    const use_user = useUser()
    return use_user.isSub
      ? use_user.subscription_info.status === SubStatus.Normal
        ? IOfficialPaymentStatus.paid_user_subscribed
        : IOfficialPaymentStatus.paid_user
      : IOfficialPaymentStatus.unpaid_user
  }

  return {
    _PointOfficePricing,
    _PointOfficePricingSwitch,
    _PointOfficePricingCustom,
    _PointOfficePricingPurchase,
    _PointOfficeUndoCancel,
    _PointOfficeUndoDowngrade,
    _PointOfficeReactive,
    _PointOfficePricingCustomMixin,
    _PointOfficePricingPurchaseMixin,
    _PointOfficePricingMixin,
    getOfficialSignStatusParams,
    getOfficialPaymentStatus
  }
}
