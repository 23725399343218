export default defineNuxtPlugin(async ({ vueApp }) => {
  vueApp.directive("lazy", {
    mounted(el, binding) {
      // el.src = loading || ""
      el.style.opacity = "0"
      const { stop } = useIntersectionObserver(
        el,
        ([{ isIntersecting }]) => {
          if (isIntersecting) {
            el.onerror = () => {
              // el.src = error || ""
            }
            stop() // 监听到可见区域 下次不在执行监听
            el.src = binding.value
            el.style.transition = "all 0.3s"
            el.onload = () => {
              el.style.opacity = ""
            }
            el.oncanplay = () => {
              el.style.opacity = ""
            }
          }
        },
        { threshold: 0 }
      )
    }
  })
})
