export const useNoCookieTipDialog = () => {
  useCommonNormalDialog({
    contentSlots({ close }) {
      return (
        <div style={{ fontSize: "14px", lineHeight: "20px" }}>
          🍪 Please note: This website requires the use of cookies to provide core functionality. Disabling cookies or
          not agreeing to the browser's cookie policy may result in the website not functioning properly. Please click
          here to learn how to enable your browser's cookie functionality.
        </div>
      )
    }
  })
}
