<template>
  <div class="ver-code-form-2">
    <div ref="attr" class="fill_message">
      <div v-for="(item, index) in code" :key="index" :ref="getInputRef" :class="{ active: item.isRead }">
        <input
          v-model="item.number"
          :style="inputStyle"
          type="tel"
          :readonly="item.isRead"
          @keyup.enter="enter"
          @keyup.delete="
            (e) => {
              backSpace(e, index)
            }
          "
          @input="
            (e) => {
              inputMsg(e, index)
            }
          "
          @click="
            (e) => {
              inputClick(e, index)
            }
          "
        />
      </div>
    </div>
  </div>
</template>

<script lang="tsx">
import { codeLength } from "@/constant/vercode"
import { CSSProperties } from "vue"
export default {
  emits: ["enterClick", "codeChange"],
  setup(props, ctx) {
    const proxy = useProxy()

    let attr = ref(null)

    const inputStyle = reactive<CSSProperties>({})

    const getInputRef = (e) => {
      let inputDom = e?.children[0]
      inputStyle.height = inputDom && inputDom.offsetWidth + "px"
    }

    let codeInit = new Array(codeLength).fill(null).map((_, i) => {
      if (i > 0) {
        return {
          number: "",
          isRead: true
        }
      } else {
        return {
          number: "",
          isRead: false
        }
      }
    })

    let code = reactive(codeInit)

    const resetCode = () => {
      code.forEach((item, i) => {
        item.number = ""
        item.isRead = i > 0
        attr.value.children[0].children[0].focus()
      })
    }

    watch(
      () => code,
      (newValue) => {
        let resultCode = newValue.map((item) => item.number).join("")
        ctx.emit("codeChange", resultCode)
      },
      {
        immediate: true,
        deep: true
      }
    )

    let resultCode = computed(() => {
      return code.map((item) => item.number)
    })

    let focus = () => {
      attr.value?.children && attr.value.children[0].children[0].focus()
    }

    ctx.expose({
      resultCode,
      resetCode,
      focus
    })

    onMounted(() => {
      nextTick(() => {
        attr.value.children[0].children[0].focus()
      })
    })

    let enter = useDebounceFn((e) => {
      if (e.keyCode === 13) {
        ctx.emit("enterClick")
      }
    }, 300)

    let copyNum = (num, index) => {
      for (let i = index; i < index + num.length; i++) {
        if (isNaN(num[i % num.length]) && num[i % num.length].length) {
          code[i].number = ""
          break
        }
        if (i > codeLength - 1) break
        code[i].number = num[i % num.length]
        if (i < codeLength - 1) {
          code[i].isRead = true
          code[i + 1].isRead = false
          attr.value.children[i + 1].children[0].focus()
        } else {
          code[i].isRead = true
          // ctx.emit("enterClick")
        }
      }
    }

    let backSpace = (e, index) => {
      let i = code.findIndex((item) => !item.number.length)
      if (i === 0) {
        nextTick(() => {
          attr.value.children[i].children[0].focus()
        })
        return
      }
      if (i !== -1) {
        code[i - 1].number = ""
        code[i].isRead = true
        code[i - 1].isRead = false
        nextTick(() => {
          attr.value.children[i - 1].children[0].focus()
        })
      } else {
        code[codeLength - 1].number = ""
        code[codeLength - 1].isRead = false
        nextTick(() => {
          attr.value.children[codeLength - 1].children[0].focus()
        })
      }
    }

    let inputMsg = (e, index) => {
      code[index].number = String(code[index].number)
      if (!/^\d+$/.test(code[index].number)) {
        // 如果输入不是数字，清除非数字字符
        code[index].number = ""
      }
      let num = code[index].number
      if (num.trim().length === 0) {
        code[index].number = ""
        return
      }
      if (num.length > 1) {
        copyNum(num, index)
      } else {
        if (isNaN(num as any) && num.length) {
          code[index].number = ""
          return
        }
        code[index].isRead = true
        if (index >= codeLength - 1) {
          // ctx.emit("enterClick")
        } else {
          code[index + 1].isRead = false
          nextTick(() => {
            attr.value.children[index + 1].children[0].focus()
          })
        }
      }
    }

    const inputClick = (e, index) => {
      // 不管点击哪里，永远聚焦在下一个输入框的位置
      let i = code.findIndex((item) => !item.number.length)
      if (i !== -1) {
        nextTick(() => {
          attr.value.children[i].children[0].focus()
        })
      } else {
        code[codeLength - 1].isRead = false
        nextTick(() => {
          attr.value.children[codeLength - 1].children[0].focus()
        })
      }
    }

    return {
      attr,
      code,
      enter,
      backSpace,
      inputMsg,
      inputClick,
      getInputRef,
      inputStyle
    }
  }
}
</script>

<style lang="scss" scoped>
.ver-code-form-2 {
  display: flex;
  align-items: center;
  .fill_message {
    display: flex;
    align-items: center;
    width: 100%;
    > div {
      flex: 1;
      margin-right: 24px;
    }
    @include device-max-width-375 {
      > div {
        margin-right: 6px;
      }
    }
    input {
      width: 100%;
      color: inherit;
      font-family: inherit;
      border: 0;
      outline: 0;
      border-bottom: 1px solid #919191;
      height: 50px;
      text-align: center;
      border: 1px solid rgba(0, 0, 0, 0.3);
      border-radius: 8px;
      font-weight: 400;
      font-size: 18px;
      line-height: normal;
      color: rgba(0, 0, 0, 0.85);
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }

    @include device-max-width-600 {
      input {
        border: none;
        border-bottom: 1px solid #919191;
        border-radius: 0;
      }
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      appearance: none;
      margin: 0;
    }
  }
}
</style>
