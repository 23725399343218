import { tsSdkEventName } from "@/constant/enumtsskd"
import tsTrackingSdkInstance from "@/utils/tssdk"
const stSdkSetEvent = tsTrackingSdkInstance.setEvent
const eventName = tsSdkEventName.ELEMENTCLICK
//自研1
export function useStSdkCommonMp() {
  // 夏促kols的more点击
  const _pointPromotionKolsClick = () => {
    stSdkSetEvent(eventName, {
      element_position: "hero_page",
      element_type: "button",
      element_name: "view_more"
    })
  }

  //点击事件
  const _pointMpWebsiteElementClick = (pop) => {
    stSdkSetEvent(eventName, pop)
  }
  //点击首页点击下载
  const _pointHomeDownLoadClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_position: "hero_section",
      element_type: "button",
      element_name: "download",
      ...pop
    })
  }
  //点击首页点击注册
  const _pointHomeSigninClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_position: "nav_bar",
      element_type: "button",
      element_name: "sign_in",
      ...pop
    })
  }
  //点击侧边栏
  const _pointSiderBarClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_position: "float_side_bar",
      element_type: "button",
      ...pop
    })
  }
  //点击首页底部的下载按钮
  const _pointHomeEndDownLoadClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_position: "cta_section",
      element_type: "button",
      element_name: "download",
      ...pop
    })
  }
  //点击下载页面的下载tab
  const _pointDownPageTabItmeClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_position: "hero_section",
      element_type: "button",
      element_name: "download",
      ...pop
    })
  }
  //点击价格页面的tab
  const _pointPricePageTabItmeClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_position: "hero_section",
      element_type: "button",
      ...pop
    })
  }
  //登录弹窗
  const _pointLoginSignUpClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_position: "popup",
      element_type: "button",
      popup_name: "login_or_signup",
      feature_module: "login_or_signup",
      feature_group: "",
      feature_name: "main",
      ...pop
    })
  }
  //注册弹窗Sign up with Code
  const _pointSignUpWithCodeClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_position: "popup",
      element_type: "button",
      popup_name: "login_or_signup",
      feature_module: "login_or_signup",
      feature_group: "signup",
      feature_name: "signup_with_code",
      ...pop
    })
  }
  //手机号注册成功添加邮箱
  const _pointSignUpAddMailClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_position: "popup",
      element_type: "button",
      popup_name: "login_or_signup",
      feature_module: "login_or_signup",
      feature_group: "signup",
      feature_name: "add_email",
      ...pop
    })
  }
  //verify_email
  const _pointVerifyEmailClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_position: "popup",
      element_type: "button",
      popup_name: "login_or_signup",
      feature_module: "login_or_signup",
      feature_group: "signup",
      feature_name: "verify_email",
      ...pop
    })
  }
  //enter-password
  const _pointEnterPwdClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_position: "popup",
      element_type: "button",
      popup_name: "login_or_signup",
      feature_module: "login_or_signup",
      feature_group: "login",
      feature_name: "enter_password",
      ...pop
    })
  }
  //reset_password
  const _pointResetPwdClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_position: "popup",
      element_type: "button",
      popup_name: "login_or_signup",
      feature_module: "login_or_signup",
      feature_group: "reset_password",
      feature_name: "reset_password",
      ...pop
    })
  }
  //check_code
  const _pointCheckCodeClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_position: "popup",
      element_type: "button",
      popup_name: "login_or_signup",
      feature_module: "login_or_signup",
      feature_group: "reset_password",
      feature_name: "check_code",
      ...pop
    })
  }

  //登录弹窗Log in with Code
  const _pointLogInWithCodeClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_position: "popup",
      element_type: "button",
      popup_name: "login_or_signup",
      feature_module: "login_or_signup",
      feature_group: "login",
      feature_name: "login_with_code",
      ...pop
    })
  }
  //Landing_combo 落地页
  const _pointLandingComboClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_type: "button",
      ...pop
    })
  }
  //landing_k_s 页
  const _pointLandingKSClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_type: "button",
      element_position: "hero_section",
      feature_module: "login_or_signup",
      feature_group: "signup",
      ...pop
    })
  }
  //landing & landing_us & landing_s & landing_s_us
  const _pointLandingClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_type: "button",
      ...pop
    })
  }
  //landing_ms 页
  const _pointLandingMsClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_type: "button",
      ...pop
    })
  }
  //landing_combo_mul 页
  const _pointLandingComboMulClick = (pop) => {
    stSdkSetEvent(eventName, {
      element_type: "button",
      element_position: "hero_section",
      ...pop
    })
  }
  //弹窗事件
  const _pointPopUpClick = (pop) => {
    stSdkSetEvent(tsSdkEventName.POPUPVIEW, {
      popup_name: "",
      ...pop
    })
  }
  return {
    _pointMpWebsiteElementClick,
    _pointHomeDownLoadClick,
    _pointHomeSigninClick,
    _pointSiderBarClick,
    _pointHomeEndDownLoadClick,
    _pointDownPageTabItmeClick,
    _pointPricePageTabItmeClick,
    _pointLoginSignUpClick,
    _pointSignUpWithCodeClick,
    _pointSignUpAddMailClick,
    _pointVerifyEmailClick,
    _pointEnterPwdClick,
    _pointResetPwdClick,
    _pointCheckCodeClick,
    _pointLogInWithCodeClick,
    _pointLandingComboClick,
    _pointLandingKSClick,
    _pointLandingClick,
    _pointLandingMsClick,
    _pointLandingComboMulClick,
    _pointPopUpClick,
    _pointPromotionKolsClick
  }
}
