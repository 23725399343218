import ytagInstance from "@/utils/ytag"
import { ytagEventName } from "@/constant/ytag"
const setEvent = ytagInstance.setEvent

export function useLandingYtag() {
  const _PointYtagLandingButtonClick = () => {
    setEvent(ytagEventName.Download, {
      yahoo_conversion_id: "1001311148",
      yahoo_conversion_label: "fD5xCODbs_QYEIW8wrUq",
      yahoo_conversion_value: "1"
    })
  }
  return {
    _PointYtagLandingButtonClick
  }
}
