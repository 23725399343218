// export const GTAG_BETA_MEASUREMENT_ID = "G-QL4KNVZSJT" // beta
export const GTAG_BETA_MEASUREMENT_ID = "G-QL4KNVZSJT" // beta

export const GTAG_MEASUREMENT_ID = "G-TG6QQ3BB5S" // release

export const APP_GTAG_MEASUREMENT_ID =
  process.env.NUXT_APP_ENV === "production" ? GTAG_MEASUREMENT_ID : GTAG_BETA_MEASUREMENT_ID

export const GOOGLE_CLOUD_BETA_URL = "https://res-dev.evoto.ai/ui/www"

export const GOOGLE_CLOUD_PRO_URL = "https://res.evoto.ai/ui/www"

export const GOOGLE_CLOUD_URL = process.env.NUXT_APP_ENV === "production" ? GOOGLE_CLOUD_PRO_URL : GOOGLE_CLOUD_BETA_URL

export enum MIXPANEL_ID {
  Beta = "461119246db47723439994212544e2b3",
  Formal = "f1a9656be8cd50ad8a959aa6c3752f20"
}
// mixpanel id
export const MIX_PANEL_ID = process.env.NUXT_APP_ENV === "production" ? MIXPANEL_ID.Formal : MIXPANEL_ID.Beta

// mixpanel v3
export enum MIXPANEL_V3_ID {
  Beta = "74bf5c4c1419aedf83aa19a54e58c671",
  Formal = "92a7e363f3bf59c2f75022dd263a6341"
}

export const MIX_PANEL_V3_ID = process.env.NUXT_APP_ENV === "production" ? MIXPANEL_V3_ID.Formal : MIXPANEL_V3_ID.Beta
export const MIX_PANEL_PROXY_DOMAIN = "https://d-api.evoto.ai"

export const EVOTO_TOP_DOMAIN = ".evoto.ai"

export function getForumAddress() {
  return `https://forum${
    process.env.NUXT_APP_ENV !== "production" ? `-${process.env.NUXT_APP_SHORT_ENV}` : ""
  }${EVOTO_TOP_DOMAIN}`
}

export const FORUM_ADDRESS = getForumAddress()

export const COOKIE_EXPIRES = 30 // cookie保存天数
