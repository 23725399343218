<template>
  <CommonDialog
    v-model="showDialog"
    popper-class="agree-submit-dialog"
    :dialog-style="dialogStyle"
    @close="closeDialog"
  >
    <div class="agree-submit-dialog_wrapper">
      <img src="@/assets/icons/common/warning-t-1.webp" alt="" />
      <div class="t1" v-html="text"></div>
      <div class="btn-wrapper">
        <CommonButton ref="btnRef" class="btn1" :button-style="buttonStyle" @btn-click="closeDialog"
          ><CommonAnimationScrollText>{{
            useTranslateI18n(`payment.comboPayment.vnDialog.cancel`)
          }}</CommonAnimationScrollText></CommonButton
        >

        <CommonButton ref="btnRef1" class="btn2" :button-style="buttonStyle1" @btn-click="submitHandler"
          ><CommonAnimationScrollText>{{
            submitBtnText || useTranslateI18n(`payment.comboPayment.zhidao`)
          }}</CommonAnimationScrollText></CommonButton
        >
      </div>
    </div>
  </CommonDialog>
</template>

<script setup lang="tsx">
import { CSSProperties } from "vue"

let showDialog = ref(true)

interface IProps {
  text: string
  width?: number
  submit: (...args: any[]) => void
  close: (...args: any[]) => void
  submitBtnText?: string
}

const prop = withDefaults(defineProps<IProps>(), {
  text: () => {
    return ""
  },
  width: () => {
    return null
  },
  submit: () => {
    return null
  },
  close: () => {
    return null
  }
})

let dialogStyle = reactive<CSSProperties>({
  width: prop.width || 438,
  backgroundColor: "#fff",
  padding: "40px"
})

const buttonStyle = reactive({
  width: "100%",
  background: "rgba(0, 0, 0, 0.10)",
  color: `#000`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "16px"
})

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `#000`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "16px"
})

const closeDialog = () => {
  prop.close && prop.close()
  showDialog.value = false
}

const submitHandler = () => {
  prop?.submit(closeDialog)
}
</script>

<style lang="scss">
.welcome-page-dialog {
  &.agree-submit-dialog {
    .agree-submit-dialog_wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 104px;
      }
      .t1 {
        font-size: 20px;
        line-height: 30px;
        margin: 24px 0;
        text-align: center;
        font-weight: 700;
        word-break: break-word;
      }
      .btn-wrapper {
        width: 100%;
        display: flex;
        align-items: center;
        .btn1 {
          margin-right: 17px;
        }
      }
      @include device-max-width-600 {
        .btn-wrapper {
          width: 100%;
          display: flex;
          flex-direction: column;
          .btn1 {
            margin-right: 0px;
            margin-bottom: 17px;
          }
        }
      }
    }
    @include device-max-width-600 {
      .content {
        width: 75%;
        .content-d {
          padding: 30px !important;
          width: 100% !important;
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 70%;
        .content-d {
          padding: 15px !important;
        }
      }
    }
  }
}
</style>
