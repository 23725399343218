import { MIX_PANEL_V3_ID, MIX_PANEL_PROXY_DOMAIN } from "~~/constant/config"
import { LocalStorageKey } from "~~/constant/cache"
import { CookiesOptions, gTagPageWhiteList } from "~~/constant/cookies"
import { getCountryIp } from "@/composables/api/common"
// mixpanel 在用户退出后 上报的 distinct id 为上次登录记录的 user_id
import { mpEventName } from "@/constant/mp"
import { convertToString } from "@/utils/utils"

class MixPanelPoint {
  ipInfo = {}
  mixpanel = null
  static instance: MixPanelPoint = null
  static getInstance(): MixPanelPoint {
    this.instance = this.instance ? this.instance : new MixPanelPoint()
    return this.instance
  }
  reset() {
    const timeId = setTimeout(() => {
      this.mixpanel && this.mixpanel?.reset()
      clearTimeout(timeId)
    })
  }
  getMixPanel() {
    return this.mixpanel
  }
  setIdentify(id?: any) {
    this.mixpanel?.identify(id)
  }
  setPeople(name: string | Record<string, any>, params?: any) {
    this.mixpanel?.people?.set(name, params)
  }
  async getIp() {
    const { ip, user_country } = await getCountryIp()
    this.ipInfo = {
      ip,
      user_country
    }
    return {
      ip,
      user_country
    }
  }
  async initMixPanel(id, properties: Record<string, any>, callback?: (...args: any[]) => any) {
    this.mixpanel = !this.mixpanel ? (await import("mixpanel-browser")).default : this.mixpanel
    this.mixpanel?.init(MIX_PANEL_V3_ID, {
      api_host: MIX_PANEL_PROXY_DOMAIN,
      debug: !(process.env.NUXT_APP_ENV === "production"),
      ip: false,
      loaded(mixpanel) {
        callback && callback(mixpanel)
      }
    })

    id && this.setIdentify(id)
    // 设置用户属性
    properties && this.setPeople(properties)
  }
  setEvent = (
    name: string,
    params?: any,
    fixedParams?: { path?: any; pageTitle?: any; featureName?: any; featureModule?: any; [x: string]: any }
  ) => {
    if (process.client) {
      const isEnabled = useIsPoint(CookiesOptions.Analytics)
      if (!isEnabled) {
        return
      }
      if (!Object.keys(this.ipInfo).length) {
        this.getIp().then((r) => {
          this.mixpanel?.track(
            name,
            params ? { ...params, ...r, ...fixedParams } : { ...r, ...fixedParams },
            (response) => {
              response !== 1 && useSelfPointer("/__static__/mp_t.txt")
              if (name === mpEventName.DataValidation && params.action_type === "leads" && response !== 1) {
                // 发送到数仓
                const action_detail = convertToString(response)
                const { _pointTsSdkEvent } = useTsSdkTest()
                _pointTsSdkEvent({ action_type: "mp_error", action_detail })
              }
            }
          )
        })
      } else {
        this.mixpanel?.track(
          name,
          params ? { ...params, ...this.ipInfo, ...fixedParams } : { ...this.ipInfo, ...fixedParams },
          (response) => {
            response !== 1 && useSelfPointer("/__static__/mp_t.txt")
            if (name === mpEventName.DataValidation && params.action_type === "leads" && response !== 1) {
              // 发送到数仓
              const action_detail = convertToString(response)
              const { _pointTsSdkEvent } = useTsSdkTest()
              _pointTsSdkEvent({ action_type: "mp_error", action_detail })
            }
          }
        )
      }
    }
  }
}

export default MixPanelPoint.getInstance()
