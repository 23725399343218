import { asyncAppendScript } from "./index"

const RECAPTCHA_SITE_KEY = "6Lft-iwnAAAAAGPR_kb2FoolnvFDTczhk-FIl8BA"

const RECAPTCHA_SDK_URL = "https://www.google.com/recaptcha/api.js"

const RECAPTCHA_SDK_ID = "recaptcha_xx1"

import { SessStorageKey } from "@/constant/cache"

export function useCaptcha({
  container,
  callback,
  theme = "dark"
}: {
  container?: string
  callback?: (...args: any[]) => void
  theme?: GrecaptchaCtxRenderOptions["theme"]
  size?: GrecaptchaCtxRenderOptions["size"]
}) {
  const isReady = ref(false)
  const token = ref("")

  async function setupScript() {
    if (isReady.value) {
      return
    }
    window.onCaptchaOnloadCallback = function () {
      window?.grecaptcha?.render(container, {
        sitekey: RECAPTCHA_SITE_KEY,
        theme,
        callback
      })
    }
    await asyncAppendScript(`${RECAPTCHA_SDK_URL}?onload=onCaptchaOnloadCallback&render=explicit`, {
      async: true,
      defer: true,
      id: RECAPTCHA_SDK_ID
    })
    isReady.value = true
  }

  function getResponse(id?: string): string {
    return window?.grecaptcha?.getResponse?.(id) ?? ""
  }

  function resetCaptcha(id?: string) {
    window?.grecaptcha?.reset?.(id)
  }

  function removeCaptchaDom() {
    document.getElementById(RECAPTCHA_SDK_ID) && document.body.removeChild(document.getElementById(RECAPTCHA_SDK_ID))
  }

  function setTkToSess(tk: string) {
    setLocalStorage(SessStorageKey.CAPTCHA_TOKEN, tk, "sessionStorage")
  }

  function getTkToSess() {
    return getLocalStorage(SessStorageKey.CAPTCHA_TOKEN, "sessionStorage")
  }

  function delTkToSess() {
    return removeLocalStorage(SessStorageKey.CAPTCHA_TOKEN, "sessionStorage")
  }

  return {
    isReady,
    token,
    removeCaptchaDom,
    setupScript,
    getResponse,
    resetCaptcha,
    setTkToSess,
    delTkToSess,
    getTkToSess
  }
}
