import { PointEvent } from "~~/constant/gtag"

export function useInviterGTag() {
  const { $gtag } = useNuxtApp()
  const { setEvent } = $gtag

  const _PointInviterRuleClick = () => setEvent(PointEvent.invitationPageRule, {})

  const _PointInviterClickInvite = (position: string) => {
    setEvent(PointEvent.invitationPageClickInvite, {
      button_position: position
    })
  }

  return {
    _PointInviterRuleClick,
    _PointInviterClickInvite
  }
}
