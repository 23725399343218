import { ITabList, ILinkType } from "@/types/components/pages/profile/tabChange"
import { ISubPaymentStatus } from "@/composables/api/profile"
import { IComboProductByMyAvailableState } from "@/composables/api/combo"
import { useUser } from "~/store/user"

// 我的账单显示方案
export enum ProfileBillingScheme {
  Sub = "Sub",
  Combo = "Combo"
}

// 我的账单显示组件 对应的value为组件名称
export const ProfileBillingSchemeCom = {
  [ProfileBillingScheme.Sub]: `PagesProfileBillingUsageWrapper`,
  [ProfileBillingScheme.Combo]: `PagesProfileBillingUsageWrapperCombo`
}

export enum TabListValue {
  BASIC_INFO = 1,
  MY_BILLING = 2,
  DEVICE = 3,
  NOTIFICATIONS = 4,
  BUSINESS = 5,
  INVITATION = 6,
  SECURITY = 7,
  MY_BILLING_USAGE,
  MY_BILLING_ACTIVE,
  MY_BILLING_INVALID,
  MY_BILLING_INVOICES,
  MY_BILLING_SUBSCRIPTION,
  MY_BILLING_PACKS
}

export enum ChildTabId {
  Billing = "billing",
  Usage = "Usage",
  // Combo
  Active = "Active",
  Invalid = "Invalid",
  Invoices = "Invoices",
  // Sub
  Subscription = "Subscription",
  Packs = "Packs"
}

export const ChildTabListMap: Record<ChildTabId, TabListValue> = {
  [ChildTabId.Billing]: TabListValue.MY_BILLING,
  [ChildTabId.Usage]: TabListValue.MY_BILLING_USAGE,
  [ChildTabId.Active]: TabListValue.MY_BILLING_USAGE,
  [ChildTabId.Invalid]: TabListValue.MY_BILLING_INVALID,
  [ChildTabId.Invoices]: TabListValue.MY_BILLING_INVOICES,
  [ChildTabId.Subscription]: TabListValue.MY_BILLING_SUBSCRIPTION,
  [ChildTabId.Packs]: TabListValue.MY_BILLING_PACKS
}

export const tabList: (...args: any[]) => ITabList = () => {
  const is_open_channel_code = useOpenChannelCode().value

  const resultTabList = [
    {
      label: useTranslateI18n("profile.tabList[0]"),
      src: getCloudAssets("/images/pages/profile/info-icon.png"),
      value: TabListValue.BASIC_INFO,
      type: ILinkType.Normal
    },
    {
      label: useTranslateI18n("profile.tabList[5]"),
      src: getCloudAssets("/images/pages/profile/secure-icon.png"),
      value: TabListValue.SECURITY,
      type: ILinkType.Normal
    },
    {
      label: useTranslateI18n("profile.tabList[1]"),
      src: getCloudAssets("/images/pages/profile/billing-icon.png"),
      value: TabListValue.MY_BILLING,
      type: ILinkType.Normal,
      children: [
        {
          label: useTranslateI18n("profile.billingUsage.billing.title"),
          type: ILinkType.Normal,
          value: TabListValue.MY_BILLING,
          id: ChildTabId.Billing
        },
        {
          label: useTranslateI18n(`profile.userDetails.title`),
          type: ILinkType.Normal,
          value: TabListValue.MY_BILLING_USAGE,
          id: ChildTabId.Usage
        },
        {
          label: useTranslateI18n("profile.billingUsage2.available.title"),
          type: ILinkType.Normal,
          value: TabListValue.MY_BILLING_ACTIVE,
          id: ChildTabId.Active
        },
        {
          label: useTranslateI18n("profile.billingUsage2.available.invalid"),
          type: ILinkType.Normal,
          value: TabListValue.MY_BILLING_INVALID,
          id: ChildTabId.Invalid
        },
        {
          label: useTranslateI18n("profile.billingUsage.invoices.title"),
          type: ILinkType.Normal,
          value: TabListValue.MY_BILLING_INVOICES,
          id: ChildTabId.Invoices
        }
      ]
    },
    {
      label: useTranslateI18n("profile.tabList[2]"),
      src: getCloudAssets("/images/pages/profile/device-icon.png"),
      value: TabListValue.DEVICE,
      type: ILinkType.Normal
    }
  ]

  is_open_channel_code &&
    resultTabList.splice(3, 0, {
      label: useTranslateI18n("profile.tabList[4]"),
      src: getCloudAssets("/images/pages/profile/invitation.png"),
      value: TabListValue.INVITATION,
      type: ILinkType.Normal
    })

  return resultTabList
}

export const BillingSubBtn = (nuxtApp?) => {
  return {
    Subscription: useTranslateI18n("payment.topCard[1].btn[8]", [], nuxtApp),
    UndoCancelation: useTranslateI18n("payment.topCard[1].btn[6]", [], nuxtApp),
    UndoDowngrading: useTranslateI18n("payment.topCard[1].btn[5]", [], nuxtApp),
    UpgradeNow: useTranslateI18n("payment.topCard[1].btn[1]", [], nuxtApp),
    ReActivate: useTranslateI18n("payment.topCard[1].btn[4]", [], nuxtApp),
    BuyCredits: useTranslateI18n("profile.billingUsage2.billing.credits", [], nuxtApp)
  }
}

// invoice 表格状态dict
export const invoicesStatus = () => {
  return {
    [ISubPaymentStatus.Paid]: useTranslateI18n(`profile.billingUsage.invoices.table.paid`),
    [ISubPaymentStatus.Failed]: useTranslateI18n(`profile.billingUsage.invoices.table.failed`),
    [ISubPaymentStatus.Due]: useTranslateI18n(`profile.billingUsage.invoices.table.due`),
    [ISubPaymentStatus.Refunded]: useTranslateI18n(`profile.billingUsage.invoices.table.refunded`)
  }
}

// invoice 表格状态样式
export const invoicesStatusStyle = {
  [ISubPaymentStatus.Paid]: 1,
  [ISubPaymentStatus.Failed]: 2,
  [ISubPaymentStatus.Due]: 3,
  [ISubPaymentStatus.Refunded]: 2
}

// invoice 表格action图标
export const invoicesStatusIcon = {
  [ISubPaymentStatus.Paid]: getCloudAssets("/icons/common/download-icon.png"),
  [ISubPaymentStatus.Failed]: getCloudAssets("/icons/common/file-icon.png"),
  [ISubPaymentStatus.Due]: getCloudAssets("/icons/common/file-icon.png"),
  [ISubPaymentStatus.Refunded]: getCloudAssets("/icons/common/file-icon.png")
}

// 套餐other packages 的状态对应文案

export const ComboOtherPackagesStateObj = () => {
  return {
    [IComboProductByMyAvailableState.REFUNDED]: {
      flag: useTranslateI18n(`profile.billingUsage2.expiredPacks.refundFlag`),
      flagDate: useTranslateI18n(`profile.billingUsage2.expiredPacks.refundDate`)
    },
    [IComboProductByMyAvailableState.CHARGEBACK]: {
      flag: useTranslateI18n(`profile.billingUsage2.expiredPacks.charge`),
      flagDate: useTranslateI18n(`profile.billingUsage2.expiredPacks.chargeDate`)
    },
    [IComboProductByMyAvailableState.REFUNDING]: {
      flag: useTranslateI18n(`profile.billingUsage2.expiredPacks.refundingFlag`),
      flagDate: useTranslateI18n(`profile.billingUsage2.expiredPacks.refundingDate`)
    },
    [IComboProductByMyAvailableState.REFUNDING2]: {
      flag: useTranslateI18n(`profile.billingUsage2.expiredPacks.refundingFlag`),
      flagDate: useTranslateI18n(`profile.billingUsage2.expiredPacks.refundingDate`)
    }
  }
}
