import mixpanelInstance from "@/utils/mixpanel"
import { mpEventName, JoinFacebookSource } from "@/constant/mp"
import {
  PAYMENT_PAGE_PATH,
  PAYMENT_COMBO_ROUTE,
  LANDING_COMBO_PAGE,
  INVITEE_PAGE_PATH,
  PAYMENT_COMBO_PROCEED_ROUTE
} from "@/constant/route"
import {
  HOME,
  COLLABORATE,
  PORTRAIT_RETOUCHING,
  COLOR_AND_TONE,
  BACKGROUND_ADJUSTMENTS,
  EXCLUSIVE_PRESETS,
  BATCH_EDITS,
  START_PAGE_PATH,
  HELP_PAGE_PATH,
  HELP_MORE_PAGE_PATH,
  DOWNLOAD_PAGE_PATH,
  PROFILE_PAGE_PATH,
  COMPANY_PATH,
  LANDING_COMBO_MUL_SPEC,
  PAYMENT_RESULT,
  LANDING_COMBO_MUL_SPEC_RDM,
  LANDING_COMBO_MUL_SPEC_RDM_RESULT
} from "@/constant/route"

export const IPageTitle = () => {
  return {
    [unref(HOME)]: "homePage",
    [unref(COLLABORATE)]: "affiliateEvotoPage",
    [unref(PORTRAIT_RETOUCHING)]: "feature1Page",
    [unref(COLOR_AND_TONE)]: "feature2Page",
    [unref(BACKGROUND_ADJUSTMENTS)]: "feature3Page",
    [unref(EXCLUSIVE_PRESETS)]: "feature4Page",
    [unref(BATCH_EDITS)]: "feature5Page",
    [unref(START_PAGE_PATH)]: "getStartedPage",
    [unref(PAYMENT_COMBO_ROUTE)]: "pricingPage",
    [unref(PAYMENT_COMBO_PROCEED_ROUTE)]: "pricingPage",
    [unref(HELP_PAGE_PATH)]: "helpCenterPage",
    [unref(HELP_MORE_PAGE_PATH)]: "faqPage",
    [unref(DOWNLOAD_PAGE_PATH)]: "downloadPage",
    [unref(PROFILE_PAGE_PATH)]: "profilePage",
    [unref(COMPANY_PATH)]: "aboutUsPage",
    [unref(LANDING_COMBO_MUL_SPEC)]: "Redemption Code Purchase Page",
    [unref(PAYMENT_RESULT)]: "Redemption Code Purchase Completion Page",
    [unref(LANDING_COMBO_MUL_SPEC_RDM)]: "Redemption code redemption page",
    [unref(LANDING_COMBO_MUL_SPEC_RDM_RESULT)]: "Redemption Code Redemption Success Page"
  }
}

export const ILinkButtonName = () => {
  return {
    [unref(PORTRAIT_RETOUCHING)]: "navigationFeature1Button",
    [unref(COLOR_AND_TONE)]: "navigationFeature2Button",
    [unref(BACKGROUND_ADJUSTMENTS)]: "navigationFeature3Button",
    [unref(EXCLUSIVE_PRESETS)]: "navigationFeature4Button",
    [unref(BATCH_EDITS)]: "navigationFeature5Button",
    [unref(START_PAGE_PATH)]: "navigationGetStartedButton",
    [unref(PAYMENT_COMBO_ROUTE)]: "navigationPricingButton",
    [unref(HELP_PAGE_PATH)]: "navigationHelpButton",
    [unref(DOWNLOAD_PAGE_PATH)]: "navigationDownloadButton"
  }
}
const setEvent = mixpanelInstance.setEvent

export enum IMpWebsiteSignInPop {
  evoto_website = "evoto_website",
  evoto_website_pricing = "evoto_website_pricing",
  evoto_purchase_landing = "evoto_purchase_landing",
  evoto_invitee_landing = "evoto_invitee_landing"
}

export const getSignInPopParams = () => {
  const path = useRoute().path
  switch (path) {
    case unref(PAYMENT_PAGE_PATH):
    case unref(PAYMENT_COMBO_ROUTE):
      return IMpWebsiteSignInPop.evoto_website_pricing
    case unref(LANDING_COMBO_PAGE):
      return IMpWebsiteSignInPop.evoto_purchase_landing
    case unref(INVITEE_PAGE_PATH):
      return IMpWebsiteSignInPop.evoto_invitee_landing
    default:
      return IMpWebsiteSignInPop.evoto_website
  }
}

export interface IMpWebsiteSignIn {
  type: "emailSignIn" | "googleSignIn" | "facebookSignIn"
  source: IMpWebsiteSignInPop
}

interface IMpWebsiteSignUp {
  type: "createAccount" | "googleSignUp" | "facebookSignUp"
  source: IMpWebsiteSignInPop
}

type JoinFacebookParams = {
  endPoint: "device" | "OS"
  source: JoinFacebookSource
}

export interface ICommonProperty {
  path?: any
  pageTitle?: any
  featureName?: any
  featureModule?: any
  buttonName?: any
}

export const useMpCommonPageTitle = () => {
  const { path, query } = useRoute()
  let pageTitle = undefined
  if (IPageTitle()[path]) {
    if (path === unref(PAYMENT_RESULT)) {
      pageTitle = query.redeem_code ? IPageTitle()[path] : undefined
    } else {
      pageTitle = IPageTitle()[path] || undefined
    }
  }
  return pageTitle
}

export const useMpCommonPath = () => {
  const { path } = useRoute()
  return path === unref(HOME) ? `/${IPageTitle()[path]}` : path
}

export function useCommonMp() {
  const _pointMpCommonPageView = (path: string, query: any) => {
    setEvent(
      mpEventName.CommonPageView,
      {},
      {
        path: useMpCommonPath(),
        pageTitle: useMpCommonPageTitle(),
        featureName: "viewOfficialPage",
        featureModule: "pageView"
      }
    )
  }

  const _pointMpWebsiteSignInPop = () => {
    setEvent(
      mpEventName.EvotoWebsiteSignInPop,
      {},
      {
        pageTitle: "officialSignInPage",
        featureName: "viewOfficialSigninPage",
        featureModule: "officialSignIn"
      }
    )
  }

  const _pointMpWebsiteSignIn = (params: IMpWebsiteSignIn) => {
    setEvent(mpEventName.EvotoWebsiteSignIn, params)
  }

  const _pointMpWebsiteSignUpPop = () => {
    setEvent(
      mpEventName.EvotoWebsiteSignupPop,
      {},
      {
        pageTitle: "officialSignUpPage",
        featureName: "viewOfficialSignUpPage",
        featureModule: "officialSignUp"
      }
    )
  }

  const _pointMpWebsiteSignUp = (params: IMpWebsiteSignUp) => {
    setEvent(mpEventName.EvotoWebsiteSignup, params)
  }

  const _pointMpWebsiteJoinFb = (params: JoinFacebookParams) => {
    setEvent(mpEventName.EvotoWebsiteJoinFacebook, params)
  }

  const _pointOfficialPageButtonClick = (params: ICommonProperty & { buttonName?: any }) => {
    const path = useRoute().path
    setEvent(
      mpEventName.evoto_official_page_button,
      {},
      { ...params, path: path === unref(HOME) ? `/${IPageTitle()[path]}` : path, pageTitle: useMpCommonPageTitle() }
    )
  }

  const _pointOfficialGroupButtonClick = (params: ICommonProperty & { buttonName?: any }) => {
    setEvent(
      mpEventName.website_officialGroupJoinButtonClick,
      {},
      {
        ...params,
        featureName: "clickJoinNowButton",
        featureModule: "joinGroupButtonClick"
      }
    )
  }

  const _pointOfficialContactSalesSumbitButtonClick = (params: ICommonProperty & { preferredPlatform: any }) => {
    setEvent(
      mpEventName.website_contactSalesSubmitButtonClick,
      {},
      {
        pageTitle: "contactSalesPage",
        featureName: `contactSalesSubmitButton`,
        featureModule: `officialPricing`,
        ...params
      }
    )
  }

  const _pointOfficialSignInButtonClick = (params: ICommonProperty & { subPageTitle }) => {
    setEvent(
      mpEventName.website_officialSignInButtonClick,
      {},
      { ...params, pageTitle: "signInPage", featureModule: "officialSignIn", featureName: "clickSignInPageButton" }
    )
  }

  const _pointOfficialSignUpButtonClick = (
    params: ICommonProperty & { emailEnable?: boolean; agreeTerms?: boolean }
  ) => {
    setEvent(
      mpEventName.website_officialSignUpButtonClick,
      {},
      { ...params, pageTitle: "signUpPage", featureModule: "officialSignUp", featureName: "clickSignUpPageButton" }
    )
  }

  const _pointOfficialSignInResult = (
    params: ICommonProperty & { method?: string; failReason?: string; isSuccess?: boolean }
  ) => {
    setEvent(
      mpEventName.website_officialSignInResult,
      {},
      { ...params, pageTitle: "signInPage", featureModule: "officialSignIn", featureName: "signInResult" }
    )
  }

  const _pointOfficialH5SignInResult = (
    params: ICommonProperty & { method?: string; failReason?: string; isSuccess?: boolean }
  ) => {
    setEvent(
      mpEventName.h5_signInResult,
      {},
      { ...params, pageTitle: "signInPage", featureModule: "h5SignIn", featureName: "signInResult" }
    )
  }

  const _pointOfficialSignUpResult = (
    params: ICommonProperty & { method?: string; failReason?: string; isSuccess?: boolean }
  ) => {
    setEvent(
      mpEventName.website_officialSignUpResult,
      {},
      { ...params, pageTitle: "signUpPage", featureModule: "officialSignUp", featureName: "signUpResult" }
    )
  }

  const _pointOfficialH5SignUpResult = (
    params: ICommonProperty & { method?: string; failReason?: string; isSuccess?: boolean }
  ) => {
    setEvent(
      mpEventName.h5_signUpResult,
      {},
      { ...params, pageTitle: "signUpPage", featureModule: "h5SignUp", featureName: "signUpResult" }
    )
  }

  const _pointLoginAndRegFlowPageView = (params: ICommonProperty) => {
    setEvent(mpEventName.website_page_view, {}, { ...params, featureModule: "login_registration" })
  }

  const _pointLoginAndRegFlowPageButtonView = (
    params: ICommonProperty & { elementName: string; eventType?: string }
  ) => {
    setEvent(mpEventName.website_button_click, {}, { ...params, featureModule: "login_registration" })
  }

  const _pointWebsitePopupView = (params: ICommonProperty) => {
    setEvent(mpEventName.website_popup_view, {}, { featureModule: "account_setting", ...params })
  }

  return {
    _pointMpCommonPageView,
    _pointMpWebsiteSignInPop,
    _pointMpWebsiteSignIn,
    _pointMpWebsiteSignUpPop,
    _pointMpWebsiteSignUp,
    _pointMpWebsiteJoinFb,
    _pointOfficialPageButtonClick,
    _pointOfficialSignInButtonClick,
    _pointOfficialSignUpButtonClick,
    _pointOfficialSignInResult,
    _pointOfficialH5SignInResult,
    _pointOfficialSignUpResult,
    _pointOfficialH5SignUpResult,
    _pointOfficialGroupButtonClick,
    _pointOfficialContactSalesSumbitButtonClick,
    _pointLoginAndRegFlowPageView,
    _pointLoginAndRegFlowPageButtonView,
    _pointWebsitePopupView
  }
}
