import {
  LANDING_PAGE_PATH,
  INVITEE_PAGE_PATH,
  UN_STATUS_LOGIN_ROUTE,
  LANDING_COMBO_PAGE,
  LANDING_SIMPLE_PAGE,
  LANDING_KOL_PAGE,
  LANDING_D,
  LANDING_KOL_S_PAGE,
  LADING_JP,
  LANDING_COMBO_BF,
  LANDING_COMBO_BF_GIFT,
  LANDING_US,
  LANDING_S_US,
  LANDING_COMBO_MUL,
  PAYMENT_RESULT,
  LANDING_COMBO_MUL_SPEC_RDM,
  LANDING_COMBO_MUL_SPEC_RDM_RESULT,
  LANDING_COMBO_MUL_SPEC,
  LANDING_PAGE_MS,
  LANDING_COMBO_MUL_XS,
  LANDING_COMBO_MUL_LN,
  LANDING_K_S_LN,
  LANDING_COMBO_MUL_T_XS,
  LANDING_PROMOTION,
  LANDING_GENERIC
} from "./route"

export enum PopupStep {
  One,
  Two,
  Three
}

export enum CookiesKey {
  LANGUAGE_1 = "language_1",
  IM_ID = "_im_id",
  LANGUAGE = "language",
  TW_1 = "guest_id",
  TW_2 = "guest_id_ads",
  TW_3 = "guest_id_marketing",
  TT_AU = "_ttp",
  TOKEN_KEY = "Token_Key",
  GA = "_ga",
  BetaGA = "_ga_6TDNVTNSE",
  ReleaseGA = "_ga_TG6QQ3BB5S",
  GTAG_AU = "_gcl_au",
  FBP_AU = "_fbp",
  TR_LOGIN_M = "_tr_login_m", // 三方登录需要携带的参数 uuid fullpath 来源 fb 或 gg
  TEMP_INVITEE_UUID = "t_i_u", //邀请活动  -》 邀请人的uuid -》  使用后删除
  LANDING_UUID = "_l_u_id", // 落地页记录uuid
  PAYMENT_URL_FLAG = "_p_c_j", // 判断没登录的情况下去哪个购买页面
  SOURCE_SID = "_s_sId", // 记录 来源sId
  SOURCE_UUID = "_s_uuid" // 记录来源 uuid
}

export enum CookiesOptions {
  Essential = "Essential",
  Analytics = "Analytics",
  Marketing = "Marketing"
}

export enum CookiesAcceptType {
  All = "All",
  Customize = "Customize"
}

export const COOKIES_SEPARATOR = ", "

export const cookiesOptions = [
  {
    label: "common.cookies.options[0].label",
    require: true,
    disabled: true,
    tip: "common.cookies.options[0].tip",
    key: CookiesOptions.Essential.toLocaleLowerCase(),
    description: "common.cookies.options[0].description"
  },
  {
    label: "common.cookies.options[1].label",
    require: false,
    key: CookiesOptions.Analytics.toLocaleLowerCase(),
    description: "common.cookies.options[1].description"
  },
  {
    label: "common.cookies.options[2].label",
    require: false,
    key: CookiesOptions.Marketing.toLocaleLowerCase(),
    description: "common.cookies.options[2].description"
  }
]

export function getTokenKey() {
  let tokenKey = CookiesKey.TOKEN_KEY as string
  if (process.env.NUXT_APP_ENV !== "production") {
    tokenKey += `_${process.env.NUXT_APP_ENV}`
  }
  return tokenKey
}

export const TOKEN_KEY = getTokenKey()

// 登录相关必须存在
export const ESSENTIAL_KEYS = [
  CookiesKey.TR_LOGIN_M,
  TOKEN_KEY,
  CookiesKey.LANGUAGE,
  CookiesKey.SOURCE_SID,
  CookiesKey.SOURCE_UUID
]

export const cookiesKey = {
  [CookiesOptions.Essential]: ESSENTIAL_KEYS,
  [CookiesOptions.Analytics]: [CookiesKey.GA, CookiesKey.BetaGA, CookiesKey.ReleaseGA],
  [CookiesOptions.Marketing]: [
    CookiesKey.GTAG_AU,
    CookiesKey.FBP_AU,
    CookiesKey.TT_AU,
    CookiesKey.TW_1,
    CookiesKey.TW_2,
    CookiesKey.TW_3
  ]
}

// Gtag白名单 名单内不需要通过cookies逻辑直接埋点
export const gTagPageWhiteList = [
  LANDING_COMBO_MUL_SPEC_RDM_RESULT,
  LANDING_COMBO_MUL_SPEC_RDM,
  LANDING_COMBO_MUL_SPEC,
  PAYMENT_RESULT,
  LANDING_PAGE_PATH,
  INVITEE_PAGE_PATH,
  ...UN_STATUS_LOGIN_ROUTE,
  LANDING_COMBO_PAGE,
  LANDING_SIMPLE_PAGE,
  LANDING_KOL_PAGE,
  LANDING_KOL_S_PAGE,
  LANDING_D,
  LADING_JP,
  LANDING_COMBO_BF,
  LANDING_COMBO_BF_GIFT,
  LANDING_US,
  LANDING_S_US,
  LANDING_COMBO_MUL,
  LANDING_PAGE_MS,
  LANDING_COMBO_MUL_XS,
  LANDING_COMBO_MUL_LN,
  LANDING_K_S_LN,
  LANDING_COMBO_MUL_T_XS,
  LANDING_PROMOTION,
  LANDING_GENERIC
]
