import { LocalStorageKey } from "~~/constant/cache"
import { CookiesOptions, gTagPageWhiteList } from "~~/constant/cookies"

class TTPoint {
  static instance: TTPoint = null
  static getInstance(): TTPoint {
    this.instance = this.instance ? this.instance : new TTPoint()
    return this.instance
  }
  setEvent(name: string, params: any) {
    if (process.client) {
      const isEnabled = useIsPoint(CookiesOptions.Marketing)
      if (!isEnabled) {
        return
      }
      // 判断是否有唯一id，用完删除
      const event_id = getLocalStorage(LocalStorageKey.SERVER_PAYMENT_EVENT_ID)
      window.ttq?.track(name, params, event_id ? { event_id } : undefined)
    }
  }
}

export default TTPoint.getInstance()
