import { StorageSerializers, timestamp } from "@vueuse/core"
import { SessStorageKey } from "~~/constant/cache"
import { useTimer } from "./utils/timer"

export type UseCountdownOptions = {
  interval?: number
  timeout?: number
  sId?: number | string
}

export function useCountdown(opts: UseCountdownOptions = {}) {
  const { interval = 60, timeout = 1000, sId = 1 } = opts
  const timer = useTimer()
  const isTiming = ref(false)
  const timestamp = ref(getLocalStorage(`${SessStorageKey.COUNTDOWN_TIMESTAMP}_${sId}`) || 0)
  const count = ref(0)

  const TIMER_ID = Symbol("countdown")

  watch(
    timestamp,
    (v) => {
      if (v) {
        count.value = getCount()
        if (count.value < interval && count.value) {
          timekeeping(false)
        }
      }
      isTiming.value = v ? getIsTiming(v).isTiming : false
    },
    { immediate: true }
  )

  function getCount() {
    const { isTiming, duration } = getIsTiming(timestamp.value)
    return isTiming && duration <= interval ? interval - duration : interval
  }

  function getIsTiming(time: number) {
    const now = Date.now()
    const formatNow = format(now)
    const duration = formatNow - time
    return {
      isTiming: duration <= interval && duration >= 0,
      duration
    }
  }

  function timekeeping(init = true) {
    if (init) {
      setTimeStamp()
    }
    if (!timer.has(TIMER_ID)) {
      timer.set({
        id: TIMER_ID,
        handler({ _timer, _id }) {
          count.value -= timeout / 1000
          if (!count.value) {
            _timer.delete(_id)
            isTiming.value = false
          }
        },
        timeout
      })
    }
  }

  function setTimeStamp() {
    const now = Date.now()
    setLocalStorage(`${SessStorageKey.COUNTDOWN_TIMESTAMP}_${sId}`, format(now))
    timestamp.value = format(now)
  }

  function format(timestamp: number) {
    return Math.round(timestamp / 1000)
  }

  return {
    count,
    isTiming,

    timekeeping
  }
}

// 倒计时 无缓存
export function useNoStorageCountDown(count = 10, timecount = 1, callback?: (...args: any[]) => void) {
  const c = ref(count)
  const timeout = 1000
  const timer = useTimer()
  const isFinish = ref(false)
  const TIMER_ID = Symbol("no_countdown")
  if (!timer.has(TIMER_ID)) {
    timer.set({
      id: TIMER_ID,
      handler({ _timer, _id }) {
        c.value = count -= timecount
        if (!c.value) {
          _timer.delete(_id)
          isFinish.value = true
          callback && callback()
        }
      },
      timeout
    })
  }
  return {
    c,
    isFinish
  }
}
