<template>
  <div class="signup-with-psd-wrapper">
    <span v-if="title" class="t-1">{{ title }}</span>
    <CommonForm
      ref="formRef"
      :form-input-list="formInputList"
      :form-inline="false"
      :form-rules="formRules"
      form-label-position="top"
      :form-model="formModel"
      @validate="signupSubmitHandler"
    />

    <CommonButton class="btn1" :button-style="buttonStyle1" @btn-click="signUpHandler">
      <CommonAnimationScrollText>{{ useTranslateI18n("login.email.done") }}</CommonAnimationScrollText>
    </CommonButton>

    <CommonButton v-if="skip" class="btn2" :button-style="buttonStyle2" @btn-click="skipHandler"
      ><CommonAnimationScrollText>
        {{ useTranslateI18n("login.newLogin.t10") }}</CommonAnimationScrollText
      ></CommonButton
    >
  </div>
</template>

<script setup lang="tsx">
import { IFormPropsInputList } from "@/types/components/common/form"
import { FormRules } from "element-plus"
import EyeIcon from "@/assets/icons/common/eyes-icon.png"
import EyeIcon1 from "@/assets/icons/common/eyes-icon-1.png"

const props = defineProps({
  title: {
    type: String,
    default: () => useTranslateI18n("login.newLogin.t9")
  },
  point: {
    type: String,
    default: ""
  },
  skip: {
    type: Boolean,
    default: true
  }
})

const emits = defineEmits(["skip", "submit"])

const { _pointLoginAndRegFlowPageButtonView } = useCommonMp()

const formRef = ref()

let formModel = reactive({
  password: "",
  password2: ""
})

let passwordTooltipStyle = reactive({
  marginTop: "8px"
})

let formInputList = ref<IFormPropsInputList<typeof formModel>>([
  {
    type: "input",
    model: "password",
    label: useTranslateI18n("login.label.psd"),
    itype: "password",
    clearable: true,
    slots: true,
    slotsFormatter() {
      let desc = useTranslateI18n("login.signUp.psdTip") + `@#$%^&*-_+=[]{}|\\:',?/\`~"();!.<>`
      return (
        <div class="password-tooltip" style={passwordTooltipStyle}>
          {desc}
        </div>
      )
    },
    suffixSlot() {
      return (
        <div class="eyes-wrapper">
          <span class="eyes-wrapper-text"></span>
          <img
            src={formInputList.value[0].itype ? EyeIcon : EyeIcon1}
            class="eyes-icon"
            onClick={() => {
              eyesClick(0)
            }}
          />
        </div>
      )
    }
  },
  {
    type: "input",
    model: "password2",
    label: useTranslateI18n("login.label.rPsd"),
    itype: "password",
    enterClick: true,
    enterClickFn: useDebounceFn(() => {
      signUpHandler()
    }, 300),
    clearable: true,
    suffixSlot() {
      return (
        <div class="eyes-wrapper">
          <span class="eyes-wrapper-text"></span>
          <img
            src={formInputList.value[1].itype ? EyeIcon : EyeIcon1}
            class="eyes-icon"
            onClick={() => {
              eyesClick(1)
            }}
          />
        </div>
      )
    }
  }
])

// 眼睛点击
const eyesClick = (index) => {
  formInputList.value[index].itype = formInputList.value[index].itype ? "" : "password"
}

const validatorPassword = (rule: any, value: any, callback: any) => {
  passwordTooltipStyle.marginTop = "25px"
  if (value.length < 8 || value.length > 24) {
    return callback(new Error(useTranslateI18n("login.signUp.errArr[2]")))
  } else if (/^[0-9]+$/.test(value)) {
    return callback(new Error(useTranslateI18n("login.signUp.errArr[0]")))
  } else if (/^[A-Za-z]+$/.test(value)) {
    return callback(new Error(useTranslateI18n("login.signUp.errArr[1]")))
  } else if (/[\u4e00-\u9fa5]+/.test(value) || /[ぁ-んァ-ヶ]+/.test(value) || /\s+/.test(value)) {
    return callback(new Error(useTranslateI18n("login.signUp.errArr[3]")))
  } else {
    passwordTooltipStyle.marginTop = "8px"
    callback()
  }
}

const validatorPassword2 = (rule: any, value: any, callback: any) => {
  if (value !== formModel.password) {
    callback(new Error(useTranslateI18n("login.signUp.errPsdRepeat")))
  } else {
    callback()
  }
}

let formRules = reactive<FormRules>({
  password: [
    {
      validator: validatorPassword,
      trigger: "blur"
    }
  ],
  password2: [
    {
      validator: validatorPassword2,
      trigger: "blur"
    }
  ]
})

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Regular"
})

const buttonStyle2 = reactive({
  width: "100%",
  background: "rgba(0, 0, 0, 0.05)",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Regular"
})

const signUpHandler = useDebounceFn(() => {
  props.point &&
    _pointLoginAndRegFlowPageButtonView({
      featureName: props.point,
      elementName: "login",
      pageTitle: "set_password"
    })
  formRef.value && formRef.value.validate()
}, 300)

const skipHandler = () => {
  props.point &&
    _pointLoginAndRegFlowPageButtonView({
      featureName: props.point,
      elementName: "skip",
      pageTitle: "set_password"
    })
  emits("skip")
}

const signupSubmitHandler = async (v) => {
  if (v) {
    if (props.point === "register") {
      await postRegPsdApi({
        password: useXorEncrypt(formModel.password)
      })
    }
    emits("submit", formModel)
  }
}
</script>

<style scoped lang="scss">
.signup-with-psd-wrapper {
  display: flex;
  flex-direction: column;
  .t-1 {
    font-size: 24px;
    line-height: normal;
    @include fontSemibold;
    color: #000;
    margin-bottom: 48px;
  }

  :deep(.pix-common-search) {
    .password-extra-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #4f5458;
      margin-bottom: 16px;
      margin-top: 8px;
    }
    // .el-form-item__content {
    //   line-height: 36px;
    // }
    .el-form-item {
      &.is-error {
        .el-input__wrapper {
          &.is-focus {
            box-shadow: none !important;
          }
        }
      }
      .el-form-item__error {
        padding-top: 8px;
      }
      label {
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
        color: rgba(0, 0, 0, 0.55);
        margin-bottom: 8px;
      }
      .el-input__wrapper {
        padding-left: 12px;
        padding-right: 12px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        box-shadow: none;
        .el-input__inner {
          height: 34px;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.85);
        }
      }

      .el-input__suffix {
        .el-input__suffix-inner {
          flex-direction: row-reverse;
        }

        .eyes-wrapper {
          display: flex;
          align-items: center;
          .eyes-wrapper-text {
            width: 1px;
            height: 16px;
            background: rgba(0, 0, 0, 0.3);
            margin-right: 8px;
          }
          .eyes-icon {
            width: 16px;
            cursor: pointer;
          }
        }
        .el-icon {
          margin-left: 0;
          margin-right: 8px;
          color: rgba(0, 0, 0, 0.55);
        }
      }
      .el-input__suffix-inner > :first-child {
        margin-left: 0;
      }
    }
    .el-form-item:nth-child(1) {
      margin-bottom: 0;
    }
    .password-tooltip {
      margin-top: 8px;
      margin-bottom: 16px;
      font-weight: 400;
      line-height: normal;
      color: rgba(0, 0, 0, 0.55);
      @include ease-in-out;
    }
  }

  .btn1 {
    margin-top: 40px;
  }
  .btn2 {
    margin-top: 12px;
  }
}
</style>
