import gtmInstance from "@/utils/gtm"
import { gtmEventName } from "@/constant/gtm"
const setEvent = gtmInstance.setEvent

export function usePaymentGtm() {
  // fb定价支付成功埋点 该事件是fb的标准事件（内置事件）
  const _PointGtmPaymentSuccess = (value, type) => {
    setEvent(gtmEventName.Ffpurchase, { currencyCode: type, conversionValue: value })
  }
  return {
    _PointGtmPaymentSuccess
  }
}
