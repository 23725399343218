import { IUserMailSubType, IBasicInfoScope } from "@/composables/api/common"
import { useUser } from "@/store/user"
import { IPostOffReason, IReceiptInfo } from "@/composables/api/profile"
import { HOME } from "@/constant/route"
import { LOGIN_SCENE, IErrorLoginType, VERIFY_TYPE } from "@/constant/login"
import { codeLength } from "@/constant/vercode"
import { SessStorageKey } from "~~/constant/cache"
import { VerifyLogInfo } from "~/constant/pages/dialog/securityLoginDialog"

export const useEmailNotifyDialog = () => {
  const use_user = useUser()

  function setInfo(data, redirect = unref(HOME)) {
    use_user.setUserInfo({
      info: data.info.user_info,
      token: data.token
    })
    location.href = redirect
  }

  // 显示成功的弹窗
  const showSuccessDialog = (options?: {
    submitCallback: ({
      closeLoading,
      closeDialog
    }: {
      closeLoading: (...args: any[]) => any
      closeDialog?: (...args: any[]) => any
    }) => any
  }) => {
    useCommonNormalDialog({
      headTitle: useTranslateI18n(`profile.basicInformation.emailNotify.dialog1.t1`),
      contentDesc: useTranslateI18n(`profile.basicInformation.emailNotify.dialog1.t2`),
      submitBtnText: useTranslateI18n(`profile.basicInformation.emailNotify.dialog1.t3`),
      submitCallback({ closeLoading, closeDialog }) {
        closeDialog()
      }
    })
  }

  // 显示全部退订？弹窗
  const showUnSubscribeAllDialog = (options?: {
    submitCallback?: ({
      closeLoading,
      closeDialog
    }: {
      closeLoading: (...args: any[]) => any
      closeDialog?: (...args: any[]) => any
    }) => any
    cancelCallback?: ({
      closeLoading,
      closeDialog
    }: {
      closeLoading: (...args: any[]) => any
      closeDialog?: (...args: any[]) => any
    }) => any
  }) => {
    useCommonNormalDialog({
      headTitle: useTranslateI18n(`profile.basicInformation.emailNotify.dialog2.t1`),
      contentDesc: useTranslateI18n(`profile.basicInformation.emailNotify.dialog2.t2`),
      submitBtnText: useTranslateI18n(`profile.basicInformation.emailNotify.dialog2.t3`),
      async submitCallback({ closeLoading, closeDialog }) {
        try {
          closeDialog()
          await use_user.setAllUserInfo()
          showCheckboxDialog({
            checkboxValue: use_user.mail_sub_type
          })
        } finally {
          closeLoading()
        }
      },
      cancelBtnText: useTranslateI18n(`profile.basicInformation.emailNotify.dialog2.t4`),
      async cancelCallback({ closeLoading, closeDialog }) {
        try {
          closeDialog()
          await postUpdateMailSub([])
          showSuccessDialog()
        } finally {
          closeLoading()
        }
      }
    })
  }

  // 显示checkbox选择弹窗
  const showCheckboxDialog = (options?: {
    submitCallback?: ({
      closeLoading,
      closeDialog
    }: {
      closeLoading: (...args: any[]) => any
      closeDialog?: (...args: any[]) => any
    }) => any
    cancelCallback?: ({
      closeLoading,
      closeDialog
    }: {
      closeLoading: (...args: any[]) => any
      closeDialog?: (...args: any[]) => any
    }) => any
    checkboxValue: IUserMailSubType[]
  }) => {
    const checkboxRef = ref()
    const value = ref(options.checkboxValue || [])
    const checkboxOptions = ref(
      Object.values(IUserMailSubType)
        .filter(Number)
        .map((_, index) => {
          return {
            value: _,
            title: useTranslateI18n(`profile.basicInformation.emailNotify.dialog3.list[${index}].t1`),
            desc: useTranslateI18n(`profile.basicInformation.emailNotify.dialog3.list[${index}].d1`)
          }
        })
    )
    useCommonNormalDialog({
      headTitle: useTranslateI18n(`profile.basicInformation.emailNotify.dialog3.t1`),
      headDesc: useTranslateI18n(`profile.basicInformation.emailNotify.dialog3.t2`),
      submitBtnText: useTranslateI18n(`profile.basicInformation.emailNotify.dialog3.t3`),
      async submitCallback({ closeLoading, closeDialog }) {
        const mail_sub_type = checkboxRef.value.getCheckboxValue()
        // 显示取消所有弹窗
        if (mail_sub_type.length === 0) {
          closeDialog()
          return showUnSubscribeAllDialog()
        } else {
          try {
            closeDialog()
            await postUpdateMailSub(mail_sub_type)
            showSuccessDialog()
          } finally {
            closeLoading()
          }
        }
      },
      contentSlots() {
        const checkboxCom = resolveComponent("PagesProfileCheckBoxGroup")
        return <checkboxCom value={value} ref={checkboxRef} checkboxOptions={checkboxOptions} />
      },
      cancelBtnText: useTranslateI18n(`profile.basicInformation.emailNotify.dialog3.t4`),
      cancelCallback({ closeLoading, closeDialog }) {
        closeDialog()
        return showUnSubscribeAllDialog()
      }
    })
  }

  return {
    showCheckboxDialog,
    showSuccessDialog,
    showUnSubscribeAllDialog
  }
}

// 删除用户流程弹窗
export const useDeleteAccountNotifyDialog = () => {
  const use_user = useUser()

  const accountResultCode = ref()

  const checkboxRef = ref()

  const checkboxValue = ref([])

  const textareaValue = ref("")

  const agreeCheckboxRef = ref()

  const agreeCheckboxValue = ref([])

  const assetCheckboxValue = ref([])

  const assetCheckboxRef = ref()

  let logOffNowToken = ""

  let assetConfirmFlag = false

  let account

  let deleteType

  // 显示发送验证码弹窗
  const showDeleteEmailDialog = (value: VERIFY_TYPE) => {
    deleteType = value
    const btnDisabled = reactive({
      submit: false,
      cancel: false
    })
    const deleteAccountRef = ref()
    const email = ref()
    let phone = reactive<{ countryValue?: string; inputValue?: string }>({})
    if (value === VERIFY_TYPE.email) {
      email.value = use_user.userEmail
      account = email.value
    } else if (value === VERIFY_TYPE.phone) {
      phone = {
        countryValue: use_user.userInfo.phone_cc,
        inputValue: use_user.userInfo.phone
      }
      account = `+${phone.countryValue} ${phone.inputValue}`
    }
    useCommonNormalDialog({
      headTitle: useTranslateI18n(`profile.basicInformation.logout.b1`),
      contentSlots(options) {
        const com = resolveComponent("PagesProfileAccountEmail")
        return (
          <com
            email={email.value}
            phone={phone}
            ref={deleteAccountRef}
            onCodeChange={(code) => {
              btnDisabled.submit = code.length !== codeLength
            }}
            success={() => {
              accountResultCode.value = deleteAccountRef.value.getCode()
              options.close && options.close()
              showReasonDialog()
            }}
          />
        )
      },
      btnDisabled,
      submitBtnText: useTranslateI18n(`profile.basicInformation.logout.t5`),
      cancelBtnText: useTranslateI18n(`profile.basicInformation.logout.t6`),
      cancelCallback({ closeLoading, closeDialog }) {
        closeDialog()
      },
      async submitCallback({ closeLoading, closeDialog }) {
        if (deleteAccountRef.value) {
          try {
            await deleteAccountRef.value.submit()
          } finally {
            closeLoading()
          }
        }
      }
    })
  }

  // 显示reason选择
  const showReasonDialog = () => {
    const btnDisabled = reactive({
      submit: false,
      cancel: false
    })
    const checkboxOptions = ref(
      Object.values(IPostOffReason)
        .filter(Number)
        .map((_, index) => {
          return {
            value: _,
            title: useTranslateI18n(`profile.basicInformation.logout.t8List[${index}]`)
          }
        })
    )
    useCommonNormalDialog({
      headTitle: useTranslateI18n(`profile.basicInformation.logout.t7`),
      headDesc: useTranslateI18n(`profile.basicInformation.logout.t4`, [account]),
      submitBtnText: useTranslateI18n(`profile.basicInformation.logout.t5`),
      contentSlots({ close }) {
        const checkboxCom = resolveComponent("PagesProfileCheckBoxGroup")
        return (
          <div class="profile-basic__delete_account_reason">
            <div class="profile-basic__delete_account_reason_text">
              {useTranslateI18n(`profile.basicInformation.logout.t8`)}
            </div>
            <checkboxCom
              value={checkboxValue}
              ref={checkboxRef}
              checkboxOptions={checkboxOptions}
              textareaValue={textareaValue}
              textareaConfig={{
                showWordLimit: true,
                maxlength: 1000,
                autosize: {
                  minRows: 3
                }
              }}
              onCheckboxChange={(val) => {
                btnDisabled.submit = !val.length
                checkboxValue.value = val
                setTimeout(() => {
                  const showTextarea = val.includes(IPostOffReason.Type6)
                  checkboxRef.value?.setTextareaShow(showTextarea)
                })
              }}
            />
          </div>
        )
      },
      cancelBtnText: useTranslateI18n(`profile.basicInformation.logout.t6`),
      btnDisabled: btnDisabled,
      cancelCallback({ closeLoading, closeDialog }) {
        closeDialog()
        showDeleteEmailDialog(deleteType)
      },
      async submitCallback({ closeLoading, closeDialog }) {
        checkboxValue.value = checkboxRef.value.getCheckboxValue()
        textareaValue.value = checkboxRef.value.getTextareaValue()
        showAgreeDialog()
        closeDialog()
      }
    })
  }

  // 同意协议页面
  const showAgreeDialog = async () => {
    let policyDom = null
    const btnDisabled = reactive({
      submit: false,
      cancel: false
    })
    const checkboxOptions = ref([
      {
        value: 1,
        title: useTranslateI18n(`profile.basicInformation.logout.t9`, [
          { type: "static", class: "basic__delete_account_agree__policy" }
        ])
      }
    ])
    useCommonNormalDialog({
      headTitle: useTranslateI18n(`profile.basicInformation.logout.b1`),
      headDesc: useTranslateI18n(`profile.basicInformation.logout.t4`, [account]),
      submitBtnText: useTranslateI18n(`profile.basicInformation.logout.t10`),
      btnDisabled,
      contentSlots({ close }) {
        const checkboxCom = resolveComponent("PagesProfileCheckBoxGroup")
        return (
          <div class="profile-basic__delete_account_agree">
            <ul class="profile-basic__delete_account_agree_list">
              {new Array(4).fill(undefined).map((_, index) => {
                return (
                  <li class="profile-basic__delete_account_agree_list_item" key={index}>
                    <span class="profile-basic__delete_account_agree_list_item_flag"></span>
                    <span class="profile-basic__delete_account_agree_list_item_t">
                      {useTranslateI18n(`profile.basicInformation.logout.agreeList[${index}]`)}
                    </span>
                  </li>
                )
              })}
            </ul>
            <checkboxCom
              value={agreeCheckboxValue}
              ref={agreeCheckboxRef}
              checkboxOptions={checkboxOptions}
              onCheckboxChange={(val) => {
                btnDisabled.submit = !val.length
                agreeCheckboxValue.value = val
              }}
            />
          </div>
        )
      },
      cancelBtnText: useTranslateI18n(`profile.basicInformation.logout.t6`),
      cancelCallback({ closeLoading, closeDialog }) {
        policyDom && policyDom()
        showReasonDialog()
        closeDialog()
      },
      async submitCallback({ closeLoading, closeDialog }) {
        try {
          policyDom && policyDom()
          // 同意的话判断三个条件 1、张数大于0 2、有订阅 3、是商户管理员
          await use_user.setAllUserInfo()
          if (
            use_user.subscription_info.total_credits > 0 ||
            use_user.subscription_info.is_subscribed ||
            use_user.isBusinessUser
          ) {
            showAssetDialog()
          } else {
            showConfirmDelDialog()
          }
          closeDialog()
        } catch (e) {
          closeLoading()
        }
        // closeDialog()
      }
    })
    setTimeout(() => {
      const { getTerms } = useGetPolicy()
      const dom = document.querySelector(".basic__delete_account_agree__policy")
      policyDom = useEventListener(dom, "click", () => {
        getTerms()
      })
    }, 400)
  }

  // 资产确认页面
  const showAssetDialog = async () => {
    const btnDisabled = reactive({
      submit: true,
      cancel: false
    })
    const credits = use_user.subscription_info.total_credits - use_user.subscription_info.used_credits
    const { isTiming, timekeeping, count } = useCountdown({ interval: 10, sId: "profile-basic__delete_account_asset" })
    const submitBtnText = ref(
      !assetConfirmFlag
        ? useTranslateI18n(`profile.basicInformation.logout.t17`, [count.value])
        : useTranslateI18n(`profile.basicInformation.logout.t10`)
    )
    watch(
      () => count.value,
      (newValue) => {
        btnDisabled.submit = !!newValue
        if (assetConfirmFlag) {
          btnDisabled.submit = false
          submitBtnText.value = useTranslateI18n(`profile.basicInformation.logout.t10`)
          return
        }
        submitBtnText.value =
          newValue && !assetConfirmFlag
            ? useTranslateI18n(`profile.basicInformation.logout.t17`, [newValue])
            : useTranslateI18n(`profile.basicInformation.logout.t10`)
        if (!newValue) {
          assetConfirmFlag = true
        }
      }
    )
    // 只倒数一次
    setTimeout(() => {
      !isTiming.value && !assetConfirmFlag && timekeeping()
    })
    const checkboxOptions = ref([
      {
        value: 1,
        title: useTranslateI18n(`profile.basicInformation.logout.t13`)
      }
    ])
    useCommonNormalDialog({
      btnDisabled,
      headTitle: useTranslateI18n(`profile.basicInformation.logout.t11`),
      headDesc: useTranslateI18n(`profile.basicInformation.logout.t4`, [account]),
      submitBtnText: submitBtnText,
      contentSlots({ close }) {
        const checkboxCom = resolveComponent("PagesProfileCheckBoxGroup")
        return (
          <div class="profile-basic__delete_account_asset">
            <div class="profile-basic__delete_account_asset_t1">
              {useTranslateI18n(`profile.basicInformation.logout.t12`)}
            </div>
            <div class="profile-basic__delete_account_asset_de">
              <div class="t1">{useTranslateI18n(`profile.basicInformation.logout.t14`, [credits])}</div>
              {use_user.subscription_info.is_subscribed && (
                <div class="t1">
                  {useTranslateI18n(`profile.basicInformation.logout.t15`, [use_user.subscription_info.next_bill_date])}
                </div>
              )}
              {use_user.isBusinessUser && (
                <div class="t1">
                  {useTranslateI18n(`profile.basicInformation.logout.t16`, [use_user.getTopBusinessName])}
                </div>
              )}
            </div>
            {use_user.isBusinessUser && (
              <checkboxCom
                value={assetCheckboxValue}
                ref={assetCheckboxRef}
                checkboxOptions={checkboxOptions}
                onCheckboxChange={(val) => {
                  btnDisabled.submit = isTiming.value
                  assetCheckboxValue.value = val
                }}
              />
            )}
          </div>
        )
      },
      cancelBtnText: useTranslateI18n(`profile.basicInformation.logout.t6`),
      cancelCallback({ closeLoading, closeDialog }) {
        showAgreeDialog()
        closeDialog()
      },
      async submitCallback({ closeLoading, closeDialog }) {
        showConfirmDelDialog()
        closeDialog()
      }
    })
  }

  // 确认删除弹窗
  const showConfirmDelDialog = async () => {
    useCommonNormalDialog({
      headTitle: useTranslateI18n(`profile.basicInformation.logout.t11`),
      headDesc: useTranslateI18n(`profile.basicInformation.logout.t4`, [account]),
      submitBtnText: useTranslateI18n(`profile.basicInformation.logout.t10`),
      contentSlots({ close }) {
        return (
          <div class="profile-basic__delete_account_confirm">
            <span>{useTranslateI18n(`profile.basicInformation.logout.confirm[0]`)}</span>
            <span class="b1">{useTranslateI18n(`profile.basicInformation.logout.confirm[1]`)}</span>
            <span>{useTranslateI18n(`profile.basicInformation.logout.confirm[2]`)}</span>
          </div>
        )
      },
      cancelBtnText: useTranslateI18n(`profile.basicInformation.logout.t6`),
      async cancelCallback({ closeLoading, closeDialog }) {
        try {
          // 同意的话判断三个条件 1、张数大于0 2、有订阅 3、是商户管理员
          await use_user.setAllUserInfo()
          if (
            use_user.subscription_info.total_credits > 0 ||
            use_user.subscription_info.is_subscribed ||
            use_user.isBusinessUser
          ) {
            showAssetDialog()
          } else {
            showAgreeDialog()
          }
          closeDialog()
        } catch (e) {
          closeLoading()
        }
      },
      async submitCallback({ closeLoading, closeDialog }) {
        const _params =
          deleteType === VERIFY_TYPE.email
            ? {
                email: use_user.userEmail
              }
            : {
                phone: use_user.userInfo.phone,
                phone_cc: use_user.userInfo.phone_cc
              }
        try {
          const { log_off_now_token } = await postLogoffUser({
            code: accountResultCode.value,
            reason: checkboxValue.value as unknown as IPostOffReason,
            reason_text: textareaValue.value,
            is_delete_org: assetCheckboxValue.value[0],
            ..._params
          })
          logOffNowToken = log_off_now_token
          showSuccessDelDialog()
          closeDialog()
        } catch (e) {
          closeLoading()
        }
      }
    })
  }

  //  成功删除弹窗
  const showSuccessDelDialog = () => {
    let signinDom = null
    let rightDeleteDom = null
    useCommonNormalDialog({
      close() {
        use_user.clearUserInfo()
      },
      headTitle: useTranslateI18n(`profile.basicInformation.logout.t18`),
      submitBtnText: useTranslateI18n(`profile.basicInformation.logout.ok`),
      contentSlots({ close }) {
        return (
          <div
            class="profile-basic__delete_account_delSuccess"
            v-html={useTranslateI18n(`profile.basicInformation.logout.t19`, [
              { type: "static", class: "profile-basic__delete_account_delSuccess_t1" },
              {
                type: "static",
                class: "profile-basic__delete_account_delSuccess_t2"
              }
            ])}
          ></div>
        )
      },
      async submitCallback({ closeLoading, closeDialog }) {
        use_user.clearUserInfo()
      }
    })

    setTimeout(() => {
      const dom = document.querySelector(".profile-basic__delete_account_delSuccess_t1")
      const delete_dom = document.querySelector(".profile-basic__delete_account_delSuccess_t2")
      signinDom = useEventListener(dom, "click", () => {
        use_user.clearUserInfo(false)
        location.href = unref(HOME) + `?auto_login=auto_login`
      })

      rightDeleteDom = useEventListener(
        delete_dom,
        "click",
        useDebounceFn(() => {
          // 弹出确认注销弹窗
          showNowLogoutDialog()
        }, 300)
      )
    }, 400)
  }

  // 确认立即注销弹窗
  const showNowLogoutDialog = () => {
    useCommonNormalDialog({
      headTitle: useTranslateI18n(`profile.basicInformation.logout.t21`),
      contentSlots({ close }) {
        return (
          <div
            v-html={useTranslateI18n(`profile.basicInformation.logout.t22`, [
              { type: "static", class: "profile-basic__delete_account_delSuccess_t3" }
            ])}
          ></div>
        )
      },
      submitBtnText: useTranslateI18n(`profile.basicInformation.logout.ok`),
      cancelBtnText: useTranslateI18n(`profile.basicInformation.logout.cancel`),
      async submitCallback({ closeLoading, closeDialog }) {
        try {
          await postLogOffNow(logOffNowToken)
          closeDialog()
          use_user.clearUserInfo()
        } catch (e) {
          closeLoading()
        }
      },
      cancelCallback({ closeDialog, closeLoading }) {
        closeDialog()
      }
    })
  }

  // 重新登录确认恢复弹窗
  const showRetrieveDialog = (
    email: string,
    recover_token: string,
    reload = true,
    url = unref(HOME),
    callback?: (...args: any[]) => any
  ) => {
    useCommonNormalDialog({
      headTitle: useTranslateI18n(`profile.basicInformation.logout.re`),
      headDesc: useTranslateI18n(`profile.basicInformation.logout.t4`, [email || use_user.userEmail]),
      submitBtnText: useTranslateI18n(`profile.basicInformation.logout.t20`),
      cancelBtnText: useTranslateI18n(`profile.basicInformation.logout.cancel`),
      contentSlots({ close }) {
        return (
          <div
            class="profile-basic__delete_account_delSuccess"
            v-html={useTranslateI18n(`profile.basicInformation.logout.ret`)}
          ></div>
        )
      },
      async submitCallback({ closeLoading, closeDialog }) {
        try {
          if (!recover_token) {
            callback && callback()
            closeDialog()
            return
          }
          const channelHistry = getLocalStorage(SessStorageKey.CHANNEL_HISTORY)
          const data = await setStateLoginApi({
            recover_token,
            ads_conversion: channelHistry ? JSON.parse(channelHistry) : undefined
          })
          use_user.setUserInfo({
            info: data.info.user_info,
            token: data.token,
            reload,
            redirectUrl: url
          })
          closeDialog()
        } finally {
          closeLoading()
        }
      },
      cancelCallback({ closeLoading, closeDialog }) {
        closeDialog()
      }
    })
  }

  return {
    showDeleteEmailDialog,
    showReasonDialog,
    showAgreeDialog,
    showAssetDialog,
    showConfirmDelDialog,
    showSuccessDelDialog,
    showRetrieveDialog
  }
}

// 用户认证二次弹窗
export const useUserAuthRepeatDialog = () => {
  const use_user = useUser()
  const { _pointLoginAndRegFlowPageView, _pointLoginAndRegFlowPageButtonView, _pointWebsitePopupView } = useCommonMp()
  // 显示发送验证码弹窗
  const showRepeatAuthDialog = async (info: VerifyLogInfo = {}, reload = true, url = unref(HOME)) => {
    const { useSecurityTypeSelectDialog } = useLoginAndRegFlow()
    // 选择二次验证方式（邮箱、密码、手机）
    const { type, formModel } = (await useSecurityTypeSelectDialog(info)) as { type: VERIFY_TYPE; formModel: any }

    // 邮箱/手机号二次验证
    if ([VERIFY_TYPE.email, VERIFY_TYPE.phone].includes(type)) {
      const deleteAccountRef = ref()
      const accountResultCode = ref()
      useCommonNormalDialog({
        headTitle:
          type === VERIFY_TYPE.email ? useTranslateI18n("login.email.title") : useTranslateI18n(`login.newLogin.t43`),
        loadSuccess() {
          _pointLoginAndRegFlowPageView({ pageTitle: "login_email_2fa", featureName: "login" })
          _pointWebsitePopupView({
            featureName: "login",
            featureModule: "login_registration",
            pageTitle: "login_email_2fa"
          })
        },
        contentSlots(options) {
          const com = resolveComponent("PagesProfileAccountEmail")
          const account =
            type === VERIFY_TYPE.email
              ? formModel.email
              : `+${formModel.phone?.countryValue} ${formModel.phone?.inputValue}`
          const channelHistry = getLocalStorage(SessStorageKey.CHANNEL_HISTORY)
          return (
            <com
              sId={"auth"}
              scene={LOGIN_SCENE.REPEAT_AUTH}
              title={useTranslateI18n("login.email.desc", [account])}
              showDesc={false}
              email={type === VERIFY_TYPE.email ? formModel.email : ""}
              phone={type === VERIFY_TYPE.phone ? formModel.phone : ""}
              ref={deleteAccountRef}
              success={async () => {
                try {
                  accountResultCode.value = deleteAccountRef.value.getCode()
                  const data = await setStateLoginApi({
                    email: type === VERIFY_TYPE.email ? formModel.email : undefined,
                    phone: type === VERIFY_TYPE.phone ? formModel.phone?.inputValue : "",
                    phone_cc: type === VERIFY_TYPE.phone ? formModel.phone?.countryValue : "",
                    mfa_verify_code: accountResultCode.value,
                    ads_conversion: channelHistry ? JSON.parse(channelHistry) : undefined
                  })
                  use_user.setUserInfo({
                    info: data.info.user_info,
                    token: data.token,
                    reload,
                    redirectUrl: url
                  })
                  options.close && options.close()
                } catch (e) {
                  if (e?.data?.code === IErrorLoginType.Retrieve) {
                    const { showRetrieveDialog } = useDeleteAccountNotifyDialog()
                    showRetrieveDialog(account, e?.data?.data?.recover_token, reload, url)
                    options.close && options.close()
                  }
                }
              }}
            />
          )
        },
        submitBtnText: useTranslateI18n("login.email.done"),
        async submitCallback({ closeLoading, closeDialog }) {
          if (deleteAccountRef.value) {
            _pointLoginAndRegFlowPageButtonView({
              featureName: "login",
              elementName: "verify",
              pageTitle: "login_email_2fa"
            })
            try {
              await deleteAccountRef.value.submit()
            } finally {
              closeLoading()
            }
          }
        }
      })
    }
    // 密码登录
    if (type === VERIFY_TYPE.password) {
      useCommonNormalDialog({
        contentSlots({ close }) {
          const com = resolveComponent("PagesProfileAccountPsd")
          return (
            <com
              email={formModel.email}
              phone={formModel.phone}
              onSuccess={({ token, info }) => {
                use_user.setUserInfo({
                  info: info.user_info,
                  token: token,
                  reload,
                  redirectUrl: url
                })
                close()
              }}
            ></com>
          )
        }
      })
    }
  }

  return {
    showRepeatAuthDialog
  }
}

// 弹出用户账单信息编辑
export const useUserInvoicesInfoEditDialog = (config: IReceiptInfo, cb?: (...args: any[]) => any) => {
  let closeDialog = null
  const submit = useDebounceFn(async (e) => {
    await postReceiptInfo({
      title: e.title,
      email_address: e.email,
      address: e.address,
      phone_number: e.phone?.inputValue,
      phone_code: e.phone?.countryValue,
      tax_code: e.tax_code
    })
    closeDialog && closeDialog()
    cb && cb()
  }, 300)
  useCommonNormalDialog({
    width: 930,
    popperClass: "profile-invoices-info_dialog",
    contentSlots({ close }) {
      closeDialog = close
      const com = resolveComponent("PagesProfileInvoicesInfoEdit")
      return <com onSubmit={submit} onCancel={() => close()} config={config} />
    }
  })
}
