import type { ICollapseDataObj, ICollapseData } from "@/types/components/common/collapse"

export const faqCSettings = () => {
  return {
    title: useTranslateI18n("help.cardSettings.faq.title"),
    desc: useTranslateI18n("help.cardSettings.faq.desc")
  }
}
export const contactCSettings = () => {
  return {
    title: useTranslateI18n("help.cardSettings.contact.title"),
    desc: useTranslateI18n("help.cardSettings.contact.desc")
  }
}

export enum faqType {
  help = 1,
  pricing = 2,
  quickStart = 3,
  subPricing = 5,
  changeSkinColor = 6
}

export enum questionType {
  SoftwareBugs = 1,
  ProblemsWithEffects = 2,
  FeatureSuggestions = 3,
  LargerPackageInquiry = 4,
  SmallerPackageInquiry = 5,
  Other = 6
}

export enum gitBookType {
  H1 = "heading-1",
  H2 = "heading-2",
  UL = "list-unordered",
  LI = "list-item",
  SPAN = "paragraph",
  A = "link"
}

export enum uploadState {
  error = 0,
  uploading = 1,
  success = 2
}

export const FEEDBACK_ID = "_feedback"
