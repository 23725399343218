import mixpanelInstance from "@/utils/mixpanel"
import { mpEventName } from "@/constant/mp"
import { ICommonProperty } from "@/composables/utils/mp/common"
import { HOME } from "@/constant/route"
const setEvent = mixpanelInstance.setEvent

export enum IMpWebsiteDownloadAppDict {
  evoto_website_download = "evoto_website_download",
  evoto_kol_landing = "evoto_kol_landing",
  evoto_website_purchaseSuccess = "evoto_website_purchaseSuccess",
  evoto_invite_landing = "evoto_invite_landing",
  evoto_website_downloadFreeTrial = "evoto_website_downloadFreeTrial",
  evoto_ads_landing_download = "evoto_ads_landing_download",
  evoto_ads_landing_StartFreeTrial = "evoto_ads_landing_StartFreeTrial",
  evoto_ads_landing_discoverDownload = "evoto_ads_landing_discoverDownload",
  evoto_influencers_landing_download = "evoto_influencers_landing_download",
  evoto_influencers_landing_StartFreeTrial = "evoto_influencers_landing_StartFreeTrial",
  evoto_influencers_landing_discoverDownload = "evoto_influencers_landing_discoverDownload",
  evoto_ads_landing2_download = "evoto_ads_landing2_download",
  evoto_ads_landing2_StartFreeTrial = "evoto_ads_landing2_StartFreeTrial",
  evoto_ads_landing2_discoverDownload = "evoto_ads_landing2_discoverDownload",
  evoto_influencers_landing2_download = "evoto_influencers_landing2_download",
  evoto_influencers_landing2_StartFreeTrial = "evoto_influencers_landing2_StartFreeTrial",
  evoto_influencers_landing2_discoverDownload = "evoto_influencers_landing2_discoverDownload",
  evoto_purchase_landing_download = "evoto_purchase_landing_download",
  evoto_influencers_landing3_StartFreeTrial = "evoto_ads_landing3_StartFreeTrial",
  evoto_ads_landing3_StartFreeTrial = "evoto_ads_landing3_StartFreeTrial",
  evoto_influencers_landing4_StartFreeTrial = "evoto_ads_landing4_StartFreeTrial",
  evoto_ads_landing4_StartFreeTrial = "evoto_ads_landing4_StartFreeTrial"
}

interface IMpWebsiteDownloadApp {
  source: IMpWebsiteDownloadAppDict
}

export function useDownloadMp() {
  const _pointMpWebsiteDownloadApp = (params: ICommonProperty & { buttonName: any }) => {
    const path = useRoute().path
    setEvent(
      mpEventName.EvotoWebsiteDownloadApp,
      {},
      { ...params, path: path === unref(HOME) ? `/${IPageTitle[path]}` : path, pageTitle: useMpCommonPageTitle() }
    )
  }
  return {
    _pointMpWebsiteDownloadApp
  }
}
