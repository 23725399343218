export default defineNuxtPlugin((nuxtApp) => {
  // Also possible
  nuxtApp.hook("vue:error", (error, instance, info) => {
    // handle error, e.g. report to a service
    console.log(`[error by vue:error]`, error)
  })

  nuxtApp.hook("app:error", (error) => {
    // handle error, e.g. report to a service
    console.log(`[error by app:created]`, error)
  })
})
