import { setupGtagDirective } from "~~/directives/gtag"
import { setupImgLazyDirective } from "~~/directives/imgLazy"

export default defineNuxtPlugin(({ vueApp }) => {
  setupGtagDirective(vueApp)

  setupImgLazyDirective(vueApp, {
    loading: "", // loading img
    error: "" // error img
  })
})
