import { PointEvent } from "~~/constant/gtag"

export function useTestGtag() {
  const { $gtag } = useNuxtApp()
  const { setEvent } = $gtag

  const _PointGaTestEvent = (action_type: string) => setEvent(PointEvent.DataValidation, { action_type })
  return {
    _PointGaTestEvent
  }
}
