import { createVNode, render } from "vue"
import ChannelError from "@/components/common/ChannelError.vue"

interface IChannelErrorOptions {
  title: string
  desc: string
  submit?: (...args: any[]) => any
  showReEnter?: boolean
  reEnter?: (...args: any[]) => any
  closeDialog?: (...args: any[]) => any
}

export const useChannelErrorDialog = (options: IChannelErrorOptions) => {
  // 挂载  因为dialog是teleport 无需append to body
  const vm = createVNode(ChannelError, {
    ...options
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
