import ytagInstance from "@/utils/ytag"
import { ytagEventName } from "@/constant/ytag"
const setEvent = ytagInstance.setEvent

export function usePaymentYtag() {
  const _PointYtagPaymentSuccess = (value: any) => {
    setEvent(ytagEventName.CompletePayment, {
      yahoo_conversion_id: "1001311148",
      yahoo_conversion_label: "HtvOCNfvsvQYEIW8wrUq",
      yahoo_conversion_value: value
    })
  }
  return {
    _PointYtagPaymentSuccess
  }
}
