<template>
  <div class="login-with-psd-wrapper">
    <div class="top-title">
      <span class="t-1">{{ title || useTranslateI18n("login.newLogin.t5") }}</span>
    </div>
    <div class="form-content">
      <CommonForm
        ref="formRef"
        :form-input-list="formInputList"
        :form-inline="false"
        :form-rules="formRules"
        form-label-position="top"
        :form-model="formModel"
        @validate="signInValidator"
      />
    </div>

    <div ref="loadingRef" class="submit-button-w">
      <CommonButton ref="btnRef" class="submit-button" :button-style="buttonStyle1" @btn-click="logInClick"
        ><CommonAnimationScrollText>{{
          useTranslateI18n("login.newLogin.t4")
        }}</CommonAnimationScrollText></CommonButton
      >
    </div>
  </div>
</template>

<script setup lang="tsx">
import { useTranslateI18n } from "@/composables/store/i18n-country"
import { IFormPropsInputList } from "@/types/components/common/form"
import { FormRules } from "element-plus"
import EyeIcon from "@/assets/icons/common/eyes-icon.png"
import EyeIcon1 from "@/assets/icons/common/eyes-icon-1.png"
import { SessStorageKey } from "~/constant/cache"
import { useUser } from "~/store/user"

interface IProps {
  title: string
  email: string
  phone: {
    countryValue: string
    inputValue: string
  }
}

const props = withDefaults(defineProps<IProps>(), {
  title() {
    return ""
  },
  email() {
    return ""
  },
  phone() {
    return {
      countryValue: "",
      inputValue: ""
    }
  }
})

const use_user = useUser()

const emits = defineEmits(["success"])

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Regular"
})

let formModel = reactive({
  email: props.email || "",
  phone: props.phone.inputValue || "",
  password: ""
})

const formRef = ref()

let formInputList = ref<IFormPropsInputList<typeof formModel>>([
  {
    type: "input",
    model: "email",
    disabled: true,
    label: props.email ? useTranslateI18n("login.label.email") : useTranslateI18n("login.newLogin.t17"),
    clearable: true,
    showItem: computed(() => !unref(props.phone.inputValue)) as any
  },
  {
    type: "input",
    model: "phone",
    disabled: true,
    label: useTranslateI18n("login.newLogin.t24"),
    showItem: computed(() => !!unref(props.phone.inputValue)) as any
  },
  {
    type: "input",
    model: "password",
    label: useTranslateI18n("login.label.psd"),
    itype: "password",
    autoComplete: "new-password",
    enterClick: true,
    enterClickFn: useDebounceFn(() => {
      logInClick()
    }, 300),
    clearable: true,
    suffixSlot() {
      return (
        <div class="eyes-wrapper">
          <span class="eyes-wrapper-text"></span>
          <img src={formInputList.value[2].itype ? EyeIcon : EyeIcon1} class="eyes-icon" onClick={eyesClick} />
        </div>
      )
    }
  }
])
// 眼睛点击
const eyesClick = () => {
  formInputList.value[2].itype = formInputList.value[2].itype ? "" : "password"
}

const validatorPassword = async (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error(useTranslateI18n("login.signIn.errorPsd")))
  } else {
    return callback()
  }
}
let formRules = reactive<FormRules>({
  password: [
    {
      validator: validatorPassword as any,
      trigger: "blur"
    }
  ]
})

const { loadingRef, openLoading, closeLoading, isClick } = useLoadingHooks()

const logInClick = () => {
  formRef.value && formRef.value.validate()
}

const signInValidator = async (v) => {
  if (v) {
    try {
      if (unref(isClick)) return
      openLoading()
      const channelHistry = getLocalStorage(SessStorageKey.CHANNEL_HISTORY)
      let data = await setStateLoginApi({
        email: props.email || undefined,
        phone: props.phone?.inputValue,
        phone_cc: props.phone?.countryValue,
        password: useMd5(formModel.password),
        ads_conversion: channelHistry ? JSON.parse(channelHistry) : undefined,
        mfa_password: true
      })
      emits("success", data)
    } finally {
      closeLoading()
    }
  }
}
</script>

<style scoped lang="scss">
.login-with-psd-wrapper {
  display: flex;
  flex-direction: column;
  .top-title {
    font-size: 24px;
    line-height: normal;
    @include fontSemibold;
    color: #000;
    display: flex;
    flex-direction: column;
    .t-2 {
      color: rgba(0, 0, 0, 0.55);
      font-size: 16px;
      line-height: 150%; /* 24px */
      margin-top: 8px;
      @include fontRegular;
      :deep(.t-2-l) {
        position: relative;
        line-height: 150%;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .form-content {
    margin: 40px 0;
  }

  :deep(.pix-common-search) {
    .el-form {
      .el-form-item {
        margin-bottom: 8px;
        .change-email {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          font-size: 12px;
          color: $bg-color-link;
          cursor: pointer;
          text-decoration: underline;
          line-height: normal;
          margin: 8px 0;
        }
        .forget-psd {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          font-size: 12px;
          line-height: normal;
          margin-top: 8px;
          color: rgba(0, 0, 0, 0.3);
          > span {
            cursor: pointer;
          }
        }
        .el-form-item__label {
          font-size: 12px;
          line-height: normal;
          color: rgba(0, 0, 0, 0.55);
        }
        .el-input__wrapper {
          .el-input__inner {
            height: 34px;
          }
          .eyes-wrapper {
            display: flex;
            align-items: center;
            .eyes-wrapper-text {
              width: 1px;
              height: 16px;
              background: rgba(0, 0, 0, 0.3);
              margin-right: 8px;
            }
            .eyes-icon {
              width: 16px;
              cursor: pointer;
            }
          }
        }
        .el-input {
          &.is-disabled {
            .el-input__wrapper {
              border: 1px solid rgba(0, 0, 0, 0.1);
              background-color: transparent;
              .el-input__inner {
                color: rgba(0, 0, 0, 0.3);
              }
            }
          }
        }
      }
    }
  }
}
</style>
