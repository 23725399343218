export enum tsSdkEventName {
  ELEMENTCLICK = "element_click",
  POPUPVIEW = "popup_view",
  PAGELEAVE = "page_leave",
  PAGEVIEW = "page_view",
  PAGESTAY = "page_stay",
  DataValidation = "data_validation",
  PAGELOAD = "page_load"
}
export interface TrackingSDKConfig {
  endpoint?: string // 日志上报地址
  is_mark?: string // 是否加密
  mark_code?: string // 解密key
  showlog?: boolean // 是否展示日志
  excludedReferrers?: string[] // 需要被排除的站外referrer
  app?: string // 数据上报的应用
  session_timeout?: number //session有效间隔时间
  sender?: string // 请求方式 beacon｜xhr
  is_base64: string
  // 可以添加更多配置项
}
export enum EnvironmentEndpoints {
  DEV = "https://ingest-api-dev.evoto.ai/v1/event/send?project=default",
  PRE = "https://ingest-api-pre.evoto.ai/v1/event/send?project=default",
  PROD = "https://ingest-api.evoto.ai/v1/event/send?project=default",
  TEST = "https://ingest-api-test.evoto.ai/v1/event/send?project=default"
}
