import revive_payload_client_4sVQNw7RlN from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/usr/src/nuxt-app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_MwZUzHrRNP from "/usr/src/nuxt-app/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yVLowv6hDl from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_A0OWXRrUgq from "/usr/src/nuxt-app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/usr/src/nuxt-app/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/usr/src/nuxt-app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import titles_m9SjywKgWm from "/usr/src/nuxt-app/node_modules/@nuxtjs/seo/dist/runtime/nuxt/plugin/titles.mjs";
import siteConfig_XJTWbJLGEl from "/usr/src/nuxt-app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/siteConfig.mjs";
import inferSeoMetaPlugin_zOJ1kxPqxh from "/usr/src/nuxt-app/node_modules/nuxt-seo-experiments/dist/runtime/nuxt/plugins/inferSeoMetaPlugin.mjs";
import element_plus_teleports_plugin_h4Dmekbj62 from "/usr/src/nuxt-app/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/usr/src/nuxt-app/.nuxt/element-plus-injection.plugin.mjs";
import i18n_yfWm7jX06p from "/usr/src/nuxt-app/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_UciE0i6zes from "/usr/src/nuxt-app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import _01_set_sdk_local_client_kHomJhfnH5 from "/usr/src/nuxt-app/plugins/01.set-sdk-local.client.ts";
import bridge_client_PsBxTK5Q5r from "/usr/src/nuxt-app/plugins/bridge.client.ts";
import device_client_IZWAmOdgTj from "/usr/src/nuxt-app/plugins/device.client.ts";
import directive_client_jS50AHLKqG from "/usr/src/nuxt-app/plugins/directive.client.ts";
import error_handler_kEP5FliEXj from "/usr/src/nuxt-app/plugins/error-handler.ts";
import global_set_vVoNIWye1y from "/usr/src/nuxt-app/plugins/global-set.ts";
import gtag_client_Zw8EQXNVTz from "/usr/src/nuxt-app/plugins/gtag.client.ts";
import gtag_RbOvmu12fv from "/usr/src/nuxt-app/plugins/gtag.ts";
import i18n_VfGcjrvSkj from "/usr/src/nuxt-app/plugins/i18n.ts";
import lazyLoad_VLTc6f4YEq from "/usr/src/nuxt-app/plugins/lazyLoad.ts";
import mitt_S0QU5gJPTl from "/usr/src/nuxt-app/plugins/mitt.ts";
import scroll_S7pZiC3d46 from "/usr/src/nuxt-app/plugins/scroll.ts";
import sdk_client_ebQhbwXLol from "/usr/src/nuxt-app/plugins/sdk.client.ts";
import videoLazyLoad_XE7srbxmaC from "/usr/src/nuxt-app/plugins/videoLazyLoad.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  _0_siteConfig_MwZUzHrRNP,
  payload_client_yVLowv6hDl,
  check_outdated_build_client_8vK7RkfGxZ,
  plugin_vue3_A0OWXRrUgq,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  titles_m9SjywKgWm,
  siteConfig_XJTWbJLGEl,
  inferSeoMetaPlugin_zOJ1kxPqxh,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  i18n_yfWm7jX06p,
  chunk_reload_client_UciE0i6zes,
  _01_set_sdk_local_client_kHomJhfnH5,
  bridge_client_PsBxTK5Q5r,
  device_client_IZWAmOdgTj,
  directive_client_jS50AHLKqG,
  error_handler_kEP5FliEXj,
  global_set_vVoNIWye1y,
  gtag_client_Zw8EQXNVTz,
  gtag_RbOvmu12fv,
  i18n_VfGcjrvSkj,
  lazyLoad_VLTc6f4YEq,
  mitt_S0QU5gJPTl,
  scroll_S7pZiC3d46,
  sdk_client_ebQhbwXLol,
  videoLazyLoad_XE7srbxmaC
]