/**
 * @use 导入useTimer即可使用定时器工具Timer类
 * @description
 *  以下为compositionAPI写法：
 *      import { useTimer, Timer } from 'xxxx/timer.ts'
 *      const timer: Timer = useTimer();
 *      1.设置默认配置
 *      Timer.setConfig({
 *          mode, // 选填，两种定时器模式('timeout' | 'interval')
 *          timeout // 选填，定时器触发时间
 *      })
 *
 *      2.设置定时器(!!!注意设置同一id定时器会覆盖)
 *      timer.set({
 *          id, // 必填，唯一标识 支持symbol字符
 *          mode, // 选填，两种定时器模式('timeout' | 'interval') 默认'interval'
 *          handler, // 必填，定时器处理函数handler(ctx)，返回的ctx包括 _timer为该类实例 _tId为定时器的tId
 *          timeout // 选填，定时器触发时间 默认300ms
 *      })
 *
 *      3.删除定时器
 *      timer.delete(id) // id 唯一标识
 *
 *      4.判断是否已经设置同一定时器
 *      timer.has(id) // id 唯一标识
 *
 *      5.清空所有定时器
 *      timer.clear()
 */
export enum TimerModeEnum {
  Timeout = "timeout",
  Interval = "interval"
}

interface TimerOptions {
  id: TimerId
  mode?: TimerMode
  handler: (ctx: TimerContext) => void
  timeout?: number
}
interface TimerInfo extends Pick<TimerOptions, "mode" | "handler"> {
  tId: NodeJS.Timer
}

type TimerId = string | number | symbol
type TimerMode = TimerModeEnum.Interval | TimerModeEnum.Timeout
type TimerConfig = Pick<TimerOptions, "mode" | "timeout">
export type TimerContext = {
  _timer: Timer
  _id: TimerId
}

export class Timer {
  readonly _timer: Timer

  static DEFAULT_TIMER_CONFIG: TimerConfig = {
    mode: TimerModeEnum.Interval,
    timeout: 300
  }
  static instance: InstanceType<typeof Timer> | null = null

  timerList = new Map<TimerId, TimerInfo>()

  constructor() {
    this._timer = this
  }

  static getInstance(): InstanceType<typeof Timer> {
    if (Timer.instance) {
      return Timer.instance
    }
    Timer.instance = new Timer()
    return Timer.instance
  }

  static setConfig(config: TimerConfig): void {
    Object.assign(Timer.DEFAULT_TIMER_CONFIG, config)
  }

  /**
     *  2.设置定时器(!!!注意设置同一id定时器会覆盖)
     * @param id 必填，唯一标识 支持symbol字符
     * @param mode, 选填，两种定时器模式('timeout' | 'interval') 默认'interval'
     * @param handler, 必填，定时器处理函数handler(ctx)，返回的ctx包括 _timer为该类实例 _tId为定时器的tId
     * @param timeout 选填，定时器触发时间 默认300ms 
       }
     */
  set(options: TimerOptions): NodeJS.Timer {
    let tId: NodeJS.Timer
    const opts = {
      ...Timer.DEFAULT_TIMER_CONFIG,
      ...options
    }
    const { id, mode, handler, timeout } = opts
    if (this.has(id)) {
      this.delete(id)
    }
    if (mode === TimerModeEnum.Interval) {
      tId = setInterval(() => {
        handler({
          _timer: this._timer,
          _id: id
        })
      }, timeout)
    } else {
      tId = setTimeout(() => {
        handler({
          _timer: this._timer,
          _id: id
        })
      }, timeout)
    }
    this.timerList.set(id, {
      tId,
      mode,
      handler
    })

    return tId
  }

  has(id: TimerId): NodeJS.Timer | null {
    if (this.timerList.has(id)) {
      return this.timerList.get(id)?.tId ?? null
    }
    return null
  }

  delete(id: TimerId): void {
    if (this.timerList.has(id)) {
      const timer = this.timerList.get(id)
      if (timer) {
        if (timer.mode === TimerModeEnum.Interval) {
          clearInterval(timer.tId)
        } else {
          clearTimeout(timer.tId)
        }
        this.timerList.delete(id)
      }
    }
  }

  clear(): void {
    if (this.timerList.size) {
      for (const timer of this.timerList.values()) {
        if (timer.mode === TimerModeEnum.Interval) {
          clearInterval(timer.tId)
        } else {
          clearTimeout(timer.tId)
        }
      }
      this.timerList.clear()
    }
  }
}

export const useTimer = (): Timer => {
  return Timer.getInstance()
}
