<template>
  <CommonDialog
    v-model="showDialog"
    :z-index="zIndex"
    :popper-class="popperClass"
    :dialog-style="dialogStyle"
    :show-close-icon="showCloseIcon"
  >
    <div class="common-normal-dialog__wrapper_content">
      <div class="t-title">
        <span class="t-1">{{ headTitle }}</span>
        <span v-if="headDesc" class="t-2">{{ headDesc }}</span>
      </div>
      <div class="t-content">
        <slot name="content" :close="closeDialog" />
        {{ contentDesc }}
      </div>
      <div class="t-bottom">
        <CommonButton
          v-if="resultSubmitBtnText"
          ref="submitRef"
          :class="[btnDisabled.submit ? 'submit-text-disabled' : '']"
          :button-style="buttonStyle"
          @btn-click="submitHandler"
          ><CommonAnimationScrollText>{{ resultSubmitBtnText }}</CommonAnimationScrollText></CommonButton
        >

        <CommonButton
          v-if="cancelBtnText"
          ref="cancelRef"
          :class="[btnDisabled.cancel ? 'cancel-text-disabled' : '', 'cancel-btn']"
          :button-style="buttonStyle1"
          @btn-click="cancelHandler"
          ><CommonAnimationScrollText>{{ cancelBtnText }}</CommonAnimationScrollText></CommonButton
        >
      </div>
    </div>
  </CommonDialog>
</template>

<script setup lang="tsx">
import { CSSProperties } from "vue"

const props = defineProps({
  zIndex: {
    type: Number,
    default() {
      return 2000
    }
  },
  popperClass: {
    type: String,
    default() {
      return ""
    }
  },
  width: {
    type: Number,
    default() {
      return 438
    }
  },
  headTitle: {
    type: String,
    default() {
      return ""
    }
  },
  headDesc: {
    type: String,
    default() {
      return ""
    }
  },
  contentDesc: {
    type: String,
    default() {
      return ""
    }
  },
  submitBtnText: {
    type: String,
    default() {
      return ""
    }
  },
  cancelBtnText: {
    type: String,
    default() {
      return ""
    }
  },
  btnDisabled: {
    type: Object,
    default() {
      return {
        submit: false,
        cancel: false
      }
    }
  },

  submitCallback: {
    type: Function,
    default: () => {}
  },
  cancelCallback: {
    type: Function,
    default: () => {}
  },
  showCloseIcon: {
    type: Boolean,
    default() {
      return true
    }
  },
  close: {
    type: Function,
    default: () => {}
  },
  loadSuccess: {
    type: Function,
    default: () => {}
  },
  dialogWrapperStyle: {
    type: Object,
    default: () => {
      return {}
    }
  }
})

onMounted(() => {
  props.loadSuccess && props.loadSuccess()
})

let showDialog = ref(true)

const popperClass = computed(() => {
  return {
    "common-normal-dialog__wrapper": true,
    [props.popperClass]: props.popperClass
  }
})

watch(
  () => showDialog.value,
  (newValue) => {
    if (!newValue) {
      props.close && props.close()
    }
  }
)

let dialogStyle = reactive<CSSProperties>({
  width: props.width,
  backgroundColor: "#fff",
  padding: "36px 40px",
  ...props.dialogWrapperStyle
})

const buttonStyle = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px"
})

const buttonStyle1 = reactive({
  width: "100%",
  background: "rgba(0, 0, 0, 0.05)",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px"
})

const closeDialog = () => {
  showDialog.value = false
}

defineExpose({
  closeDialog
})

const submitRef = ref()

const cancelRef = ref()

const submitHandler = () => {
  if (props.btnDisabled?.submit) {
    return
  }
  submitRef.value && submitRef.value.openLoading()
  props.submitCallback &&
    props.submitCallback({
      closeLoading: submitRef.value.closeLoading,
      closeDialog
    })
}

const cancelHandler = () => {
  if (props.btnDisabled?.cancel) {
    return
  }
  cancelRef.value && cancelRef.value.openLoading()
  props.cancelCallback &&
    props.cancelCallback({
      closeLoading: cancelRef.value.closeLoading,
      closeDialog
    })
}

let resultSubmitBtnText = ref("")

watch(
  () => unref(props.submitBtnText),
  (newVal) => {
    resultSubmitBtnText.value = unref(newVal)
  },
  {
    immediate: true,
    deep: true
  }
)
</script>

<style lang="scss">
.welcome-page-dialog {
  &.common-normal-dialog__wrapper {
    .common-normal-dialog__wrapper_content {
      display: flex;
      flex-direction: column;
      line-height: normal;
      .t-title {
        display: flex;
        flex-direction: column;
        .t-1 {
          font-size: 23px;
          @include fontSemibold;
        }
        .t-2 {
          margin-top: 8px;
          font-size: 14px;
        }
      }
      .t-content {
        margin: 40px 0;
        font-size: 12px;
        line-height: 150%;
        color: rgba(0, 0, 0, 0.55);
      }
      .t-bottom {
        .common-button {
          @include fontRegular;
        }
        .cancel-btn {
          margin-top: 8px;
        }
        .submit-text-disabled {
          background-color: rgb(255, 224, 80, 0.3) !important;
          cursor: not-allowed;
          .scroll-text {
            .bottom {
              background: rgba(0, 0, 0, 0.3) !important;
            }
          }
        }
        .cancel-text-disabled {
          background-color: rgba(0, 0, 0, 0.3) !important;
        }
      }
    }
    @include device-max-width-600 {
      .content {
        width: 75%;
        .content-d {
          padding: 30px !important;
          width: 100% !important;
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 70%;
        .content-d {
          padding: 15px !important;
        }
      }
    }
  }
}
</style>
