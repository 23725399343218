export default defineNuxtPlugin({
  name: "global-set",
  dependsOn: ["i18n-f"],
  async setup(nuxtApp) {
    const { isInlineClient } = useClientBridgeRouteGuard()
    const { group_id, is_open_im, is_open_channel_code, iso_code, language, is_v2_subscription } =
      (nuxtApp.$_need_init_data || {}) as any

    const path = nuxtApp._route.path
    // 如果是落地页的话不走自动浏览器检测
    if (
      (language && useIsLandingByLanguage(path)) ||
      (language && isInlineClient) ||
      (language && useIsSensitiveLogin(path))
    ) {
      await setI18nLocale(nuxtApp, language)
    }
    useIsNewSubPage().value = is_v2_subscription
    is_open_im && setImGroupId(group_id)
    setOpenChannelCode(is_open_channel_code)
    setCountryIsoCode(iso_code)
    // 客户端不设置im
    ;(!is_open_im || isInlineClient) && setImGroupId(null)
    // nuxtApp.$i18n.onLanguageSwitched = (oldLocale, newLocale) => {
    //   if (nuxtApp.$_language_change && process.client) {
    //     window.location.reload()
    //   }
    // }
  }
})
