export enum PointEvent {
  Demo = "demo",
  Download = "official_download",
  Landing = "kol_lead",
  OfficialDownload = "official_download",
  OfficialPricing = "official_pricing",
  OfficialPricingSwitch = "official_pricing_switch",
  OfficialPricingCustom = "official_pricing_custom",
  OfficialPricingPurchase = "official_pricing_purchase",
  OfficialUndoCancel = "official_undo_cancel",
  OfficialUndoDowngrade = "official_undo_downgrade",
  OfficialReactivate = "official_reactivate",
  OfficialBilling = "official_billing",
  OfficialUpgrade = "official_upgrade",
  OfficialSignIn = "official_signin",
  OfficialSignUp = "official_signup",
  OfficialSocial = "official_social",
  OfficialForgotPassword = "official_forgot_password",
  OfficialAdEvent = "conversion",
  InviteePageCreateAccount = "invitee_page_create_account",
  InviteePageParty = "invitee_page_third_party",
  InviteePageClickDownload = "invitee_page_click_download",
  InvitationPopupJoin = "Invitation_popup_join",
  InvitationPopupClose = "Invitation_popup_close",
  invitationPageRule = "invitation_page_rule",
  invitationPageClickInvite = "invitation_page_click_invite",
  OfficialWebsiteSocail = "OfficialWebsite_socialEntrance",
  DataValidation = "data_validation"
}

export enum LandingOperate {
  ClickTrial = "click_trial",
  ClickDiscover = "click_discover",
  CloseDiscover = "close_discover",
  ClickDownload = "click_download"
}
