import { PointEvent } from "~~/constant/gtag"
import {
  START_PAGE_PATH,
  PAYMENT_PAGE_PATH,
  HELP_MORE_PAGE_PATH,
  HELP_PAGE_PATH,
  DOWNLOAD_PAGE_PATH,
  HOME
} from "@/constant/route"

export enum IOfficialSignInOperate {
  click_signin = "click_signin",
  click_popup_signin = "click_popup_signin",
  input_email = "input_email",
  enter_password = "input_password",
  click_forget_password = "click_forget_password",
  click_google = "click_google",
  click_facebook = "click_facebook",
  click_signup = "click_signup",
  from_app = "from_app"
}

export enum IOfficialSignInPosition {
  signin_landing = "signin_landing",
  signin_features = "signin_features",
  signin_started = "signin_started",
  signin_pricing = "signin_pricing",
  signin_help = "signin_help",
  signin_download = "signin_download"
}

export const pointSignInPositionDict = () => {
  return {
    [unref(HOME)]: IOfficialSignInPosition.signin_landing,
    [unref(START_PAGE_PATH)]: IOfficialSignInPosition.signin_started,
    [unref(PAYMENT_PAGE_PATH)]: IOfficialSignInPosition.signin_pricing,
    [unref(HELP_PAGE_PATH)]: IOfficialSignInPosition.signin_help,
    [unref(HELP_MORE_PAGE_PATH)]: IOfficialSignInPosition.signin_help,
    [unref(DOWNLOAD_PAGE_PATH)]: IOfficialSignInPosition.signin_download
  }
}

export enum IOfficialSignUpOperate {
  input_email = "input_email",
  input_password = "input_password",
  input_password_again = "input_password_again",
  check_terms = "check_terms",
  prompt_please_tick = "prompt_please_tick",
  click_signup = "click_signup",
  click_verification_change = "click_verification_change",
  click_verification_resend = "click_verification_resend",
  click_verification_signin = "click_verification_signin",
  click_signin = "click_signin",
  click_google = "click_google",
  click_facebook = "click_facebook"
}

export enum IOfficialSocialOperate {
  click_social_agreement_agree = "click_social_agreement_agree"
}

export enum IOfficialForgotPasswordOperate {
  input_email = "input_email",
  input_email_submit = "input_email_submit",
  input_verification_code = "input_verification_code",
  input_new_password = "input_new_password",
  input_new_password_again = "input_new_password_again",
  click_reset = "click_reset"
}

// 登录
interface IOfficialSignIn {
  official_operate?: IOfficialSignInOperate
  official_position?: IOfficialSignInPosition
}
//注册
interface IOfficialSignUp {
  official_operate?: IOfficialSignUpOperate
}
//第三方账号
interface IOfficialSocial {
  official_operate?: IOfficialSocialOperate
}

// 忘记密码
interface IOfficialForgotPassword {
  official_operate?: IOfficialForgotPasswordOperate
}

export function useLoginGTag() {
  const { $gtag } = useNuxtApp()
  const { setEvent } = $gtag
  // 2.setEvent声明的变量->在方法中直接调用
  const _PointOfficialSignIn = (params: IOfficialSignIn) => setEvent(PointEvent.OfficialSignIn, params)
  const _PointOfficialSignUp = (params: IOfficialSignUp) => setEvent(PointEvent.OfficialSignUp, params)
  const _PointOfficialSocial = (params: IOfficialSocial) => setEvent(PointEvent.OfficialSocial, params)
  const _PointOfficialForgotPassword = (params: IOfficialForgotPassword) =>
    setEvent(PointEvent.OfficialForgotPassword, params)
  return {
    _PointOfficialSignIn,
    _PointOfficialSignUp,
    _PointOfficialSocial,
    _PointOfficialForgotPassword
  }
}
