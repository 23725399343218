import { IErrorLoginType, LOGIN_SCENE } from "@/constant/login"

export const useVerCodeCountDown = (sId: string, emit?: any, resendCallback?: (...args: any[]) => any) => {
  const proxy = useProxy() as any

  const { isTiming, timekeeping, count } = useCountdown({ sId })

  const { judgeVerCodeCapthaFn, delTkToSess, getTkToSess } = useVerCodeCaptha()

  const verCodeRef = ref()

  const invalidCodeShow = ref(false)

  const resendCode = useDebounceFn(() => {
    if (!isTiming.value) {
      verCodeRef.value && verCodeRef.value.resetCode()
      invalidCodeShow.value = false
      sendCodeApi()
      emit && emit("resendCode")
      resendCallback && resendCallback()
    }
  }, 300)

  async function sendCodeApi() {
    // 兼容处理
    const phone = unref(proxy?.phone)?.inputValue || unref(proxy?.registryQuery)?.phone?.inputValue
    const phone_cc = unref(proxy?.phone)?.countryValue || unref(proxy?.registryQuery)?.phone?.countryValue
    const email = proxy.email || proxy?.registryQuery?.email
    const scene = unref(proxy?.sceneRecord) || proxy?.scene
    // 发送手机验证码的情况下需要验证人机
    if (phone) {
      try {
        await judgeVerCodeCapthaFn({
          phone_cc,
          phone
        })
      } catch (e) {}
    }
    try {
      const params = {
        t: getTkToSess(),
        email,
        phone,
        phone_cc,
        scene
      }
      // 辅助邮箱需要更换验证码接口
      if (scene === LOGIN_SCENE.RECOVER_EMAIL) {
        const user_email = unref(proxy.user_email) || unref(proxy.registryQuery?.user_email)
        await sendRecoverVerCodeAPi({ ...params, user_email })
      } else {
        await sendVerCodeAPi(params)
      }
      nextTick(() => {
        verCodeRef.value && verCodeRef.value.focus()
      })
    } catch (e) {
      if (e?.code == IErrorLoginType.AuthVerify) {
        sendCodeApi()
      }
    }
    delTkToSess()
    timekeeping()
    // useSuccessMessage("The email verification code has been sent")
  }

  return {
    isTiming,
    timekeeping,
    count,
    verCodeRef,
    resendCode,
    sendCodeApi,
    invalidCodeShow
  }
}
