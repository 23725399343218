import { CookiesOptions } from "~~/constant/cookies"
import { APPEND_SDK_JS } from "@/constant/sdk"
import { IAppendScriptBatchArr } from "@/composables/utils"

async function addScript(arr: IAppendScriptBatchArr = []) {
  return asyncAppendScriptBatch(arr)
}

export default function useSdk() {
  const isEnabled = useIsPoint(CookiesOptions.Marketing)

  const useAddScript = async () => {
    if (document.querySelector("#fb-sdk")) return
    try {
      if (isEnabled) {
        await addScript(APPEND_SDK_JS)
      }
    } catch (e) {
      console.log("inject sdk error", e)
    }
  }

  return {
    useAddScript
  }
}
