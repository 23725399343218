<template>
  <span class="scroll-text" @mouseenter="debHandlerEnter" @mouseleave="debHandlerLeave">
    <span class="scroll-text__content top" :class="{ 'is-hover': isHover, 'is-leave': !isHover }">
      <span class="scroll-text__text">
        <slot />
      </span>
    </span>

    <span
      class="scroll-text__content bottom"
      :style="{ '--hover-color': hoverColor, '--hover-background-color': hoverBackground }"
      aria-hidden="true"
      :class="{ 'is-hover': isHover, 'is-leave': !isHover }"
    >
      <span class="scroll-text__text">
        <slot />
      </span>
    </span>
  </span>
</template>
<script lang="ts" setup>
import { CSSProperties } from "vue"

interface ScrollTextProps {
  hoverColor?: CSSProperties["color"]
  hoverBackground?: CSSProperties["background"]
}
withDefaults(defineProps<ScrollTextProps>(), {
  hoverColor: "#fff",
  hoverBackground: "#000"
})
const isHover = ref(false)
function handleMouseEnter(e) {
  isHover.value = true
}

function handleMouseLeave(e) {
  isHover.value = false
}
const debHandlerEnter = useDebounceFn(handleMouseEnter, 100)
const debHandlerLeave = useDebounceFn(handleMouseLeave, 100)
</script>
<style lang="scss" scoped>
.scroll-text,
.scroll-text__content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  text-align: center;
  justify-content: center;
}

.scroll-text {
  overflow: hidden;
  height: 100%;
  border-radius: inherit;
}

.scroll-text__content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;

  &:nth-child(2) {
    position: absolute;
    left: 0;
    top: 100%;
    border-radius: inherit;
    color: var(--hover-color);
    background-color: var(--hover-background-color);
    background: var(--hover-background-color);
  }
}

.is-hover {
  transition: all 0.6s cubic-bezier(0.25, 0.74, 0.22, 0.99);
  transform: translateY(-100%);
}

.is-leave {
  transition: all 0.6s cubic-bezier(0.25, 0.74, 0.22, 0.99);
  transform: translateY(0);
}

.top {
  &.is-hover {
    opacity: 0.5;
  }

  &.is-leave {
    opacity: 1;
  }
}

.bottom {
  &.is-hover {
    opacity: 1;
  }

  &.is-leave {
    opacity: 0.5;
  }
}
</style>
