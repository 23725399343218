export const EMAIL_RE = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/

export const SPECIAL_CHART_RE = /[`@$%&*()+=<>?:"{}|,\/;\\[\]·！@￥%……&*（）——+={}|《》？：“”【】、；‘，。、「」]/

export const CHECK_BROWSER_ANDROS_RE = /(msie|firefox|chrome|opera).*?([\d.]+)/

export const CHECK_BROWSER_NORMAL_RE = /(msie|firefox|chrome|opera|version|crios|fxios|edgios).*?([\d.]+)/

export const VERITY_CODE_RE = /^\d{4}$/

// 日本电话号码正则
export const JP_PHONE_RE = /^\d{10,11}$/

// 美国电话号码正则
export const US_PHONE_RE = /^(\+?1\s*[-.\s]?)?(\(\d{3}\)|\d{3})[-.\s]?\d{3}[-.\s]?\d{4}$/

// 非空正则
export const IS_NULL_RE = /^.+$/
