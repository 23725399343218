<template>
  <div class="code-input-wrapper">
    <ElInput v-bind="$attrs" v-model="model" :class="inputClass">
      <template #suffix>
        <slot name="action" :is-timing="isTiming" :count="count">
          <span class="send-btn" :class="{ 'is-disabled': isDisabled, 'is-sent': isTiming }" @click="sendVerCode">{{
            text
          }}</span>
        </slot>
      </template>
    </ElInput>
    <div v-if="showErrorMessage && isError" class="code-input-wrapper__message">{{ errorMessage }}</div>
  </div>
</template>
<script lang="ts" setup>
import { PropType } from "vue"
import { IScene } from "~~/composables/api/common"
import { ElInput } from "element-plus"

const props = defineProps({
  modelValue: {
    type: String,
    default: ""
  },
  scene: {
    type: Number as PropType<IScene>,
    default: 0
  },
  inputClass: {
    type: String as PropType<string[] | string | Recordable>,
    default: ""
  },
  showErrorMessage: {
    type: Boolean,
    default: false
  },
  email: {
    type: String,
    default: ""
  },
  disabled: {
    type: Boolean,
    default: false
  }
})

const { email } = toRefs(props)

const emits = defineEmits(["update:modelValue", "sent", "error"])

const model = computed({
  get() {
    return props.modelValue
  },
  set(val) {
    emits("update:modelValue", val)
  }
})

const { isTiming, timekeeping, count } = useCountdown()

const text = computed(() => {
  return isTiming.value ? `${useTranslateI18n("common.sent")}(${count.value}s)` : useTranslateI18n("common.send")
})

const { validator, isError, errorMessage } = useValidator({
  target: email
})

const isDisabled = computed(() => {
  return props.disabled || isTiming.value || isError.value || !props.email
})

let sendFlag = false
async function sendVerCode() {
  if (!isTiming.value && props.scene && !props.disabled && !sendFlag) {
    sendFlag = true
    validator()
    await nextTick()
    if (!isError.value) {
      await sendVerCodeAPi({
        email: props.email,
        scene: props.scene
      })
      sendFlag = false
      emits("sent")
      timekeeping()
    } else {
      sendFlag = false
      emits("error", errorMessage.value)
    }
  }
}

defineExpose({
  sendVerCode
})
</script>
<style lang="scss" scoped>
.code-input-wrapper {
  width: 100%;

  :deep(.el-input__wrapper) {
    box-shadow: unset;

    .el-input__inner {
      &:focus {
        border-color: unset;
      }
    }
    .el-input__suffix-inner {
      flex-direction: row-reverse;
    }

    &.is-focus {
      box-shadow: unset;
    }
  }

  .send-btn {
    position: relative;
    cursor: pointer;
    padding-left: 8px;
    font-size: 14px;
    line-height: 17px;
    color: rgba(0, 0, 0, 0.3);

    &::before {
      content: "";
      position: absolute;
      left: -1px;
      width: 0.5px;
      height: 18px;
      z-index: 1;
      top: 50%;
      transform: translateY(-50%);
      background-color: rgba(0, 0, 0, 0.15);
    }
    &.is-disabled {
      cursor: not-allowed;
    }

    &.is-sent {
      min-width: 88px;
    }
  }
}
</style>
