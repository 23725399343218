import { JoinFacebookSource } from "@/constant/mp"
import { AbOn } from "@/composables/api/download"

export enum SoftwareType {
  Trial = 2,
  Stable = 1,
  All = 3,
  Channel = 9 // 渠道包
}

export const SoftwareTypeDict = {
  [AbOn.All]: SoftwareType.All,
  [AbOn.Trial]: SoftwareType.Trial,
  [AbOn.Stable]: SoftwareType.Stable
}

export enum JoinFacebookSourceKeyEnum {
  Kol = "kol",
  Invitee = "invitee"
}

export const joinFacebookSourceMap: Record<JoinFacebookSourceKeyEnum, JoinFacebookSource> = {
  [JoinFacebookSourceKeyEnum.Kol]: JoinFacebookSource.KolLanding,
  [JoinFacebookSourceKeyEnum.Invitee]: JoinFacebookSource.InviteeLanding
}

export const DOWNLOAD_INFO_KEY = "download_info_key"
