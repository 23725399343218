<template>
  <div class="security-dialog">
    <div class="security-dialog-header">
      <div class="title">{{ useTranslateI18n(`profile.security.t1`) }}</div>
      <div class="desc">{{ useTranslateI18n(`profile.security.sign`) }}</div>
    </div>
    <div v-loading="loading" class="security-dialog-content">
      <div class="select">{{ useTranslateI18n(`profile.security.choose`) }}</div>
      <div class="list">
        <template v-for="(item, index) in list" :key="index">
          <div :class="['list-item', item.disabled ? 'disabled' : '']" @click="select(item.type)">
            <div class="icon">
              <component :is="item.icon"></component>
            </div>
            <div class="step">
              <span>{{ item.title }}</span>
            </div>
          </div>
          <ElDivider v-if="index + 1 < list.length"></ElDivider>
        </template>
      </div>
    </div>
  </div>
</template>

<script setup lang="tsx">
import PasswordSvg from "~/assets/icons/pages/profile/password.svg"
import EmailSvg from "~/assets/icons/pages/profile/email.svg"
import SMSSvg from "~/assets/icons/pages/profile/sms.svg"
import { VerifyInfo, VerifyLogInfo } from "~/constant/pages/dialog/securityLoginDialog"
import { VERIFY_TYPE } from "~/constant/login"
import { useTranslateI18n } from "@/composables/store/i18n-country"

const props = defineProps({
  preInfo: {
    type: Object as PropType<VerifyLogInfo>,
    default: () => ({
      email: "",
      phone: {
        countryValue: "",
        inputValue: ""
      },
      password: ""
    })
  }
})
const emits = defineEmits(["selectType"])

const loading = ref(false)

const formModel = reactive({})

const list = ref<VerifyInfo[]>([
  {
    icon: <PasswordSvg />,
    title: useTranslateI18n(`profile.security.password`),
    type: VERIFY_TYPE.password
  },
  {
    icon: <EmailSvg />,
    title: useTranslateI18n(`profile.security.email`),
    type: VERIFY_TYPE.email
  },
  {
    icon: <SMSSvg />,
    title: useTranslateI18n(`profile.security.sms`),
    type: VERIFY_TYPE.phone
  }
])

watch(
  () => props.preInfo,
  (info) => {
    const { email, phone, password } = info
    if (password) {
      const ele = unref(list).find((e) => e.type === VERIFY_TYPE.password)
      ele && (ele.disabled = true)
    }
    if (email && !password) {
      const ele = unref(list).find((e) => e.type === VERIFY_TYPE.email)
      ele && (ele.disabled = true)
    }
    if (!email && phone.inputValue && !password) {
      const ele = unref(list).find((e) => e.type === VERIFY_TYPE.phone)
      ele && (ele.disabled = true)
    }
  },
  { immediate: true, deep: true }
)

const init = async () => {
  const { email, phone } = props.preInfo
  try {
    loading.value = true
    const {
      verified_methods,
      phone: phone_n,
      phone_cc: phone_cc_n,
      email: email_n
    } = await getAuthList({ email, phone: phone?.inputValue, phone_cc: phone?.countryValue })
    Object.assign(formModel, {
      email: email_n,
      phone: {
        countryValue: phone_cc_n,
        inputValue: phone_n
      }
    })
    const bitList = bitToArr(verified_methods)
    list.value = unref(list).filter((ele) => bitList.includes(ele.type))
  } finally {
    loading.value = false
  }
}

const select = useDebounceFn((val: VERIFY_TYPE) => {
  emits("selectType", {
    type: val,
    formModel
  })
}, 300)

onMounted(() => init())
</script>

<style scoped lang="scss">
.security-dialog {
  background-color: #fff;
  font-size: 24px;
  font-style: normal;
  line-height: normal;
  color: #000;

  &-header {
    margin-bottom: 24px;
    .title {
      color: #000;
      font-family: "SF Pro";
      font-weight: 590;
      margin-bottom: 8px;
    }

    .desc {
      color: rgba(0, 0, 0, 0.55);
      font-size: 14px;
      font-weight: 400;
    }
  }

  &-content {
    .select {
      color: #000;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 16px;
    }
    .list {
      display: flex;
      flex-direction: column;

      &-item {
        display: flex;
        padding: 16px 0;
        color: #000;
        font-size: 16px;
        font-weight: 510;
        cursor: pointer;

        .icon {
          width: 24px;
        }

        .step {
          display: flex;
          flex-wrap: wrap;
          gap: 4px;
          line-height: 24px;
          padding-left: 8px;
        }
      }

      &-item.disabled {
        opacity: 0.3;
        pointer-events: none;
      }

      :deep(.el-divider--horizontal) {
        margin: 12px 0;
      }
    }
  }
}
</style>
