<template>
  <div class="progress">
    <el-icon class="icon"><Document /></el-icon>
    <div class="progress-bar">
      <div class="info-area">
        <div class="name">{{ props.data.fileName }}{{ getErrorName }}</div>
        <div class="operation-area">
          <svg
            :class="['icon', { hide: !uploadError }]"
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            @click="reloadHander"
          >
            <path
              opacity="0.55"
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.64594 3.84615C11.2162 3.84615 13.2998 5.92975 13.2998 8.5C13.2998 11.0702 11.2162 13.1538 8.64594 13.1538C7.26766 13.1538 6.02976 12.5552 5.177 11.6025L4.89485 11.2872L4.26435 11.8515L4.5465 12.1668C5.55299 13.2913 7.01698 14 8.64594 14C11.6835 14 14.1459 11.5376 14.1459 8.5C14.1459 5.46243 11.6835 3 8.64594 3C6.02063 3 3.82494 4.83938 3.27729 7.29971L2.55815 6.22099L1.8541 6.69035L3.21699 8.73468C3.3466 8.9291 3.60928 8.98163 3.80369 8.85202L5.84802 7.48914L5.37866 6.78509L4.06789 7.65894C4.46376 5.49016 6.36286 3.84615 8.64594 3.84615Z"
              fill="black"
              stroke="black"
              stroke-width="0.4"
              stroke-linecap="square"
              stroke-linejoin="round"
            />
          </svg>
          <svg
            class="icon"
            width="16"
            height="17"
            viewBox="0 0 16 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            @click="removeHander"
          >
            <g opacity="0.55">
              <path
                d="M7.96425 7.63016L12.1224 3.50146L13 4.37281L8.84182 8.50151L12.9999 12.6301L12.1223 13.5015L7.96425 9.37286L3.87766 13.4305L3.00009 12.5591L7.08668 8.50151L3 4.44381L3.87757 3.57246L7.96425 7.63016Z"
                fill="black"
              />
            </g>
          </svg>
        </div>
      </div>
      <div class="bar" :class="getProgressClass" :style="`--transform-progress: ${props.data.progress - 100}%`"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { Document } from "@element-plus/icons-vue"
import { uploadState } from "~~/constant/pages/help"

const emits = defineEmits(["remove", "reload"])

interface IProps {
  data: {
    fileName: string
    progress: number
    state: uploadState
  }
}

const uploadError = computed(() => {
  return props.data.state === uploadState.error
})

const props = withDefaults(defineProps<IProps>(), {
  data: () => {
    return {
      fileName: "test",
      progress: 0,
      state: uploadState.uploading
    }
  }
})

const getProgressClass = computed(() => {
  if (props.data.state === uploadState.success) {
    return "success-bar"
  } else if (props.data.state === uploadState.uploading) {
    return "uploading-bar"
  } else if (props.data.state === uploadState.error) {
    return "error-bar"
  }
})

const getErrorName = computed(() => {
  return uploadError.value ? `(${useTranslateI18n("help.cardForm.uploadErr")})` : ""
})

const reloadHander = () => {
  emits("reload", props.data)
}

const removeHander = () => {
  emits("remove", props.data)
}
</script>

<style scoped lang="scss">
.progress {
  box-sizing: border-box;
  width: 100%;
  height: 32px;
  padding: 0 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 7px;
  .icon {
    width: 16px;
    height: 16px;
  }
  .progress-bar {
    flex: 1;
    overflow: hidden;
    .info-area {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      .name {
        width: 100%;
        color: rgba(0, 0, 0, 0.55);
        font-family: SF Pro;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        line-height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      .operation-area {
        display: flex;
        flex-direction: row;
        gap: 8px;
        flex-shrink: 0;
        .icon {
          cursor: pointer;
          opacity: 1;
          transition: all 0.3s;
        }
        .hide {
          visibility: hidden;
          opacity: 0;
          pointer-events: none;
        }
      }
    }
    .bar {
      width: 100%;
      height: 2px;
      transform: translate(var(--transform-progress));
    }
  }
  .success-bar {
    background: transparent;
  }
  .uploading-bar {
    transition: all 0.3s;
    background: #1877f2;
  }
  .error-bar {
    transition: all 0.3s;
    background: #ff7d00;
  }
}
</style>
