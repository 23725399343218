export enum mpEventName {
  PaymentPurchaseSuccess = "evoto_website_purchaseSuccess",
  PaymentWebsiteOfficialPricing = "website_offcialPricingButtonCick",
  EvotoWebsiteDownloadApp = "website_officialDownloadButtonClick",
  EvotoWebsitePurchase = "evoto_website_purchase",
  EvotoWebsiteSignInPop = "website_viewOfficialSignIn",
  EvotoWebsiteSignIn = "evoto_website_signIn",
  EvotoWebsiteSignupPop = "website_viewOfficialSignUp",
  EvotoWebsiteSignup = "evoto_website_signUp",
  EvotoInviteeLanding = "evoto_invitee_landing",
  CommonPageView = "website_viewOfficialPage",
  EvotoWebsiteJoinFacebook = "evoto_website_joinFacebook",
  EvotoInviteeLandingSignUp = "evoto_invitee_landing_signUp",
  evoto_official_page_button = "website_officialPageButtonClick",
  website_officialPricingBuyButtonClick = "website_officialPricingBuyButtonClick",
  website_officialSignInButtonClick = "website_officialSignInButtonClick",
  website_officialSignUpButtonClick = "website_officialSignUpButtonClick",
  website_officialSignInResult = "website_officialSignInResult",
  h5_signInResult = "h5_signInResult",
  website_officialSignUpResult = "website_officialSignUpResult",
  h5_signUpResult = "h5_signUpResult",
  h5_viewH5Page = "h5_viewH5Page",
  h5_downloadButtonClick = "h5_downloadButtonClick",
  h5_buyButtonClick = "h5_buyButtonClick",
  DT_h5PricingBuyButtonClick = "DT_h5PricingBuyButtonClick",
  DT_h5PricingButtonClick = "DT_h5PricingButtonClick",
  website_officialGroupJoinButtonClick = "website_officialGroupJoinButtonClick",
  website_contactSalesSubmitButtonClick = "website_allContactSalesSubmitButtonClick",
  h5_DetailButtonClick = "h5_DetailButtonClick",
  h5_redeemH5ButtonClick = "h5_redeemH5ButtonClick",
  website_page_view = "website_page_view",
  website_button_click = "website_button_click",
  website_popup_view = "website_popup_view",
  DataValidation = "data_validation"
}

// 客户端埋点事件
export enum ClientMpEventName {
  evoto_DT_pricing = "evoto_DT_pricing",
  evoto_DT_purchasePage = "evoto_DT_purchasePage"
}

export enum JoinFacebookSource {
  Website = "evoto_website",
  KolLanding = "evoto_kol_landing",
  InviteeLanding = "evoto_invitee_landing"
}
