import { $message, $loading } from "@/utils/message"
import { IMessageTypes } from "@/types/element"
import { MessageProps, ElMessageBox, ElMessageBoxOptions } from "element-plus"

// 成功message
export const useSuccessMessage = (options: string | Partial<MessageProps>) => {
  if (typeof options === "string") {
    return $message(options, "success" as IMessageTypes)
  } else {
    return $message(options as MessageProps, "success" as IMessageTypes)
  }
}

// error message
export const useErrorMessage = (options: string | Partial<MessageProps>) => {
  if (typeof options === "string") {
    return $message(options, "error" as IMessageTypes)
  } else {
    return $message(options as MessageProps, "error" as IMessageTypes)
  }
}

// danger message
export const useWarningMessage = (options: string | Partial<MessageProps>) => {
  if (typeof options === "string") {
    return $message(options, "warning" as IMessageTypes)
  } else {
    return $message(options as MessageProps, "warning" as IMessageTypes)
  }
}

// info message
export const useInfoMessage = (options: string | Partial<MessageProps>) => {
  if (typeof options === "string") {
    return $message(options, "info" as IMessageTypes)
  } else {
    return $message(options as MessageProps, "info" as IMessageTypes)
  }
}

// loading
export const useLoading = (
  options: Partial<{
    target: HTMLElement
    fullscreen: boolean
    lock: boolean
    text: string
    spinner: string
    background: string
    "custom-class": string
    [x: string]: any
  }>
) => {
  return $loading(options)
}

// confirm
export const useConfirm = (options: ElMessageBoxOptions) => {
  return ElMessageBox({
    center: true,
    showCancelButton: true,
    confirmButtonText: useTranslateI18n("profile.confirm.ok"),
    cancelButtonText: useTranslateI18n("profile.confirm.cancel"),
    customClass: "pix-messageBox_custom",
    title: "",
    message: "",
    ...options
  })
}

//
export const useLoadingHooks = (options?: Record<string, any>) => {
  const loadingInstance = ref()

  const loadingRef = ref()

  const closeLoading = () => {
    loadingInstance.value && loadingInstance.value.close()
    loadingInstance.value = undefined
  }

  const isClick = computed(() => loadingInstance.value)

  const openLoading = () => {
    loadingInstance.value = useLoading({
      target: loadingRef.value,
      ...options
    })
  }
  return {
    isClick,
    loadingRef,
    loadingInstance,
    closeLoading,
    openLoading
  }
}
