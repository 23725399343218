export enum DownloadPlatform {
  Mac = "mac",
  Win = "win",
  MacIntel = "macIntel",
  DownloadMac = "download-mac",
  DownloadWin = "download-win",
  DownloadMacIntel = "download-mac-intel"
}

export const downloadPlatformAppType = {
  [DownloadPlatform.MacIntel]: 11,
  [DownloadPlatform.Win]: 12,
  [DownloadPlatform.Mac]: 13,
  [DownloadPlatform.DownloadMacIntel]: 14,
  [DownloadPlatform.DownloadWin]: 15,
  [DownloadPlatform.DownloadMac]: 16
}

export const downloadPlatformInstallerAppTypeList = [
  downloadPlatformAppType[DownloadPlatform.DownloadMacIntel],
  downloadPlatformAppType[DownloadPlatform.DownloadWin],
  downloadPlatformAppType[DownloadPlatform.DownloadMac]
]

// 对应的下载的安装器
export const downloadPlatformInstallerAppType = {
  [downloadPlatformAppType[DownloadPlatform.MacIntel]]: downloadPlatformAppType[DownloadPlatform.DownloadMacIntel],
  [downloadPlatformAppType[DownloadPlatform.Win]]: downloadPlatformAppType[DownloadPlatform.DownloadWin],
  [downloadPlatformAppType[DownloadPlatform.Mac]]: downloadPlatformAppType[DownloadPlatform.DownloadMac]
}

// 1 是点击事件 2是跳转链接 3是hover -》 navbar的头部
export enum IClickType {
  Click = 1,
  Skip = 2,
  Hover = 3
}

export const browserVersionBlacklistMap = {
  chrome: 78,
  crios: 78, // Chrome for IOS
  firefox: 78,
  fxios: 78, // Firefox for IOS
  safari: 12,
  edgios: 79,
  msie: 12
}

export const supportEmail = "support@evoto.ai"
