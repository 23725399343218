import { VERIFY_TYPE } from "~/constant/login"
import { GOOGLE_CLOUD_URL } from "~~/constant/config"

// export const getAssets = (p: string, dir = "images") => {
//   let module
//   switch (dir) {
//     case "icons":
//       module = import.meta.glob(`/assets/icons/**/*`, { eager: true })
//       break

//     case "videos":
//       module = import.meta.glob(`/assets/videos/**/*`, { eager: true })
//       break

//     default:
//       module = import.meta.glob(`/assets/images/**/*`, { eager: true })
//       break
//   }
//   const path = p.startsWith("/") ? "/assets" + p : `/assets/${p}`
//   return module[path]?.default ?? ""
// }

export const getCloudAssets = (p: string) => {
  return `${GOOGLE_CLOUD_URL}${p}`
}

export function bytesToMB(bytes) {
  return bytes / (1024 * 1024)
}

export function delay(time: number) {
  return new Promise((resolve, reject) => {
    const timeoutobj = setTimeout(() => {
      resolve(null)
      clearTimeout(timeoutobj)
    }, time)
  })
}

export function bitToArr(bit: number) {
  return [VERIFY_TYPE.email, VERIFY_TYPE.phone, VERIFY_TYPE.password].filter((e) => e & bit)
}
