import CommonNormalDialog from "~~/components/common/CommonNormalDialog.vue"
import { CSSProperties, VNode, createVNode, render } from "vue"

export const useCommonNormalDialog = (options?: {
  zIndex?: number
  popperClass?: string
  width?: number
  headTitle?: string
  headDesc?: string
  contentDesc?: string
  submitBtnText?: string
  cancelBtnText?: string
  submitCallback?: ({ closeLoading, closeDialog }: { closeLoading: () => any; closeDialog: () => any }) => any
  cancelCallback?: ({ closeLoading, closeDialog }: { closeLoading: () => any; closeDialog: () => any }) => any
  btnDisabled?: { submit: boolean; cancel: boolean }
  contentSlots?: ({ close }: { close: () => any }) => VNode
  showCloseIcon?: boolean | Ref<boolean>
  close?: (...args: any[]) => any
  loadSuccess?: (...args: any[]) => any
  dialogWrapperStyle?: CSSProperties
}) => {
  // 挂载  因为dialog是teleport 无需append to body
  const vm = createVNode(
    CommonNormalDialog,
    {
      ...options
    },
    {
      content: options.contentSlots
    }
  )
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
