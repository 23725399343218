import { tsSdkEventName } from "@/constant/enumtsskd"
import tsTrackingSdkInstance from "@/utils/tssdk"
const stSdkSetEvent = tsTrackingSdkInstance.setEvent
const eventName = tsSdkEventName.DataValidation
//自研1
export function useTsSdkTest() {
  //点击事件
  const _pointTsSdkEvent = ({ action_type, action_detail }: { action_type: any; action_detail?: string }) => {
    stSdkSetEvent(eventName, { action_type, action_detail })
  }
  return {
    _pointTsSdkEvent
  }
}
