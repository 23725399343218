export const useVerCodeCaptha = () => {
  const { getTkToSess, delTkToSess } = useCaptcha({})

  function judgeVerCodeCapthaFn(
    params: { phone_cc: string; phone: string } = { phone_cc: "", phone: "" }
  ): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      try {
        const { n } = await postVc(params)
        if (n) {
          useAuthCodeDialog({
            success() {
              resolve(true)
            }
          })
        } else {
          resolve(true)
        }
      } catch (e) {
        reject(false)
      }
    })
  }

  return {
    judgeVerCodeCapthaFn,
    getTkToSess,
    delTkToSess
  }
}
