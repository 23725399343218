<template>
  <div class="common-upload">
    <el-upload
      ref="uploadRef"
      :auto-upload="false"
      :show-file-list="false"
      :on-change="beforeAvatarUpload"
      :on-exceed="handleExceed"
      :multiple="true"
      :limit="limit"
      drag
      action="#"
      :disabled="disabled"
      :class="{ disable: disabled }"
    >
      <div class="upload-content">
        <div class="desc">
          <img class="icon" src="@/assets/icons/common/upload_btn_blue.png" alt="" />
          <span
            v-html="
              uploadTitle ||
              useTranslateI18n(`help.cardForm.upDesc`, [
                {
                  type: 'static',
                  class: 'upload_image'
                }
              ])
            "
          ></span>
        </div>
      </div>
    </el-upload>

    <slot name="tip"></slot>

    <div v-if="!isEmpty(props.modelValue)" class="progress-area">
      <CommonFileProgress
        v-for="item in props.modelValue"
        :key="item.file.uid"
        :data="item"
        @reload="reload"
        @remove="removeFile"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import type { UploadInstance, UploadProps } from "element-plus"
import { Close } from "@element-plus/icons-vue"
// import { publishFirebase, geFileUrl, fileDir } from "@/utils/firebaseUpload"
import { uploadState } from "~~/constant/pages/help"
import { nanoid, random } from "nanoid"
import { ComputedRef } from "nuxt/dist/app/compat/capi"

interface IProps {
  modelValue: Record<
    string,
    {
      fileName: string
      file: File & { uid: string }
      progress: number
      state: uploadState
    }
  >
  uploadTitle?: string
  uploadSizeLimit?: number
  uploadErrorTypeMessage?: string
  uploadErrorLimitMessage?: string
  uploadExceedMessage?: string
  typeList: string[]
  limit: number
  disabled: boolean | ComputedRef<boolean>
}

const props = withDefaults(defineProps<IProps>(), {
  modelValue: () => {
    return {}
  },
  uploadTitle: () => {
    return ""
  },
  uploadSizeLimit: () => {
    return null
  },
  uploadErrorTypeMessage: () => {
    return useTranslateI18n(`help.cardForm.errMessage`)
  },
  uploadErrorLimitMessage: () => {
    return ""
  },
  uploadExceedMessage: () => {
    return useTranslateI18n(`help.cardForm.exceedMessage`)
  },
  typeList: () => {
    return ["image/jpg", "image/jpeg", "image/png", "image/gif"]
  },
  limit: () => 1,
  disabled: () => {
    return false
  }
})

const fileObj = ref<any>({})

const urlObj = {}

const hashObj = {}

const emit = defineEmits(["update:modelValue", "getUploadRef"])

let uploadRef = ref<UploadInstance>()

const handleExceed: UploadProps["onExceed"] = (files) => {
  console.log(props)
  useErrorMessage(props.uploadExceedMessage)
  // uploadRef.value!.clearFiles()
}

const removeFile = async ({ file }: { file: File }) => {
  const uid = (file as any).uid.toString()
  await uploadRef.value.handleRemove(file as any)
  fileObj.value = {
    ...Object.keys(fileObj.value)
      .filter((v) => v !== uid)
      .reduce((accumulator, key) => {
        return {
          ...accumulator,
          [key]: props.modelValue[key]
        }
      }, {})
  }
  emit("update:modelValue", fileObj.value)
}

const progressCallback = async (e, uid) => {
  if (!fileObj.value[uid]) {
    return
  }
  fileObj.value[uid] = {
    ...fileObj.value[uid],
    progress: e.progress * 100,
    state: uploadState.uploading
  }
  emit("update:modelValue", fileObj.value)
}

const errorCallback = (e) => {
  fileObj.value[e.uid] = {
    ...fileObj.value[e.uid],
    state: uploadState.error
  }
  emit("update:modelValue", fileObj.value)
}

const successCallback = (e) => {
  if (!fileObj.value[e.uid]) {
    return
  }
  fileObj.value[e.uid] = {
    ...fileObj.value[e.uid],
    progress: 0,
    state: uploadState.success,
    hash: hashObj[e.uid]
  }
  emit("update:modelValue", fileObj.value)
}

const beforeAvatarUpload = async (rawFile) => {
  let sizeLimit = props.uploadSizeLimit && props.uploadSizeLimit * 1024 * 1024
  let rawFileType = rawFile.raw.type
  let fileSize = rawFile.raw.size
  if (!props.typeList.includes(rawFileType)) {
    useErrorMessage(props.uploadErrorTypeMessage)
  } else if (sizeLimit && fileSize > sizeLimit) {
    useErrorMessage(props.uploadErrorLimitMessage)
    return
  }
  let file = rawFile.raw
  const sameFile = Object.values(props.modelValue).filter(
    (v) => v.file.name === file.name && v.file.size === file.size && v.file.type === file.type
  )
  if (sameFile.length > 0) {
    console.log("file same")
    // file.uid = sameFile[0].file.uid
    await uploadRef.value.handleRemove(file as any)
    return
  }
  fileObj.value[file.uid] = {
    fileName: file.name,
    file,
    progress: 0,
    state: uploadState.uploading
  }
  await emit("update:modelValue", fileObj.value)
  uploadReq(file.uid)
}

const reload = ({ file }: { file: File }) => {
  fileObj.value[(file as any).uid] = {
    ...fileObj.value[(file as any).uid],
    progress: 0,
    state: uploadState.uploading
  }
  emit("update:modelValue", fileObj.value)
  uploadReq((file as any).uid)
}

const uploadReq = async (uid) => {
  if (!urlObj[uid]) {
    const ext = props.modelValue[uid].fileName.slice(props.modelValue[uid].fileName.lastIndexOf(".") + 1)
    let hashId = nanoid()
    // 获取上传的url
    let url = await postGetUploadUrl({
      hash: hashId,
      ext,
      file_type: props.modelValue[uid].file.type
    })
    urlObj[uid] = url
    hashObj[uid] = hashId
  }

  let axios = await (await import("axios")).default

  axios({
    method: "put",
    url: urlObj[uid],
    data: props.modelValue[uid].file,
    onUploadProgress: (e) => progressCallback(e, uid),
    headers: {
      "content-type": props.modelValue[uid].file.type
    }
  })
    .then((res) => {
      ;(res as any).uid = uid
      successCallback(res)
    })
    .catch((err) => {
      ;(err as any).uid = uid
      errorCallback(err)
    })
}

onMounted(() => {
  emit("getUploadRef", uploadRef)
  const clearFiles = uploadRef.value.clearFiles
  uploadRef.value.clearFiles = (states = ["ready", "uploading", "success", "fail"]) => {
    clearFiles(states)
    fileObj.value = {}
  }
})
</script>

<style scoped lang="scss">
.common-upload {
  width: 100%;
  line-height: 17px;
  :deep(.el-upload) {
    width: 100%;
    .el-upload-dragger {
      padding: 0;
      border: 0;
    }
  }
  .upload-content {
    width: 100%;
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-radius: 8px;
    display: flex;
    align-items: center;
    padding: 10px 12px;
    .file-name {
      width: 100%;
      position: relative;
      padding-right: 16px;
      .close {
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        width: 14px;
        height: 14px;
        color: #000;
        top: 50%;
        right: 0;
        transform: translateY(-50%);
      }
    }
    span {
      font-weight: 510;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.85);
    }
    .desc {
      display: flex;
      flex-direction: row;
      align-items: center;
      span:nth-child(2) {
        margin: 0 4px;
      }
      :deep(.upload_image) {
        font-weight: 510;
        font-size: 14px;
        line-height: 17px;
        color: #1877f2;
      }
      .icon {
        width: 16px;
        height: 16px;
        margin-right: 4px;
      }
    }
  }
  .progress-area {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 12px;
  }
  .disable {
    opacity: 0.3;
    cursor: not-allowed;
    :deep(.el-upload) {
      pointer-events: none;
    }
  }
}
</style>
