<template>
  <div class="verify-account-wrapper">
    <div class="top-title">
      {{ useTranslateI18n("login.newLogin.t38") }}
    </div>

    <div class="form-content">
      <CommonForm
        ref="formRef"
        :form-input-list="formInputList"
        :form-inline="false"
        :form-rules="formRules"
        form-label-position="top"
        :form-model="formModel"
        @validate="signInValidator"
      />
    </div>

    <CommonButton ref="btnRef" class="submit-button" :button-style="buttonStyle1" @btn-click="submit"
      ><CommonAnimationScrollText>{{
        useTranslateI18n("profile.basicInformation.logout.t5")
      }}</CommonAnimationScrollText></CommonButton
    >
  </div>
</template>

<script setup lang="ts">
import { IFormPropsInputList } from "@/types/components/common/form"
import { VERIFY_TYPE } from "@/constant/login"

const emit = defineEmits(["submit"])

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Regular"
})

const formRef = ref()

let formModel = reactive({
  selectValue: 1
})

let formInputList = ref<IFormPropsInputList<typeof formModel>>([
  {
    type: "radio",
    model: "selectValue",
    options: [
      {
        label: useTranslateI18n("login.newLogin.t39"),
        value: VERIFY_TYPE.email
      },
      {
        label: useTranslateI18n("login.newLogin.t40"),
        value: VERIFY_TYPE.phone
      }
    ]
  }
])

const submit = () => {
  emit("submit", formModel.selectValue)
}
</script>

<style scoped lang="scss">
.verify-account-wrapper {
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .top-title {
    color: #000;
    @include fontSemibold;
    font-size: 24px;
    line-height: normal;
  }
  .submit-button {
    margin-top: 40px;
  }
  .form-content {
    margin-top: 48px;
    :deep(.pix-common-search) {
      .el-radio-group {
        flex-direction: column;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .el-radio {
          margin: 0;
          height: auto;
          margin-bottom: 16px;
          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
          .el-radio__label {
            color: #000;
            height: auto;
            line-height: normal;
          }
          .el-radio__input {
            &.is-checked {
              .el-radio__inner {
                background-color: #3083ff;
              }
            }
          }

          .el-radio__inner::after {
            width: 6px;
            height: 6px;
          }
        }
      }
    }
  }
}
</style>
