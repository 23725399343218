// 排除落地页不展示
import { useUser } from "@/store/user"

const liveChatHandlerEvent = () => {
  const use_user = useUser()

  const dictKey = {
    ["user_status"]: "User Blacklist Status",
    ["product_name"]: "Current Package Name",
    ["expiration"]: "Current Package Expiration Date",
    ["total_count"]: "Org Total Credits",
    ["used_count"]: "Org Used Credits",
    ["left_count"]: "Remaining Credits",
    ["order_time"]: "Latest Order Date",
    ["device_count"]: "Devices Currently Logged In",
    ["device_limit"]: "Device Limit"
  }

  ;(function (api) {
    ;[
      "ready",
      "new_event",
      "form_submitted",
      "greeting_hidden",
      "rating_submitted",
      "visibility_changed",
      "greeting_displayed",
      "availability_changed",
      "rich_message_button_clicked"
    ].forEach(function (eventName) {
      api.on(eventName, function (payload) {
        onEvent(eventName, payload)
      })
    })
  })(window.LiveChatWidget)

  const onEvent = async (eventName, payload) => {
    // console.log("[LiveChatWidget] on", eventName, payload)
    if (eventName === "ready") {
      if (use_user.isLogin) {
        window.LiveChatWidget.call("set_customer_name", use_user.nickname)
        window.LiveChatWidget.call("set_customer_email", use_user.userEmail)
        const data = await getLiveChatParams()
        const result = {}
        for (const key in dictKey) {
          if (Object.prototype.hasOwnProperty.call(dictKey, key)) {
            const val = dictKey[key]
            result[val] = (data || {})[key]
            window.LiveChatWidget.call("update_session_variables", result)
          }
        }
      }
    }
  }
}

// 添加im sdk
export function useIm(to) {
  // 排除不需要展示im的路由
  if (useLandingPageStaticPath(to?.path) || usePolicyPage()) return
  // 用户已登录取登录的国家，没有则取ip
  if (useImGroupId().value) {
    const group = useImGroupId().value
    const imDomID = `im_00101`
    const scriptContent = `window.__lc = window.__lc || {};
    window.__lc.group = ${group}
    window.__lc.license = 15857949;
    ;(function(n,t,c){function i(n){return e._h?e._h.apply(null,n):e._q.push(n)}var e={_q:[],_h:null,_v:"2.0",on:function(){i(["on",c.call(arguments)])},once:function(){i(["once",c.call(arguments)])},off:function(){i(["off",c.call(arguments)])},get:function(){if(!e._h)throw new Error("[LiveChatWidget] You can't use getters before load.");return i(["get",c.call(arguments)])},call:function(){i(["call",c.call(arguments)])},init:function(){var n=t.createElement("script");n.async=!0,n.type="text/javascript",n.src="https://cdn.livechatinc.com/tracking.js",t.head.appendChild(n)}};!n.__lc.asyncInit&&e.init(),n.LiveChatWidget=n.LiveChatWidget||e}(window,document,[].slice))
  `
    const noScriptContent = `<a href="https://www.livechat.com/chat-with/15857949/" rel="nofollow">Chat with us</a>, powered by <a href="https://www.livechat.com/?welcome" rel="noopener nofollow" target="_blank">LiveChat</a>`

    const script = document.createElement("script")
    script.id = imDomID
    script.innerHTML = scriptContent
    script.defer = true

    const noScript = document.createElement("noscript")
    noScript.innerHTML = noScriptContent

    if (!document.querySelector(`#${imDomID}`)) {
      document.body.appendChild(script)
      document.body.appendChild(noScript)
      script.onload = () => {
        liveChatHandlerEvent()
      }
    }
  }
}
