<template>
  <div :class="['country-select', showSelect ? 'country-select-show-select' : '']">
    <el-select
      v-show="showSelect"
      :key="selectIndexKey"
      v-model="modelValue.countryValue"
      popper-class="contact-sales_s"
      :filterable="true"
      :filter-method="countryFilter"
    >
      <!-- <div class="search-item-w">
        <el-input
          ref="search1"
          v-model="searchName"
          clearable
          class="search-item"
          :placeholder="useTranslateI18n('login.newLogin.t19')"
        >
          <template #prefix>
            <span :class="['s-flag', searchName ? 's-flag-active' : '']"></span>
          </template>
        </el-input>
      </div> -->
      <!-- <div class="options-wrapper"> -->
      <el-option v-for="item in countriesResultData" :key="item.iso2" :label="item.label" :value="item.dialCode">
        <div class="country-select-li">
          <span>
            {{ item.name }}
          </span>
          <span class="label-t">{{ item.label }}</span>
        </div>
      </el-option>
      <!-- </div> -->
      <!-- <template #empty>
        <div class="search-item-w">
          <el-input
            ref="search2"
            v-model="searchName"
            clearable
            class="search-item"
            :placeholder="useTranslateI18n('login.newLogin.t19')"
          >
            <template #prefix>
              <span :class="['s-flag', searchName ? 's-flag-active' : '']"></span>
            </template>
          </el-input>
        </div> -->
      <!-- <div class="select-no-data">
          <span>{{ useTranslateI18n("login.newLogin.t20") }}</span>
        </div> -->
      <!-- </template> -->
    </el-select>
    <el-input
      v-model="modelValue.inputValue"
      class="country-number"
      type="number"
      :disabled="disabled"
      :clearable="clearable"
      :placeholder="placeholder"
      @blur="changeBlur"
    ></el-input>
  </div>
</template>

<script setup lang="ts">
import { ICountrySelctModelValue } from "@/types/components/common/countrySelect"
import { countriesData, allCountries, countriesName } from "@/constant/pages/payment"
import { ElSelect, ElOption, ElInput } from "element-plus"

interface IProps {
  disabled?: boolean
  isRemotePhone?: boolean
  openCurrentCountry?: boolean
  clearable?: boolean
  placeholder?: string
  showSelect?: boolean
  modelValue: ICountrySelctModelValue
}

const searchName = ref("")

const search1 = ref()
const search2 = ref()

// watch(
//   () => searchName.value,
//   (newValue) => {
//     if (newValue.length) {
//       nextTick(async () => {
//         countriesResultData.value = originCountriesResultData.value
//         countriesResultData.value = countriesResultData.value.filter(
//           (_) => _.name.toLowerCase().includes(newValue.toLowerCase()) || _.dialCode.includes(newValue)
//         )
//         await nextTick()
//         if (countriesResultData.value.length) {
//           search1.value && search1.value.focus()
//         } else {
//           search1.value && search2.value.focus()
//         }
//       })
//     }
//   }
// )

let countriesResultData = ref([])
let originCountriesResultData = shallowRef([])

const selectIndexKey = ref(0)

const emit = defineEmits(["update:modelValue", "blur"])

const props = withDefaults(defineProps<IProps>(), {
  isRemotePhone: () => {
    return false
  },
  clearable: () => true,
  placeholder: () => "",
  modelValue: () => {
    return {
      countryValue: "",
      inputValue: ""
    } as ICountrySelctModelValue
  },
  showSelect: () => true,
  openCurrentCountry: () => true
})

const setInitValue = () => {
  if (!Object.keys(props.modelValue).length || !props.modelValue.countryValue || !props.modelValue.inputValue) {
    emit("update:modelValue", {
      countryValue: countriesResultData.value[0].dialCode,
      inputValue: ""
    })
  }
}

const countryFilter = (value) => {
  countriesResultData.value = originCountriesResultData.value
  countriesResultData.value = countriesResultData.value.filter(
    (_) => _.name.toLowerCase().includes(value.toLowerCase()) || _.dialCode.includes(value)
  )
}

const changeBlur = () => {
  emit("blur")
}

const getCurrentCountry = async () => {
  if (!Object.keys(props.modelValue).length || !props.modelValue.countryValue || !props.modelValue.inputValue) {
    let value =
      countriesResultData.value.find((_) => _.iso2 === useGetCountryIsoCode().value.toLocaleLowerCase())?.dialCode ||
      countriesResultData.value[0].dialCode
    emit("update:modelValue", {
      countryValue: value,
      inputValue: ""
    })
  }
}

const getRemoteCountry = async () => {
  let data = await getCountryList()
  let filterPhoneCountry = data.filter((_) => {
    return _.is_open_phone_verify
  })
  let filterCountryList = filterPhoneCountry.map((_) => {
    let id = (_.id as number) - 1
    return allCountries.find((item, i) => id === i)
  })

  let result = []

  for (let i = 0; i < filterCountryList.length; i++) {
    const c = filterCountryList[i]
    result[i] = {
      name: `${c[0]}` as string,
      label: `+${c[2]}`,
      nameCN: countriesName[i], // 中文的国家名称
      iso2: c[1],
      dialCode: c[2] as string | number,
      priority: c[3] || 0,
      areaCodes: c[4] || null
    }
  }

  return result
}

onMounted(async () => {
  if (props.isRemotePhone) {
    countriesResultData.value = await getRemoteCountry()
  } else {
    countriesResultData.value = countriesData
  }
  setInitValue()
  originCountriesResultData.value = useCloneDeep(countriesResultData.value)
  props.openCurrentCountry && getCurrentCountry()
  if (props.modelValue?.inputValue && props.modelValue?.countryValue) {
    emit("update:modelValue", {
      countryValue: props.modelValue?.countryValue,
      inputValue: props.modelValue?.inputValue
    })
  }
  selectIndexKey.value = selectIndexKey.value + 1
})
</script>

<style scoped lang="scss">
.country-select {
  width: 100%;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  &.country-select-show-select {
    > :deep(.el-input) {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      z-index: 1;
      .el-input__wrapper {
        padding-left: 100px !important;
        .el-input__inner {
          padding-left: 12px;
        }
      }
    }
  }
  :deep(.el-select) {
    z-index: 2;
    margin-left: 3px;
    width: unset !important;
    .el-select__input {
      margin-left: 0;
      width: 100%;
      height: 100%;
    }
    .el-input__inner {
      width: 55px;
      height: 37px !important;
    }
    .el-input__wrapper {
      border: none !important;
      padding: 0 4px !important;
      box-shadow: none !important;
      &::after {
        content: "";
        width: 1px;
        height: 18px;
        background-color: rgba(0, 0, 0, 0.15);
        position: absolute;
        right: 0;
      }
    }
    .el-input.is-focus .el-input__wrapper,
    .el-input__wrapper.is-focus {
      box-shadow: none !important;
    }
    .el-input__suffix {
      i {
        margin: 0;
        width: 24px;
        height: 24px;
        background: url("@/assets/icons/common/unfold-icon.png") no-repeat;
        background-size: 100% 100%;
        transform: rotate(0);
        svg {
          display: none;
        }
      }
    }
    .el-select__caret.is-reverse {
      transform: rotate(180deg);
    }
  }
}
</style>

<style lang="scss">
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type="number"] {
  -moz-appearance: textfield;
}
.contact-sales_s {
  .label-t {
    color: rgba(0, 0, 0, 0.55);
    margin-left: 4px;
  }
  .el-select-dropdown__list .el-select-dropdown__item:hover {
    color: #fff;
    .label-t {
      color: #fff;
    }
  }
  .el-select-dropdown__list .el-select-dropdown__item.selected {
    .label-t {
      color: rgba(255, 255, 255, 0.85);
    }
  }
  .country-select-li {
    display: flex;
    align-items: center;
  }

  .search-item-w {
    display: flex;
    padding-top: 4px;
    background-color: #fff;
    position: sticky;
    top: 0;
    z-index: 2;
    margin-bottom: 4px;
    border-radius: 10px;
    .search-item {
      .el-input__wrapper {
        background-color: #fff;
        box-shadow: none;
        padding: 0 12px;
        .el-input__inner {
          height: 25px;
          font-size: 12px;
          line-height: normal;
          color: rgba(0, 0, 0, 0.85);
          @include inputPlaceholder(12px, rgba(0, 0, 0, 0.2));
        }
        .el-input__prefix {
          .el-input__prefix-inner {
            .s-flag {
              display: inline-flex;
              width: 16px;
              height: 16px;
              background: url("@/assets/icons/common/ic_search.png") no-repeat;
              background-position: 0 0;
              background-size: 100% 100%;
              margin-right: 4px;
              &.s-flag-active {
                background: url("@/assets/icons/common/ic_search_active.png") no-repeat;
                background-position: 0 0;
                background-size: 100% 100%;
              }
            }
          }
        }
      }
    }
  }
  .options-wrapper {
    padding: 0 4px 4px;
  }
  .select-no-data {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 166px;
    padding: 20px;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.2);
  }
}
</style>
