import { LandingApi } from "~~/constant/api/landing"
import http from "~~/http"
import http2 from "@/http/http2"

import { IChannelCodeTag } from "@/constant/referrer"

export interface IPostLandingCustomLinkData {
  param: Record<string, any>
  path: string
}

export type PostLandingInfoParams = {
  email: string
  uuid: string
  s_id: any
  verify_code?: string
  phone?: string
  agree_mail_sub?: number // 是否同意发送邮件
  validation?: any //测试埋点接口
}

export const postLandingInfo = (postLandingInfoParams: PostLandingInfoParams) => {
  return http.post<void>(LandingApi.PostLandingInfo, {
    ...postLandingInfoParams,
    ...useFprCookie()
  })
}

export type CheckSrcIdParams = {
  uuid: string
}
export type CheckSrcIdReturn = {
  ok: boolean
}
export const CheckSrcId = (checkSrcIdParams: CheckSrcIdParams) => {
  return http.post<CheckSrcIdReturn>(LandingApi.CheckSrcId, checkSrcIdParams)
}

export const gerLandingCreditsByUuid = (opts: { uuid: string; s_id: string }) => {
  return http.get<{ credits: number; has_credits: boolean }>(LandingApi.GetLandingCredits, opts)
}

export const CheckSrcId2 = (channel_code: string) => {
  return http.get<{ tag: IChannelCodeTag }>(LandingApi.CheckSrcId2, { channel_code })
}

export const postLandingCustomLink = (opts: { path?: string; name?: string; s_id?: string; uuid?: string }) => {
  return http2.post<IPostLandingCustomLinkData>(LandingApi.GetLandingCustomLink, opts)
}

// 兑换码-》 发送兑换码邮件
export const postRedeemCodeSend = (opts: { code: string; email: string }) => {
  return http.post<any>(LandingApi.PostRedeemCodeSend, opts)
}

// 兑换码-》兑换张数
export const postRedeemCodeExchange = (opts: { code: string }) => {
  return http.post<{ product_name: string }>(LandingApi.PostRedeemCodeExchange, opts)
}

// 兑换码-》获取兑换码
export const postRedeemCodeGet = (opts: { trade_no: string }) => {
  return http.post<{ redemption_code: string }>(LandingApi.PostRedeemCodeGet, opts)
}

// 夏促获取开关
export const getHotPageOpen = () => {
  return http2.get<{ url: string; open: boolean }>(LandingApi.GetHotPageOpen)
}
