import { GtagAuEventName } from "~~/constant/gtagAu"
import gtagAuInstance from "@/utils/gtagAu"
const setEvent = gtagAuInstance.setEvent

export function useLandingGtagAu() {
  const _PointLandingButtonClick = () =>
    setEvent(GtagAuEventName.OfficialAdEvent, { send_to: "AW-11030880059/SQPKCJPyho4YELu-94sp" })
  return {
    _PointLandingButtonClick
  }
}
