import { LocalStorageKey } from "~~/constant/cache"
import { CookiesOptions } from "~~/constant/cookies"
import tsTrackingSdkInstance from "@/utils/tssdk"
import { TrackingSDKConfig, EnvironmentEndpoints } from "~~/constant/enumtsskd"
import { useUser } from "@/store/user"
// 是否开启 ts-tracking-sdk注册
let enabledFlag = true

export async function registryTsSdk(changeEnabled?: boolean) {
  if (changeEnabled) {
    enabledFlag = changeEnabled
  }
  const { userInfo } = useUser()
  const { initTsSdk, loginUserAndOrg } = useTsSdk()
  const config = getConfigSdk()
  await initTsSdk(config)
  if (userInfo && userInfo.id && userInfo.org_id) {
    const pop = {
      user_id: userInfo.id,
      org_id: userInfo.org_id
    }
    loginUserAndOrg(pop)
  }
}
//根据不同环境生成不同的init初始化的配置
export const getConfigSdk = (): TrackingSDKConfig => {
  const endpoint = process.env.NUXT_APP_TS_SDK_ENDPOINT
  switch (endpoint) {
    case EnvironmentEndpoints.DEV: //dev
      return {
        endpoint,
        // is_mark: "1",
        // mark_code: "55aa",
        is_base64: "1",
        showlog: false,
        session_timeout: 900, //30分钟
        app: "evoto_web",
        excludedReferrers: ["dev.evoto.ai"]
      }
    case EnvironmentEndpoints.PRE: //pre
      return {
        endpoint,
        // is_mark: "1",
        // mark_code: "55aa",
        is_base64: "1",
        showlog: false,
        session_timeout: 900,
        app: "evoto_web",
        excludedReferrers: ["pre.evoto.ai"]
      }
    case EnvironmentEndpoints.PROD: //prod
      return {
        endpoint,
        // is_mark: "1",
        // mark_code: "55aa",
        is_base64: "1",
        showlog: false,
        session_timeout: 900,
        app: "evoto_web",
        excludedReferrers: ["evoto.ai"]
      }
    case EnvironmentEndpoints.TEST: //test
      return {
        endpoint,
        // is_mark: "1",
        // mark_code: "55aa",
        is_base64: "1",
        showlog: false,
        session_timeout: 900,
        app: "evoto_web",
        excludedReferrers: ["test.evoto.ai"]
      }
    default:
      return {}
  }
}

export const logoutUserAndOrg = () => {
  const { logoutUserAndOrg } = useTsSdk()
  logoutUserAndOrg()
}
export const stHeaderEncodeURI = () => {
  const { getTsHeaderData } = useTsSdk()
  const st_header_params = getTsHeaderData() || {}
  const resultHeader = { ...st_header_params }
  // 获取event_time,timezone_offset
  const resultTime = {
    $event_time: new Date().getTime(),
    $timezone_offset: new Date().getTimezoneOffset()
  }
  const tsHeaderStr = JSON.stringify({
    ...resultTime,
    ...resultHeader
  })
  try {
    return encodeURIComponent(tsHeaderStr)
  } catch (error) {
    return encodeURIComponent(
      JSON.stringify({
        ...resultTime
      })
    )
  }
}
export default function useTsSdk() {
  const isEnabled = useIsPoint(CookiesOptions.Analytics)
  // 注册tssdk 以及内置属性
  const initTsSdk = async (config) => {
    // 是否开启关闭内置事件
    tsTrackingSdkInstance.enabledEvent(isEnabled)
    if (isEnabled && enabledFlag) {
      await tsTrackingSdkInstance.initTrackingSdk(config)
      enabledFlag = false
    }
  }
  //退出清空 user_id 和 org_id
  const logoutUserAndOrg = () => {
    tsTrackingSdkInstance.logout()
  }
  //退出清空 user_id 和 org_id
  const loginUserAndOrg = ({ user_id, org_id }) => {
    tsTrackingSdkInstance.login(user_id, org_id)
  }
  //获得$ts_header的数据 对象
  const getTsHeaderData = () => {
    const $ts_header = tsTrackingSdkInstance.useTsHeader()
    return $ts_header
  }

  return {
    initTsSdk,
    logoutUserAndOrg,
    loginUserAndOrg,
    getTsHeaderData
  }
}
