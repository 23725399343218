import { LocalStorageKey } from "~~/constant/cache"
import { CookiesOptions, gTagPageWhiteList } from "~~/constant/cookies"

class YTagPoint {
  static instance: YTagPoint = null
  static getInstance(): YTagPoint {
    this.instance = this.instance ? this.instance : new YTagPoint()
    return this.instance
  }
  setEvent(name: string, params: any) {
    if (process.client) {
      const isEnabled = useIsPoint(CookiesOptions.Marketing)
      if (!isEnabled) {
        return
      }
      window.ytag &&
        window.ytag({
          type: name,
          config: params
        })
    }
  }
}

export default YTagPoint.getInstance()
