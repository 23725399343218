import { PointEvent } from "~~/constant/gtag"
import { IOfficialSiginStatus, IOfficialPaymentStatus } from "./payment"
import { DownloadPlatform } from "@/constant/index"
import {
  HOME,
  PORTRAIT_RETOUCHING,
  COLOR_AND_TONE,
  BACKGROUND_ADJUSTMENTS,
  EXCLUSIVE_PRESETS,
  BATCH_EDITS,
  PAYMENT_PAGE_PATH,
  HELP_PAGE_PATH,
  HELP_MORE_PAGE_PATH,
  START_PAGE_PATH,
  DOWNLOAD_PAGE_PATH
} from "@/constant/route"

export enum IOfficialDownloadPosition {
  official_page = "official_page", //首页 banner
  official_page_bottom = "official_page_bottom", // 首页底部
  portrait_page_bottom = "portrait_page_bottom", //人像功能介绍页底部
  color_page_bottom = "color_page_bottom", // 色彩功能介绍页底部
  image_page_bottom = "image_page_bottom", // 图像功能介绍页底部
  preset_page_bottom = "preset_page_bottom", // 预设功能介绍页底部
  batch_page_bottom = "batch_page_bottom", //（批量功能介绍页底部）
  started_page_bottom = "started_page_bottom", //新手教程页面底部
  pricing_page_started = "pricing_page_started", //(定价界面get started按钮)
  pricing_page_bottom = "pricing_page_bottom", //（定价页面底部）
  success_page = "success_page", //(订阅成功界面)
  help_page_bottom = "help_page_bottom", //帮助页面底部
  download_page = "download_page", //下载页面
  download_page_bottom = "download_page_bottom" // 下载页面底部
}

// 不同页面底部footer要传的position值
export const pointPositionDict = () => {
  return {
    [unref(HOME)]: IOfficialDownloadPosition.official_page_bottom,
    [unref(PORTRAIT_RETOUCHING)]: IOfficialDownloadPosition.portrait_page_bottom,
    [unref(COLOR_AND_TONE)]: IOfficialDownloadPosition.color_page_bottom,
    [unref(BACKGROUND_ADJUSTMENTS)]: IOfficialDownloadPosition.image_page_bottom,
    [unref(EXCLUSIVE_PRESETS)]: IOfficialDownloadPosition.preset_page_bottom,
    [unref(BATCH_EDITS)]: IOfficialDownloadPosition.batch_page_bottom,
    [unref(PAYMENT_PAGE_PATH)]: IOfficialDownloadPosition.pricing_page_bottom,
    [unref(HELP_PAGE_PATH)]: IOfficialDownloadPosition.help_page_bottom,
    [unref(HELP_MORE_PAGE_PATH)]: IOfficialDownloadPosition.help_page_bottom,
    [unref(START_PAGE_PATH)]: IOfficialDownloadPosition.started_page_bottom,
    [unref(DOWNLOAD_PAGE_PATH)]: IOfficialDownloadPosition.download_page_bottom
  }
}

interface IOfficialDownload {
  official_position?: IOfficialDownloadPosition
  official_download_platform?: DownloadPlatform
  official_sigin_status?: IOfficialSiginStatus
  official_payment_status?: IOfficialPaymentStatus
}

export function useDownloadGTag() {
  const { $gtag } = useNuxtApp()
  const { setEvent } = $gtag
  // 2.setEvent声明的变量->在方法中直接调用
  const _PointOfficeDownload = (params: IOfficialDownload) => setEvent(PointEvent.OfficialDownload, params)

  const _PointOfficeDownloadMixin = ({
    official_position,
    official_download_platform
  }: {
    official_position: IOfficialDownloadPosition
    official_download_platform: DownloadPlatform
  }) => {
    const { usePaymentGTag } = useGTag()
    const { getOfficialSignStatusParams, getOfficialPaymentStatus } = usePaymentGTag()
    return _PointOfficeDownload({
      official_sigin_status: getOfficialSignStatusParams(),
      official_payment_status: getOfficialPaymentStatus(),
      official_position,
      official_download_platform
    })
  }
  return {
    _PointOfficeDownload,
    _PointOfficeDownloadMixin
  }
}
