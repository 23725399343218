import { App } from "vue"

function sendEvent({ event_id, data }: { event_id: string; data: any }) {
  if (event_id) {
    const { $gtag } = useNuxtApp()
    $gtag.setEvent(event_id, data)
  }
}
function registerGtag(app: App) {
  app.directive("gtag", {
    mounted(el, binding) {
      if (binding.value) {
        el.addEventListener(
          "click",
          () => {
            if (typeof binding.value === "function") {
              binding.value()
            } else {
              sendEvent(binding.value)
            }
          },
          { passive: true }
        )
      } else {
        console.error("Please set valid parameters！exp: { event_id: string, data: any }")
      }
    }
  })
}

export function setupGtagDirective(app: App) {
  app.use(registerGtag)
}
