<template>
  <div class="check-box-group">
    <CommonForm ref="formRef" :form-input-list="formInputList" form-label-position="top" :form-model="formModel" />
  </div>
</template>

<script setup lang="tsx">
import { IFormPropsInputList } from "@/types/components/common/form"

interface IProps {
  checkboxOptions: { title: any; desc: any; value: any }[]
  value: []
  textareaValue: string
  textareaConfig: Record<string, any>
}

let formRef = ref()

const emits = defineEmits(["update:modelValue", "checkboxChange"])

const props = withDefaults(defineProps<IProps>(), {
  checkboxOptions: () => [],
  textareaConfig: () => {
    return {
      showWordLimit: true,
      maxlength: 1000
    }
  }
})

let formModel = reactive({
  checkboxValue: [],
  textareaValue: ""
})

const formInputList = ref<IFormPropsInputList<typeof formModel>>([
  {
    model: "checkboxValue",
    type: "checkbox",
    options: []
  },
  {
    model: "textareaValue",
    type: "textarea",
    showItem: false,
    ...props.textareaConfig
  }
])

let resultCheckboxOptions = ref([])

watch(
  () => props.checkboxOptions,
  (newValue) => {
    resultCheckboxOptions.value = (unref(newValue) || []).map((item) => {
      return {
        value: item.value,
        formatter() {
          return (
            <div class="formatter-label">
              <span class="formatter-label-t" v-html={item.title}></span>
              {item.desc && <span class="formatter-label-d">{item.desc}</span>}
            </div>
          )
        }
      }
    })
    formInputList.value[0].options = resultCheckboxOptions.value
  },
  {
    immediate: true
  }
)

watch(
  () => [props.value, props.textareaValue],
  ([newValue1, newValue2]) => {
    if (newValue1) {
      formModel.checkboxValue = unref(newValue1)
    }
    if (newValue2) {
      formModel.textareaValue = unref(newValue2)
    }
  },
  {
    immediate: true
  }
)

watch(
  () => formModel.checkboxValue,
  (newValue) => {
    emits("checkboxChange", newValue)
  },
  {
    immediate: true
  }
)

function setTextareaShow(show = true) {
  formInputList.value.find((item) => item.model === "textareaValue").showItem = show
}

const getCheckboxValue = () => {
  return formModel.checkboxValue
}

const getTextareaValue = () => {
  return formModel.textareaValue
}

defineExpose({
  getCheckboxValue,
  getTextareaValue,
  setTextareaShow
})
</script>

<style scoped lang="scss">
.check-box-group {
  :deep(.el-form-item) {
    margin-right: 0;
    width: 100%;
    margin-bottom: 16px;
    &:nth-last-child(1) {
      margin-bottom: 0;
    }
    .el-form-item__content {
      .el-textarea__inner {
        color: rgba(0, 0, 0, 0.55);
        line-height: 150%;
        font-size: 14px;
        padding: 8px 12px 13px 12px;
        border-radius: 4px;
        border: 1px solid rgba(0, 0, 0, 0.3);
      }
    }
    .el-textarea {
      .el-input__count {
        color: rgba(0, 0, 0, 0.3);
        font-size: 12px;
      }
    }
  }
  :deep(.el-checkbox-group) {
    display: flex;
    flex-direction: column;
    width: 100%;
    .el-checkbox {
      align-items: flex-start;
      margin-bottom: 16px;
      white-space: wrap;
      height: auto;
      margin-right: 0;
      &:nth-last-child(1) {
        margin-bottom: 0;
      }
    }
    .el-checkbox__input {
      .el-checkbox__inner {
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border: 1px solid rgba(0, 0, 0, 0.3);
      }
      &.is-checked {
        .el-checkbox__inner {
          border: none;
          background-color: #3083ff;
          &::after {
            left: 5.5px;
            top: 2px;
            width: 4px;
            height: 8px;
          }
        }
      }
    }
    .formatter-label {
      display: flex;
      flex-direction: column;
      line-height: normal;
      &-t {
        font-size: 14px;
        @include fontSemibold;
      }
      &-d {
        margin-top: 8px;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
      }
    }
    .el-checkbox__input.is-checked + .el-checkbox__label {
      color: #000;
    }
  }
}
</style>
