<template>
  <div class="phone-sign-in_add_email">
    <span class="t-1">
      {{ topTitle || useTranslateI18n("login.newLogin.t27") }}
    </span>
    <CommonForm
      ref="formRef"
      :form-input-list="formInputList"
      :form-inline="false"
      :form-rules="formRules"
      form-label-position="top"
      :form-model="formModel"
      @validate="submit"
    />

    <CommonButton class="btn1" :button-style="buttonStyle1" @btn-click="addEmail"
      ><CommonAnimationScrollText>{{
        useTranslateI18n("profile.basicInformation.logout.t5")
      }}</CommonAnimationScrollText></CommonButton
    >
  </div>
</template>

<script setup lang="tsx">
import { IFormPropsInputList } from "@/types/components/common/form"
import { FormRules } from "element-plus"
import { EMAIL_RE } from "@/constant/regex"
import { PropType } from "vue"

// interface IProps {}

// const props = withDefaults(defineProps<IProps>(), {})

const props = defineProps({
  topTitle: {
    type: String as PropType<string>,
    default() {
      return ""
    }
  }
})

const emit = defineEmits(["submit", "addEmail"])

let formModel = reactive({
  email: ""
})

let user_exist = ref()

const formRef = ref()

const validatorEmail = async (rule: any, value: any, callback: any) => {
  let regex = EMAIL_RE
  if (!regex.test(value)) {
    return callback(new Error(useTranslateI18n("login.signIn.error")))
  } else {
    let loginInfo = await getLoginInfoApi({ email: formModel.email })
    user_exist.value = loginInfo.exist
    if (user_exist.value) {
      return callback(new Error(useTranslateI18n("login.newLogin.t42")))
    } else {
      return callback()
    }
  }
}

let formInputList = ref<IFormPropsInputList<typeof formModel>>([
  {
    type: "input",
    model: "email",
    inputRef: "",
    label: useTranslateI18n("login.label.email"),
    clearable: true,
    enterClick: true,
    enterClickFn: useDebounceFn(() => {
      addEmail()
    }, 300)
  }
])

let formRules = reactive<FormRules>({
  email: [
    {
      validator: validatorEmail as any,
      trigger: "blur"
    }
  ]
})

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Regular"
})

const addEmail = () => {
  formRef.value && formRef.value.validate()
  emit("addEmail")
}

const submit = (v) => {
  if (v) {
    emit("submit", formModel.email)
  }
}

onMounted(() => {
  nextTick(() => {
    formInputList.value.find((item) => item.model === "email")?.inputRef?.focus?.()
  })
})
</script>

<style scoped lang="scss">
.phone-sign-in_add_email {
  display: flex;
  flex-direction: column;
  .t-1 {
    font-size: 24px;
    line-height: normal;
    @include fontSemibold;
    color: #000;
    margin-bottom: 48px;
  }

  :deep(.pix-common-search) {
    .password-extra-text {
      font-weight: 400;
      font-size: 12px;
      line-height: 17px;
      color: #4f5458;
      margin-bottom: 16px;
      margin-top: 8px;
    }
    // .el-form-item__content {
    //   line-height: 36px;
    // }
    .el-form-item {
      &.is-error {
        .el-input__wrapper {
          &.is-focus {
            box-shadow: none !important;
          }
        }
      }
      .el-form-item__error {
        padding-top: 8px;
      }
      label {
        font-weight: 500;
        font-size: 12px;
        line-height: normal;
        color: rgba(0, 0, 0, 0.55);
        margin-bottom: 8px;
      }
      .el-input__wrapper {
        padding-left: 12px;
        padding-right: 12px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        box-shadow: none;
        .el-input__inner {
          height: 34px;
          font-weight: 400;
          font-size: 12px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.85);
        }
      }

      .el-input__suffix {
        .el-input__suffix-inner {
          flex-direction: row-reverse;
        }

        .eyes-wrapper {
          display: flex;
          align-items: center;
          .eyes-wrapper-text {
            width: 1px;
            height: 16px;
            background: rgba(0, 0, 0, 0.3);
            margin-right: 8px;
          }
          .eyes-icon {
            width: 16px;
            cursor: pointer;
          }
        }
        .el-icon {
          margin-left: 0;
          margin-right: 8px;
          color: rgba(0, 0, 0, 0.55);
        }
      }
      .el-input__suffix-inner > :first-child {
        margin-left: 0;
      }
    }
    .el-form-item:nth-child(1) {
      margin-bottom: 0;
    }
    .password-tooltip {
      margin-top: 8px;
      margin-bottom: 16px;
      font-weight: 400;
      line-height: normal;
      color: rgba(0, 0, 0, 0.55);
      @include ease-in-out;
    }
  }

  .btn1 {
    margin-top: 40px;
  }
}
</style>
