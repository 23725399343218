export const commonErrorCode: commonErrorCodeMap = {
  403: "Permission verification failed",
  404: "NOT FOUND",
  413: "The request entity is too long",
  415: "Algorithm internal exception",
  424: "Image download failed",
  500: "The server is abnormal. Please try again later",
  502: "The server is abnormal. Please try again later",
  503: "The service is temporarily unavailable. Please try again later",
  599: "Algorithmic connection timeout"
}
interface commonErrorCodeMap {
  [index: number]: string
}

export enum enumErrorMessage {
  NET_WORK_MESSAGE = "Network connection error",
  UNKNOWN_MESSAGE = "Unknown error"
}

export const codePos = "data.code"
export const messagePos = "data.message"

export const TIMEOUT_TIME = 2 * 60 * 1000
