import twqInstance from "@/utils/twitter"
import { twqEventName } from "@/constant/twq"
const setEvent = twqInstance.setEvent

export function useLandingTwq() {
  const _PointTwqLandingButtonClick = () => {
    setEvent(twqEventName.Download, {})
  }
  return {
    _PointTwqLandingButtonClick
  }
}
