export enum cardBtnTypeList {
  BackDrop = "1", // 非镂空样式
  Hollow = "2", // 镂空样式
  HollowActive = "3", // 镂空按钮
  BackDropCancel = "4" // 非镂空样式
}

export type ICardBtnType = "1" | "2" | "3" | "4"

export interface IContentListObj {
  showTopRightFlag: boolean
  titleContent: {
    title: string
    desc?: string
    showCustomDesc?: boolean
  }
  descContent: {
    title: string
    list: string[]
  }
  footerContent: string[]
  btnText: string
  btnDisabled: boolean
  centerText?: string
  centerTextFlag?: string
}

// 下拉列表
export type ISelectList = {
  label: string | number
  value: string | number
  [x: string]: any
}[]
