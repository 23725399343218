import { createVNode, render } from "vue"
import AgreeSubmitDialog from "~~/components/common/AgreeSubmitDialog.vue"
type IOptions = {
  submit: (...args: any[]) => any
  close?: (...args: any[]) => any
  text: string
  width?: number
  submitBtnText?: string
}
export function useAgreeSubmitDialog(
  options: IOptions = {
    text: "",
    width: 502,
    submit() {},
    close() {},
    submitBtnText: ""
  }
) {
  const vm = createVNode(AgreeSubmitDialog, {
    ...options
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
  return vm
}
