export enum PaymentStatusType {
  Loading = 1,
  Success = 2,
  Failed = 3
}

export interface IPaymentTextObj {
  title: string
  desc?: string
  bottomDesc: string[] | string
  img: string
  btnText?: string
  bottomTip?: string
}
