import { LandingOperate, PointEvent } from "~~/constant/gtag"

export function useLandingGTag() {
  const { $gtag } = useNuxtApp()
  const { setEvent } = $gtag

  const _PointLandingOperate = (opts: LandingOperate) =>
    setEvent(PointEvent.Landing, {
      kol_operate: opts
    })

  return {
    _PointLandingOperate
  }
}
