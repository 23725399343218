import PolicyCheckboxDialog from "~~/components/common/PolicyCheckboxDialog.vue"
import { createVNode, render } from "vue"

export const usePolicyCheckboxDialog = ({
  submit,
  closeDialog
}: {
  submit: (...args: any[]) => void
  closeDialog?: (...args: any[]) => void
}) => {
  const vm = createVNode(PolicyCheckboxDialog, {
    submit,
    closeDialog
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
