import { PaymentStatusType } from "@/types/components/common/paymentStatus"
import { PROFILE_PAGE_PATH } from "@/constant/route" // 邀请注册页面路由
import { TabListValue } from "@/constant/pages/profile"
import { useUser } from "@/store/user"

export const usePaymentStatusDialog = (showCloseIcon: boolean, type: PaymentStatusType) => {
  let closeDialog = null
  const use_user = useUser()

  const btnClickHandler = () => {
    if (type === PaymentStatusType.Success) {
      if (useRoute().fullPath.includes(unref(PROFILE_PAGE_PATH))) {
        return location.reload()
      }
      navigateTo(`${unref(PROFILE_PAGE_PATH)}?t=${TabListValue.MY_BILLING}`)
    } else if (type === PaymentStatusType.Failed) {
      window.open(use_user.subscription_info_v2.payment_updated_url, "_blank")
    }
  }
  useCommonNormalDialog({
    showCloseIcon,
    width: 600,
    close() {
      setTimeout(() => {
        location.reload()
      }, 10)
    },
    contentSlots({ close }) {
      closeDialog = close
      const com = resolveComponent("CommonPaymentStatus")
      return <com type={type} onBtnClick={btnClickHandler} />
    }
  })
}
