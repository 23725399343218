import type { ResponseConfig } from "./interface"
import { getToken, handlerError } from "./"
import { getHeadLanguage } from "@/constant/i18n-country"

const baseUrl = process.env.BASE_URL || "/"

async function fetch<K = any, T = ResponseConfig<K>["data"]>(
  url: string,
  options?: Record<string, any>,
  isTotalData?: boolean
): Promise<T> {
  const rawHeaders = useRequestHeaders()
  if (useRoute().fullPath.includes("/_nuxt/") && process.server) {
    console.log(useRoute().path, "404 CDN")
    return Promise.reject(`server not path`)
  }
  const headers = {
    ["x-forwarded-for-nuxt"]: rawHeaders["x-forwarded-for"],
    Authorization: getToken(),
    Language: getHeadLanguage() // 语言标识
  }
  if (options.unAuthorization) {
    delete headers.Authorization
  }
  const { data, error } = await useFetch<ResponseConfig<K>>(url, {
    baseURL: baseUrl,
    ...options,
    headers
  })
  if (data.value) {
    if (data.value instanceof Blob || isTotalData) {
      return data.value
    }
    switch (data.value.code) {
      case 0:
      case 200: {
        return isTotalData ? data.value : (data.value.data as unknown as T)
      }
    }
    return Promise.reject(data.value)
  }
  if (error.value) {
    return handlerError(Promise.reject.bind(Promise), error.value)
  }
}

export default new (class Http2 {
  get<K = any, T = ResponseConfig<K>["data"]>(
    url: string,
    params?: Record<string, any>,
    options?: Record<string, any>,
    isTotalData?: boolean
  ): Promise<T> {
    return fetch(
      url,
      {
        method: "get",
        params,
        ...options
      },
      isTotalData
    )
  }

  post<K = any, T = ResponseConfig<K>["data"]>(
    url: string,
    body?: Record<string, any>,
    options?: Record<string, any>,
    isTotalData?: boolean
  ): Promise<T> {
    return fetch(
      url,
      {
        method: "post",
        body,
        ...options
      },
      isTotalData
    )
  }
})()
