import fbqInstance from "@/utils/fbq"
import { FbqEventName } from "@/constant/fbq"
const setEvent = fbqInstance.setEvent

export function useLandingFbq() {
  const _PointFbqLandingButtonClick = () => {
    setEvent(FbqEventName.LandingButtonClick, {})
  }
  return {
    _PointFbqLandingButtonClick
  }
}
