export function useAdsDownloadClick() {
  const { useLandingGtagAu } = useGTagAu()
  const { _PointLandingButtonClick } = useLandingGtagAu()

  const { useLandingFbq } = useFbq()
  const { _PointFbqLandingButtonClick } = useLandingFbq()

  const { useLandingTtq } = useTtq()
  const { _PointTtqLandingButtonClick } = useLandingTtq()

  const { useLandingTwq } = useTwq()
  const { _PointTwqLandingButtonClick } = useLandingTwq()

  const { useLandingWcs } = useWcs()
  const { _PointWcsLandingButtonClick } = useLandingWcs()

  const { useLandingYtag } = useYtag()
  const { _PointYtagLandingButtonClick } = useLandingYtag()

  const { useLandingGtm } = useGtm()
  const { _PointGtmLandingButtonClick } = useLandingGtm()

  _PointTwqLandingButtonClick()
  _PointLandingButtonClick()
  _PointFbqLandingButtonClick()
  _PointTtqLandingButtonClick()
  _PointWcsLandingButtonClick()
  _PointYtagLandingButtonClick()
  _PointGtmLandingButtonClick()
}
