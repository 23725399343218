export enum LandingApi {
  PostLandingInfo = "/v1/web/add_landing_email",
  CheckSrcId = "/v1/web/check_org_src_uuid",
  GetLandingCredits = "/v1/web/get_credits_by_uuid",
  CheckSrcId2 = `/v1/web/check_channel_code`,
  GetLandingCustomLink = "/v1/web/get_landing_custom_link",
  PostRedeemCodeSend = `/v1/api/redemption_code/send_email`, // 发送兑换码邮件
  PostRedeemCodeExchange = "/v1/api/redemption_code/exchange", // 兑换张数
  PostRedeemCodeGet = "/v1/api/redemption_code/get", // 获取兑换码
  GetHotPageOpen = "/v1/web/get_main_page_activity" // 获取夏促的开关和url链接
}
