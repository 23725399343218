import wcsInstance from "@/utils/wcs"
import { wcsEventName } from "@/constant/wcs"
const setEvent = wcsInstance.setEvent

export function usePaymentWcs() {
  const _PointWcsPaymentSuccess = (value: any) => {
    setEvent(wcsEventName.CompletePayment, value)
  }
  return {
    _PointWcsPaymentSuccess
  }
}
