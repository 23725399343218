import { isMac, isMobile, isWindows, deviceType as checkDevice, isAndroid, isIOS, isIpad } from "~~/utils/deviceType"

export function useDevice() {
  const headers = useRequestHeaders()
  const nav = (process.server ? headers : navigator) as any
  const userAgent = process.server ? useRequestHeaders()["user-agent"] : navigator.userAgent

  return {
    userAgent,
    isMobile: computed(() => isMobile(userAgent)),
    isMac: computed(() => isMac(userAgent)),
    isWin: computed(() => isWindows(userAgent)),
    isAndroid: computed(() => isAndroid(userAgent)),
    isIOS: computed(() => isIOS(userAgent)),
    isSafari: computed(() => isSafariBrowser(userAgent)),
    deviceType: computed(() => checkDevice(userAgent)),
    isIpad: computed(() => isIpad(nav))
  }
}
