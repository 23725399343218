import http from "@/http/index"
import { helpApi } from "@/constant/api/helpApi"
import { faqType } from "~~/constant/pages/help"

export interface IContactObj {
  email?: string
  subject?: string
  message?: string
  file?: any
}

export interface IGetUrl {
  hash: string
  ext: string
  file_type: string
}

// 传送 formdata 格式不需要headers的content-type
export const postContactAdd = (args: FormData) => {
  return http.post(helpApi.contactAdd, args, {
    headers: {}
  })
}

export const getFAQListReq = (data: { id: faqType }) => {
  return http.get(helpApi.getFaqApi, data, null, true)
}

export const postGetUploadUrl = (args: IGetUrl) => {
  return http.post(helpApi.getUploadUrl, args)
}

export const getDocSearch = (data: { query: string; page: number; page_size: number }) => {
  return http.get(helpApi.docSearch, data, null, true)
}
