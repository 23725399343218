import { useDemoGTag } from "./utils/gtag/demo"
import { useDownloadGTag } from "./utils/gtag/download"
import { useLandingGTag } from "./utils/gtag/landing"
import { useLoginGTag } from "./utils/gtag/login"
import { usePaymentGTag } from "./utils/gtag/payment"
import { useProfileGTag } from "./utils/gtag/profile"
import { useInviteeGtag } from "./utils/gtag/invitee"
import { useInviterGTag } from "./utils/gtag/welfare/inviter"
import { useFooterlinkGatg } from "./utils/gtag/footerlink"
import { useTestGtag } from "./utils/gtag/test"

export function useGTag() {
  return {
    useDemoGTag,
    useDownloadGTag,
    useLandingGTag,
    useLoginGTag,
    usePaymentGTag,
    useProfileGTag,
    useInviteeGtag,
    useInviterGTag,
    useFooterlinkGatg,
    useTestGtag
  }
}
