import { gTagPageWhiteList } from "~~/constant/cookies"
import {
  LANDING_SHOT_C,
  LANDING_SHOT_S,
  LANDING_COMBO_BF,
  LANDING_COMBO_BF_GIFT,
  LANDING_COMBO_MUL_SPEC,
  LANDING_COMBO_TRIAL,
  LANDING_PAGE_MS,
  LANDING_GENERIC,
  COLOR_TRACKING_FORM_PAGE
} from "@/constant/route"
import { LocalStorageKey } from "@/constant/cache"
import { showLandingDict } from "@/constant/landingShot"
// import { useLocalePath } from "~~/constant/useLocalPathConfig"
import { useOriginPath } from "~~/constant/useLocalPathConfig"

export const useWhiteList = () => {
  const isWhiteList = (p?: string) => {
    const path = useOriginPath(p || useRoute().path)
    return (
      gTagPageWhiteList.map((route) => useOriginPath(route?.value)).includes(path) ||
      path?.includes(LANDING_SHOT_C) ||
      path?.includes(LANDING_SHOT_S)
    )
  }
  return {
    isWhiteList
  }
}

// /c/xxx  /landing_combo_mul 的静态sid _uuid 渠道 配置
export const useExhibitionStaticParams = () => {
  const config = process.env.EXHIBITION_LANDING_STATIC as any

  const isStaticChannel = (srcId: string, _uuid: string) => {
    return config.find((item) => {
      return srcId === item.LANDING_MUL_SID && _uuid === item.LANDING_MUL_UUID
    })
  }

  const isShotStaticChannel = (params_id: string) => {
    return config.find((item) => {
      return item.LANDING_MUL_CID === params_id
    })
  }

  const getChannel = (srcId: string, _uuid: string) => {
    const _config = isStaticChannel(srcId, _uuid)
    if (_config) {
      useReferrer2().value = _config.LANDING_MUL_SID
      setLocalStorage(LocalStorageKey.TEMP_AD_CHANNEL_P2, _config.LANDING_MUL_SID)
      useChannelGroupName().value.channel = _config.LANDING_CHANNEL
      useChannelGroupName().value.subchannel = _config.LANDING_SUB_CHANNEL
      return true
    } else {
      return false
    }
  }

  return {
    getChannel,
    isStaticChannel,
    isShotStaticChannel
  }
}

// 是否是落地页
export const useLandingPageStaticPath = (path?) => {
  const _p = path || useRoute().path
  const pathArr = [
    LANDING_COMBO_BF,
    LANDING_COMBO_BF_GIFT,
    LANDING_COMBO_MUL_SPEC,
    LANDING_COMBO_TRIAL,
    LANDING_PAGE_MS,
    LANDING_GENERIC,
    COLOR_TRACKING_FORM_PAGE
  ].map((route) => useOriginPath(route.value))
  for (const p in showLandingDict()) {
    pathArr.push(useOriginPath(p))
  }
  return pathArr.includes(_p) || _p?.includes(LANDING_SHOT_C) || _p?.includes(LANDING_SHOT_S)
}

// 是否是协议页面
export const usePolicyPage = (path) => {
  const _p = path || useRoute().path
  return _p.includes("/policy/")
}

// 判断是否是落地页以及/c/ 开头的落地页-》为了区分落地页语言逻辑
export const useIsLandingByLanguage = (path?) => {
  const _p = useOriginPath(path || useRoute().path)
  return _p.startsWith("/landing") || _p.startsWith("/c/")
}

// 判断是否是三放登录中转页
export const useIsSensitiveLogin = (path?) => {
  // return useOriginPath(path) === "/start"
  return useOriginPath(path) === "/redirect/sensitiveLogin"
}
