import { IAppendScriptBatchArr } from "@/composables/utils"

export const APPEND_SDK_JS: IAppendScriptBatchArr = [
  {
    opts: {
      innerHTML: `
      !function(f,b,e,v,n,t,s)
      {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
      n.callMethod.apply(n,arguments):n.queue.push(arguments)};
      if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
      n.queue=[];t=b.createElement(e);t.async=!0;
      t.src=v;s=b.getElementsByTagName(e)[0];
      s.parentNode.insertBefore(t,s)}(window, document,'script',
      'https://connect.facebook.net/en_US/fbevents.js');
      fbq('init', '831321388090724');
      fbq('track', 'PageView');
      `,
      id: "fb-sdk",
      className: "fb-sdk"
    },
    domName: "script"
  },
  {
    opts: {
      innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
      })(window,document,'script','dataLayer','GTM-PNBB9JBW');`,
      id: "g-gtm",
      className: `g-gtm`
    },
    domName: `script`
  },
  {
    opts: {
      innerHTML: `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-PNBB9JBW"
      height="0" width="0" style="display:none;visibility:hidden"></iframe>`,
      id: "g-n-gtm",
      className: `g-n-gtm`
    },
    domName: `noscript`
  },
  {
    opts: {
      innerHTML: `
      !function (w, d, t) {
        w.TiktokAnalyticsObject=t;var ttq=w[t]=w[t]||[];ttq.methods=["page","track","identify","instances","debug","on","off","once","ready","alias","group","enableCookie","disableCookie"],ttq.setAndDefer=function(t,e){t[e]=function(){t.push([e].concat(Array.prototype.slice.call(arguments,0)))}};for(var i=0;i<ttq.methods.length;i++)ttq.setAndDefer(ttq,ttq.methods[i]);ttq.instance=function(t){for(var e=ttq._i[t]||[],n=0;n<ttq.methods.length;n++
    )ttq.setAndDefer(e,ttq.methods[n]);return e},ttq.load=function(e,n){var i="https://analytics.tiktok.com/i18n/pixel/events.js";ttq._i=ttq._i||{},ttq._i[e]=[],ttq._i[e]._u=i,ttq._t=ttq._t||{},ttq._t[e]=+new Date,ttq._o=ttq._o||{},ttq._o[e]=n||{};n=document.createElement("script");n.type="text/javascript",n.async=!0,n.src=i+"?sdkid="+e+"&lib="+t;e=document.getElementsByTagName("script")[0];e.parentNode.insertBefore(n,e)};
        ttq.load('CG81ORJC77U573C8PU20');
        ttq.page();
      }(window, document, 'ttq');
      `,
      id: "tiktok-sdk",
      className: "tiktok-sdk"
    },
    domName: "script"
  },
  {
    opts: {
      innerHTML: `
      !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
      },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='https://static.ads-twitter.com/uwt.js',
      a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
      twq('config','ofcif');
      `,
      id: "twitter-sdk",
      className: "twitter-sdk"
    },
    domName: "script"
  },
  {
    opts: {
      innerHTML: `
      <img height="1" width="1" style="display:none"
      src="https://www.facebook.com/tr?id=831321388090724&ev=PageView&noscript=1"
    />`
    },
    domName: "noscript"
  },
  {
    opts: {
      src: `https://www.googletagmanager.com/gtag/js?id=AW-11030880059`,
      innerHTML: `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
      
        gtag('config', 'AW-11030880059');
      `,
      id: "gtag-sdk",
      className: "gtag-sdk"
    },
    domName: "script"
  },
  {
    opts: {
      src:
        process.env.NUXT_APP_ENV === "production" ? `https://www.dwin1.com/50047.js` : `https://www.dwin1.com/49227.js`,
      id: "dwin1-sdk",
      className: "dwin1-sdk"
    },
    domName: "script"
  },
  {
    opts: {
      src: `https://s.yimg.jp/images/listing/tool/cv/ytag.js`,
      innerHTML: `window.yjDataLayer = window.yjDataLayer || [];
      function ytag() { yjDataLayer.push(arguments); }
      ytag({"type":"ycl_cookie"});`,
      id: "ytag-sdk",
      className: "ytag-sdk"
    },
    domName: "script"
  },
  {
    opts: {
      src: `https://wcs.naver.net/wcslog.js`,
      innerHTML: `
      if(!wcs_add) var wcs_add = {};
      wcs_add["wa"] = "s_6855a3964a3";
      if(!_nasa)var _nasa={};
      wcs.inflow();
      `,
      id: "naver-sdk",
      className: "naver-sdk"
    },
    domName: "script"
  }
]
