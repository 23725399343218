<template>
  <div>
    <el-config-provider :locale="locale">
      <nuxt-layout>
        <nuxt-page></nuxt-page>
      </nuxt-layout>
    </el-config-provider>
  </div>
</template>

<script setup lang="ts">
import { useELementLocale } from "@/constant/i18n-country"
import { SessStorageKey } from "@/constant/cache"
import { useUser } from "@/store/user"
import { googleAuthWhiteList } from "./constant/route"
import { GoogleAuthService } from "./composables/utils/googleAuthService"

// 监听性能上报tssdk 埋点lcp、fcp
usePerfOvserver()

const use_user = useUser()

let { locale } = useELementLocale()

if (process.client) {
  createBreakpointListen()
}

watch(
  () => use_user.common_uuid,
  (newValue) => {
    if (newValue) {
      use_user.showFbEmailDialog()
    }
  },
  {
    immediate: true
  }
)

// navbar login点击
const showLoginDialogHandler = () => {
  use_user.showLoginDialogAction()
}

function autoLogin() {
  let auto_login = useRoute().query.auto_login
  if (auto_login && !use_user.isLogin) {
    showLoginDialogHandler()
  }
}

let detectedStorageAlert = false
const detectedBrowserCookies = () => {
  if (detectedStorage() === "backupStorage" && !detectedStorageAlert) {
    useNoCookieTipDialog()
    detectedStorageAlert = true
  }
}

const getChannelHistoryParams = () => {
  const route = useRoute()
  let fb_click_id = Array.isArray(route.query.fbclid) ? route.query.fbclid[0] : route.query.fbclid
  let google_click_id = Array.isArray(route.query.gclid) ? route.query.gclid[0] : route.query.gclid
  let twitter_click_id = Array.isArray(route.query.twclid) ? route.query.twclid[0] : route.query.twclid
  let keyword = Array.isArray(route.query.utm_kw) ? route.query.utm_kw[0] : route.query.utm_kw
  let ad_id = Array.isArray(route.query.utm_adid) ? route.query.utm_adid[0] : route.query.utm_adid
  let group_id = Array.isArray(route.query.utm_groupid) ? route.query.utm_groupid[0] : route.query.utm_groupid
  let campaign_id = Array.isArray(route.query.utm_campaignid)
    ? route.query.utm_campaignid[0]
    : route.query.utm_campaignid
  let source = Array.isArray(route.query.utm_source) ? route.query.utm_source[0] : route.query.utm_source
  if (fb_click_id || google_click_id || twitter_click_id) {
    setLocalStorage(
      SessStorageKey.CHANNEL_HISTORY,
      JSON.stringify({
        fb_click_id: fb_click_id as string,
        google_click_id: google_click_id as string,
        twitter_click_id: twitter_click_id as string,
        keyword: keyword as string,
        ad_id: ad_id as string,
        group_id: group_id as string,
        campaign_id: campaign_id as string,
        source: source as string
      })
    )
  }
}

const googleLogin = async () => {
  const { path } = useRoute()
  const { isLogin } = use_user
  if (!isLogin && googleAuthWhiteList(path)) {
    await asyncAppendScript("https://accounts.google.com/gsi/client", {}, "script")
    new GoogleAuthService().promptGoogleLogin()
  }
}

onMounted(() => {
  // 判断当前语言和登录后的用户语言不一样，弹窗问是否继续当前语言
  useGlobalUserSwitchLanguage()

  autoLogin()

  detectedBrowserCookies()

  //判断注册成功后的弹窗
  useShowSignUpSuccessDialog()

  getChannelHistoryParams()
  // 谷歌三方登录
  googleLogin()
})

watchEffect(() => {
  useDefaultSEO()
})
</script>

<style scoped lang="scss"></style>
