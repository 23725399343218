<template>
  <div ref="emailRef" class="login-mail">
    <div class="login-mail-title">{{ topTitle }}</div>
    <div class="login-mail-content">
      <div class="desc">
        <span>
          {{
            useTranslateI18n("login.email.desc", [
              registryQuery.email || `+${registryQuery.phone?.countryValue} ${registryQuery.phone?.inputValue}`
            ])
          }}
          <span v-if="showChangeText" class="desc-change-mail" @click="changeMailClick">{{ changeTitle }}</span>
        </span>
      </div>
      <CommonVerCodeForm2 ref="verCodeRef" @enter-click="submit" />
      <Transition name="fade">
        <span v-show="invalidCodeShow" class="err-code">{{ useTranslateI18n("login.email.invalid") }}</span>
      </Transition>
      <div :class="['resend-code']">
        <span v-show="isTiming" :class="[isTiming ? 'resend-code-active' : '']">
          {{ useTranslateI18n("login.email.resend") }}&nbsp;({{ count }})</span
        >

        <span v-show="!isTiming" @click="resendCodeEvent"> {{ useTranslateI18n("login.email.resend") }}</span>
      </div>

      <div ref="loadingRef" class="submit-button-w">
        <CommonButton ref="btnRef" class="submit-button" :button-style="buttonStyle1" @btn-click="submit"
          ><CommonAnimationScrollText>{{ submitText }}</CommonAnimationScrollText></CommonButton
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { IRegistryBody } from "@/composables/api/common"
import { LOGIN_SCENE, IErrorLoginType } from "@/constant/login"
import { IOfficialSignUpOperate, IOfficialForgotPasswordOperate } from "@/composables/utils/gtag/login"
const { _pointCheckCodeClick } = useStSdkCommonMp()
interface IProps {
  registryQuery: IRegistryBody
  showChangeText: boolean
  sceneRecord: LOGIN_SCENE
  submitText?: string
  countId: string
}
const prop = withDefaults(defineProps<IProps>(), {
  submitText: () => useTranslateI18n("login.email.done"),
  registryQuery: () => {
    return {} as IRegistryBody
  },
  showChangeText: true,
  sceneRecord: () => {
    return null
  },
  countId: () => {
    return "0"
  }
})
const emit = defineEmits(["resendCode", "submitCode", "goBack"])

const changeTitle = computed(() => {
  return prop.registryQuery.email ? useTranslateI18n("login.email.change") : useTranslateI18n("login.newLogin.t25")
})

const topTitle = computed(() => {
  return prop.registryQuery.email ? useTranslateI18n("login.email.title") : useTranslateI18n("login.newLogin.t43")
})

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Regular"
})

let { useLoginGTag } = useGTag()
let { _PointOfficialSignUp, _PointOfficialForgotPassword } = useLoginGTag()

let { useCommonMp } = useMp()
let { _pointOfficialSignInButtonClick, _pointOfficialSignUpButtonClick } = useCommonMp()

const { isTiming, timekeeping, count, verCodeRef, resendCode, invalidCodeShow, sendCodeApi } = useVerCodeCountDown(
  `default-login-mail-${prop.countId}-${prop.sceneRecord || 1}`
)
const resendCodeEvent = () => {
  _pointCheckCodeClick({
    element_name: "resend_code",
    element_position: "popup",
    element_type: "button",
    popup_name: "login_or_signup",
    feature_module: "login_or_signup",
    feature_group: "reset_password",
    feature_name: "check_code"
  })
  resendCode()
}
let proxy = useProxy()
let emailRef = ref()

onMounted(() => {
  setTimeout(() => {
    if (!isTiming.value) {
      sendCodeApi()
    }
  })
})

const { loadingRef, openLoading, closeLoading, isClick } = useLoadingHooks()

const submit = useDebounceFn(async () => {
  if (verCodeRef.value) {
    let verCodeArr = verCodeRef.value.resultCode
    if (
      !verCodeArr.every((item) => {
        return item.length
      })
    ) {
      invalidCodeShow.value = true
    } else {
      if (unref(isClick)) {
        return
      }
      invalidCodeShow.value = false
      openLoading()
      try {
        let data = await checkVerCodeApi({
          code: useMd5(verCodeArr.join("")),
          email: prop.registryQuery?.email,
          phone: prop.registryQuery?.phone?.inputValue,
          phone_cc: prop.registryQuery?.phone?.countryValue,
          scene: prop.sceneRecord
        })
        emit("submitCode", { code: data.code, query: prop.registryQuery })
      } catch (e) {
        invalidCodeShow.value = true
        let account =
          prop.registryQuery.email ||
          `+${prop.registryQuery?.phone?.countryValue} ${prop.registryQuery?.phone?.inputValue}`
        if (e?.data?.code === IErrorLoginType.Retrieve) {
          const { showRetrieveDialog } = useDeleteAccountNotifyDialog()
          const tk = e?.data?.data?.code
          showRetrieveDialog(account, null, null, null, () => {
            // 校验通过显示密码框
            emit("submitCode", { code: tk, query: prop.registryQuery })
          })
        }
      } finally {
        closeLoading()
      }
    }
  }
}, 300)

const changeMailClick = () => {
  emit("goBack")
}
</script>

<style scoped lang="scss">
.login-mail {
  display: flex;
  flex-direction: column;
  width: 100%;
  &-title {
    // @include fontSemibold;
    @include fontSemibold;
    font-size: 24px;
    line-height: 29px;
    color: #000;
  }
  &-content {
    display: flex;
    flex-direction: column;
    margin-top: 48px;
    .desc {
      font-weight: 400;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.55);
      margin-bottom: 40px;
      word-break: break-word;
      .desc-change-mail {
        color: #1877f2;
        cursor: pointer;
        text-decoration: underline;
      }
    }

    @include device-max-width-375 {
      .desc {
        margin-bottom: 0;
      }
      :deep(.input-box) {
        .input-content {
          input {
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0;
          }
        }
      }
    }
    .err-code {
      margin-top: 8px;
      font-weight: 400;
      line-height: 17px;
      color: #fc5555;
    }
    .resend-code {
      margin-top: 8px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 12px;
      font-style: normal;
      display: flex;
      cursor: pointer;
      .resend-code-active {
        color: rgba(0, 0, 0, 0.7);
        display: flex;
        padding: 4px 8px;
        border-radius: 42px;
        background: rgba(0, 0, 0, 0.05);
        cursor: not-allowed;
      }
    }
    .submit-button-w {
      margin-top: 40px;
      display: flex;
    }
  }
}
</style>
