// 来源参数
export enum IReferrerParams {
  gclid = "google", // 通过google广告进入会传gclid参数，对应的传给后端值为gclid
  fbclid = "facebook", // 通过facebook 广告进入会传fbclid参数
  ttclid = "tiktok",
  twclid = "twitter"
}

export enum AdDownloadChannel {
  google = `Google-Ads-Download`,
  facebook = "Facebook-Ads-Download",
  tiktok = "Tiktok-Ads-Download",
  twitter = "Twitter-Ads-Download"
}

export enum IRefererUUid {
  app = "app",
  web = "web"
}

// 目前服务端 只做了 tt 和 fb的购买上报
export const postToServerReferrer = [IReferrerParams.fbclid, IReferrerParams.ttclid, IReferrerParams.twclid]

// 判断渠道来源的tag对应
export enum IChannelCodeTag {
  Success = 0,
  Error, // 错误
  Invalid, // 无效
  HasGet, // 已领取
  Exceed // 超过7天
}
