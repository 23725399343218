import gtmInstance from "@/utils/gtm"
import { gtmEventName } from "@/constant/gtm"
const setEvent = gtmInstance.setEvent

export function useLandingGtm() {
  const _PointGtmLandingButtonClick = () => {
    setEvent(gtmEventName.Trialclick, {})
  }

  const _PointGtmLandingLeadClick = (params: {
    email?: string
    sha256_email_address?: string
    phone_number?: string
    sha256_phone_number?: string
  }) => {
    setEvent(gtmEventName.Leadssubmit, { user_data: params })
  }
  return {
    _PointGtmLandingButtonClick,
    _PointGtmLandingLeadClick
  }
}
