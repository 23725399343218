export const useDefaultChannelCode = () => {
  async function checkSrcLink(uuid: string) {
    try {
      const { ok } = await CheckSrcId({ uuid })
      return ok
    } catch (error) {
      return false
    }
  }

  const show_channel_code = ref(false)

  const channel_code = ref("")

  const channel_code_disabled = ref(false)

  const showChannelCode = computed(() => {
    return useOpenChannelCode().value
  })

  const channelCodeClick = () => {
    show_channel_code.value = !show_channel_code.value
  }

  const setDefaultChannelCode = async () => {
    const { sId: sid } = useGetLandingShotParams()
    // 有sid的话直接走，不请求接口判断，因为中间件有判断
    if (sid) {
      channel_code_disabled.value = useReferrer2IsCorrect().value
      channel_code.value = useReferrer2IsCorrect().value ? useReferrer2().value || useReferrer().value : ""
    } else {
      // 否则判断
      const sid = useReferrer2().value || useReferrer().value
      if (sid) {
        try {
          const ok = await checkSrcLink(sid)
          channel_code.value = ok ? sid : ""
          channel_code_disabled.value = ok
        } catch (e) {}
      }
    }
  }

  onMounted(() => {
    setDefaultChannelCode()
  })

  return {
    channel_code,
    channel_code_disabled,
    show_channel_code,
    showChannelCode,
    channelCodeClick,
    setDefaultChannelCode
  }
}
