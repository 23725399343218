<template>
  <div :class="['payment-status', `payment-status-${type}`]">
    <div class="s-top">
      <div class="l-s-top">
        <img src="@/assets/icons/common/bill-icon.png" alt="" />
      </div>
      <div class="r-s-top">
        <span class="t-1">{{ detailText.title }}</span>
        <span v-if="type === PaymentStatusType.Loading" class="t-2">{{ detailText.desc }}</span>
      </div>
    </div>
    <div class="s-content">
      <img v-if="detailText.img" :src="detailText.img" alt="" />
      <div class="t-group">
        <span
          v-for="(_text, i) in detailText.bottomDesc"
          :key="i"
          :class="['t-1', activeIndex === i || type !== PaymentStatusType.Loading ? 't-1-active' : '']"
          >{{ _text }}</span
        >
      </div>
      <span v-if="type === PaymentStatusType.Loading" class="s-tip">
        <img src="@/assets/icons/common/danger-icon.png" alt="" />
        <span>{{ detailText.bottomTip }}</span>
      </span>
      <CommonButton
        v-if="type === PaymentStatusType.Success || type === PaymentStatusType.Failed"
        :button-style="sendBtnStyle"
        @btn-click="goPage"
        ><CommonAnimationScrollText>{{ detailText.btnText }}</CommonAnimationScrollText></CommonButton
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import variables from "@/assets/scss/responsiveExport.module.scss"
import { PaymentStatusType, IPaymentTextObj } from "@/types/components/common/paymentStatus"
import { paymentStatusDict } from "@/constant/pages/payment"

interface IProps {
  type: PaymentStatusType
}
const props = withDefaults(defineProps<IProps>(), {
  type: () => {
    return PaymentStatusType.Loading
  }
})

const emit = defineEmits(["btnClick"])

const detailText = ref<IPaymentTextObj>()

let activeIndex = ref(0)
let flagIndex = 0

function pollTextChange() {
  let timeId = setInterval(() => {
    activeIndex.value = flagIndex++
    if (activeIndex.value === detailText.value.bottomDesc.length - 1) {
      clearInterval(timeId)
    }
  }, 1000)
}

onMounted(() => {
  if (props.type === PaymentStatusType.Loading) {
    pollTextChange()
  }
})

watch(
  () => props.type,
  (newValue) => {
    detailText.value = paymentStatusDict.value[newValue]
  },
  {
    immediate: true
  }
)

const sendBtnStyle = reactive({
  width: "349px",
  height: "42px",
  borderRadius: "60px",
  marginTop: "16px",
  fontSize: "16px",
  lineHeight: "17px",
  color: "#000",
  backgroundColor: variables.bgColor
})

const goPage = () => {
  emit("btnClick")
}
</script>

<style scoped lang="scss">
.payment-status {
  .s-top {
    padding: 33px 0 33px 40px;
    display: flex;
    align-items: center;
    background: rgba(255, 224, 80, 0.2);
    border-radius: 8px 8px 0px 0px;
    .l-s-top {
      width: 48px;
      height: 48px;
      background-color: #fff;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      img {
        width: 32px;
      }
    }
    .r-s-top {
      display: flex;
      flex-direction: column;
      margin-left: 16px;
      .t-1 {
        font-size: 20px;
        line-height: 24px;
        @include fontSemibold;
      }
      .t-2 {
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.55);
        margin-top: 6px;
      }
    }
  }
  .s-content {
    padding-top: 24px;
    padding-bottom: 40px;
    padding-left: 40px;
    padding-right: 40px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    > img {
      width: 100px;
      margin-bottom: 24px;
    }
    .t-1 {
      font-size: 24px;
      line-height: 29px;
      text-align: center;
      @include fontSemibold;
    }
    .t-group {
      position: relative;
      width: 100%;
      display: flex;
      justify-content: center;
      .t-1 {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        opacity: 0;
        @include ani-linear;
        &.t-1-active {
          width: 100%;
          position: relative;
          opacity: 1;
        }
      }
    }
    .s-tip {
      display: flex;
      align-items: center;
      padding: 8px 21px 8px 21px;
      background: rgba(255, 224, 80, 0.2);
      border-radius: 8px;
      margin-top: 12px;
      img {
        width: 16px;
        margin-right: 8px;
      }
      span {
        font-size: 14px;
        line-height: 17px;
        color: rgba(0, 0, 0, 0.85);
      }
    }
  }
  &.payment-status-1 {
  }
  &.payment-status-2 {
  }
  &.payment-status-3 {
    .s-content {
      .t-1 {
        font-size: 16px;
        line-height: 19px;
        @include fontRegular;
      }
    }
  }
}
</style>
