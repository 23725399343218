import { tsSdkEventName } from "@/constant/enumtsskd"
import tsTrackingSdkInstance from "@/utils/tssdk"
const stSdkSetEvent = tsTrackingSdkInstance.setEvent

export const usePerfTsSdk = () => {
  const _pointPerf = (options: { action_parameter0: any; action_parameter1: any }) => {
    stSdkSetEvent(tsSdkEventName.PAGELOAD, {
      ...options
    })
  }

  return {
    _pointPerf
  }
}
