import { GtagAuEventName } from "~~/constant/gtagAu"
import gtagAuInstance from "@/utils/gtagAu"
const setEvent = gtagAuInstance.setEvent

export function usePaymentGtagAu() {
  // google ad 购买埋点
  const _PointOfficePaymentAd = (value, type) => {
    return setEvent(
      GtagAuEventName.OfficialAdEvent,
      Object.assign(
        {
          send_to: "AW-11030880059/tiX9CI-f7YMYELu-94sp",
          currency: type
        },
        { value }
      )
    )
  }
  return {
    _PointOfficePaymentAd
  }
}
