import { usePaymentMP } from "./utils/mp/payment"
import { useCommonMp } from "./utils/mp/common"
import { useDownloadMp } from "./utils/mp/download"
import { useLandingMp } from "./utils/mp/landing"
import { useTestMp } from "./utils/mp/test"

export function useMp() {
  return {
    usePaymentMP,
    useCommonMp,
    useDownloadMp,
    useLandingMp,
    useTestMp
  }
}
