<template>
  <div class="signup-success-logo-w">
    <img src="@/assets/images/common/success-logo-1.png" />

    <span class="t1">
      {{ useTranslateI18n("login.newLogin.t29") }}
    </span>

    <span class="t2">
      {{ useTranslateI18n("login.newLogin.t30") }}
    </span>

    <CommonButton ref="btnRef" class="submit-button" :button-style="buttonStyle1" @btn-click="submit"
      ><CommonAnimationScrollText>{{
        useTranslateI18n("profile.basicInformation.logout.t5")
      }}</CommonAnimationScrollText></CommonButton
    >

    <CommonButton class="btn2" :button-style="buttonStyle2" @btn-click="close"
      ><CommonAnimationScrollText>
        {{ useTranslateI18n("login.newLogin.t28") }}</CommonAnimationScrollText
      ></CommonButton
    >
  </div>
</template>

<script setup lang="ts">
const emit = defineEmits(["submit", "close"])

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Regular"
})

const buttonStyle2 = reactive({
  width: "100%",
  background: "rgba(0, 0, 0, 0.05)",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Regular"
})

const submit = () => {
  emit("submit")
}

const close = () => {
  emit("close")
}
</script>

<style scoped lang="scss">
.signup-success-logo-w {
  color: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  line-height: normal;
  > img {
    width: 103px;
  }
  .submit-button {
    margin-top: 40px;
  }
  .btn2 {
    margin-top: 12px;
  }
  .t1 {
    font-size: 23px;
    @include fontSemibold;
    margin: 24px 0;
  }
  .t2 {
    color: rgba(0, 0, 0, 0.55);
    font-size: 12px;
    line-height: 150%;
  }
}
</style>
