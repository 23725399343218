<template>
  <CommonDialog
    v-model="showDialog"
    popper-class="channel-error-dialog"
    :dialog-style="dialogStyle"
    @close="closeDialogFn"
  >
    <div class="channel-error-dialog-wrapper">
      <div class="title">{{ title }}</div>
      <div class="desc">{{ desc }}</div>
      <div class="btn-wrapper">
        <CommonButton ref="btnRef" class="btn1" :button-style="buttonStyle1" @btn-click="submitClick"
          ><CommonAnimationScrollText>{{
            useTranslateI18n(`help.moreDialog.ok`)
          }}</CommonAnimationScrollText></CommonButton
        >

        <CommonButton
          v-if="showReEnter"
          ref="btnRef1"
          class="btn2"
          :button-style="buttonStyle"
          @btn-click="reEnterClick"
          ><CommonAnimationScrollText>{{
            useTranslateI18n("login.signUp.re")
          }}</CommonAnimationScrollText></CommonButton
        >
      </div>
    </div>
  </CommonDialog>
</template>

<script setup lang="tsx">
import { CSSProperties } from "vue"
const props = defineProps({
  title: {
    type: String,
    default() {
      return ""
    }
  },
  desc: {
    type: String,
    default() {
      return ""
    }
  },
  submit: {
    type: Function,
    default() {
      return () => ({})
    }
  },
  reEnter: {
    type: Function,
    default() {
      return () => ({})
    }
  },
  showReEnter: {
    type: Boolean,
    default() {
      return true
    }
  },
  closeDialog: {
    type: Function,
    default() {
      return () => ({})
    }
  }
})

let dialogStyle = reactive<CSSProperties>({
  width: 438,
  backgroundColor: "#fff",
  padding: "40px"
})

const showDialog = ref(true)

const buttonStyle = reactive({
  width: "100%",
  background: "rgba(0, 0, 0, 0.10)",
  color: `#000`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "16px"
})

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `#000`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "16px"
})

const closeDialog = () => {
  showDialog.value = false
}

const submitClick = useDebounceFn(() => {
  closeDialog()
  props.submit && props.submit()
}, 200)
const reEnterClick = useDebounceFn(() => {
  closeDialog()
  props.reEnter && props.reEnter()
}, 200)

const closeDialogFn = () => {
  props.closeDialog && props.closeDialog()
}
</script>

<style lang="scss" scoped>
.channel-error-dialog-wrapper {
  display: flex;
  flex-direction: column;
  .title {
    font-size: 24px;
    line-height: normal;
    @include fontSemibold;
  }
  .desc {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.55);
    line-height: 22px;
    margin-top: 24px;
    margin-bottom: 40px;
  }
  .btn2 {
    margin-top: 10px;
  }
}
</style>

<style lang="scss">
.welcome-page-dialog {
  &.channel-error-dialog {
    @include device-max-width-600 {
      .content {
        width: 75%;
        .content-d {
          padding: 30px !important;
          width: 100% !important;
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 70%;
        .content-d {
          padding: 15px !important;
        }
      }
    }
  }
}
</style>
