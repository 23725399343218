import { CookieOptions } from "#app"
import { LocalStorageKey } from "~~/constant/cache"
import { cookiesKey, CookiesOptions, COOKIES_SEPARATOR, ESSENTIAL_KEYS } from "~~/constant/cookies"
import { titleCase } from "~~/utils/utils"
import { SelectionOptions, SetSelectedOptionParam } from "./interface/cookies"
import useSdk from "~~/composables/useSdk"
import { registryMixpanel } from "@/composables/useMixpanel"
import { registryTsSdk } from "@/composables/useTsSdk"

interface CookiesState {
  selectedOptions?: string
  // selections: SelectionOptions
}
export const useCookiesStore = defineStore("cookies", () => {
  const cookiesState = reactive<CookiesState>({
    selectedOptions: ""
  })

  const selections = reactive({
    essential: true,
    analytics: true,
    marketing: true
  })

  const getSelectedOptions = computed(() => cookiesState.selectedOptions)
  // const getSelection = computed(() => .selections)
  const getAllowedCookiesKeys = computed(() => {
    if (process.client) {
      cookiesState.selectedOptions = getLocalStorage(LocalStorageKey.AllowCookiesPrivacy) || ""
    }

    if (!cookiesState.selectedOptions) {
      return [...ESSENTIAL_KEYS]
    } else {
      const options = cookiesState.selectedOptions.split(COOKIES_SEPARATOR)
      const keys = options.reduce((pre, cur) => {
        pre.push(...(cookiesKey?.[cur] ?? []))
        return pre
      }, [])
      return [...keys]
    }
  })

  const getCheckedCookiesOptionString = computed<string>(() => {
    const res = []
    for (const key in selections) {
      if (Object.prototype.hasOwnProperty.call(selections, key)) {
        const isChecked = selections[key]
        if (isChecked) {
          res.push(titleCase(key))
        }
      }
    }
    return res.join(COOKIES_SEPARATOR)
  })

  function setCookie(key: string, value: string, _opt?: CookieOptions<string>) {
    if (!getAllowedCookiesKeys.value.includes(key)) {
      return
    }
    const expires = new Date()
    expires.setDate(expires.getDate() + 30)
    const defaultOpt = { expires }
    const options = {
      ...defaultOpt,
      _opt
    }
    const cookiesRef = useCookie(key, options)
    cookiesRef.value = value
  }

  function getCookie(key: string, _opt?: CookieOptions<string>) {
    return unref(useCookie(key, _opt))
  }

  if (process.client) {
    const allowCookiesKey = getLocalStorage(LocalStorageKey.AllowCookiesPrivacy)
    for (const key in selections) {
      if (Object.prototype.hasOwnProperty.call(selections, key) && key != "essential") {
        const isExist = allowCookiesKey.includes(titleCase(key))
        selections[key] = isExist
      }
    }
  }

  function setSelectedOption(option: SetSelectedOptionParam) {
    // cookiesState.selectedOptions = option
    const { $gtag } = useNuxtApp()
    if (option === "all") {
      for (const key in selections) {
        if (Object.prototype.hasOwnProperty.call(selections, key)) {
          if (!selections[key]) {
            selections[key] = true
          }
        }
      }
    } else {
      for (const key in option) {
        if (Object.prototype.hasOwnProperty.call(option, key)) {
          const element = option[key]
          if (element !== selections[key]) {
            selections[key] = element
          }
        }
      }
      // 如果是decline All 操作
      if (!Object.keys(option).length) {
        for (const key in selections) {
          if (Object.prototype.hasOwnProperty.call(selections, key) && key != "essential") {
            selections[key] = false
          }
        }
      }
    }

    //gg埋点 只要同意analytics协议才行
    $gtag.changeEnabled(selections.analytics)
    if (process.client) {
      cookiesState.selectedOptions = getCheckedCookiesOptionString.value
      setLocalStorage(LocalStorageKey.AllowCookiesPrivacy, getCheckedCookiesOptionString.value)
      // 注册fb tt gg的广告埋点 只有同意Marketing协议才行
      const { useAddScript } = useSdk()
      useAddScript()
      // mixpanel埋点注册 只要同意analytics协议才行
      registryMixpanel(true)
      registryTsSdk(true)
    }
  }

  return {
    cookiesState,
    selections,

    getAllowedCookiesKeys,
    getSelectedOptions,
    // getSelection,

    setCookie,
    getCookie,
    // setSelectedOptions,
    setSelectedOption
  }
})
