import { countriesData } from "@/constant/pages/payment"

export async function isValidPhoneNumber(phoneNumber, countryCode) {
  try {
    const { parsePhoneNumber } = await import("libphonenumber-js")
    const phoneNumberObject = parsePhoneNumber(phoneNumber, countryCode)
    return phoneNumberObject !== null && phoneNumberObject.isValid()
  } catch (e) {
    return false
  }
}

export const useValidatorInterPhone = (
  isValidatorPhone = true,
  cb?: (...args: any[]) => any,
  isValidatorPhoneExist = false
) => {
  const validator = async (rule, value, callback) => {
    const resValue = `${value.inputValue}`
    const countryCode = countriesData.find((item) => item.dialCode === value.countryValue)?.iso2
    if (countryCode) {
      const valid = await isValidPhoneNumber(resValue, countryCode.toUpperCase())
      if (valid) {
        if (isValidatorPhone) {
          const loginInfo = await getLoginInfoApi({ phone: value.inputValue, phone_cc: value.countryValue })
          cb && cb(loginInfo)
          if (isValidatorPhoneExist) {
            return loginInfo.exist ? callback(new Error(useTranslateI18n("login.newLogin.t41"))) : callback()
          }
        }
        return callback()
      } else {
        return callback(new Error(useTranslateI18n("login.newLogin.t21")))
      }
    } else {
      return callback(new Error(useTranslateI18n("login.newLogin.t21")))
    }
  }

  return {
    validator
  }
}
