import { IPolicyKeyEnum } from "@/composables/api/common"
export const useGetPolicy = () => {
  const policy_address = usePolicyAddress()

  const getPrivacy = async (isOpen = true) => {
    const privacyUrl = policy_address.value.privacy
    if (privacyUrl) {
      isOpen && useLocationOpen(privacyUrl)
      return privacyUrl
    }
    const c = await getApiPrivacy(IPolicyKeyEnum.privatePolicyUrl)
    policy_address.value.privacy = c
    isOpen && useLocationOpen(c)
    return c
  }

  const getTerms = async (isOpen = true) => {
    const termsUrl = policy_address.value.terms
    if (termsUrl) {
      isOpen && useLocationOpen(termsUrl)
      return termsUrl
    }
    const c = await getApiPrivacy(IPolicyKeyEnum.termsOfUseUrl)
    policy_address.value.terms = c
    isOpen && useLocationOpen(c)
    return c
  }

  return {
    getPrivacy,
    getTerms
  }
}
