import variables from "@/assets/scss/responsiveExport.module.scss"
// import { useLocalePath() } from "@/constant/useLocalPathConfig"

export const HOME = computed(() => useLocalePath()("/"))

export const COMPANY_PATH = computed(() => useLocalePath()("/company"))

export const LANDING_PAGE_PATH = computed(() => useLocalePath()("/landing"))

export const INVITEE_PAGE_PATH = computed(() => useLocalePath()("/welfare/invitee"))

export const PROFILE_PAGE_PATH = computed(() => useLocalePath()("/profile"))

export const HELP_MORE_PAGE_PATH = computed(() => useLocalePath()("/help/more"))

export const DOWNLOAD_PAGE_PATH = computed(() => useLocalePath()("/download"))

export const DOWNLOAD_HELP_PAGE_PATH = computed(() => useLocalePath()("/download/help"))

export const DOWNLOAD_GUIDE_PAGE_PATH = computed(() => useLocalePath()("/download/guide"))

export const PAYMENT_PAGE_PATH = computed(() => useLocalePath()("/payment"))

export const START_PAGE_PATH = computed(() => useLocalePath()("/start"))

export const HELP_PAGE_PATH = computed(() => useLocalePath()("/help"))

export const SEARCH_PAGE_PATH = computed(() => useLocalePath()("/search"))

export const UN_STATUS_LOGIN_ROUTE = [computed(() => useLocalePath()("/redirect/sensitiveLogin"))]

export const PAYMENT_COMBO_ROUTE = computed(() => useLocalePath()("/payment/combo"))

export const LANDING_COMBO_PAGE = computed(() => useLocalePath()("/landing_combo"))

export const LANDING_SIMPLE_PAGE = computed(() => useLocalePath()("/landing_s"))

export const LANDING_KOL_PAGE = computed(() => useLocalePath()("/landing_k"))

export const LANDING_KOL_S_PAGE = computed(() => useLocalePath()("/landing_k_s"))

export const LADING_JP = computed(() => useLocalePath()("/landing_jp"))

export const COLLABORATE = computed(() => useLocalePath()("/collaborate"))

export const CHANGE_SKIN_COLOR = computed(() => useLocalePath()("/features/change-skin-color"))
export const BACKGROUND_REMOVAL = computed(() => useLocalePath()("/features/background-removal-service"))
export const PORTRAIT_RETOUCHING = computed(() => useLocalePath()("/features/portrait-retouching"))
export const COLOR_AND_TONE = computed(() => useLocalePath()("/features/color-and-tone"))
export const BACKGROUND_ADJUSTMENTS = computed(() => useLocalePath()("/features/background-adjustments"))
export const EXCLUSIVE_PRESETS = computed(() => useLocalePath()("/features/exclusive-presets"))
export const BATCH_EDITS = computed(() => useLocalePath()("/features/batch-edits"))

export const LANDING_D = computed(() => useLocalePath()("/landing_d"))

export const LANDING_COMBO_TRIAL = computed(() => useLocalePath()("/landing_combo_trial"))

export const PAYMENT_RESULT = computed(() => useLocalePath()("/payment/result"))

export const LANDING_COMBO_BF = computed(() => useLocalePath()("/landing_combo_bf"))

export const LANDING_COMBO_BF_GIFT = computed(() => useLocalePath()("/landing_combo_bf_gift"))

export const PAYMENT_COMBO_PROCEED_ROUTE = computed(() => useLocalePath()("/payment/combo_proceed"))

export const PAYMENT_COMBO_SUB = computed(() => useLocalePath()("/payment/combo-sub"))

export const LANDING_US = computed(() => useLocalePath()("/landing_us"))

export const LANDING_S_US = computed(() => useLocalePath()("/landing_s_us"))

export const LANDING_COMBO_MUL = computed(() => useLocalePath()("/landing_combo_mul"))

export const LANDING_SHOT_C = "/c/"

export const LANDING_SHOT_S = "/s/"

export const LANDING_COMBO_MUL_SPEC = computed(() => useLocalePath()("/landing_combo_mul_spec"))

export const LANDING_COMBO_MUL_SPEC_RDM = computed(() => useLocalePath()("/rdm"))

export const LANDING_COMBO_MUL_SPEC_RDM_RESULT = computed(() => useLocalePath()("/rdm/result"))

export const LANDING_PAGE_MS = computed(() => useLocalePath()("/landing_ms"))

export const BLOG_PAGE = computed(() => useLocalePath()("/blog"))

export const NEW_HOME_PAGE = computed(() => useLocalePath()("/home"))

export const LANDING_COMBO_MUL_LN = computed(() => useLocalePath()("/landing_combo_mul_ln"))
export const LANDING_K_S_LN = computed(() => useLocalePath()("/landing_k_s_ln"))

export const LANDING_COMBO_MUL_XS = computed(() => useLocalePath()("/landing_combo_mul_xs"))

export const LANDING_COMBO_MUL_T_XS = computed(() => useLocalePath()("/landing_combo_mul_t_xs"))
export const LANDING_GENERIC = computed(() => useLocalePath()("/landing_generic"))

export const LANDING_PROMOTION = computed(() => useLocalePath()("/landing_promotion"))

export const REDIRECT_BLOG_PAGE = "https://blog.evoto.ai/"

export const IPAD_PAGE = computed(() => useLocalePath()("/ipad"))

export const COLOR_TRACKING_FORM_PAGE = computed(() => useLocalePath()("/color-tracking-form"))

export const IPAD_APPSTORE_URL = "https://apps.apple.com/app/id6596737043"

// 页面路由颜色配置
export const routerBgDict = () => {
  return {
    // [unref(HOME)]: variables.bgColor,
    [unref(HOME)]: "#FCFCF3",
    [unref(PAYMENT_PAGE_PATH)]: "rgba(253,241,229,1)",
    [unref(PROFILE_PAGE_PATH)]: "#eff1f7",
    [unref(HELP_PAGE_PATH)]: "rgba(229,226,254,1)",
    [unref(HELP_MORE_PAGE_PATH)]: "#E6E2FF",
    [unref(START_PAGE_PATH)]: "rgba(255,252,240,1)",
    [unref(DOWNLOAD_PAGE_PATH)]: "#FFE050",
    [unref(DOWNLOAD_GUIDE_PAGE_PATH)]: "#FFF5DE",
    [unref(CHANGE_SKIN_COLOR)]: "#FFEDDC",
    [unref(BACKGROUND_REMOVAL)]: "#FFF3D4",
    [unref(PORTRAIT_RETOUCHING)]: "#FFF2D1",
    [unref(COLOR_AND_TONE)]: "#D8D7FF",
    [unref(BACKGROUND_ADJUSTMENTS)]: "#FFF4D7",
    [unref(EXCLUSIVE_PRESETS)]: "#FFEFE0",
    [unref(BATCH_EDITS)]: "#FFE2E0",
    [unref(PAYMENT_COMBO_ROUTE)]: "rgba(253,241,229,1)",
    [unref(COLLABORATE)]: variables.bgColor,
    [unref(LANDING_D)]: variables.bgColor,
    [unref(LANDING_COMBO_TRIAL)]: "#ffffff",
    [unref(PAYMENT_RESULT)]: "rgba(253,241,229,1)",
    [unref(COMPANY_PATH)]: variables.bgColor,
    [unref(PAYMENT_COMBO_PROCEED_ROUTE)]: "rgba(253,241,229,1)",
    [unref(PAYMENT_COMBO_SUB)]: "rgba(253,241,229,1)",
    [unref(SEARCH_PAGE_PATH)]: "rgba(255, 255, 255, 1)",
    [unref(BLOG_PAGE)]: "#FFFCEE",
    [unref(LANDING_COMBO_MUL_XS)]: "rgba(250,225,119,1)",
    [unref(LANDING_COMBO_MUL_T_XS)]: "rgba(250,225,119,1)",
    [unref(NEW_HOME_PAGE)]: "#FCFCF3",
    [unref(LANDING_PROMOTION)]: "#C0D7FF",
    [unref(LANDING_GENERIC)]: variables.bgColor,
    [unref(IPAD_PAGE)]: "#ffffff"
  }
}

// 支持Google授权登录弹窗白名单
export const googleAuthWhiteList = (path: string) => {
  const googleWhitePath = [
    HOME.value,
    NEW_HOME_PAGE.value,
    CHANGE_SKIN_COLOR.value,
    BACKGROUND_REMOVAL.value,
    PORTRAIT_RETOUCHING.value,
    COLOR_AND_TONE.value,
    BACKGROUND_ADJUSTMENTS.value,
    EXCLUSIVE_PRESETS.value,
    BATCH_EDITS.value,
    START_PAGE_PATH.value,
    BLOG_PAGE.value,
    PAYMENT_PAGE_PATH.value,
    PAYMENT_COMBO_ROUTE.value,
    PAYMENT_COMBO_PROCEED_ROUTE.value,
    HELP_PAGE_PATH.value,
    HELP_MORE_PAGE_PATH.value,
    DOWNLOAD_PAGE_PATH.value,
    DOWNLOAD_HELP_PAGE_PATH.value,
    DOWNLOAD_GUIDE_PAGE_PATH.value,
    PAYMENT_COMBO_SUB.value,
    IPAD_PAGE.value
  ]

  return googleWhitePath.includes(path)
}

export const businessAdminUrl = process.env.Business_Website_Url + "/login"
