import { PointEvent } from "~~/constant/gtag"
import { useUser } from "@/store/user"

import { IOfficialPaymentStatus } from "./payment"

export enum IOfficialBillingSource {
  from_app = "from_app",
  from_web = "from_web"
}

export enum IOfficialUpgradeOperate {
  click_upgrade = "click_upgrade"
}

export enum IOfficialUpgradePosition {
  credit_app = "credit_app",
  credit_web = "credit_web",
  my_app = "my_app",
  my_web = "my_web"
}

// 进入我的账单页面就统计
interface IOfficialBilling {
  official_source?: IOfficialBillingSource
  official_payment_status?: IOfficialPaymentStatus
}

// 点击升级订阅就统计
interface IOfficialUpgrade {
  official_operate?: IOfficialUpgradeOperate
  official_position?: IOfficialUpgradePosition
  official_payment_status?: IOfficialPaymentStatus
}

export function useProfileGTag() {
  const { $gtag } = useNuxtApp()
  const { setEvent } = $gtag
  // 2.setEvent声明的变量->在方法中直接调用
  const _PointOfficialBilling = (params: IOfficialBilling) => setEvent(PointEvent.OfficialBilling, params)
  const _PointOfficialUpgrade = (params: IOfficialUpgrade) => setEvent(PointEvent.OfficialUpgrade, params)
  const getProfileOfficialSource = () => {
    const use_user = useUser()
    return use_user.isAppLogin() ? IOfficialBillingSource.from_app : IOfficialBillingSource.from_web
  }
  return {
    _PointOfficialBilling,
    _PointOfficialUpgrade,
    getProfileOfficialSource
  }
}
