import { LocalStorageKey, SessStorageKey, myInfoKey } from "~/constant/cache"
import { CookiesKey } from "~/constant/cookies"
import { IErrorLoginType, VERIFY_TYPE } from "~/constant/login"
import { HOME, INVITEE_PAGE_PATH, LANDING_COMBO_PAGE, PAYMENT_PAGE_PATH } from "~/constant/route"
import { useOriginPath, useGetPathLanguage } from "~/constant/useLocalPathConfig"
import { useUser } from "~/store/user"
import { ILocaleValue } from "@/constant/i18n-country"

function setInfo(data, redirect = "") {
  const use_user = useUser()
  use_user.setUserInfo({
    info: data.info.user_info,
    token: data.token,
    reload: false,
    redirectUrl: redirect
  })
}
export async function setLogin(
  query: { uuid: string; code: string; state: string; redirect: string },
  navigate = true
) {
  const { uuid: uuids, state, code, redirect: redirects } = query
  const loginInfoCookieValue = (useCookie(CookiesKey.TR_LOGIN_M).value || {}) as any

  const uuid = decodeURIComponent(decodeURIComponent((uuids as string) || ""))
  const redirect = decodeURIComponent(decodeURIComponent((redirects as string) || unref(HOME)))

  const _navRedirect = decodeURIComponent(loginInfoCookieValue[myInfoKey.TEMP_REDIRECT] || unref(HOME))
  const statusUuid = loginInfoCookieValue[myInfoKey.SIDE_UUID]
  const agree_mail_sub = loginInfoCookieValue[myInfoKey.AGREE_EMAIL_SUB]

  const isH5Pointer = computed(
    () => _navRedirect.startsWith(unref(INVITEE_PAGE_PATH)) || _navRedirect.startsWith(unref(LANDING_COMBO_PAGE))
  )

  const { useCommonMp } = useMp()
  const {
    _pointOfficialSignInResult,
    _pointOfficialH5SignInResult,
    _pointOfficialSignUpResult,
    _pointOfficialH5SignUpResult
  } = useCommonMp()

  // 邀请活动 标识
  const { getInviterUuid, setInviteeFailedFlag, removeInviterUuid } = useInviteeSuccessFlag()
  // 落地页的 _uuid
  const { getLandingRecordUuid, removeLandingRecordUuid } = useLandingRecordUuid()

  if (uuid) {
    try {
      const channelHistry = getLocalStorage(SessStorageKey.CHANNEL_HISTORY)
      // 免登录
      const data = await setStateLoginApi({
        jump_token: uuid as string,
        ads_conversion: channelHistry ? JSON.parse(channelHistry) : undefined
      })

      let resRedirect = redirect

      // 判断是否是软件内购买跳进来的, 软件内跳转进来的话 如果有带语言就是对应语言，没带走 /redirect/senstiveLogin设置的ip语言
      if (redirect.includes(useOriginPath(unref(PAYMENT_PAGE_PATH)))) {
        if (!useGetPathLanguage(redirect)) {
          resRedirect = useI18nCountry().value !== ILocaleValue.en ? `/${useI18nCountry().value}${redirect}` : redirect
        }
      }

      // 标注是否是 evoto客户端跳转登录
      setLocalStorage(LocalStorageKey.SignInApp, "true")

      setInfo(data, navigate ? resRedirect : "")
      // 获取成功 token 看跳到哪 url？参数
    } catch (e) {
      useErrorMessage(useTranslateI18n("login.auth.errMsg"))
      // 失效直接跳转到首页
      navigate && navigateTo(unref(HOME))
    }
  } else if (code && state) {
    let user_exist
    // 第三方oauth 认证
    // 需要在客户端请求 避免服务端请求一次 客户端失效
    // 如果是邀请活动跳进来的话 需要传inviter字段来判断邀请者
    if (process.client) {
      try {
        const { user_exist: exist } = await oauthApi({
          code: decodeURIComponent(code) as string,
          uuid: decodeURIComponent(state) as string,
          state: decodeURIComponent(state) as string,
          inviter: getInviterUuid() || undefined,
          landing_uuid: getLandingRecordUuid() || undefined,
          agree_mail_sub: agree_mail_sub ? 1 : undefined
        })
        user_exist = exist
      } catch (e) {
        // 该三方账号已经被绑定
        return navigateTo(_navRedirect)
      } finally {
        removeLandingRecordUuid()
        removeInviterUuid()
      }

      // 如果是邀请注册页点击的三方登录 判断是否注册，有注册直接返回
      if (user_exist && _navRedirect.startsWith(unref(INVITEE_PAGE_PATH))) {
        setInviteeFailedFlag()
        return navigateTo(_navRedirect)
      }
      await outhUuid(statusUuid, {
        from: "redirect",
        navigate
      })
    }
  } else {
    navigate && navigateTo(unref(HOME))
  }
}
/**
 * 三方授权登录（获取用户信息）
 * @param uuid {string} 授权码（uuid 或 token）
 * @param options
 */
export async function outhUuid(
  uuid: string,
  options: {
    from: "redirect" | "popup"
    navigate?: boolean
  }
) {
  const { from, navigate } = options
  // 邀请活动 标识
  const { setInviteeSuccessFlag, setInviteeSuccessGoogleFlag } = useInviteeSuccessFlag()
  const use_user = useUser()
  const { _PointGtmSignUpSuccess } = useLoginGtm()
  const { useProfileAddPhoneOrEmailDialog } = useLoginAndRegFlow()

  const loginInfoCookieValue = (useCookie(CookiesKey.TR_LOGIN_M).value || {}) as any
  const _navRedirect = decodeURIComponent(loginInfoCookieValue[myInfoKey.TEMP_REDIRECT] || unref(HOME))
  const socialPlatform = loginInfoCookieValue[myInfoKey.TEMP_OTHER_LINk_SOURCE]

  const data =
    from === "redirect"
      ? await getOauthUuidApi({ uuid })
      : await postAuthByToken({ access_token: uuid, type: 1, scene: 2 })

  const { getSSORedirectUrl } = useSSO()
  const ssoRedirectUrl = getSSORedirectUrl()

  // 不需要填写email
  if (!data.need_verify_email) {
    // 如果是邀请活动进来的话  设置注册成功标识
    if (_navRedirect.startsWith(unref(INVITEE_PAGE_PATH))) {
      setInviteeSuccessFlag()
    }
    // 不存在
    if (!data.user_exist) {
      // 设置标识避免下面 navigateTo(_navRedirect) 拿到成功状态的问题
      if (_navRedirect.startsWith(unref(INVITEE_PAGE_PATH))) {
        setInviteeSuccessGoogleFlag()
      }
      navigate && navigateTo(_navRedirect)
      setTimeout(() => {
        const channelHistry = getLocalStorage(SessStorageKey.CHANNEL_HISTORY)
        usePolicyCheckboxDialog({
          async submit() {
            const loginData = await setStateLoginApi({
              uuid: data.uuid,
              ads_conversion: channelHistry ? JSON.parse(channelHistry) : undefined
            })
            // 邮箱注册成功标识
            setLocalStorage(LocalStorageKey.OTHER_SIGNUP_DIALOG, "1")
            use_user.setTk(loginData.token)

            // 注册成功上报到gtm
            _PointGtmSignUpSuccess({
              sha256_email_address: loginData?.info?.user_info?.email
            })

            // 新用户弹窗是否绑定手机号码
            await useProfileAddPhoneOrEmailDialog({ value: VERIFY_TYPE.phone })

            // 注册成功上报到gtm,绑定了手机号的情况
            if (use_user.isBindPhone) {
              _PointGtmSignUpSuccess({
                sha256_email_address: loginData?.info?.user_info?.email,
                sha256_phone_number: use_user.getFullPhoneCode
              })
            }
            setInfo(loginData, ssoRedirectUrl || (navigate ? _navRedirect : ""))
          },
          // 关闭回到redirect
          closeDialog() {
            setTimeout(() => {
              location.reload()
            }, 10)
          }
        })
      })
    } else {
      try {
        const channelHistry = getLocalStorage(SessStorageKey.CHANNEL_HISTORY)
        const loginData = await setStateLoginApi({
          uuid: data.uuid,
          ads_conversion: channelHistry ? JSON.parse(channelHistry) : undefined
        })
        setInfo(loginData, ssoRedirectUrl || (navigate ? _navRedirect : ""))
      } catch (e) {
        // 判断用户是否开启二次认证了，如果是二次认证的话
        if (e?.data?.code === IErrorLoginType.RepeatVerify) {
          // 判断是否用email验证，否则用手机
          const _params = e?.data?.data?.email
            ? {
                email: e?.data?.data?.email
              }
            : {
                phone: {
                  countryValue: e?.data?.data?.phone_cc,
                  inputValue: e?.data?.data?.phone
                }
              }
          navigate && navigateTo(_navRedirect)
          const { showRepeatAuthDialog } = useUserAuthRepeatDialog()
          showRepeatAuthDialog(
            {
              ..._params
            },
            false,
            ssoRedirectUrl || _navRedirect
          )
        } else if (e?.data?.code === IErrorLoginType.Retrieve) {
          const account = e?.data?.data?.email || `+${e?.data?.data?.phone_cc} ${e?.data?.data?.phone}`
          navigate && navigateTo(_navRedirect)
          const { showRetrieveDialog } = useDeleteAccountNotifyDialog()
          showRetrieveDialog(account, e?.data?.data?.recover_token, false, ssoRedirectUrl || _navRedirect)
        }
      }
    }
  } else {
    // 1、email和code有一个没有 那就让fb多填一个邮箱验证,记录uuid 2、该邮箱是一个用户已经绑定的三方邮箱，需要创建新用户，需要用户绑定新的email
    use_user.common_uuid = data.uuid
    use_user.platform_id = Number(socialPlatform)
    // 重定向地址
    navigate && navigateTo(_navRedirect)
  }
}
