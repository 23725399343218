import { localeList, ILocaleValue } from "@/constant/i18n-country"

export const useOriginPath = (path: string) => {
  const localePattern = new RegExp(`^/(${localeList.join("|")})(/|$)`)
  path = path.replace(localePattern, "/")
  return path
}

export const useGetPathLanguage = (path: string) => {
  const language_list = localeList.filter((language) => language !== ILocaleValue.en)
  const localePattern = new RegExp(`/(${language_list.join("|")})`)
  const v = path.match(localePattern)
  return v ? v[1] : v
}
