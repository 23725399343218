import { PointEvent } from "~~/constant/gtag"

enum IPlatform {
  win = "Win",
  mac = "Mac"
}

enum IDeviceType {
  mobile = 2,
  pc = 1
}

interface IInviteeParams {
  system: IPlatform
  platform: IDeviceType
}

function getPlatform() {
  const { deviceType } = useDevice()
  return IPlatform[deviceType.value]
}

function getDeviceType() {
  const { isMobile } = useDevice()
  return isMobile.value ? IDeviceType.mobile : IDeviceType.pc
}

function getMixinParams(): IInviteeParams {
  return {
    platform: getDeviceType(),
    system: getPlatform()
  }
}

export function useInviteeGtag() {
  const { $gtag } = useNuxtApp()
  const { setEvent } = $gtag
  // 2.setEvent声明的变量->在方法中直接调用
  const _PointInviteeCreateCount = () => setEvent(PointEvent.InviteePageCreateAccount, getMixinParams())
  const _PointInviteeParty = () => setEvent(PointEvent.InviteePageParty, getMixinParams())
  const _PointInviteeDownload = () => setEvent(PointEvent.InviteePageClickDownload, getMixinParams())
  return {
    _PointInviteeCreateCount,
    _PointInviteeParty,
    _PointInviteeDownload
  }
}
