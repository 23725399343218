import en from "element-plus/dist/locale/en.mjs"
import vi from "element-plus/dist/locale/vi.mjs"
import ko from "element-plus/dist/locale/ko.mjs"
import ja from "element-plus/dist/locale/ja.mjs"
import de from "element-plus/dist/locale/de.mjs"
import fr from "element-plus/dist/locale/fr.mjs"
import es from "element-plus/dist/locale/es.mjs"
import it from "element-plus/dist/locale/it.mjs"
import pt from "element-plus/dist/locale/pt.mjs"
import zh_Hant from "element-plus/dist/locale/zh-tw.mjs"

export enum ILocaleValue {
  en = "en",
  vi = "vi",
  ko = "ko",
  ja = "ja",
  de = "de",
  fr = "fr",
  es = "es",
  it = "it",
  pt = "pt",
  es_Es = "es_Es", // 欧西
  "zh-Hant" = "zh-Hant" // 繁中
}

export const localeList = Object.keys(ILocaleValue)

export const defaultLocale = ILocaleValue.en

export enum ICountryDict {
  US = "US", //美国
  VN = "VN", // 越南
  EN = "EN",
  MY = "MY", // 马来西亚
  ID = "ID", // 印度尼西亚
  TH = "TH", // 泰国
  PH = "PH", // 菲律宾
  JP = "JP", // 日本
  KR = "KR", // 韩国
  DE = "DE", // 德国
  FR = "FR", // 法国
  ES = "ES", // 西班牙
  IT = "IT", // 意大利
  PT = "PT", // 葡萄牙
  MX = "MX", // 墨西哥
  AR = "AR", // 阿根廷
  CO = "CO", // 哥伦比亚
  PE = "PE", // 秘鲁
  VE = "VE", // 委内瑞拉
  CL = "CL", // 智利
  EC = "EC", // 厄瓜多尔
  CU = "CU", // 古巴
  DO = "DO", // 多米尼加
  UY = "UY", // 乌拉圭
  HN = "HN", // 洪都拉斯
  SV = "SV", // 萨尔瓦多
  NI = "NI", // 尼加拉瓜
  BR = "BR", // 巴西
  AO = "AO", // 安哥拉
  MZ = "MZ", // 莫桑比克
  CV = "CV", // 佛得角
  MC = "MC", // 摩纳哥
  AT = "AT", // 奥地利
  LI = "LI" // 列支敦士登
}

// 全局语言切换常量
export const Global_Language = [
  {
    name: "English",
    value: ILocaleValue.en
  },
  {
    name: "日本語",
    value: ILocaleValue.ja
  },
  {
    name: "한국어",
    value: ILocaleValue.ko
  },
  {
    name: "Tiếng Việt",
    value: ILocaleValue.vi
  },
  {
    name: "Deutsch",
    value: ILocaleValue.de
  },
  {
    name: "Français",
    value: ILocaleValue.fr
  },
  {
    name: "Español (Latinoamérica)",
    value: ILocaleValue.es
  },
  {
    name: "Español(España)",
    value: ILocaleValue.es_Es
  },
  {
    name: "Italiano",
    value: ILocaleValue.it
  },
  {
    name: "Português",
    value: ILocaleValue.pt
  },
  {
    name: "繁體中文",
    value: ILocaleValue["zh-Hant"]
  }
]

export const setGlobalLanguage = async (language: string) => {
  // 记录选择的语言
  const nuxtApp = useNuxtApp()
  await setI18nLocale(nuxtApp, language)
  location.reload()
}

export const getHeadLanguage = () => {
  const locale = useI18nCountry().value
  return locale || ILocaleValue.en
}

// export const getHeadLanguage = () => {
//   const locale = useCookie(CookiesKey.LANGUAGE).value
//   return locale ? locale : ILocaleValue.en
// }

export const ElementLocaleDict = {
  [ILocaleValue.vi]: vi,
  [ILocaleValue.en]: en,
  [ILocaleValue.ko]: ko,
  [ILocaleValue.ja]: ja,
  [ILocaleValue.de]: de,
  [ILocaleValue.fr]: fr,
  [ILocaleValue.es]: es,
  [ILocaleValue.it]: it,
  [ILocaleValue.pt]: pt,
  [ILocaleValue.es_Es]: es,
  [ILocaleValue["zh-Hant"]]: zh_Hant
}

export const useELementLocale = () => {
  const locale = ref(ElementLocaleDict[ILocaleValue.en])
  locale.value = ElementLocaleDict[useI18nCountry().value] || ElementLocaleDict[ILocaleValue.en]
  return {
    locale
  }
}
