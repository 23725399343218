export const usePerfOvserver = () => {
  if (!process.client) return
  const params = {
    lcp: null,
    fcp: null
  }

  const executeSdkReport = () => {
    setTimeout(() => {
      const { _pointPerf } = usePerfTsSdk()
      if (params.lcp && params.fcp) {
        _pointPerf({
          action_parameter0: params.fcp,
          action_parameter1: params.lcp
        })
      }
    })
  }

  if ("PerformanceObserver" in window && process.client) {
    const observer = new PerformanceObserver((entryList) => {
      const entries = entryList.getEntries()
      for (const entry of entries) {
        if (entry.entryType === "largest-contentful-paint") {
          params.lcp = entry.startTime
          console.log("LCP:", entry.startTime)
          executeSdkReport()
        } else if (entry.entryType === "paint" && entry.name === "first-contentful-paint") {
          params.fcp = entry.startTime
          console.log("FCP:", entry.startTime)
          executeSdkReport()
        }
      }
    })
    observer.observe({ type: "largest-contentful-paint", buffered: true })
    observer.observe({ type: "paint", buffered: true })
  }
}
