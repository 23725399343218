import type tsTrackingSdk from "ts-tracking-sdk"
import { CookiesOptions } from "~~/constant/cookies"
class TsSdk {
  tstrackingSdk: tsTrackingSdk = null
  static instance: TsSdk = null
  static getInstance(): TsSdk {
    this.instance = this.instance ? this.instance : new TsSdk()
    return this.instance
  }
  setEvent = (eventName: string, params = {}) => {
    if (process.client) {
      const isEnabled = useIsPoint(CookiesOptions.Analytics)
      if (!isEnabled) {
        return
      }
      this.tstrackingSdk?.trackEvent(eventName, params)
    }
  }
  public enabledEvent(open: boolean) {
    this.tstrackingSdk?.setShouldSendRequest(open)
  }
  async initTrackingSdk(config) {
    if (this.tstrackingSdk) return
    this.tstrackingSdk = (await import("ts-tracking-sdk")).default.getInstance() as tsTrackingSdk
    this.tstrackingSdk?.init(config)
  }
  // 封装登录逻辑
  public login(user_id: string | number, org_id: string | number): void {
    this.tstrackingSdk?.login(user_id, org_id)
  }
  // 封装登出逻辑
  public logout(): void {
    this.tstrackingSdk?.logout()
  }
  //返回当前时间戳和时间偏移量
  public getTimestampAndTimezoneOffset() {
    const timeinfo = this.tstrackingSdk?.getTimestampAndTimezoneOffset()
    return timeinfo
  }
  public useTsHeader() {
    const $ts_header = this.tstrackingSdk?.setHeadCookieProperties()
    return $ts_header
  }
  public coverUtmCookieValue(options: { $source_sid: any; $source_uuid: any }) {
    return this.tstrackingSdk?.exposeSetUtmCookie(options)
  }
  getTrackingSDK() {
    return this.tstrackingSdk
  }
}

export default TsSdk.getInstance()
