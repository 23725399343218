<template>
  <div class="delete-account-email-wrapper">
    <div class="email-title">
      <span v-if="showTitle">{{ title || useTranslateI18n(`profile.basicInformation.logout.t3`) }}</span>
      <span
        v-if="showDesc"
        v-html="
          desc ||
          useTranslateI18n(`profile.basicInformation.logout.t4`, [
            { text: email || `+${phone?.countryValue} ${phone?.inputValue}`, class: 'email-title-d1' }
          ])
        "
      ></span>
    </div>
    <div class="email-code">
      <CommonVerCodeForm2 ref="verCodeRef" @enter-click="submit" @code-change="codeChange" />
      <Transition name="fade">
        <span v-show="invalidCodeShow" class="err-code">{{ useTranslateI18n("login.email.invalid") }}</span>
      </Transition>
      <div :class="['resend-code']">
        <span v-show="isTiming" :class="[isTiming ? 'resend-code-active' : '']">
          {{ useTranslateI18n("login.email.resend") }}&nbsp;({{ count }})</span
        >

        <span v-show="!isTiming" @click="resendCode"> {{ useTranslateI18n("login.email.resend") }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LOGIN_SCENE } from "@/constant/login"
import { useTranslateI18n } from "@/composables/store/i18n-country"

interface IProps {
  scene?: LOGIN_SCENE
  email: string
  phone?: {
    countryValue: string
    inputValue: string
  }
  title?: string
  desc?: string
  showTitle?: boolean
  showDesc?: boolean
  sId?: string | number
  checkVerCode?: boolean
  success: (...args: any[]) => any
  error: (...args: any[]) => any
}

const props = withDefaults(defineProps<IProps>(), {
  email: () => {
    return ""
  },
  phone: () => {
    return undefined
  },
  success: () => {
    return () => {}
  },
  scene: () => {
    return LOGIN_SCENE.DELETE_ACCOUNT
  },
  sId: () => {
    return null
  },
  title: () => {
    return ""
  },
  desc: () => {
    return ""
  },
  showTitle: () => {
    return true
  },
  showDesc: () => {
    return true
  },
  checkVerCode: () => {
    return true
  }
})

const emits = defineEmits(["enterClick", "codeChange"])

function codeChange(e) {
  emits("codeChange", e)
}

const { isTiming, timekeeping, count, verCodeRef, resendCode, invalidCodeShow, sendCodeApi } = useVerCodeCountDown(
  `delete-account_email_${props.sId}`
)

const codeValue = ref()

const submit = useDebounceFn(async () => {
  if (verCodeRef.value) {
    let verCodeArr = verCodeRef.value.resultCode
    if (
      !verCodeArr.every((item) => {
        return item.length
      })
    ) {
      invalidCodeShow.value = true
    } else {
      invalidCodeShow.value = false
      if (!props.checkVerCode) {
        codeValue.value = useMd5(verCodeArr.join(""))
        props.success && props.success()
        return
      } else {
        try {
          let data = await checkVerCodeApi({
            code: useMd5(verCodeArr.join("")),
            email: props.email || undefined,
            phone: props.phone?.inputValue,
            phone_cc: props.phone?.countryValue,
            scene: props.scene
          })
          codeValue.value = data.code
          props.success && props.success()
        } catch (e) {
          invalidCodeShow.value = true
        }
      }
    }
  }
}, 300)

const sendCode = () => {
  setTimeout(async () => {
    if (!isTiming.value) {
      try {
        await sendCodeApi()
      } catch (e) {}
    }
  })
}

const getCode = () => {
  return codeValue.value
}

defineExpose({
  getCode,
  submit
})

onMounted(sendCode)
</script>

<style scoped lang="scss">
.delete-account-email-wrapper {
  .email-title {
    color: rgba(0, 0, 0, 0.55);
    font-size: 12px;
    line-height: normal;
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
    :deep(.email-title-d1) {
      @include fontSemibold;
    }
  }
  .email-code {
    display: flex;
    flex-direction: column;
    .err-code {
      margin: 8px;
      font-weight: 400;
      line-height: 17px;
      color: #fc5555;
    }
    .resend-code {
      margin-top: 8px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 12px;
      font-style: normal;
      display: flex;
      cursor: pointer;
      .resend-code-active {
        color: rgba(0, 0, 0, 0.7);
        display: flex;
        padding: 4px 8px;
        border-radius: 42px;
        background: rgba(0, 0, 0, 0.05);
        cursor: not-allowed;
      }
    }
  }
}
</style>

<style lang="scss"></style>
