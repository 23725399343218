<template>
  <div class="error-wrapper">
    <div class="error-wrapper-inner">
      <CommonContainer class="c-inner">
        <div class="left-code">
          <div class="t">{{ error.statusCode }}</div>
        </div>
        <div class="right-detail">
          <div class="t1">Oh no! There have been some accidents 🙁</div>
          <div class="t2">{{ error.message }}</div>
          <CommonButton ref="btnRef" class="btn1" :button-style="buttonStyle" @btn-click="handleError"
            ><CommonAnimationScrollText>{{ "Back to Home" }}</CommonAnimationScrollText></CommonButton
          >
        </div>
      </CommonContainer>
    </div>
  </div>
</template>

<script setup lang="ts">
import { HOME } from "@/constant/route"
defineProps({
  error: Object
})

const buttonStyle = reactive({
  width: "100%",
  background: "transparent",
  color: `rgba(0,0,0,.8)`,
  borderRadius: "3px",
  border: "2px solid rgba(0,0,0,.8)",
  height: "42px",
  fontSize: "16px"
})

const handleError = () => clearError({ redirect: unref(HOME) })
</script>

<style lang="scss">
.error-wrapper {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #ffe050;
  &-inner {
    display: flex;
    width: 100%;
    justify-content: center;
    min-height: 100vh;
    background-color: #ffe050;
    align-items: center;
    .c-inner {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 100vh;
      background: url("@/assets/images/common/ev-bg.png") no-repeat;
      background-position: 0 bottom;
      background-size: 100% auto;
    }
    .left-code {
      color: rgba(0, 0, 0, 0.8);
      .t {
        font-size: 80px;
        @include fontSemibold;
      }
    }
    .right-detail {
      color: rgba(0, 0, 0, 0.8);
      margin-left: 20px;
      font-size: 30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 30%;
      text-align: center;
      .t1 {
        display: flex;
      }
      .t2 {
        display: flex;
        margin-top: 20px;
      }
      .btn1 {
        margin-top: 20px;
      }
    }
  }
  @include device-max-width-600 {
    &-inner {
      flex-direction: column;
      .c-inner {
        flex-direction: column;
      }
      .left-code,
      .right-detail {
        width: 100%;
      }
      .right-detail {
        margin-left: 0;
      }
      .left-code {
        text-align: center;
      }
    }
  }
}
</style>
