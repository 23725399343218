import { PointEvent } from "~~/constant/gtag"

export enum IPointOfficialWebsiteClickType {
  cF = "clickFacebook",
  cI = "clickInstagram",
  cY = "clickYoutube",
  cTi = "clickTiktok",
  cT = "clickTwitter",
  cE = "clickEmail"
}

interface IPointOfficialWebsiteClick {
  official_operate: IPointOfficialWebsiteClickType
}

export function useFooterlinkGatg() {
  const { $gtag } = useNuxtApp()
  const { setEvent } = $gtag

  const _PointOfficialWebsiteClick = (params: IPointOfficialWebsiteClick) =>
    setEvent(PointEvent.OfficialWebsiteSocail, params)

  return {
    _PointOfficialWebsiteClick
  }
}
