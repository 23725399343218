<template>
  <div class="login-with-psd-wrapper">
    <div class="top-title">
      <span class="t-1">{{ useTranslateI18n("login.newLogin.t5") }}</span>
      <span
        v-if="showCodeChange"
        class="t-2"
        v-html="useTranslateI18n('login.newLogin.t6', [{ type: 'static', class: 't-2-l' }])"
      ></span>
    </div>

    <div class="form-content">
      <CommonForm
        ref="formRef"
        :form-input-list="formInputList"
        :form-inline="false"
        :form-rules="formRules"
        form-label-position="top"
        :form-model="formModel"
        @validate="signInValidator"
      />
    </div>

    <div ref="loadingRef" class="submit-button-w">
      <CommonButton ref="btnRef" class="submit-button" :button-style="buttonStyle1" @btn-click="logInClick"
        ><CommonAnimationScrollText>{{
          useTranslateI18n("login.newLogin.t4")
        }}</CommonAnimationScrollText></CommonButton
      >
    </div>
  </div>
</template>

<script setup lang="tsx">
import { IFormPropsInputList } from "@/types/components/common/form"
import { FormRules } from "element-plus"
import EyeIcon from "@/assets/icons/common/eyes-icon.png"
import EyeIcon1 from "@/assets/icons/common/eyes-icon-1.png"
import { IErrorLoginType } from "@/constant/login"
import { useUser } from "@/store/user"
import { SessStorageKey } from "~~/constant/cache"

interface IProps {
  email: string
  showCodeChange: boolean
  phone: {
    countryValue: string
    inputValue: string
  }
}

const props = withDefaults(defineProps<IProps>(), {
  email() {
    return ""
  },
  showCodeChange() {
    return true
  },
  phone() {
    return undefined
  }
})
// 判断选择方式 email or phone
let enterEmail = ref(!unref(props.phone))

const emits = defineEmits(["goWithCode", "goBack", "goForgetPsd"])

let account = computed(() => {
  return unref(props.phone) ? `+${unref(props.phone)?.countryValue} ${unref(props.phone)?.inputValue}` : props.email
})

const { _pointLoginAndRegFlowPageButtonView } = useCommonMp()
const { _pointEnterPwdClick } = useStSdkCommonMp()

let use_user = useUser()

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Regular"
})

const goBack = useDebounceFn(() => {
  _pointLoginAndRegFlowPageButtonView({
    featureName: "login",
    elementName: "change_account",
    pageTitle: "login_with_password"
  })
  emits("goBack")
}, 300)

const forgetPsdClick = useDebounceFn(() => {
  emits("goForgetPsd")
}, 300)
let formModel = reactive({
  email: props.email,
  phone: unref(props.phone)?.inputValue,
  password: ""
})

const formRef = ref()

let formInputList = ref<IFormPropsInputList<typeof formModel>>([
  {
    type: "input",
    model: "email",
    disabled: true,
    label: useTranslateI18n("login.label.email"),
    showItem: computed(() => !unref(props.phone)),
    clearable: true,
    childSlots: (item) => {
      return (
        <div class="change-email" onClick={goBack}>
          {useTranslateI18n("login.newLogin.t13")}
        </div>
      )
    }
  },
  {
    type: "input",
    model: "phone",
    disabled: true,
    showItem: computed(() => !!unref(props.phone)),
    label: useTranslateI18n("login.newLogin.t24"),
    clearable: true,
    childSlots: (item) => {
      return (
        <div class="change-email" onClick={goBack}>
          {useTranslateI18n("login.newLogin.t25")}
        </div>
      )
    }
  },
  {
    type: "input",
    model: "password",
    label: useTranslateI18n("login.label.psd"),
    itype: "password",
    autoComplete: "new-password",
    enterClick: true,
    enterClickFn: useDebounceFn(() => {
      logInClick()
    }, 300),
    clearable: true,
    suffixSlot() {
      return (
        <div class="eyes-wrapper">
          <span class="eyes-wrapper-text"></span>
          <img src={formInputList.value[2].itype ? EyeIcon : EyeIcon1} class="eyes-icon" onClick={eyesClick} />
        </div>
      )
    },
    childSlots: (item) => {
      return (
        <div class="forget-psd">
          <span onClick={forgetPsdClick}>{useTranslateI18n("login.newLogin.t7")}</span>
        </div>
      )
    }
  }
])
// 眼睛点击
const eyesClick = () => {
  formInputList.value[2].itype = formInputList.value[2].itype ? "" : "password"
}

const validatorPassword = async (rule: any, value: any, callback: any) => {
  if (!value) {
    return callback(new Error(useTranslateI18n("login.signIn.errorPsd")))
  } else {
    return callback()
  }
}
let formRules = reactive<FormRules>({
  password: [
    {
      validator: validatorPassword as any,
      trigger: "blur"
    }
  ]
})

const { loadingRef, openLoading, closeLoading, isClick } = useLoadingHooks()

const logInClick = () => {
  _pointEnterPwdClick({
    element_name: "login",
    element_position: "popup",
    element_type: "button",
    popup_name: "login_or_signup",
    feature_module: "login_or_signup",
    feature_group: "login",
    feature_name: "enter_password"
  })
  _pointLoginAndRegFlowPageButtonView({
    featureName: "login",
    elementName: "login",
    pageTitle: "login_with_password"
  })
  formRef.value && formRef.value.validate()
}

const { getSSORedirectUrl } = useSSO()
const ssoRedirectUrl = getSSORedirectUrl()

const signInValidator = async (v) => {
  if (v) {
    try {
      if (unref(isClick)) return
      openLoading()
      const channelHistry = getLocalStorage(SessStorageKey.CHANNEL_HISTORY)
      let data = await setStateLoginApi({
        email: props.email || undefined,
        phone: props.phone?.inputValue,
        phone_cc: props.phone?.countryValue,
        password: useMd5(formModel.password),
        ads_conversion: channelHistry ? JSON.parse(channelHistry) : undefined
      })
      use_user.setUserInfo({
        info: data.info.user_info,
        token: data.token,
        redirectUrl: ssoRedirectUrl
      })
    } catch (e) {
      // 判断用户是否开启二次认证了，如果是二次认证的话
      if (e?.data?.code === IErrorLoginType.RepeatVerify) {
        const { showRepeatAuthDialog } = useUserAuthRepeatDialog()
        showRepeatAuthDialog(
          {
            email: props.email,
            phone: props.phone,
            password: useMd5(formModel.password)
          },
          true,
          ssoRedirectUrl
        )
      } else if (e?.data?.code === IErrorLoginType.Retrieve) {
        const { showRetrieveDialog } = useDeleteAccountNotifyDialog()
        showRetrieveDialog(unref(account), e?.data?.data?.recover_token, true, ssoRedirectUrl)
      }
    } finally {
      closeLoading()
    }
  }
}

let enterWithCodeEvent = null
onMounted(() => {
  if (props.showCodeChange) {
    enterWithCodeEvent = useEventListener(
      document.querySelector(".login-with-psd-wrapper .top-title .t-2 .t-2-l"),
      "click",
      useDebounceFn(() => {
        emits("goWithCode")
      }, 300)
    )
  }
})

onBeforeUnmount(() => {
  enterWithCodeEvent && enterWithCodeEvent()
})
</script>

<style scoped lang="scss">
.login-with-psd-wrapper {
  display: flex;
  flex-direction: column;
  .top-title {
    font-size: 24px;
    line-height: normal;
    @include fontSemibold;
    color: #000;
    display: flex;
    flex-direction: column;
    .t-2 {
      color: rgba(0, 0, 0, 0.55);
      font-size: 16px;
      line-height: 150%; /* 24px */
      margin-top: 8px;
      @include fontRegular;
      :deep(.t-2-l) {
        position: relative;
        line-height: 150%;
        cursor: pointer;
        text-decoration: underline;
      }
    }
  }

  .form-content {
    margin: 40px 0;
  }

  :deep(.pix-common-search) {
    .el-form {
      .el-form-item {
        margin-bottom: 8px;
        .change-email {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          font-size: 12px;
          color: $bg-color-link;
          cursor: pointer;
          text-decoration: underline;
          line-height: normal;
          margin: 8px 0;
        }
        .forget-psd {
          display: flex;
          width: 100%;
          justify-content: flex-end;
          font-size: 12px;
          line-height: normal;
          margin-top: 8px;
          color: rgba(0, 0, 0, 0.3);
          > span {
            cursor: pointer;
          }
        }
        .el-form-item__label {
          font-size: 12px;
          line-height: normal;
          color: rgba(0, 0, 0, 0.55);
        }
        .el-input__wrapper {
          .el-input__inner {
            height: 34px;
          }
          .eyes-wrapper {
            display: flex;
            align-items: center;
            .eyes-wrapper-text {
              width: 1px;
              height: 16px;
              background: rgba(0, 0, 0, 0.3);
              margin-right: 8px;
            }
            .eyes-icon {
              width: 16px;
              cursor: pointer;
            }
          }
        }
        .el-input {
          &.is-disabled {
            .el-input__wrapper {
              border: 1px solid rgba(0, 0, 0, 0.1);
              background-color: transparent;
              .el-input__inner {
                color: rgba(0, 0, 0, 0.3);
              }
            }
          }
        }
      }
    }
  }
}
</style>
