import twqInstance from "@/utils/twitter"
import { twqEventName } from "@/constant/twq"
const setEvent = twqInstance.setEvent

export function usePaymentTwq() {
  const _PointTwqPaymentSuccess = (value: any, type) => {
    setEvent(twqEventName.CompletePayment, { currency: type, value })
  }
  return {
    _PointTwqPaymentSuccess
  }
}
