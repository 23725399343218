<template>
  <div ref="forgetRef" class="forget-password">
    <div class="forget-password-title">
      {{ topTitle }}
    </div>
    <div v-if="forgetPsdConfig.showForgetPsd" class="forget-password-count">
      <span>{{ useTranslateI18n("login.forgetPsd.countDesc") }}</span>
      <span>{{
        registryQuery.email || `+${registryQuery.phone?.countryValue} ${registryQuery.phone?.inputValue}`
      }}</span>
    </div>
    <div class="forget-password-content">
      <CommonForm
        ref="formRef"
        :form-inline="false"
        form-label-position="top"
        :form-model="formModel"
        :form-input-list="formInputList"
        :form-rules="formRules"
        @validate="validate"
      />
      <div class="submit-button" @click="submitClick">
        <CommonAnimationScrollText>{{ useTranslateI18n(`login.forgetPsd.submit`) }}</CommonAnimationScrollText>
      </div>
      <div
        v-if="forgetPsdConfig.showForgetGoBack"
        class="go-back"
        v-html="
          useTranslateI18n(`login.forgetPsd.goBack`, [
            { type: 'static', class: 'go-back-s1' },
            { type: 'static', class: 'forget-psd_goBack_btn' }
          ])
        "
      ></div>
    </div>
  </div>
</template>

<script setup lang="tsx">
import { IFormPropsInputList } from "@/types/components/common/form"
import { FormRules } from "element-plus"
import EyeIcon from "@/assets/icons/common/eyes-icon.png"
import EyeIcon1 from "@/assets/icons/common/eyes-icon-1.png"
import type { IRegistryBody } from "@/composables/api/common"
import { EMAIL_RE } from "@/constant/regex"
import { IOfficialForgotPasswordOperate } from "@/composables/utils/gtag/login"
import { LOGIN_SCENE, IForgetPsdType, IForgetPsdConfig, Login_Method } from "@/constant/login"
import { useTranslateI18n } from "~~/composables/store/i18n-country"
import { useUser } from "@/store/user"
import { isNthBinaryDigitOne } from "@/utils/utils"

let use_user = useUser()

let { useLoginGTag } = useGTag()
let { _PointOfficialForgotPassword } = useLoginGTag()

let { useCommonMp } = useMp()
let { _pointOfficialSignInButtonClick } = useCommonMp()

interface IProps {
  type: IForgetPsdType
  registryQuery: IRegistryBody
  sceneRecord: LOGIN_SCENE
  validator_email: boolean
  custom_title: string
  eval_email: boolean
}

const emit = defineEmits(["goBack", "submitReset"])
const prop = withDefaults(defineProps<IProps>(), {
  type: () => IForgetPsdType.Type1,
  registryQuery: () => {
    return {} as IRegistryBody
  },
  validator_email() {
    return false
  },
  custom_title() {
    return ""
  },
  eval_email() {
    return true
  }
})

const forgetPsdConfig = computed(() => {
  return IForgetPsdConfig(prop.type)
})

const topTitle = computed(() => {
  return (
    prop.custom_title ||
    (forgetPsdConfig.value.showPsdTitle
      ? useTranslateI18n("login.forgetPsd.title")
      : useTranslateI18n("login.forgetPsd.title2"))
  )
})

const forgetRef = ref()
const formRef = ref()

let passwordTooltipStyle = reactive({
  marginTop: "8px"
})

// focus
onMounted(() => {
  ;(document.querySelector(".forget-password .el-form .el-input__inner") as HTMLElement).focus()
})

const validatorEmail = async (rule: any, value: any, callback: any) => {
  let regex = EMAIL_RE
  if (!regex.test(value)) {
    return callback(new Error(useTranslateI18n("login.signIn.error")))
  } else {
    //  判断是否注册过
    if (unref(forgetPsdConfig).showPsdTitle) {
      let loginInfo = await getLoginInfoApi({ email: formModel.email })
      if (loginInfo.exist) {
        return callback()
      } else {
        // 没注册过返回 报错
        return callback(new Error(useTranslateI18n("login.forgetPsd.errEmail")))
      }
    } else if (prop.validator_email) {
      let loginInfo = await getLoginInfoApi({ email: formModel.email })
      if (!loginInfo.exist) {
        return callback()
      } else {
        let platform = use_user.platform_id
        if (platform === Login_Method.Google && !isNthBinaryDigitOne(loginInfo.login_tag, 2)) {
          return callback()
        } else if (platform === Login_Method.Facebook && !isNthBinaryDigitOne(loginInfo.login_tag, 3)) {
          return callback()
        } else {
          return callback(new Error(useTranslateI18n("login.newLogin.t42")))
        }
      }
    } else {
      return callback()
    }
  }
}

const validatorPassword = (rule: any, value: any, callback: any) => {
  passwordTooltipStyle.marginTop = "25px"
  if (value.length < 8 || value.length > 24) {
    return callback(new Error(useTranslateI18n("login.signUp.errArr[2]")))
  } else if (/^[0-9]+$/.test(value)) {
    return callback(new Error(useTranslateI18n("login.signUp.errArr[0]")))
  } else if (/^[A-Za-z]+$/.test(value)) {
    return callback(new Error(useTranslateI18n("login.signUp.errArr[1]")))
  } else if (/[\u4e00-\u9fa5]+/.test(value) || /[ぁ-んァ-ヶ]+/.test(value) || /\s+/.test(value)) {
    return callback(new Error(useTranslateI18n("login.signUp.errArr[3]")))
  } else {
    passwordTooltipStyle.marginTop = "8px"
    callback()
  }
}

const validatorPassword2 = (rule: any, value: any, callback: any) => {
  if (value !== formModel.password) {
    callback(new Error(useTranslateI18n("login.signUp.errPsdRepeat")))
  } else {
    callback()
  }
}

let formModel = reactive({
  phoneConfig: {},
  email: "",
  password: "",
  password2: ""
})

let formInputList = ref<IFormPropsInputList<typeof formModel>>([
  {
    model: "email",
    type: "input",
    onBlur() {},
    label: useTranslateI18n("login.forgetPsd.codeLabel"),
    enterClick: true,
    enterClickFn: useDebounceFn(() => {
      submitClick()
    }, 300)
  },
  {
    type: "countrySelect",
    model: "phoneConfig",
    isRemotePhone: true,
    label: useTranslateI18n("login.newLogin.t24"),
    placeholder: useTranslateI18n("login.newLogin.t18"),
    enterClick: true,
    enterClickFn: useDebounceFn(() => {
      submitClick()
    }, 300)
  },
  {
    type: "input",
    model: "password",
    label: useTranslateI18n("login.label.psd"),
    itype: "password",
    clearable: true,
    slots: true,
    onBlur() {},
    slotsFormatter() {
      let desc = useTranslateI18n("login.signUp.psdTip") + `@#$%^&*-_+=[]{}|\\:',?/\`~"();!.<>`
      return (
        <div class="password-tooltip" style={passwordTooltipStyle}>
          {desc}
        </div>
      )
    },
    suffixSlot() {
      return (
        <div class="eyes-wrapper">
          <span class="eyes-wrapper-text"></span>
          <img
            src={formInputList.value[0].itype ? EyeIcon : EyeIcon1}
            class="eyes-icon"
            onClick={() => {
              eyesClick(0)
            }}
          />
        </div>
      )
    }
  },
  {
    type: "input",
    model: "password2",
    label: useTranslateI18n("login.label.rPsd"),
    itype: "password",
    clearable: true,
    onBlur() {},
    suffixSlot() {
      return (
        <div class="eyes-wrapper">
          <span class="eyes-wrapper-text"></span>
          <img
            src={formInputList.value[1].itype ? EyeIcon : EyeIcon1}
            class="eyes-icon"
            onClick={() => {
              eyesClick(1)
            }}
          />
        </div>
      )
    },
    enterClick: true,
    enterClickFn: useDebounceFn(() => {
      submitClick()
    }, 300)
  }
])

const { validator: validatorP } = useValidatorInterPhone(false)

const formRules = reactive<FormRules>({
  email: [
    {
      validator: validatorEmail as any,
      trigger: "blur"
    }
  ],
  phoneConfig: [
    {
      validator: validatorP,
      trigger: "blur"
    }
  ],
  password: [
    {
      validator: validatorPassword,
      trigger: "blur"
    }
  ],
  password2: [
    {
      validator: validatorPassword2,
      trigger: "blur"
    }
  ]
})

watch(
  () => unref(forgetPsdConfig).showForgetPsd,
  (newValue) => {
    if (newValue) {
      formInputList.value.splice(0, 1)
      formInputList.value.splice(0, 1)
      delete formModel["phoneConfig"]
      delete formRules["phoneConfig"]
      delete formModel["email"]
      delete formRules["email"]
    } else {
      if (prop.registryQuery.email) {
        prop.eval_email && (formModel.email = prop.registryQuery.email)
        formInputList.value = formInputList.value.slice(0, 1)
        delete formRules["phoneConfig"]
        delete formRules["phoneConfig"]
        delete formModel["phoneConfig"]
        delete formModel["phoneConfig"]
      }
      if (prop.registryQuery.phone) {
        formModel.phoneConfig = prop.registryQuery.phone
        formInputList.value = formInputList.value.slice(1, 2)
        delete formRules["email"]
        delete formRules["email"]
        delete formModel["email"]
        delete formModel["email"]
      }
      delete formRules["password"]
      delete formRules["password2"]
      delete formModel["password"]
      delete formModel["password2"]
    }
  },
  {
    immediate: true
  }
)

// 眼睛点击
const eyesClick = (index) => {
  if (formInputList.value[index].itype) {
    // _pointOfficialSignInButtonClick({
    //   buttonName: "clickResetPasswordShowPassword",
    //   subPageTitle: "signInForgetPasswordPage"
    // })
  }
  formInputList.value[index].itype = formInputList.value[index].itype ? "" : "password"
}

const signInClick = () => {
  emit("goBack")
}

const submitClick = useDebounceFn(() => {
  setTimeout(() => {
    formRef.value.validate()
  })
}, 300)

const validate = (v) => {
  if (v) {
    // 忘记密码输入邮箱后点击提交
    // if (unref(forgetPsdConfig).showForgetGoBack && !unref(forgetPsdConfig).showForgetPsd) {
    //   _pointOfficialSignInButtonClick({
    //     buttonName: "clickResetPasswordEmailDone",
    //     subPageTitle: "signInForgetPasswordPage"
    //   })
    //   _PointOfficialForgotPassword({
    //     official_operate: IOfficialForgotPasswordOperate.input_email_submit
    //   })
    // } else if (prop.sceneRecord === LOGIN_SCENE.FORGET_PSD) {
    //   _PointOfficialForgotPassword({
    //     official_operate: IOfficialForgotPasswordOperate.click_reset
    //   })
    //   _pointOfficialSignInButtonClick({
    //     buttonName: "clickResetPasswordSave",
    //     subPageTitle: "signInForgetPasswordPage"
    //   })
    // } else if (prop.sceneRecord === LOGIN_SCENE.EDIT_PSD) {
    //   _pointOfficialSignInButtonClick({
    //     buttonName: "clickResetPasswordSave",
    //     subPageTitle: "signInForgetPasswordPage"
    //   })
    // }
    emit("submitReset", formModel)
  }
}

let signInDomEvent

onMounted(() => {
  let timeId = setTimeout(() => {
    signInDomEvent = useEventListener(document.querySelector(".forget-psd_goBack_btn"), "click", signInClick)
    clearTimeout(timeId)
  })
})

onBeforeUnmount(() => {
  signInDomEvent && signInDomEvent()
})
</script>

<style scoped lang="scss">
.forget-password {
  display: flex;
  flex-direction: column;
  width: 100%;
  &-title {
    color: #000;
    // @include fontSemibold;
    @include fontSemibold;
    font-size: 24px;
    line-height: 29px;
  }
  &-count {
    margin-top: 30px;
    font-weight: 400;
    line-height: 17px;
    color: #4f5458;
    display: flex;
    flex-direction: column;
  }
  &-content {
    margin-top: 30px;
    :deep(.pix-common-search) {
      .password-extra-text {
        font-weight: 400;
        font-size: 12px;
        line-height: 17px;
        color: #4f5458;
        margin-bottom: 16px;
        margin-top: 8px;
      }
      .el-input {
        line-height: 36px;
      }
      // .el-form-item__content {
      //   line-height: 36px;
      // }
      .el-form-item {
        &.is-error {
          .el-input__wrapper {
            &.is-focus {
              box-shadow: none !important;
            }
          }
        }
        .el-form-item__error {
          padding-top: 8px;
        }
        label {
          font-weight: 500;
          font-size: 12px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.55);
          margin-bottom: 8px;
        }
        .el-input__wrapper {
          padding-left: 12px;
          padding-right: 12px;
          border: 1px solid rgba(0, 0, 0, 0.3);
          border-radius: 8px;
          box-shadow: none;
          .el-input__inner {
            height: 36px;
            line-height: 36px;
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            color: rgba(0, 0, 0, 0.85);
          }
        }
        &:nth-child(1) {
          margin-bottom: 0px;
          .el-input__inner {
            padding-right: 37px;
          }
        }

        .el-input__suffix {
          .el-input__suffix-inner {
            flex-direction: row-reverse;
          }

          .eyes-wrapper {
            display: flex;
            align-items: center;
            .eyes-wrapper-text {
              width: 1px;
              height: 16px;
              background: rgba(0, 0, 0, 0.3);
              margin-right: 8px;
            }
            .eyes-icon {
              width: 16px;
              cursor: pointer;
            }
          }
          .el-icon {
            margin-left: 0;
            margin-right: 8px;
            color: rgba(0, 0, 0, 0.55);
          }
        }
        .el-input__suffix-inner > :first-child {
          margin-left: 0;
        }
      }
      .password-tooltip {
        margin-top: 8px;
        margin-bottom: 16px;
        font-weight: 400;
        line-height: 17px;
        color: #4f5458;
        @include ease-in-out;
      }
      .el-form-item:nth-of-type(2) {
        // margin-bottom: 41px;
        margin-bottom: 0;
        .el-input__inner {
          padding-right: 25px;
        }
      }
      .el-form-item:nth-last-of-type(1) {
        margin-bottom: 41px;
        .el-input__inner {
          padding-right: 25px;
        }
      }

      .country-select {
        height: 36px;
        line-height: 36px;
        .el-select {
          margin-left: 1px;
          .el-input {
            .el-input__wrapper {
              height: 100%;
              padding: 0 12px !important;
              border-radius: 0;
              .el-input__inner {
                height: 100% !important;
                width: 45px;
                padding-right: 0;
              }
              .el-input__suffix {
                .el-input__suffix-inner {
                  i {
                    width: 16px;
                    height: 16px;
                    background-image: url("@/assets/images/pages/payment/select-icon2.png");
                    background-size: 100% 100%;
                    font-size: 0;
                    margin-left: 0;
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
        > .el-input {
          .el-input__wrapper {
            padding-left: 85px !important;
            .el-input__inner {
              height: 36px;
              padding-right: 0;
            }
          }
        }
      }
    }
    .submit-button {
      margin-top: 30px;
      background-color: $bg-color;
      height: 42px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 510;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.85);
      border-radius: 60px;
      @include ease-in-out;
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 224, 80, 0.5);
      }
    }
    .go-back {
      display: flex;
      margin-top: 22px;
      line-height: 17px;
      :deep(.forget-psd_goBack_btn) {
        font-weight: 500;
        font-size: 12px;
        color: #1877f2;
        cursor: pointer;
        margin-left: 4px;
      }
      :deep(.go-back-s1) {
        font-weight: 400;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
      }
      span:nth-child(1) {
        font-weight: 400;
        font-size: 12px;
        color: rgba(0, 0, 0, 0.55);
      }
      span:nth-child(2) {
        font-weight: 500;
        font-size: 12px;
        color: #1877f2;
        margin-left: 4px;
        cursor: pointer;
      }
    }
  }
}
</style>
