import { LocalStorageKey } from "~~/constant/cache"
import { CookiesOptions } from "@/constant/cookies"
import { LANDING_COMBO_MUL_LN, LANDING_K_S_LN } from "@/constant/route"
import { useOriginPath } from "~~/constant/useLocalPathConfig"

// 省流特殊路由不注册埋点
export const useIsNoPointRoute = (path?: string) => {
  const route = useOriginPath(path || useRoute().path)
  const extraRouteOriginArr = [unref(LANDING_COMBO_MUL_LN), unref(LANDING_K_S_LN)]
  const extraRoute = extraRouteOriginArr.map((item) => useOriginPath(item))
  return extraRoute.includes(route)
}

export const useIsNoPointRouteValue = () => useState("is_no_point_route_value", () => false)

export const useIsPoint = (CookieOptions: CookiesOptions) => {
  const { isWhiteList } = useWhiteList()
  const selectedOptions = getLocalStorage(LocalStorageKey.AllowCookiesPrivacy) || ""
  const isEnabled = (selectedOptions && selectedOptions.includes(CookieOptions)) || isWhiteList()
  return isEnabled && !useIsNoPointRoute() && !unref(useIsNoPointRouteValue())
}
