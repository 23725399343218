import VueGtag, { trackRouter } from "vue-gtag-next"
import { APP_GTAG_MEASUREMENT_ID } from "~~/constant/config"
import { CookiesOptions } from "~~/constant/cookies"

export default defineNuxtPlugin(({ vueApp }) => {
  const isEnabled = useIsPoint(CookiesOptions.Analytics)
  vueApp.use(VueGtag, {
    isEnabled: isEnabled,
    property: [
      {
        id: APP_GTAG_MEASUREMENT_ID
        //   appName: "evoto"
      }
    ]
  })
  const router = useRouter()
  trackRouter(router)
})
