import { PaymentStatus } from "@/composables/api/common"
import { postReadPaymentStatus } from "@/composables/api/payment"
import { useUser } from "@/store/user"
import { PaymentStatusType } from "@/types/components/common/paymentStatus"

// 设置最大请求数量
let request_count = 0
const REQUEST_MAX_COUTN = 100

// 判断是否显示payment_status 状态
export async function useShowPaymentStatusDialog(pageOpen = false) {
  // mixpanel
  const use_user = useUser()

  // 获取用户信息
  if (use_user.paymentStatus !== PaymentStatus.Read && !isUndefined(use_user.paymentStatus)) {
    const timeId = setInterval(async () => {
      request_count++
      if (request_count > REQUEST_MAX_COUTN) {
        clearInterval(timeId)
        usePaymentStatusDialog(false, PaymentStatusType.Loading)
        return
      }
      await use_user.setAllUserInfo()
      if (process.client) {
        if (use_user.paymentStatus === PaymentStatus.Loading) {
          usePaymentStatusDialog(false, PaymentStatusType.Loading)
        } else if (use_user.paymentStatus === PaymentStatus.Success) {
          clearInterval(timeId)
          if (!pageOpen) {
            usePaymentStatusDialog(true, PaymentStatusType.Success)
          }
          // 重置payment_status
          await postReadPaymentStatus()
          pageOpen && location.reload()
        } else if (use_user.paymentStatus === PaymentStatus.Failed) {
          clearInterval(timeId)
          usePaymentStatusDialog(true, PaymentStatusType.Failed)
          // 重置payment_status
          await postReadPaymentStatus()
        }
      }
    }, 1000)
  }
}

// 判断订阅页面和套餐页面的显示方式
// export function paymentLocationRedirect() {
//   const use_user = useUser()
//   let queryString = ""
//   for (const key in useRoute().query) {
//     let queryValue = useRoute().query[key]
//     queryValue = Array.isArray(queryValue) ? queryValue[0] : queryValue
//     queryString += `${key}=${queryValue}&`
//   }
//   queryString = queryString ? queryString.slice(0, -1) : ""
//   if (process.client) {
//     // 判断是否sharesales来的，记录下sharesales的session
//     if (useRoute().query.sscid) {
//       sessionStorage.setItem(SessStorageKey.SHARESALES_FLAG, "true")
//     }
//   }
//   onBeforeMount(async () => {
//     if (process.client) {
//       if (use_user.isLogin) {
//         // 套餐的话
//         if (getComboTypeList().includes(use_user.productType)) {
//           return navigateTo(`${queryString ? `${PAYMENT_COMBO_ROUTE}?${queryString}` : PAYMENT_COMBO_ROUTE}`)
//         } else {
//           return navigateTo(`${queryString ? `${PAYMENT_PAGE_PATH}?${queryString}` : PAYMENT_PAGE_PATH}`)
//         }
//       } else {
//         return navigateTo(`${queryString ? `${PAYMENT_COMBO_ROUTE}?${queryString}` : PAYMENT_COMBO_ROUTE}`)
//       }
//     }
//   })
// }
