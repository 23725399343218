import ttqInstance from "@/utils/tiktok"
import { ttqEventName } from "@/constant/ttq"
const setEvent = ttqInstance.setEvent

export function usePaymentTtq() {
  const _PointTtqPaymentSuccess = (value: any, type) => {
    setEvent(ttqEventName.CompletePayment, { currency: type, content_type: "product", value })
  }
  return {
    _PointTtqPaymentSuccess
  }
}
