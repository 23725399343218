import { DownloadApi } from "~~/constant/api/download"
import http from "~~/http"
import http2 from "~/http/http2"
export type SoftwareInfo = {
  id: number
  version: string
  package_url: string
  package_name: string
  type: number
  app_type: number
  is_force_update: number
  is_need_update: number
  update_mode: number
  update_condition: string
  release_note: string
  runtime: string
  created_at: string
  package_size: string
}

export enum AbOn {
  Trial = 1,
  Stable = 2,
  All = 3
}

export interface IMeta {
  ab_on: AbOn
  introduction: string
  open_arm: boolean
  total: number
  mac_url: string
  windows_url: string
  is_channel_mac_arm_web: boolean
  is_channel_mac_intel_web: boolean
  is_channel_win_web: boolean
}

export const fetchSoftwareInfo = () => {
  return http2.get<SoftwareInfo[]>(DownloadApi.SoftwareInfo, { from_web: 1 }, undefined, true)
}

export const sendDownloadEmail = (email: string) => {
  return http.post(DownloadApi.SendDownloadEmail, {
    email
  })
}

export const getDownloadUrl = (sid: string, software: { id: number }) => {
  return http.post<{
    download_url: string
    file_name: string
  }>(DownloadApi.GetDownloadUrl, {
    sid,
    software
  })
}

// 下载次数叠加
export const postDownloadCount = (app_type: any) => {
  return http.post(DownloadApi.DownloadCount, { app_type })
}
