import { browserVersionBlacklistMap } from "~~/constant"

export function useBrowserValidatorGuard() {
  const { userAgent } = useDevice()
  const { browser, version } = getBrowserInfo(userAgent)
  const bv = browserVersionBlacklistMap[browser]
  if (bv && version <= bv) {
    return navigateTo("/unsupported_browser.html")
  }
}
