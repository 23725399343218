<template>
  <CommonDialog
    v-model="showDialog"
    popper-class="auth-code_dialog"
    :dialog-style="dialogStyle"
    :show-close-icon="showCloseIcon"
    @close="closeDialogFn"
  >
    <div class="auth-dialog">
      <div class="auth-tip-wrapper">
        <img src="@/assets/images/common/auth-code-1.webp" />
        <span class="t1">{{ useTranslateI18n(`login.auth.title`) }}</span>
        <span class="t2">{{ useTranslateI18n(`login.auth.desc`) }}</span>
        <transition name="fade">
          <div v-show="isReady" :id="interCapthcaContainerId" class="auth-container"></div>
        </transition>
        <CommonButton ref="btnRef" :button-style="buttonStyle" @btn-click="goPage"
          ><CommonAnimationScrollText>{{ useTranslateI18n(`login.auth.btn`) }}</CommonAnimationScrollText></CommonButton
        >
      </div>
    </div>
  </CommonDialog>
</template>

<script setup lang="tsx">
import { CSSProperties } from "vue"
import CommonButton from "@/components/common/Button.vue"
import { useCaptcha } from "@/composables/utils/useCaptcha"

const prop = defineProps({
  closeDialog: {
    type: Function,
    default() {
      return () => {}
    }
  },
  success: {
    type: Function,
    default() {
      return () => {}
    }
  },
  showCloseIcon: {
    type: Boolean,
    default() {
      return true
    }
  }
})

let showDialog = ref(true)

let btnRef = ref<InstanceType<typeof CommonButton>>()

let dialogStyle = reactive<CSSProperties>({
  width: 438,
  backgroundColor: "#fff",
  padding: "40px"
})

const buttonStyle = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px"
})

// 判断是否人机验证获取token
const goPage = async () => {
  btnRef.value.openLoading()
  // 没有token不通过
  let tk = getResponse()
  if (!tk) {
    btnRef.value.closeLoading()
    return
  }
  // 设置到缓存
  setTkToSess(tk)
  prop.success && prop.success()
  btnRef.value.closeLoading()
  closeDialogFn()
}

const closeDialogFn = () => {
  removeCaptchaDom()
  showDialog.value = false
  prop.closeDialog && prop.closeDialog()
}

const interCapthcaContainerId = "capthca-contanier_dak231dalkdsa"

const { token, isReady, setupScript, getResponse, resetCaptcha, setTkToSess, removeCaptchaDom } = useCaptcha({
  container: interCapthcaContainerId,
  callback: handleResponse,
  theme: "light"
})

setupScript()

// 点击人机验证
function handleResponse(tk: string) {
  token.value = tk
}
</script>

<style lang="scss" scoped>
.auth-dialog {
  display: flex;
  flex-direction: column;
  .auth-tip-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    img {
      width: 104px;
    }
    .t1 {
      margin-top: 24px;
      font-size: 24px;
      font-weight: 590;
      @include fontSemibold;
    }
    .t2 {
      margin-top: 24px;
      font-size: 14px;
      color: rgba(0, 0, 0, 0.55);
      line-height: 22px;
    }
    :deep(.common-button) {
      margin-top: 40px;
    }
  }

  .auth-container {
    margin-top: 24px;
  }
}
</style>

<style lang="scss">
.welcome-page-dialog {
  &.auth-code_dialog {
    @include device-max-width-600 {
      .content {
        width: 75%;
        .content-d {
          padding: 30px !important;
          width: 100% !important;
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 70%;
        .content-d {
          padding: 15px !important;
        }
      }
    }
  }
}

#capthca-contanier_dak231dalkdsa {
  > div {
    width: 100% !important;
    iframe {
      #rc-anchor-container {
        width: 100%;
      }
    }
  }
}
</style>
