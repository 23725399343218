export const useSdkMapTestHook = () => {
  const { _PointGaTestEvent } = useTestGtag()

  const { _pointMpTestEvent } = useTestMp()

  const { _pointTsSdkEvent } = useTsSdkTest()

  const leadsPointTestAll = (type = "leads") => {
    // ga
    _PointGaTestEvent(type)

    // mp
    _pointMpTestEvent(type)

    // tssdk
    _pointTsSdkEvent({ action_type: type })
  }

  const sidPointTestAll = (type = "sid") => {
    // ga
    _PointGaTestEvent(type)

    // mp
    _pointMpTestEvent(type)

    // tssdk
    _pointTsSdkEvent({ action_type: type })
  }

  return {
    leadsPointTestAll,
    sidPointTestAll
  }
}
