declare const google: any

export class GoogleAuthService {
  private clientId = "415059541000-id4logfsfr08291kf3jskuarmis46muj.apps.googleusercontent.com" // 替换为您的 CLIENT_ID

  constructor() {
    this.initClient()
  }

  private initClient(): void {
    google.accounts.id.initialize({
      client_id: this.clientId,
      callback: this.handleCredentialResponse.bind(this),
      use_fedcm_for_prompt: true
    })
  }

  // 处理授权登录后的响应
  private async handleCredentialResponse(response: google.accounts.id.CredentialResponse) {
    const credential = response.credential
    await outhUuid(credential, {
      from: "popup",
      navigate: false
    })
  }

  public promptGoogleLogin(): void {
    google.accounts.id.prompt()
  }
}
