import OfflineReminder from "@/components/common/OfflineReminder.vue"
import { useUser } from "@/store/user"
import { render } from "vue"

export const useOfflineReminderDialog = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const userStore = useUser()
      if (!userStore.userInfo.id) {
        resolve(true)
        return
      }
      const res = await getMachineList()

      if (!res.machine_list) {
        resolve(true)
        return
      }
      const list = res.machine_list.map((m) => JSON.parse(m.info))

      const onClose = () => {
        resolve(true)
      }

      const vm = h(OfflineReminder, { list, onClose })
      const { vueApp } = useNuxtApp()
      vm.appContext = vueApp._context
      render(vm, document.createElement("div"))
    } catch (error) {
      reject(error)
    }
  })
}
