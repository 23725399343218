export enum profileApi {
  MY_SUBSCRIPTION = "/v1/api/subscription/my", // 我的订阅
  GET_MACHINE_LIST = "/v1/api/user/machine/list", // 获取活跃机器列表
  REMOVE_MACHINE = "/v1/api/user/machine/remove", // 用户下线机器
  GET_PROCESS_DETAIL_LIST = "/v1/api/process_detail/list", // 获取导出列表
  GET_PROCESS_DETAIL_LIST_D = "/v1/api/process_detail/get", // 导出明细
  LOG_OFF_USER = "/v1/api/user/log_off", //注销用户
  GET_RECEIPT_INFO = "/v1/api/trade/receipt/info/get", // 获取账单信息
  POST_RECEIPT_INFO = "/v1/api/trade/receipt/info/edit", // 完善开票信息
  POST_RECEIPT_CREATE = "/v1/api/trade/receipt/create", // 开票
  POST_LOG_OFF_NOW = "/v1/api/user/log_off_now" //立即注销
}
