import wcsInstance from "@/utils/wcs"
import { wcsEventName } from "@/constant/wcs"
const setEvent = wcsInstance.setEvent

export function useLandingWcs() {
  const _PointWcsLandingButtonClick = () => {
    setEvent(wcsEventName.Download, "1")
  }
  return {
    _PointWcsLandingButtonClick
  }
}
