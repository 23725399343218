<template>
  <CommonDialog v-model="showDialog" popper-class="offline-reminde-dialog" :dialog-style="dialogStyle" @close="close">
    <template #close>
      <CommonImage class="close" :src="getCloudAssets('/icons/common/close085.png', 'icons')" />
    </template>
    <div class="dialog-content">
      <div class="title">
        {{ useTranslateI18n("login.offlineReminde.title") }}
      </div>

      <div class="devices-area">
        <div class="tip">
          {{ useTranslateI18n("login.offlineReminde.tip1") }}
        </div>
        <ol class="list">
          <li v-for="item in props.list" :key="item.hostname">{{ item.hostname }}</li>
        </ol>
        <div
          class="tip"
          v-html="
            useTranslateI18n(`login.offlineReminde.tip2`, [
              {
                type: `static`,
                class: `link`,
                onclick: `
                  window.open('/profile?t=3','_blank')
                `
              }
            ])
          "
        />
      </div>

      <button class="btn" @click="close">
        <span class="text">{{ useTranslateI18n("login.offlineReminde.btn") }}</span>
      </button>
    </div>
  </CommonDialog>
</template>

<script setup lang="ts">
import { CSSProperties } from "vue"

const props = defineProps({
  list: {
    type: Array<{
      hostname: string
    }>,
    default() {
      return []
    }
  }
})

const emits = defineEmits(["close"])

const dialogStyle = reactive<CSSProperties>({})

const showDialog = ref(true)

const close = () => {
  showDialog.value = false
  emits("close")
}
</script>

<style lang="scss" scoped>
.offline-reminde-dialog {
  .close {
    position: absolute;
    top: 16px;
    right: 24px;
    width: 24px;
    height: 24px;
    cursor: pointer;
  }
  .dialog-content {
    box-sizing: border-box;
    width: 480px;
    border-radius: 12px;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 8px 32px 0px rgba(0, 0, 0, 0.25);
    padding: 16px 24px;
    display: flex;
    flex-direction: column;
    .title {
      color: rgba(48, 51, 55, 0.85);
      font-family: "SF Pro";
      font-size: 16px;
      font-style: normal;
      font-weight: 510;
      line-height: normal;
      padding: 0 0 16px 0;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.1);
    }
    .devices-area {
      padding: 16px 0;
      display: flex;
      flex-direction: column;
      gap: 16px;

      .tip {
        color: rgba(48, 51, 55, 0.65);
        font-feature-settings: "clig" off, "liga" off;
        font-family: "SF Pro";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        :deep(.link) {
          color: #4865f7;
          font-feature-settings: "clig" off, "liga" off;
          font-family: "SF Pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
          text-decoration-line: underline;
          cursor: pointer;
        }
      }
      .list {
        display: flex;
        flex-direction: column;
        gap: 16px;

        li {
          color: rgba(48, 51, 55, 0.65);
          font-feature-settings: "clig" off, "liga" off;
          font-family: "SF Pro";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          list-style: none;
          display: flex;
          flex-direction: row;
          align-items: center;
          &::before {
            content: "";
            display: inline-block;
            width: 2px;
            height: 2px;
            border-radius: 50%;
            background: rgba(48, 51, 55, 0.65);
            margin-right: 5px;
          }
        }
      }
    }
    .btn {
      align-self: flex-end;
      margin: 16px 0 0 0;
      color: #000;
      text-align: center;
      font-family: "SF Pro";
      font-size: 13px;
      font-style: normal;
      font-weight: 590;
      line-height: 150%; /* 19.5px */
      display: flex;
      padding: 4px 6px;
      border-radius: 300px;
      background: #ffe050;
      cursor: pointer;
      .text {
        min-width: 52px;
      }
    }
  }
}
</style>

<style lang="scss">
.offline-reminde-dialog {
  .content {
    background: unset;
    box-shadow: unset;
  }
}
</style>
