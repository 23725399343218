<template>
  <CommonDialog
    v-model="showDialog"
    popper-class="policy-checkbox_dialog"
    :dialog-style="dialogStyle"
    @close="closeDialog"
  >
    <div class="policy-checkbox">
      <div class="policy-checkbox-title">
        {{ useTranslateI18n("login.policy.title") }}
      </div>
      <div class="policy-checkbox-content">
        <CommonForm :form-input-list="checkboxInputList" :form-inline="false" :form-model="checkboxFormModel" />
        <Transition name="fade">
          <div v-show="showCheckboxErrorTip" class="form-checkbox-error-tip">
            {{ useTranslateI18n("login.signUp.checkboxTip") }}
          </div>
        </Transition>
        <div class="submit-btn" @click="submitCreate">
          <CommonAnimationScrollText>{{ useTranslateI18n("login.signUp.buttonText") }}</CommonAnimationScrollText>
        </div>
      </div>
    </div>
  </CommonDialog>
</template>

<script setup lang="tsx">
import { IFormPropsInputList } from "@/types/components/common/form"
import { CSSProperties, Transition } from "vue"
import CommonDialog from "@/components/common/Dialog.vue"
import CommonForm from "@/components/common/Form.vue"
import { IOfficialSocialOperate } from "@/composables/utils/gtag/login"
import { useTranslateI18n } from "~/composables/store/i18n-country"

let { useLoginGTag } = useGTag()
let { _PointOfficialSocial } = useLoginGTag()

let { useCommonMp } = useMp()
let { _pointOfficialSignInButtonClick } = useCommonMp()

interface IProps {
  submit: (...args: any[]) => void
  closeDialog: (...args: any[]) => void
}

const prop = withDefaults(defineProps<IProps>(), {
  submit: () => {
    return null
  },
  closeDialog: () => {
    return null
  }
})

let showDialog = ref(true)

let dialogStyle = reactive<CSSProperties>({
  width: 438,
  backgroundColor: "#fff",
  padding: "40px"
})

let showCheckboxErrorTip = ref(false)

let checkboxFormModel = reactive({
  checkbox: [1]
})

const checkboxInputList = ref<IFormPropsInputList<typeof checkboxFormModel>>([
  {
    model: "checkbox",
    type: "checkbox",
    options: [
      {
        formatter() {
          return (
            <div class="custom-checkbox">
              <span>{useTranslateI18n("login.signUp.checkbox[0]")}</span>
              <span class="s1">{useTranslateI18n("login.signUp.desc")}</span>
            </div>
          )
        },
        value: 1
      },
      {
        formatter() {
          let str1 = useTranslateI18n(`login.signUp.checkbox[1]`, [
            {
              type: "static",
              class: "checkbox-formatter-t1"
            },
            {
              type: "static",
              class: `checkbox-formatter-t3`
            }
          ])
          // let str2 = useTranslateI18n("login.signUp.checkboxT[0]")
          // let str3 = useTranslateI18n("login.signUp.checkboxT[1]")
          // let str4 = useTranslateI18n("login.signUp.checkboxT[2]")
          return (
            <div class="checkbox-formatter" v-html={str1}>
              {/* <span>{str1}&nbsp;</span>
              <span
                class="checkbox-formatter-t1"
                onClick={() => {
                  _pointOfficialSignInButtonClick({
                    buttonName: "clickCheckAgreement",
                    subPageTitle: "PolicyAgreementPage"
                  })
                  window.open("/policy/terms")
                }}
              >
                {str2}
              </span>
              <span class="checkbox-formatter-t2">&nbsp;{str4}&nbsp;</span>
              <span
                class="checkbox-formatter-t3"
                onClick={() => {
                  _pointOfficialSignInButtonClick({
                    buttonName: "clickCheckAgreement",
                    subPageTitle: "PolicyAgreementPage"
                  })
                  window.open("/policy/privacy")
                }}
              >
                {str3}
              </span> */}
            </div>
          )
        },
        value: 2
      }
    ]
  }
])

watch(
  () => checkboxFormModel.checkbox,
  (newValue) => {
    if (newValue.length && newValue.find((item) => item === 2)) {
      showCheckboxErrorTip.value = false
    }
  },
  { deep: true }
)

// 创建用户点击
const submitCreate = () => {
  _PointOfficialSocial({
    official_operate: IOfficialSocialOperate.click_social_agreement_agree
  })
  _pointOfficialSignInButtonClick({
    buttonName: "clickThirdPartyAgreementAgree",
    subPageTitle: "PolicyAgreementPage"
  })
  if (checkboxFormModel.checkbox.length && checkboxFormModel.checkbox.find((item) => item === 2)) {
    prop.submit({ checkboxFormModel, showDialog })
  } else {
    // 显示checkbox 提示
    showCheckboxErrorTip.value = true
  }
}

const closeDialog = () => {
  _pointOfficialSignInButtonClick({
    buttonName: "clickThirdPartyAgreementClose",
    subPageTitle: "PolicyAgreementPage"
  })
  prop.closeDialog && prop.closeDialog(showDialog)
}
const { getPrivacy, getTerms } = useGetPolicy()

let policyTermsDomEvent
let privacyDomEvent
function addPolicyClick() {
  policyTermsDomEvent = useEventListener(document.querySelector(".checkbox-formatter-t1"), "click", () => {
    _pointOfficialSignInButtonClick({
      buttonName: "clickCheckAgreement",
      subPageTitle: "PolicyAgreementPage"
    })
    getTerms()
  })
  privacyDomEvent = useEventListener(document.querySelector(".checkbox-formatter-t3"), "click", () => {
    _pointOfficialSignInButtonClick({
      buttonName: "clickCheckAgreement",
      subPageTitle: "PolicyAgreementPage"
    })
    getPrivacy()
  })
}

onMounted(addPolicyClick)
onBeforeUnmount(() => {
  policyTermsDomEvent && policyTermsDomEvent()
  privacyDomEvent && privacyDomEvent()
})
</script>

<style lang="scss">
.welcome-page-dialog {
  &.policy-checkbox_dialog {
    .policy-checkbox {
      display: flex;
      flex-direction: column;
      width: 100%;
      &-title {
        // @include fontSemibold;
        @include fontSemibold;
        font-size: 24px;
        line-height: 29px;
        margin-bottom: 48px;
      }
      &-content {
        .pix-common-search {
          .el-form-item:nth-child(1) {
            margin-bottom: 0;
          }
          .el-checkbox-group {
            display: flex;
            flex-direction: column;
            width: 100%;
            .el-checkbox {
              height: unset;
              display: flex;
              align-items: flex-start;
              .el-checkbox__input {
                .el-checkbox__inner {
                  width: 16px;
                  height: 16px;
                  border-radius: 2px;
                  &::after {
                    top: 2px;
                    left: 5px;
                  }
                  &:hover {
                    border-color: #3083ff;
                  }
                }
              }
              .el-checkbox__input.is-checked .el-checkbox__inner {
                background-color: #3083ff;
                border-color: #3083ff;
              }
              .el-checkbox__input.is-focus .el-checkbox__inner {
                border-color: #3083ff;
              }
              .el-checkbox__label {
                white-space: pre-wrap;
                font-weight: 400;
                line-height: 17px;
                color: rgba(0, 0, 0, 0.55);
                padding-left: 8px;
                font-size: 12px;
                .checkbox-formatter {
                  .checkbox-formatter-t1,
                  .checkbox-formatter-t3 {
                    color: rgba(0, 0, 0, 0.7);
                    text-decoration: underline;
                    cursor: pointer;
                  }
                }
              }
              &:nth-child(2) {
                align-items: flex-start;
                margin-top: 8px;
              }
            }
          }

          .custom-checkbox {
            display: flex;
            flex-direction: column;
            .s1 {
              margin-top: 8px;
            }
          }
        }
        .form-checkbox-error-tip {
          color: #f56c6c;
          margin-top: 8px;
          line-height: 17px;
        }
        .submit-btn {
          margin-top: 40px;
          background-color: $bg-color;
          height: 42px;
          display: flex;
          justify-content: center;
          align-items: center;
          font-weight: 510;
          font-size: 14px;
          color: rgba(0, 0, 0, 0.85);
          border-radius: 60px;
          @include ease-in-out;
          cursor: pointer;
          // &:hover {
          //   background-color: rgba(255, 224, 80, 0.5);
          // }
        }
      }
    }

    @include device-max-width-600 {
      .content {
        width: 75%;
        .content-d {
          padding: 30px !important;
          width: 100% !important;
        }
      }
    }
    @include device-max-width-375 {
      .content {
        width: 70%;
        .content-d {
          padding: 15px !important;
        }
      }
    }
  }
}
</style>
