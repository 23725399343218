import { ChildTabId } from "~/constant/pages/profile"

export enum ILinkType {
  Normal = 1,
  Click = 2
}

export type ITabList = {
  label: string
  value: string | number
  src: any
  type: ILinkType
  linkSrc?: string
  id?: ChildTabId
  children?: Partial<ITabList>
}[]
