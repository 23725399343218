import { useUser } from "~/store/user"

let timeId = null
let refreshTokenSeconds = null

const setK = (tk: string) => {
  const { setTk } = useUser()
  // const { setCookie: setC } = useCookieWithout()
  setTk(tk)
}

const refreshToken = async () => {
  const { token } = await postRefreshToken()
  setK(token)
}

export const useRefreshToken = async () => {
  const { lfts, rlfts } = await postCheckToken()
  if (rlfts <= 0) {
    await refreshToken()
  } else {
    refreshTokenSeconds = rlfts
    timeId = setInterval(async () => {
      if (refreshTokenSeconds >= 0) {
        refreshTokenSeconds--
      } else {
        clearInterval(timeId)
        timeId = null
        refreshTokenSeconds = null
        await refreshToken()
        useRefreshToken()
      }
    }, 1000)
  }
}
