import { useCookiesStore } from "~~/store/cookies"

//  客户端传过来的唯一标识token key
const INLINE_TOKEN_KEY = `_inline_t`

export function useClientBridgeRouteGuard(to?: any) {
  const { $bridge } = useNuxtApp()
  let tk
  const isInlineClient = !!($bridge && Object.keys($bridge).length && process.client)
  // 判断是否事内嵌注入的，判断是否有uuid，有的话设置token
  if ($bridge && Object.keys($bridge).length && process.client) {
    const _token = to?.query[INLINE_TOKEN_KEY]
    if (_token) {
      tk = decodeURIComponent(_token)
    }
  }
  return {
    tk,
    isInlineClient
  }
}

// 客户端开启埋点
export function useClientPointOpen() {
  const { $bridge } = useNuxtApp()
  if ($bridge && Object.keys($bridge).length && process.client) {
    const cookiesStore = useCookiesStore()
    cookiesStore.setSelectedOption("all")
  }
}
