import mixpanelInstance from "@/utils/mixpanel"
import { mpEventName } from "@/constant/mp"
const setEvent = mixpanelInstance.setEvent
import { ICommonProperty } from "@/composables/utils/mp/common"

// enum IKolLandingSource {
//   faceBookSouthEastAsiaPurchase = "faceBookSouthEastAsiaPurchase",
//   googleSouthEastAsiaKeyWord = "googleSouthEastAsiaKeyWord",
//   googleSouthEastAsiaPic = "googleSouthEastAsiaPic",
//   googleSouthEastAsiaVideo = "googleSouthEastAsiaVideo",
//   googleSouthEastAsiaEvoto = "googleSouthEastAsiaEvoto",
//   faceBookUS = "faceBookUS",
//   googleUS = "googleUS",
//   faceBookSouthEastAsia = "faceBookSouthEastAsia",
//   faceBookVietnam = "faceBookVietnam",
//   faceBookMessage = "faceBookMessage",
//   googleSouthEastAsiaKeyCompeteKey = "googleSouthEastAsiaKeyCompeteKey",
//   insPurchase = "insPurchase"
// }

// export const sIdDict = {
//   cskbxdtk: IKolLandingSource.faceBookSouthEastAsiaPurchase,
//   ikdhfreq: IKolLandingSource.googleSouthEastAsiaKeyWord,
//   "6dgt5ywv": IKolLandingSource.googleSouthEastAsiaPic,
//   gsxbbj3e: IKolLandingSource.googleSouthEastAsiaVideo,
//   cnw9cdod: IKolLandingSource.googleSouthEastAsiaEvoto,
//   fnxwvomd: IKolLandingSource.faceBookUS,
//   uevtkbdh: IKolLandingSource.googleUS,
//   mcumwp7z: IKolLandingSource.faceBookSouthEastAsia,
//   srqhdbe7: IKolLandingSource.faceBookVietnam,
//   pwa7ree6: IKolLandingSource.faceBookMessage,
//   xkqvacry: IKolLandingSource.googleSouthEastAsiaKeyCompeteKey,
//   "3jln8qvc": IKolLandingSource.insPurchase
// }

// 落地页面 sid 对应 utm映射
export const utmDict = {
  cskbxdtk: "FB-1",
  mcumwp7z: "FB-2",
  srqhdbe7: "FB-3",
  pwa7ree6: "FB-4",
  arulwmzn: "FB-5",
  o449vr2l: "FB-6",
  jc7ushzo: "FB-7",
  fqah3gl6: "FB-8",
  "6wzjjkta": "FB-9",
  "6gt8ehwy": "FB-10",
  "3jln8qvc": "INS-1",
  ouu46msz: "FB-11",
  v9dcwdqx: "FB-12",
  qzur6cdd: "FB-13",
  "4b4b57d7": "FB-14",
  "103b5452": "FB-15",
  fnxwvomd: "FBus-1",
  akbox4a3: "FBus-2",
  goabbpiz: "FBus-3",
  vjazrr99: "FBus-4",
  hskuopq6: "FBus-5",
  nik4npl4: "FBus-6",
  qqkrt7hf: "INSus-1",
  p2mwkzst: "FBus-7",
  f4b2bad3: "FBus-8",
  bc7b6ee2: "FBus-9",
  ikdhfreq: "GG-1",
  "6dgt5ywv": "GG-2",
  gsxbbj3e: "YT-1",
  cnw9cdod: "GG-3",
  xkqvacry: "GG-4",
  "2j7cb4zc": "GG-5",
  "4jiai7xl": "GG-6",
  aouiy2vv: "YT-2",
  kj88t85r: "YT-3",
  gz3t4j6u: "GG-7",
  pe2ckqsg: "GG-8",
  wrss2vho: "YT-4",
  q4l57icz: "GG-9",
  ky5qjtai: "GG-10",
  xehp23gq: "GG-11",
  dtp9pprr: "YT-5",
  fdc0b24b: "YT-6",
  a46bfb89: "GG-12",
  e185a1dc: "GG-13",
  "1b64bfae": "GG-14",
  uevtkbdh: "GGus-1",
  "97hmrmtr": "GGus-2",
  exk4jncp: "GGus-3",
  "8sjlrpbp": "GGus-4",
  qxwwwito: "YTus-1",
  rvqzxrif: "GGus-5",
  x9kiaqut: "GGus-6",
  "7tjnxxss": "GGus-7",
  kwpswbk3: "YTus-2"
}

// 落地页面sid对应 kol映射
export const kolDict = {
  "48afc091": `kol-1`,
  "28b36dfa": `kol-2`,
  "4a352798": `kol-3`,
  "2ce89b70": `kol-4`,
  "62b3e908": `kol-5`,
  "23232942": `kol-6`,
  "221c0b75": `kol-7`,
  "71de8d50": `kol-8`,
  c51e88ba: `kol-9`,
  "0d282617": `kol-10`,
  swuo4mfr: `kol-11`,
  govy64vd: `kol-12`,
  fgikxpnx: `kol-13`,
  jea3z58p: `kol-14`,
  ivocnhbs: `kol-15`,
  tprpbc4z: `kol-16`,
  t3jalnq2: `kol-17`,
  hzjbqe6g: `kol-18`,
  lg7rputx: `kol-19`,
  "7ky5zuvm": `kol-20`,
  hjnppb3e: `kol-21`,
  "6ngkfhnt": `kol-22`,
  rnjpeaol: `kol-23`,
  hjkn5sr6: `kol-24`,
  wbmoqcrx: `kol-25`,
  begfnqkc: `kol-26`,
  oe28c2bf: `kol-27`,
  ko5z2jq6: `kol-28`,
  pm86cn4u: `kol-29`,
  fvnzq4wz: `kol-30`,
  e5gn6xjo: `kol-31`,
  ywnu5ejg: `kol-32`,
  l2jewcda: `kol-33`,
  hhyzzbny: `kol-34`,
  "3jmegefx": `kol-35`,
  meywp4lq: `kol-36`,
  xlrqjwtp: `kol-37`,
  xzqyfepa: `kol-38`,
  t8uqgvks: `kol-39`
}

export function useLandingMp() {
  const _pointLandingH5ViewPage = (params: ICommonProperty & { channel?: any; subchannel?: any }) => {
    setEvent(mpEventName.h5_viewH5Page, {}, { ...params, featureModule: "pageView", featureName: "viewH5Page" })
  }

  const _pointLandingH5DownloadButtonClick = (params: ICommonProperty & { channel?: any; subchannel?: any }) => {
    setEvent(
      mpEventName.h5_downloadButtonClick,
      {},
      { ...params, featureModule: "downloadButtonClick", featureName: "clickH5DownloadButton" }
    )
  }

  const _pointLandingH5ButtonClick = (params: ICommonProperty) => {
    setEvent(
      mpEventName.h5_DetailButtonClick,
      {},
      { ...params, featureName: "clickH5DetailButton", featureModule: "DetailButtonClick" }
    )
  }

  const _pointLandingH5RedeemH5ButtonClick = (params: ICommonProperty & { channel?: any; subchannel?: any }) => {
    const { channel, subchannel } = useGetChannel()

    setEvent(
      mpEventName.h5_redeemH5ButtonClick,
      {},
      {
        ...params,
        channel: unref(channel),
        subchannel: unref(subchannel),
        featureModule: "redemptionCode",
        featureName: "redemptionCode",
        path: useMpCommonPath(),
        pageTitle: useMpCommonPageTitle()
      }
    )
  }

  return {
    _pointLandingH5ViewPage,
    _pointLandingH5DownloadButtonClick,
    _pointLandingH5ButtonClick,
    _pointLandingH5RedeemH5ButtonClick
  }
}
