import fbqInstance, { FbqEvent } from "@/utils/fbq"
import { FbqEventName } from "@/constant/fbq"
const setEvent = fbqInstance.setEvent

export function usePaymentFbq() {
  // fb定价支付成功埋点 该事件是fb的标准事件（内置事件）
  const _PointFbqPaymentSuccess = (params: { value: any }, type: any) => {
    setEvent(FbqEventName.PaymentPurchase, Object.assign({ currency: type }, params), FbqEvent.Stand)
  }
  return {
    _PointFbqPaymentSuccess
  }
}
