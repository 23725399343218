<template>
  <div class="signup-with-code-wrapper">
    <div class="top-title">
      <span class="t-1">{{ useTranslateI18n("login.newLogin.t8") }}</span>
    </div>
    <div class="desc">
      <span class="t-1" v-html="topTitle"> </span>
    </div>
    <CommonVerCodeForm2 ref="verCodeRef" @enter-click="signupClick" />
    <Transition name="fade">
      <span v-show="invalidCodeShow" class="err-code">{{ useTranslateI18n("login.email.invalid") }}</span>
    </Transition>

    <div :class="['resend-code']">
      <span v-show="isTiming" :class="[isTiming ? 'resend-code-active' : '']">
        {{ useTranslateI18n("login.email.resend") }}&nbsp;({{ count }})</span
      >

      <span v-show="!isTiming" @click="resendCodeEvent"> {{ useTranslateI18n("login.email.resend") }}</span>
    </div>

    <!-- 邀请码 -->
    <div v-if="showChannelCode" class="channel-code">
      <div class="channel-code-title">
        <span @click="channelCodeClick"> {{ useTranslateI18n("login.signUp.invitation") }}</span>
      </div>
      <el-input v-show="show_channel_code" v-model="channel_code" :disabled="channel_code_disabled"></el-input>
    </div>

    <div ref="loadingRef" class="submit-button-w">
      <CommonButton ref="btnRef" class="submit-button" :button-style="buttonStyle1" @btn-click="signupClick"
        ><CommonAnimationScrollText>{{
          useTranslateI18n("login.signUp.title")
        }}</CommonAnimationScrollText></CommonButton
      >
    </div>

    <div :class="['form-checkbox']">
      <CommonForm :form-input-list="checkboxInputList" :form-inline="false" :form-model="checkboxFormModel" />
      <Transition name="fade">
        <div v-show="showCheckboxErrorTip" class="form-checkbox-error-tip">
          {{ useTranslateI18n("login.signUp.checkboxTip") }}
        </div>
      </Transition>
    </div>
  </div>
</template>

<script setup lang="tsx">
import { IFormPropsInputList } from "@/types/components/common/form"
import { LOGIN_SCENE, IErrorLoginType, VERIFY_TYPE } from "@/constant/login"
import { useUser } from "@/store/user"
import { LocalStorageKey, SessStorageKey } from "@/constant/cache"
import { IChannelCodeTag } from "@/constant/referrer"
import { PROFILE_PAGE_PATH } from "@/constant/route" // 邀请注册页面路由
import { TabListValue } from "@/constant/pages/profile"

const use_user = useUser()

interface IProps {
  isReload: boolean
  email: string
  phone: {
    countryValue: string
    inputValue: string
  }
  sceneRecord: LOGIN_SCENE
}

const props = withDefaults(defineProps<IProps>(), {
  isReload() {
    return true
  },
  email() {
    return ""
  },
  phone() {
    return undefined
  },
  sceneRecord() {
    return LOGIN_SCENE.CODE_LOGIN
  }
})
// 判断选择方式 email or phone
let enterEmail = ref(!unref(props.phone))

const emits = defineEmits(["goBack", "submit"])

const topTitle = computed(() => {
  return unref(props.phone)
    ? useTranslateI18n("login.newLogin.t23", [
        { text: `+${unref(props.phone)?.countryValue} ${unref(props.phone)?.inputValue}`, class: "t-1-a" },
        { type: "static", class: "t-1-l" }
      ])
    : useTranslateI18n("login.newLogin.t14", [
        { text: props.email, class: "t-1-a" },
        { type: "static", class: "t-1-l" }
      ])
})

const { _pointLoginAndRegFlowPageButtonView } = useCommonMp()
const { _pointSignUpWithCodeClick } = useStSdkCommonMp()

const { _PointGtmSignUpSuccess } = useLoginGtm()

const { channel_code_disabled, show_channel_code, showChannelCode, channel_code, channelCodeClick } =
  useDefaultChannelCode()

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Regular"
})

let showCheckboxErrorTip = ref(false)

const { isTiming, timekeeping, count, verCodeRef, resendCode, invalidCodeShow, sendCodeApi } =
  useVerCodeCountDown("signup-with-code-wrapper")

const resendCodeEvent = () => {
  _pointSignUpWithCodeClick({
    element_name: "resend_code",
    element_position: "popup",
    element_type: "button",
    popup_name: "login_or_signup",
    feature_module: "login_or_signup",
    feature_group: "signup",
    feature_name: "signup_with_code"
  })
  resendCode()
}
const goBack = useDebounceFn(() => {
  _pointSignUpWithCodeClick({
    element_name: unref(enterEmail) ? "change_email_address" : "change_phone_number",
    element_position: "popup",
    element_type: "button",
    popup_name: "login_or_signup",
    feature_module: "login_or_signup",
    feature_group: "signup",
    feature_name: "signup_with_code"
  })
  _pointLoginAndRegFlowPageButtonView({
    featureName: "register",
    elementName: "change_account",
    pageTitle: "register_with_code"
  })
  emits("goBack")
}, 300)

let checkboxFormModel = reactive({
  checkbox: []
})

watch(
  () => checkboxFormModel.checkbox,
  (newValue) => {
    if (newValue.length && newValue.find((item) => item === 2)) {
      showCheckboxErrorTip.value = false
    }
  },
  { deep: true }
)

const emailEnable = computed(() => !!checkboxFormModel.checkbox.find((item) => item === 1))
const agreeTerms = computed(() => !!checkboxFormModel.checkbox.find((item) => item === 2))

const isAgreeEmailSub = () => {
  return checkboxFormModel.checkbox.includes(1)
}

const checkboxInputList = ref<IFormPropsInputList<typeof checkboxFormModel>>([
  {
    model: "checkbox",
    type: "checkbox",
    options: [
      {
        formatter() {
          return (
            <div class="checkbox-formatter-1">
              <span>{useTranslateI18n("login.signUp.checkbox[0]")}</span>
              <span>{useTranslateI18n("login.signUp.desc")}</span>
            </div>
          )
        },
        value: 1
      },
      {
        formatter() {
          let str1 = useTranslateI18n(`login.signUp.checkbox[1]`, [
            {
              type: "static",
              class: "checkbox-formatter-t1"
            },
            {
              type: "static",
              class: `checkbox-formatter-t3`
            }
          ])
          return <div class="checkbox-formatter" v-html={str1}></div>
        },
        value: 2
      }
    ]
  }
])

watch(
  () => unref(props.phone),
  (newValue) => {
    if (newValue) {
      checkboxInputList.value[0].options = checkboxInputList.value[0].options.splice(1, 2)
    }
  },
  {
    immediate: true
  }
)

let agreeDialog = null

const { loadingRef, openLoading, closeLoading, isClick } = useLoadingHooks()

const { getSSORedirectUrl } = useSSO()
const ssoRedirectUrl = getSSORedirectUrl()

const signupClick = useDebounceFn(async () => {
  async function checkNext(verCodeArr: any[]) {
    try {
      let data = await checkVerCodeApi({
        code: useMd5(verCodeArr.join("")),
        email: props.email || undefined,
        phone: props.phone?.inputValue,
        phone_cc: props.phone?.countryValue,
        scene: props.sceneRecord
      })
      next(data.code)
    } catch (e) {
      invalidCodeShow.value = true
    }
  }

  _pointSignUpWithCodeClick({
    element_name: "signup",
    element_position: "popup",
    element_type: "button",
    popup_name: "login_or_signup",
    feature_module: "login_or_signup",
    feature_group: "signup",
    feature_name: "signup_with_code"
  })
  _pointLoginAndRegFlowPageButtonView({
    featureName: "register",
    elementName: "signup",
    pageTitle: "register_with_code"
  })
  if (verCodeRef.value) {
    let verCodeArr = verCodeRef.value.resultCode

    if (
      !verCodeArr.every((item) => {
        return item.length
      })
    ) {
      invalidCodeShow.value = true
    } else {
      if (unref(isClick)) return
      openLoading()
      invalidCodeShow.value = false

      try {
        if (!(checkboxFormModel.checkbox.length && checkboxFormModel.checkbox.find((item) => item === 2))) {
          showCheckboxErrorTip.value = true
          if (!agreeDialog) {
            agreeDialog = useAgreeSubmitDialog({
              text: useTranslateI18n(`login.signUp.checkbox[3]`, [
                {
                  type: "static"
                },
                {
                  type: "static"
                }
              ]),
              width: 502,
              async submit(closeDialog) {
                checkboxFormModel.checkbox.push(2)
                closeDialog()
                await checkNext(verCodeArr)
              },
              close() {
                agreeDialog = null
              },
              submitBtnText: useTranslateI18n(`login.signUp.checkbox[2]`)
            })
          }
        } else {
          await checkNext(verCodeArr)
        }
      } catch (e) {
        invalidCodeShow.value = true
        return
      } finally {
        closeLoading()
      }
    }
  }
}, 300)

const next = async (code: string) => {
  if (checkboxFormModel.checkbox.length && checkboxFormModel.checkbox.find((item) => item === 2)) {
    let { sId, _uuid } = useGetLandingShotParams()
    let uuid = null
    let { isStaticChannel } = useExhibitionStaticParams()
    if (isStaticChannel(sId, _uuid)) {
      uuid = _uuid
    }

    try {
      openLoading()

      let { n } = await postNc({
        uuid
      })

      // 需要人机验证
      if (n) {
        useAuthCodeDialog({
          success() {
            nextFn(code)
          }
        })
      } else {
        nextFn(code)
      }
    } finally {
      closeLoading()
    }
  }
}

async function nextFn(code) {
  const { usePhoneSignUpAddEmailDialog, usePhoneSignUpAddEmailCodeDialog } = useLoginAndRegFlow()

  let isAgreeeEmail = isAgreeEmailSub()
  let { getTkToSess, delTkToSess } = useCaptcha({})
  let { sId, _uuid } = useGetLandingShotParams()
  let uuid = null
  let { isStaticChannel } = useExhibitionStaticParams()
  if (isStaticChannel(sId, _uuid)) {
    uuid = _uuid
  }

  try {
    let _email

    openLoading()

    // 手机号注册弹窗填写邮箱
    if (unref(props.phone)) {
      _email = await usePhoneSignUpAddEmailDialog(false)
    }
    const channelHistry = getLocalStorage(SessStorageKey.CHANNEL_HISTORY)
    let data = await setStateLoginApi({
      landing_uuid: uuid,
      edm_email: _email,
      email: props.email || undefined,
      phone: unref(props.phone)?.inputValue,
      phone_cc: unref(props.phone)?.countryValue,
      verify_code: code,
      no_passwd: 1,
      agree_mail_sub: isAgreeeEmail ? 1 : undefined,
      t: getTkToSess() || undefined,
      channel: useReferrer2().value || useReferrer().value || undefined,
      channel_code: channel_code.value || undefined,
      ads_conversion: channelHistry ? JSON.parse(channelHistry) : undefined
      // 是否要渠道码
    })

    use_user.setTk(data.token)

    if (unref(props.phone)) {
      _PointGtmSignUpSuccess({
        sha256_email_address: _email,
        sha256_phone_number: `+${unref(props.phone)?.countryValue}${unref(props.phone)?.inputValue}`
      })

      // 邮箱校验绑定，可不填关闭
      await usePhoneSignUpAddEmailCodeDialog({
        email: _email,
        showCloseIcon: true,
        update: true
      })
    }

    delTkToSess()

    // 邮箱注册弹窗弹窗进入个人中心页面
    if (props.email) {
      _PointGtmSignUpSuccess({
        sha256_email_address: props.email
      })

      setLocalStorage(LocalStorageKey.OTHER_SIGNUP_DIALOG, "1")
    }

    // 判断渠道码是否有错
    if (channel_code.value) {
      let { tag } = await CheckSrcId2(channel_code.value)
      if (tag === IChannelCodeTag.Success) {
        useReferrer2().value = channel_code.value
        setLocalStorage(LocalStorageKey.TEMP_AD_CHANNEL_P2, channel_code.value)
        use_user.setUserInfo({
          info: data.info.user_info,
          token: data.token,
          redirectUrl: ssoRedirectUrl
        })
      } else if (tag === IChannelCodeTag.Error) {
        useChannelErrorDialog({
          title: useTranslateI18n(`login.signUp.error`),
          desc: useTranslateI18n(`login.signUp.cError`),
          submit() {
            use_user.setUserInfo({
              info: data.info.user_info,
              token: data.token,
              redirectUrl: ssoRedirectUrl
            })
          },
          closeDialog() {
            use_user.setUserInfo({
              info: data.info.user_info,
              token: data.token,
              redirectUrl: ssoRedirectUrl
            })
          },
          reEnter() {
            use_user.setUserInfo({
              info: data.info.user_info,
              token: data.token,
              reload: true,
              redirectUrl: ssoRedirectUrl || `${unref(PROFILE_PAGE_PATH)}?t=${TabListValue.INVITATION}`
            })
          }
        })
      } else if (tag === IChannelCodeTag.Invalid) {
        useChannelErrorDialog({
          title: useTranslateI18n(`login.signUp.invalid`),
          desc: useTranslateI18n(`login.signUp.iError`),
          submit() {
            use_user.setUserInfo({
              info: data.info.user_info,
              token: data.token,
              redirectUrl: ssoRedirectUrl
            })
          },
          closeDialog() {
            use_user.setUserInfo({
              info: data.info.user_info,
              token: data.token,
              redirectUrl: ssoRedirectUrl
            })
          },
          reEnter() {
            use_user.setUserInfo({
              info: data.info.user_info,
              token: data.token,
              reload: true,
              redirectUrl: ssoRedirectUrl || `${unref(PROFILE_PAGE_PATH)}?t=${TabListValue.INVITATION}`
            })
          }
        })
      }
    } else {
      use_user.setUserInfo({
        info: data.info.user_info,
        token: data.token,
        redirectUrl: ssoRedirectUrl
      })
    }
  } catch (e) {
    if (e?.code == IErrorLoginType.AuthVerify) {
      useAuthCodeDialog({
        success() {
          nextFn(code)
        }
      })
    }
  } finally {
    closeLoading()
  }
}

const { getPrivacy, getTerms } = useGetPolicy()

let goBackEvent

let policyTermsDomEvent
let privacyDomEvent
function addPolicyClick() {
  policyTermsDomEvent = useEventListener(document.querySelector(".checkbox-formatter-t1"), "click", (e) => {
    e.stopPropagation()
    getTerms()
  })
  privacyDomEvent = useEventListener(document.querySelector(".checkbox-formatter-t3"), "click", async (e) => {
    e.stopPropagation()

    getPrivacy()
  })
}

onMounted(() => {
  goBackEvent = useEventListener(document.querySelector(".signup-with-code-wrapper .desc .t-1 .t-1-l"), "click", goBack)
  nextTick(addPolicyClick)
  // 发送验证码
  setTimeout(() => {
    if (!isTiming.value) {
      sendCodeApi()
    }
  })
})

onBeforeUnmount(() => {
  goBackEvent && goBackEvent()
  policyTermsDomEvent && policyTermsDomEvent()
  privacyDomEvent && privacyDomEvent()
})
</script>

<style scoped lang="scss">
.signup-with-code-wrapper {
  display: flex;
  flex-direction: column;
  .top-title {
    font-size: 24px;
    line-height: normal;
    @include fontSemibold;
    color: #000;
    display: flex;
    flex-direction: column;
  }
  .desc {
    color: rgba(0, 0, 0, 0.55);
    font-size: 12px;
    font-style: normal;
    line-height: 150%;
    margin-top: 48px;
    margin-bottom: 29px;
    :deep(.t-1-l) {
      color: $bg-color-link;
      cursor: pointer;
      text-decoration: underline;
    }
    :deep(.t-1-a) {
      text-decoration: underline;
    }
  }

  .resend-code {
    margin-top: 8px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    font-style: normal;
    display: flex;
    cursor: pointer;
    .resend-code-active {
      color: rgba(0, 0, 0, 0.7);
      display: flex;
      padding: 4px 8px;
      border-radius: 42px;
      background: rgba(0, 0, 0, 0.05);
      cursor: not-allowed;
    }
  }

  .channel-code {
    margin-top: 16px;
    .channel-code-title {
      color: $bg-color-link;
      line-height: 17px;
      font-size: 12px;
      display: flex;
      > span {
        cursor: pointer;
      }
    }
    :deep(.el-input) {
      margin-top: 8px;
      line-height: 34px;
      .el-input__wrapper {
        padding-left: 12px;
        padding-right: 12px;
        border: 1px solid rgba(0, 0, 0, 0.3);
        border-radius: 8px;
        box-shadow: none;
        padding-top: 0;
        padding-bottom: 0;
        .el-input__inner {
          height: 34px;
          line-height: 34px;
          font-weight: 400;
          font-size: 14px;
          line-height: 17px;
          color: rgba(0, 0, 0, 0.85);
        }
      }
    }
  }

  .sign-up-desc {
    margin-top: 24px;
    color: rgba(0, 0, 0, 0.55);
    font-weight: 400;
    line-height: 17px;
  }
  .form-checkbox {
    margin-top: 24px;
    .form-checkbox-error-tip {
      color: #f56c6c;
      margin-top: 8px;
    }
    :deep(.pix-common-search) {
      .checkbox-formatter {
        word-break: break-word;
        .checkbox-formatter-t1,
        .checkbox-formatter-t3 {
          color: rgba(0, 0, 0, 0.7);
          text-decoration: underline;
          cursor: pointer;
        }
      }
      .el-form {
        .el-form-item {
          margin-bottom: 0;
          .el-form-item__content {
            line-height: 17px;
            .el-checkbox-group {
              display: flex;
              flex-direction: column;
              width: 100%;
              .el-checkbox {
                height: unset;
                display: flex;
                align-items: flex-start;
                margin-right: 0;
                .el-checkbox__input {
                  .el-checkbox__inner {
                    width: 16px;
                    height: 16px;
                    border-radius: 2px;
                    &::after {
                      top: 2px;
                      left: 5px;
                    }
                    &:hover {
                      border-color: #3083ff;
                    }
                  }
                }
                .el-checkbox__input.is-checked .el-checkbox__inner {
                  background-color: #3083ff;
                  border-color: #3083ff;
                }
                .el-checkbox__input.is-focus .el-checkbox__inner {
                  border-color: #3083ff;
                }
                .el-checkbox__label {
                  white-space: pre-wrap;
                  font-weight: 400;
                  line-height: 17px;
                  color: rgba(0, 0, 0, 0.55);
                  padding-left: 8px;
                  font-size: 12px;
                  .checkbox-formatter-1 {
                    flex-direction: column;
                    display: flex;
                    flex: 1;
                  }
                }
                &:nth-child(2) {
                  align-items: flex-start;
                  margin-top: 8px;
                }
              }
            }
          }
        }
      }
    }
  }
  .err-code {
    margin-top: 8px;
    font-weight: 400;
    line-height: 17px;
    color: #fc5555;
  }
  .submit-button-w {
    display: flex;
    margin-top: 40px;
  }
}
</style>
