import { StorageSerializers } from "@vueuse/core"
import type { Ref } from "vue"
import { SessStorageKey } from "~~/constant/cache"
import { EMAIL_RE, VERITY_CODE_RE, JP_PHONE_RE, US_PHONE_RE, IS_NULL_RE } from "~~/constant/regex"
import { useTimer } from "./utils/timer"
import { countriesData } from "@/constant/pages/payment"

export type UseValidatorOptions = {
  originTarget?: Ref<any>
  target?: Ref<string>
  validatorRegEx?: ValidatorRegExCtx
}
export type ValidatorRegExCtx = {
  regEx: RegExp
  validateFn?: (...args: any[]) => any
  errorMessage: {
    required: () => string
    invalid: () => string
  }
}

export const ValidatorRegEx = {
  email: {
    regEx: EMAIL_RE,
    errorMessage: {
      required: () => useTranslateI18n("landing.banner.form.requireErrMsg"),
      invalid: () => useTranslateI18n("landing.banner.form.invalidErrMsg")
    }
  },
  verityCode: {
    regEx: VERITY_CODE_RE,
    errorMessage: {
      required: () => useTranslateI18n("landing.banner.form.verCodeRequireErrMsg"),
      invalid: () => useTranslateI18n("landing.banner.form.verCodeRequireErrMsg")
    }
  },
  verityJpPhone: {
    regEx: JP_PHONE_RE,
    errorMessage: {
      required: () => useTranslateI18n("landing.banner.form.verRequiredPhone"),
      invalid: () => useTranslateI18n("landing.banner.form.verInvalidPhone")
    }
  },
  verityIntelPhone: {
    // 校验国际电话号码
    regEx: IS_NULL_RE,
    async validateFn(value) {
      const resValue = `${value.inputValue}`
      const countryCode = countriesData.find((item) => item.dialCode === value.countryValue)?.iso2
      const valid = await isValidPhoneNumber(resValue, countryCode.toUpperCase())
      return valid
    },
    errorMessage: {
      required: () => useTranslateI18n("landing.banner.form.verRequiredPhone"),
      invalid: () => useTranslateI18n("landing.banner.form.verInvalidPhone")
    }
  },
  name: {
    regEx: /^[^\s]+(\s+[^\s]+)*$/,
    errorMessage: {
      required: () => useTranslateI18n("landing.landingComboBfGift.validatorName"),
      invalid: () => useTranslateI18n("landing.landingComboBfGift.validatorName")
    }
  },
  monthly: {
    regEx: /^[^\s]+(\s+[^\s]+)*$/,
    errorMessage: {
      required: () => useTranslateI18n("payment.contactSales.form.monthly"),
      invalid: () => useTranslateI18n("payment.contactSales.form.monthly")
    }
  },
  businessType: {
    regEx: /^[^\s]+(\s+[^\s]+)*$/,
    errorMessage: {
      required: () => useTranslateI18n("payment.contactSales.form.businessTypeTip"),
      invalid: () => useTranslateI18n("payment.contactSales.form.businessTypeTip")
    }
  }
} as Recordable<ValidatorRegExCtx>

export function useValidator(options: UseValidatorOptions = {}) {
  const { target, validatorRegEx = ValidatorRegEx.email, originTarget } = options
  const errorMessage = ref("")
  const isError = ref(false)

  async function validator(): Promise<boolean> {
    if (!validatorRegEx || isUndefined(target.value)) {
      return false
    }
    isError.value = !validatorRegEx.regEx.test(target.value)
    if (isError.value) {
      setValidWatch()
      errorMessage.value = target.value.length
        ? validatorRegEx.errorMessage.invalid()
        : validatorRegEx.errorMessage.required()
    } else {
      if (validatorRegEx.validateFn) {
        const v = await validatorRegEx.validateFn(originTarget.value)
        isError.value = !v
        errorMessage.value = validatorRegEx.errorMessage.invalid()
        if (isError.value) {
          setValidWatch()
        } else {
          errorMessage.value = ""
          stopWatch && stopWatch()
          stopWatch = null
        }
      } else {
        errorMessage.value = ""
        stopWatch && stopWatch()
        stopWatch = null
      }
    }

    return !unref(isError)
  }
  let stopWatch
  function setValidWatch() {
    if (stopWatch) {
      return
    }
    stopWatch = watch(
      [target, originTarget],
      () => {
        validator()
      },
      { deep: true }
    )
  }

  return {
    isError,
    errorMessage,

    validator
  }
}
