import gtmInstance from "@/utils/gtm"
import { gtmEventName } from "@/constant/gtm"
import { useSha256 } from "@/utils/utils"

const setEvent = gtmInstance.setEvent

export function useLoginGtm() {
  // fb定价支付成功埋点 该事件是fb的标准事件（内置事件）
  const _PointGtmSignUpSuccess = async (options: { sha256_email_address?: string; sha256_phone_number?: string }) => {
    const resultParams = {} as any
    if (options.sha256_email_address) {
      resultParams.sha256_email_address = await useSha256(options.sha256_email_address)
    }
    if (options.sha256_phone_number) {
      resultParams.sha256_phone_number = await useSha256(options.sha256_phone_number)
    }
    setEvent(gtmEventName.WebSignUp, { user_data: resultParams })
  }
  return {
    _PointGtmSignUpSuccess
  }
}
