import { INavbarTheme } from "@/constant/navbar"

export const useNavBarBg = () => useState("navBarBg", () => "")

export const useNavBarShow = () => useState("navBarShow", () => true)

export const useNavBarShadowShow = () => useState("navBarShadowShow", () => false)

export const useShowNavBarAlert = () => useState("showNavBarAlert", () => false)

export const useShowLightNav = () => useState("showLightNav", () => false)

// 协议地址
export const usePolicyAddress = () =>
  useState<{ privacy: string; terms: string }>("usePolicyAddress", () => {
    return {
      privacy: "",
      terms: ""
    }
  })

// 头部alert的图片、内容、按钮配置
export const useNavBarAlertConfig = () =>
  useState("navBarAlertConfig", () => {
    return {
      btn_style: null,
      btn_text: null,
      btn_url: null,
      element: null,
      icon: null,
      content: null
    }
  })

export const useNavBarLanguage = () => useState("navbarLanguage", () => false)

export const setNavbarLanguageShow = (show: boolean) => {
  useNavBarLanguage().value = show
}
export const useFooterLinkShow = () => useState("footerLinkShow", () => true)

export const homeShotConfig = () =>
  useState<{ com: string; layout: string }>("homeShotConfig", () => {
    return {
      com: null,
      layout: null,
      resCom: null
    }
  })

// 订阅的组件配置获取
export const subShotConfig = () =>
  useState<{ com: string; layout: string }>("subShotConfig", () => {
    return {
      com: null,
      layout: null
    }
  })

// navbar主题色
export const useNavbarTheme = () =>
  useState<INavbarTheme>("useNavbarTheme", () => {
    return INavbarTheme.White
  })

// 设置白色主题
export const useSetNavbarTheme = (theme: INavbarTheme) => {
  useNavbarTheme().value = theme
}

// 是否固定主题，滚动主题也不变动
export const useFixedNavbarTheme = () =>
  useState<boolean>("useFixedNavbarTheme", () => {
    return false
  })

//navbar活动滚动的背景色
export const useNavbarCustomBg = () =>
  useState<string>("useNavbarCustomBg", () => {
    return "transparent"
  })
