import ttqInstance from "@/utils/tiktok"
import { ttqEventName } from "@/constant/ttq"
const setEvent = ttqInstance.setEvent

export function useLandingTtq() {
  const _PointTtqLandingButtonClick = () => {
    setEvent(ttqEventName.Download, {})
  }
  return {
    _PointTtqLandingButtonClick
  }
}
