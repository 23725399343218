import { createVNode, render } from "vue"
import AuthCodeDialog from "@/components/common/AuthDialog.vue"

type IOptions = {
  closeDialog?: (...args) => any
  success?: (...args) => any
  showCloseIcon?: boolean
}

export function useAuthCodeDialog(options: IOptions) {
  const vm = createVNode(AuthCodeDialog, {
    ...options
  })
  const { vueApp } = useNuxtApp()
  vm.appContext = vueApp._context
  render(vm, document.createElement("div"))
}
