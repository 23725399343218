import { SessStorageKey } from "~/constant/cache"
import { TOKEN_KEY } from "~/constant/cookies"
import { useUser } from "~/store/user"

export function useSSO() {
  const { query } = useRoute()
  const userStore = useUser()

  async function bootstrap() {
    if (import.meta.client) {
      if (query.sso_redirect_url) {
        setLocalStorage(SessStorageKey.SSO_REDIRECT_URL, query.sso_redirect_url, "sessionStorage")
      }

      await nextTick()
      if (getSSORedirectUrl()) {
        if (!userStore.isLogin) {
          userStore.showLoginDialogAction()
        } else {
          const tk = useCookie(TOKEN_KEY, {
            readonly: true
          })
          // 去除子域token 赋值顶域token
          if (tk.value && import.meta.client) {
            clearCookie(TOKEN_KEY)
            userStore.setTk(tk.value)
          }
          await nextTick()
          redirect()
        }
      }
    }
  }

  function getSSORedirectUrl() {
    if (import.meta.client) {
      const redirectUrl = getLocalStorage(SessStorageKey.SSO_REDIRECT_URL, "sessionStorage")
      return redirectUrl ? decodeURIComponent(decodeURIComponent(redirectUrl)) : undefined
    }
  }

  function clearSSORedirectUrl() {
    removeLocalStorage(SessStorageKey.SSO_REDIRECT_URL, "sessionStorage")
  }

  function redirect() {
    const href = getSSORedirectUrl()
    clearSSORedirectUrl()
    return window.location.replace(href)
  }

  return {
    bootstrap,
    getSSORedirectUrl,
    clearSSORedirectUrl,
    redirect
  }
}
