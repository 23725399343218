import { CookiesOptions } from "~~/constant/cookies"

class GtmPoint {
  static instance: GtmPoint = null
  static getInstance(): GtmPoint {
    this.instance = this.instance ? this.instance : new GtmPoint()
    return this.instance
  }
  setEvent(event: string, params: any) {
    if (process.client) {
      const isEnabled = useIsPoint(CookiesOptions.Marketing)
      if (!isEnabled) {
        return
      }
      window?.dataLayer.push({
        event,
        ...params
      })
    }
  }
}

export default GtmPoint.getInstance()
