export const useDomObserver = (target: HTMLElement, callback: () => void) => {
  const observerRef = ref(null)

  const findFun = (entries) => {
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        observerRef.value.unobserve(target)
        callback()
      }
    })
  }

  observerRef.value = new IntersectionObserver(findFun)

  if (observerRef.value && target) {
    observerRef.value.observe(target)
  }
}
