<template>
  <div ref="emailRef" class="phone-account-email">
    <div class="phone-account-email-title">{{ useTranslateI18n("login.newLogin.t26") }}</div>
    <div class="phone-account-email-content">
      <div class="desc">
        <span class="t-1" v-html="topTitle"> </span>
      </div>
      <CommonVerCodeForm2 ref="verCodeRef" @enter-click="submit" />
      <Transition name="fade">
        <span v-show="invalidCodeShow" class="err-code">{{ useTranslateI18n("login.email.invalid") }}</span>
      </Transition>
      <div :class="['resend-code']">
        <span v-show="isTiming" :class="[isTiming ? 'resend-code-active' : '']">
          {{ useTranslateI18n("login.email.resend") }}&nbsp;({{ count }})</span
        >

        <span v-show="!isTiming" @click="resendCodeEvent"> {{ useTranslateI18n("login.email.resend") }}</span>
      </div>

      <div ref="loadingRef" class="submit-button-w">
        <CommonButton ref="btnRef" class="submit-button" :button-style="buttonStyle1" @btn-click="submit"
          ><CommonAnimationScrollText>{{
            useTranslateI18n("profile.basicInformation.logout.t5")
          }}</CommonAnimationScrollText></CommonButton
        >
      </div>

      <CommonButton class="btn2" :button-style="buttonStyle2" @btn-click="close"
        ><CommonAnimationScrollText>
          {{ useTranslateI18n("login.newLogin.t28") }}</CommonAnimationScrollText
        ></CommonButton
      >

      <div :class="['form-checkbox']">
        <CommonForm :form-input-list="checkboxInputList" :form-inline="false" :form-model="checkboxFormModel" />
      </div>
    </div>
  </div>
</template>

<script setup lang="tsx">
import { LOGIN_SCENE } from "@/constant/login"
import { IFormPropsInputList } from "@/types/components/common/form"
const { _pointVerifyEmailClick } = useStSdkCommonMp()
interface IProps {
  email: string
  sceneRecord: LOGIN_SCENE
}
const prop = withDefaults(defineProps<IProps>(), {
  email() {
    return ""
  },
  phone() {
    return undefined
  },
  sceneRecord() {
    return LOGIN_SCENE.VERIFY_EMAIL_CODE
  }
})

const topTitle = computed(() => {
  return useTranslateI18n("login.newLogin.t14", [
    { text: prop.email, class: "t-1-a" },
    { type: "static", class: "t-1-l" }
  ])
})

const buttonStyle1 = reactive({
  width: "100%",
  background: "#FFE050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Regular"
})

const buttonStyle2 = reactive({
  width: "100%",
  background: "rgba(0, 0, 0, 0.05)",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "14px",
  fontFamily: "Inter-Regular"
})
let enterEmail = ref(!unref(prop.phone))
const emit = defineEmits(["resendCode", "submitCode", "close", "goBack", "pointSubmit"])

const { isTiming, timekeeping, count, verCodeRef, resendCode, invalidCodeShow, sendCodeApi } = useVerCodeCountDown(
  `default-phone-login-mail-${prop.sceneRecord || 1}`,
  emit
)
const resendCodeEvent = () => {
  _pointVerifyEmailClick({
    element_name: "resend_code",
    element_position: "popup",
    element_type: "button",
    popup_name: "login_or_signup",
    feature_module: "login_or_signup",
    feature_group: "signup",
    feature_name: "verify_email"
  })
  resendCode()
}

let emailRef = ref()

let goBackEvent

const goBack = useDebounceFn(() => {
  _pointVerifyEmailClick({
    element_name: unref(enterEmail) ? "change_email_address" : "change_phone_number",
    element_position: "popup",
    element_type: "button",
    popup_name: "login_or_signup",
    feature_module: "login_or_signup",
    feature_group: "signup",
    feature_name: "verify_email"
  })
  emit("goBack")
}, 300)

onMounted(() => {
  goBackEvent = useEventListener(document.querySelector(".phone-account-email .desc .t-1 .t-1-l"), "click", goBack)

  setTimeout(() => {
    if (!isTiming.value) {
      sendCodeApi()
    }
  })
})

onBeforeUnmount(() => {
  goBackEvent && goBackEvent()
})

let loadingInstance = ref()

defineExpose({
  loadingInstance
})

const isAgreeEmailSub = () => {
  return checkboxFormModel.checkbox.includes(1)
}

let checkboxFormModel = reactive({
  checkbox: []
})

const checkboxInputList = ref<IFormPropsInputList<typeof checkboxFormModel>>([
  {
    model: "checkbox",
    type: "checkbox",
    options: [
      {
        formatter() {
          return (
            <div class="checkbox-formatter-1">
              <span>{useTranslateI18n("login.signUp.checkbox[0]")}</span>
              <span>{useTranslateI18n("login.signUp.desc")}</span>
            </div>
          )
        },
        value: 1
      }
    ]
  }
])

const { loadingRef, openLoading, closeLoading, isClick } = useLoadingHooks()

const submit = useDebounceFn(async () => {
  emit("pointSubmit")
  if (verCodeRef.value) {
    let verCodeArr = verCodeRef.value.resultCode
    if (
      !verCodeArr.every((item) => {
        return item.length
      })
    ) {
      invalidCodeShow.value = true
    } else {
      if (unref(isClick)) {
        return
      }
      invalidCodeShow.value = false
      const resCode = useMd5(verCodeArr.join(""))
      try {
        openLoading()
        const data = await checkVerCodeApi({
          code: resCode,
          email: prop.email,
          scene: prop.sceneRecord
        })
        // 记录验证码返回的code
        emit("submitCode", { code: data.code, email: prop.email, agree_mail_sub: isAgreeEmailSub() })
      } catch (e) {
        invalidCodeShow.value = true
      } finally {
        closeLoading()
      }
    }
  }
}, 300)

const close = () => {
  emit("close")
}
</script>

<style scoped lang="scss">
.phone-account-email {
  display: flex;
  flex-direction: column;
  width: 100%;
  &-title {
    // @include fontSemibold;
    @include fontSemibold;
    font-size: 24px;
    color: #000;
    line-height: 29px;
  }
  &-content {
    display: flex;
    flex-direction: column;
    .desc {
      color: rgba(0, 0, 0, 0.55);
      font-size: 12px;
      font-style: normal;
      line-height: 150%;
      margin-top: 48px;
      margin-bottom: 29px;
      :deep(.t-1-l) {
        color: $bg-color-link;
        cursor: pointer;
        text-decoration: underline;
      }
      :deep(.t-1-a) {
        text-decoration: underline;
      }
    }

    @include device-max-width-375 {
      .desc {
        margin-bottom: 0;
      }
      :deep(.input-box) {
        .input-content {
          input {
            border-top: none;
            border-left: none;
            border-right: none;
            border-radius: 0;
          }
        }
      }
    }
    .err-code {
      margin-top: 8px;
      font-weight: 400;
      line-height: 17px;
      color: #fc5555;
    }
    .resend-code {
      margin-top: 8px;
      color: rgba(0, 0, 0, 0.85);
      font-size: 12px;
      font-style: normal;
      display: flex;
      cursor: pointer;
      .resend-code-active {
        color: rgba(0, 0, 0, 0.7);
        display: flex;
        padding: 4px 8px;
        border-radius: 42px;
        background: rgba(0, 0, 0, 0.05);
        cursor: not-allowed;
      }
    }
    .submit-button-w {
      display: flex;
      margin-top: 40px;
    }
    .btn2 {
      margin-top: 12px;
    }

    .sign-up-desc {
      margin-top: 24px;
      color: rgba(0, 0, 0, 0.55);
      font-weight: 400;
      line-height: 17px;
    }
    .form-checkbox {
      margin-top: 24px;
      .form-checkbox-error-tip {
        color: #f56c6c;
        margin-top: 8px;
      }
      :deep(.pix-common-search) {
        /* .checkbox-formatter{
          word-break: break-word;
          .checkbox-formatter-t1,
          .checkbox-formatter-t3 {
            color: rgba(0, 0, 0, 0.7);
            text-decoration: underline;
            cursor: pointer;
          }
        } */
        .el-form {
          .el-form-item {
            margin-bottom: 0;
            .el-form-item__content {
              line-height: 17px;
              .el-checkbox-group {
                display: flex;
                flex-direction: column;
                width: 100%;
                .el-checkbox {
                  height: unset;
                  display: flex;
                  align-items: flex-start;
                  .el-checkbox__input {
                    .el-checkbox__inner {
                      width: 16px;
                      height: 16px;
                      border-radius: 2px;
                      &::after {
                        top: 2px;
                        left: 5px;
                      }
                      &:hover {
                        border-color: #3083ff;
                      }
                    }
                  }
                  .el-checkbox__input.is-checked .el-checkbox__inner {
                    background-color: #3083ff;
                    border-color: #3083ff;
                  }
                  .el-checkbox__input.is-focus .el-checkbox__inner {
                    border-color: #3083ff;
                  }
                  .el-checkbox__label {
                    white-space: pre-wrap;
                    font-weight: 400;
                    line-height: 17px;
                    color: rgba(0, 0, 0, 0.55);
                    padding-left: 8px;
                    font-size: 12px;
                    .checkbox-formatter-1 {
                      flex-direction: column;
                      display: flex;
                      flex: 1;
                    }
                  }
                  &:nth-child(2) {
                    align-items: flex-start;
                    margin-top: 8px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>
