import { LocalStorageKey } from "~~/constant/cache"
import { CookiesOptions, gTagPageWhiteList } from "~~/constant/cookies"

class WcsPoint {
  static instance: WcsPoint = null
  static getInstance(): WcsPoint {
    this.instance = this.instance ? this.instance : new WcsPoint()
    return this.instance
  }
  setEvent(name: string, params: any) {
    if (process.client && window.wcs && window.wcs_do) {
      const isEnabled = useIsPoint(CookiesOptions.Marketing)
      if (!isEnabled) {
        return
      }
      const _nasa = {}
      _nasa["cnv"] = window?.wcs?.cnv(name, params)
      window?.wcs_do(_nasa)
    }
  }
}

export default WcsPoint.getInstance()
