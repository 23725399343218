<template>
  <teleport to="body">
    <div v-if="dialogShow" :class="['welcome-page-dialog', popperClass]">
      <div class="share_cover">
        <div :class="['content', { close: isClose }]">
          <div class="content-d" :style="dialogContentStyle">
            <slot />
          </div>
          <div v-if="showCloseIconRes" @click="closeDialog">
            <slot name="close">
              <div class="close-icon"></div>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </teleport>
</template>

<script setup lang="ts">
import type { CSSProperties } from "vue"

interface IProps {
  modelValue: boolean
  popperClass?: string | Record<string, any>
  dialogStyle?: CSSProperties
  showCloseIcon?: boolean
  zIndex?: number
}
const props = withDefaults(defineProps<IProps>(), {
  modelValue: false,
  dialogStyle: () => {
    return {
      width: 438,
      backgroundColor: "#fff"
    }
  },
  popperClass: "",
  showCloseIcon: true,
  zIndex: () => 2000
})

const zIndex = computed(() => {
  return props.zIndex
})

const showCloseIconRes = computed(() => unref(props.showCloseIcon))

const emit = defineEmits(["update:modelValue", "close"])

const isClose = ref(false)

let dialogShow = ref(false)

const dialogContentStyle = computed(() => {
  return {
    ...props.dialogStyle,
    width: typeof props.dialogStyle.width === "number" ? props.dialogStyle.width + "px" : props.dialogStyle.width
  }
})

watch(
  () => props.modelValue,
  (newValue) => {
    if (newValue) {
      nextTick(() => {
        dialogShow.value = true
      })
      if (process.client) {
        setTimeout(() => {
          document.body.style.overflow = "hidden"
        }, 300)
      }
    } else {
      nextTick(() => {
        closeDialog()
      })
      if (process.client) {
        document.body.style.overflow = ""
      }
    }
  },
  {
    immediate: true
  }
)

defineExpose({
  closeDialog
})

function closeDialog() {
  if (dialogShow.value) {
    isClose.value = true
    setTimeout(() => {
      dialogShow.value = false
      emit("update:modelValue", false)
      emit("close")
      isClose.value = false
    }, 500)
  }
}
</script>

<style scoped lang="scss">
@keyframes popIn {
  0% {
    transform: scale3d(0.5, 0.5, 0.5) translate(-50%, -50%);
    opacity: 0;
  }
  50% {
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  100% {
    transform: scale3d(1, 1, 1) translate(-50%, -50%);
    animation-timing-function: cubic-bezier(0.25, 0.46, 0.45, 0.94);
    opacity: 1;
  }
}

@keyframes popOut {
  0% {
    transform: scale3d(1, 1, 1) translate(-50%, -50%);
    opacity: 1;
  }
  50% {
    animation-timing-function: cubic-bezier(0.47, 0, 0.745, 0.715);
  }
  100% {
    transform: scale3d(0.5, 0.5, 0.5) translate(-50%, -50%);
    opacity: 0;
  }
}
.share_cover {
  position: fixed;
  width: 100%;
  height: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.6);
  z-index: v-bind(zIndex);
}
.content {
  transform-origin: 10px 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  animation: popIn 0.5s;
  max-height: 90vh;
  border-radius: 8px;
  background: #252833;
  box-shadow: 0px 16px 100px rgba(0, 0, 0, 0.15);
  box-shadow: -1px 4px 60px rgba(0, 0, 0, 0.4);
  .content-d {
    overflow-y: auto;
    width: 100%;
    border-radius: 8px;
    @include scrollbar(8px);
  }
  &.close {
    animation: popOut 0.5s;
  }
  .close-icon {
    cursor: pointer;
    background-image: url("@/assets/icons/common/close@3x.png");
    background-repeat: no-repeat;
    background-size: 100% 100%;
    width: 24px;
    height: 24px;
    position: absolute;
    right: -40px;
    top: 0;
    background-color: transparent;
  }
}
</style>
