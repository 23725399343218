import { SessStorageKey } from "@/constant/cache"
import { JoinFacebookSource } from "~~/constant/mp"
import { CookiesKey } from "@/constant/cookies"
import { clearCookie } from "@/utils/utils"

export const useCommonBtnDownload = (btnStyle: any, source?: JoinFacebookSource) => {
  const { download } = useDownload({
    source
  })
  const { deviceType } = useDevice()
  const downloadBtnRef = ref()
  const sendBtnStyle = reactive(btnStyle)

  const freeDownloadHandler = async () => {
    downloadBtnRef.value.openLoading()
    await download(deviceType.value)
    downloadBtnRef.value.closeLoading()
  }

  return {
    downloadBtnRef,
    sendBtnStyle,
    freeDownloadHandler
  }
}

export const useInviteeSuccessFlag = () => {
  const setInviterUuid = (uuid) => {
    const cookie = useCookie(CookiesKey.TEMP_INVITEE_UUID)
    cookie.value = uuid
  }
  const getInviterUuid = () => {
    return useCookie(CookiesKey.TEMP_INVITEE_UUID).value
  }
  const removeInviterUuid = () => {
    useCookie(CookiesKey.TEMP_INVITEE_UUID).value = null
  }
  const setInviteeSuccessFlag = () => {
    setLocalStorage(SessStorageKey.TEMP_INVITEE_SUCCESS, "true", "sessionStorage")
  }
  const getInviteeSuccessFlag = () => {
    return getLocalStorage(SessStorageKey.TEMP_INVITEE_SUCCESS, "sessionStorage")
  }
  const removeInviteeSuccessFlag = () => {
    removeLocalStorage(SessStorageKey.TEMP_INVITEE_SUCCESS, "sessionStorage")
  }
  const setInviteeSuccessGoogleFlag = () => {
    setLocalStorage(SessStorageKey.TEMP_INVITEE_SUCCESS_GOOGLE, "true", "sessionStorage")
  }
  const getInviteeSuccessGoogleFlag = () => {
    return getLocalStorage(SessStorageKey.TEMP_INVITEE_SUCCESS_GOOGLE, "sessionStorage")
  }
  const removeInviteeSuccessGoogleFlag = () => {
    removeLocalStorage(SessStorageKey.TEMP_INVITEE_SUCCESS_GOOGLE, "sessionStorage")
  }

  const getInviteeFailedFlag = () => {
    return getLocalStorage(SessStorageKey.TEMP_INVITEE_FAILED, "sessionStorage")
  }
  const setInviteeFailedFlag = () => {
    setLocalStorage(SessStorageKey.TEMP_INVITEE_FAILED, "true", "sessionStorage")
  }
  const removeInviteeFailedFlag = () => {
    removeLocalStorage(SessStorageKey.TEMP_INVITEE_FAILED, "sessionStorage")
  }

  return {
    setInviteeSuccessGoogleFlag,
    getInviteeSuccessGoogleFlag,
    removeInviteeSuccessGoogleFlag,
    setInviteeSuccessFlag,
    getInviteeSuccessFlag,
    removeInviteeSuccessFlag,
    getInviteeFailedFlag,
    setInviteeFailedFlag,
    removeInviteeFailedFlag,
    setInviterUuid,
    getInviterUuid,
    removeInviterUuid
  }
}
