import { useDomObserver } from "~/composables/useDomObserver"

export default defineNuxtPlugin(async ({ vueApp }) => {
  vueApp.directive("video-lazy", {
    mounted(el, binding) {
      useDomObserver(el, () => {
        el.onerror = () => {
          // el.src = error || ""
        }
        el.poster = binding.value.poster
        el.src = binding.value.src
        el.oncanplay = () => {}
      })
    }
  })
})
