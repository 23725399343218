<template>
  <div ref="btnRef" class="common-button" :style="buttonStyle" @click="submit">
    <slot />
  </div>
</template>

<script setup lang="ts">
interface IProps {
  buttonStyle?: {
    width?: string
    height?: string
    backgroundColor?: string
  }
  customLoadingClass?: string
}

const emit = defineEmits(["btnClick", "update:modelValue"])

const props = withDefaults(defineProps<IProps>(), {
  buttonStyle: () => {
    return {
      width: "100%",
      height: "42px",
      borderRadius: "60px"
    }
  },
  customLoadingClass: () => {
    return ""
  }
})

let isClick = true

const loadingInstance = ref()

const btnRef = ref()

const closeLoading = () => {
  isClick = true
  loadingInstance.value && loadingInstance.value.close()
}

const openLoading = () => {
  isClick = false
  loadingInstance.value = useLoading({
    target: btnRef.value,
    "custom-class": props.customLoadingClass || ""
  })
}

defineExpose({
  closeLoading,
  openLoading
})

const submit = useDebounceFn(() => {
  if (isClick) {
    emit("btnClick")
  }
}, 300)
</script>

<style scoped lang="scss">
.common-button {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  @include fontSemibold;
  font-size: 16px;
  line-height: 19px;
  color: #fff;
  cursor: pointer;
}
</style>
