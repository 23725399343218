import { MessageProps } from "element-plus"
import { IMessageTypes, IMessageFn } from "@/types/element"

const types = ["success", "warning", "info", "error"]

export const $message: IMessageFn = (message: string | MessageProps, type: IMessageTypes = "success") => {
  const options = {
    message,
    "custom-class": "pix-message_custom",
    type,
    duration: 3000 // 更新提示信息的显示为3秒
  }
  if (typeof message === "string") {
    return ElMessage(options as any as MessageProps)
  } else {
    message = { ...options, ...message }
    return ElMessage(message)
  }
}
types.map((t) => {
  $message[t] = (options: string | { type: IMessageTypes; [x: string]: any }) => {
    if (typeof options === "string") {
      return $message(options, t as IMessageTypes)
    } else {
      options.type = t as IMessageTypes
      return $message(options as MessageProps, t as IMessageTypes)
    }
  }
})

export const $loading = (
  options: Partial<{
    target: HTMLElement
    fullscreen: boolean
    lock: boolean
    text: string
    spinner: string
    background: string
    "custom-class": string
    [x: string]: any
  }>
) => {
  return ElLoading.service(options)
}
