import { PointEvent } from "~~/constant/gtag"

export function useDemoGTag() {
  const { $gtag } = useNuxtApp()
  const { formatPointData, setEvent } = $gtag

  // 1.formatPointData声明的变量->在v-gtag中使用
  const _PointPopupClick = formatPointData(PointEvent.Demo, {})
  // 2.setEvent声明的变量->在方法中直接调用
  const _PointPopupExposure = () => setEvent(PointEvent.Demo, {})
  return {
    _PointPopupClick,
    _PointPopupExposure
  }
}
