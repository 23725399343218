import { DownloadPlatform } from "@/constant"
import { CHECK_BROWSER_NORMAL_RE, CHECK_BROWSER_ANDROS_RE } from "@/constant/regex"

export const isMac = (UA: string) => {
  return /macintosh|mac os x/i.test(UA) && !isMobile(UA)
}

/** * 是否为windows系统 * */
export const isWindows = (UA: string) => {
  return /windows|win32/i.test(UA)
}

export const isMobile = (UA: string) => {
  return /Mobi|Android|iPhone/i.test(UA)
}

export const isIOS = (UA: string) => {
  return !!UA?.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
}

export const isAndroid = (UA: string) => {
  return UA?.indexOf("Android") !== -1 || UA?.indexOf("Linux") !== -1
}

export const isSafariBrowser = (UA: string) => {
  return UA?.indexOf("Safari") !== -1 && UA?.indexOf("Chrome") === -1
}

export const isIpad = (nav: any) => {
  return nav.maxTouchPoints && nav.maxTouchPoints > 2 && /MacIntel/.test(nav.platform)
}

function checkMacArmChip() {
  if (process.server) {
    return false
  }
  const canvas_find_gpu = document.createElement("canvas")
  let gl_find_gpu
  let debugInfo_find_gpu
  // let vendor_find_gpu
  let renderer_find_gpu = ""

  try {
    gl_find_gpu = (canvas_find_gpu.getContext("webgl") ||
      canvas_find_gpu.getContext("experimental-webgl")) as WebGLRenderingContextBase
  } catch (e) {
    return false
  }

  if (gl_find_gpu) {
    debugInfo_find_gpu = gl_find_gpu.getExtension("WEBGL_debug_renderer_info")
    // vendor_find_gpu = debugInfo_find_gpu && gl_find_gpu.getParameter(debugInfo_find_gpu.UNMASKED_VENDOR_WEBGL)
    renderer_find_gpu = debugInfo_find_gpu && gl_find_gpu.getParameter(debugInfo_find_gpu.UNMASKED_RENDERER_WEBGL)
  }

  return renderer_find_gpu.includes("Apple M")
}

export const isMacARM = checkMacArmChip()

export function deviceType(UA: string) {
  if (isMac(UA) && isMacARM) {
    return DownloadPlatform.Mac
  } else if (isMac(UA)) {
    return DownloadPlatform.MacIntel
  } else {
    return DownloadPlatform.Win
  }
}

type BrowserInfo = {
  browser: string
  version: number
}

export function getBrowserInfo(ua: string) {
  const browser = {} as BrowserInfo
  const getIsAndroid = isAndroid(ua)
  ua = ua?.toLocaleLowerCase()
  const [$1, b, v] = (getIsAndroid ? ua?.match(CHECK_BROWSER_ANDROS_RE) : ua?.match(CHECK_BROWSER_NORMAL_RE)) || [
    undefined,
    "unknown",
    ""
  ]
  browser.browser = b.replace(/version/, "safari")
  browser.version = Number(v.split(".")[0])
  return browser
}
