import mixpanelInstance from "@/utils/mixpanel"
import { mpEventName } from "@/constant/mp"
const setEvent = mixpanelInstance.setEvent

export function useTestMp() {
  const _pointMpTestEvent = (action_type) => {
    setEvent(mpEventName.DataValidation, { action_type }, {})
  }

  return {
    _pointMpTestEvent
  }
}
