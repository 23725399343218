<template>
  <div :class="['invoices-info-edit']">
    <div class="invoices-info-edit-left">
      <div class="title">{{ useTranslateI18n("profile.billingUsage.invoices.t1") }}</div>
      <div class="desc">
        {{ useTranslateI18n("profile.billingUsage.invoices.t12") }}
      </div>
      <div class="ev-title">
        <img src="@/assets/images/common/ev-bg-2.png" alt="" />
      </div>
    </div>
    <div class="invoices-info-edit-right-content">
      <!-- <div class="top-title">{{ useTranslateI18n("payment.contactSales.formTitle") }}</div> -->
      <div class="content-form">
        <ClientOnly>
          <CommonForm
            ref="formRef"
            :form-input-list="formInputList"
            form-label-position="top"
            :form-model="formModel"
            :form-rules="formRules"
            @validate="validateForm"
          />
        </ClientOnly>
      </div>
      <div class="footer">
        <CommonButton ref="btnRef" class="submit-button" :button-style="buttonStyle" @btn-click="submitClick"
          ><CommonAnimationScrollText>{{
            useTranslateI18n("profile.basicInformation.save")
          }}</CommonAnimationScrollText></CommonButton
        >

        <CommonButton ref="btnRef" class="cancel-button" :button-style="buttonStyle1" @btn-click="cancelClick"
          ><CommonAnimationScrollText>{{
            useTranslateI18n("profile.basicInformation.logout.cancel")
          }}</CommonAnimationScrollText></CommonButton
        >
      </div>
    </div>
  </div>
</template>

<script setup lang="tsx">
import { IFormPropsInputList } from "@/types/components/common/form"
import { FormRules } from "element-plus"
import { ICountrySelctModelValue, IContactInfo } from "@/types/components/common/countrySelect"
import { EMAIL_RE } from "@/constant/regex"
import CommonForm from "@/components/common/Form.vue"
import CommonAnimationScrollText from "@/components/common/Animation/ScrollText.vue"
import { useUser } from "@/store/user"
import { useTranslateI18n } from "~/composables/store/i18n-country"
import { PropType } from "vue"
import { IReceiptInfo } from "@/composables/api/profile"

const props = defineProps({
  config: {
    type: Object as PropType<IReceiptInfo>
  }
})

const formRef = ref()

const buttonStyle = reactive({
  width: "100%",
  background: "#FFE050",
  color: `#000`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "16px",
  fontFamily: "Inter-Regular"
})

const buttonStyle1 = reactive({
  width: "100%",
  background: "rgba(0, 0, 0, 0.05)",
  color: `#000`,
  borderRadius: "60px",
  height: "42px",
  fontSize: "16px",
  fontFamily: "Inter-Regular"
})

const btnRef = ref()

const validatorEmail = (rule: any, value: any, callback: any) => {
  let regex = EMAIL_RE
  if (!regex.test(value)) {
    return callback(new Error(useTranslateI18n("login.signIn.error")))
  } else {
    return callback()
  }
}

const { validator: validatorP } = useValidatorInterPhone(false)

let formModel = reactive({
  title: props.config.title,
  phone: {
    inputValue: props.config.phone_number,
    countryValue: props.config.phone_code
  } as ICountrySelctModelValue,
  email: props.config.email_address,
  address: props.config.address,
  tax_code: props.config.tax_code
})
const formInputList = ref<IFormPropsInputList<typeof formModel>>([
  {
    model: "title",
    clearable: true,
    label: useTranslateI18n("profile.billingUsage.invoices.t2"),
    type: "input",
    noTrim: true,
    placeholder: useTranslateI18n("profile.billingUsage.invoices.t6")
  },
  {
    model: "address",
    clearable: true,
    label: useTranslateI18n("profile.billingUsage.invoices.t3"),
    type: "input",
    noTrim: true,
    placeholder: useTranslateI18n("profile.billingUsage.invoices.t7")
  },
  {
    label: useTranslateI18n("profile.billingUsage.invoices.t11"),
    placeholder: useTranslateI18n("profile.billingUsage.invoices.t8"),
    model: "phone",
    type: "countrySelect",
    maxlength: 200
  },
  {
    model: "email",
    clearable: true,
    label: useTranslateI18n("profile.billingUsage.invoices.t4"),
    type: "input",
    placeholder: useTranslateI18n("profile.billingUsage.invoices.t9")
  },
  {
    model: "tax_code",
    clearable: true,
    label: useTranslateI18n("profile.billingUsage.invoices.t5"),
    type: "input",
    placeholder: useTranslateI18n("profile.billingUsage.invoices.t10")
  }
])

let formRules = reactive<FormRules>({
  title: [
    {
      required: true,
      message: useTranslateI18n("profile.billingUsage.invoices.t6")
    }
  ],
  address: [
    {
      required: true,
      message: useTranslateI18n("profile.billingUsage.invoices.t7")
    }
  ],
  email: [
    {
      required: true,
      trigger: "blur",
      validator: validatorEmail
    }
  ],
  phone: [
    {
      required: true,
      trigger: "blur",
      validator: validatorP
    }
  ]
})

const emit = defineEmits(["submit", "cancel"])

const submitClick = () => {
  formRef.value && formRef.value.validate()
}

const cancelClick = () => {
  emit("cancel", { ...formModel })
}

const validateForm = (v) => {
  if (v) {
    emit("submit", { ...formModel })
  }
}
</script>

<style scoped lang="scss">
.invoices-info-edit {
  display: flex;
  width: 100%;
  .link-text {
    color: #1877f2;
    cursor: pointer;
  }
  &-left {
    width: 398px;
    flex-shrink: 0;
    padding: 36px;
    opacity: 0.7;
    background: #f3f3f3;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
    display: flex;
    flex-direction: column;
    position: relative;
    .title {
      @include fontSemibold;
      font-size: 48px;
      line-height: 57px;
      color: #000;
    }
    .desc {
      color: rgba(0, 0, 0, 0.55);
      font-size: 14px;
      margin-top: 24px;
    }
    .desc-content {
      margin-top: 24px;
      font-weight: 510;
      font-size: 14px;
      line-height: 17px;
      color: rgba(0, 0, 0, 0.55);
      .desc-look {
        margin-top: 16px;
        :deep(.contact-sales_a_go_help) {
          color: #1877f2;
          cursor: pointer;
        }
        :deep(.contact-sales_a_go_email) {
          color: #1877f2;
          cursor: pointer;
        }
      }
    }
    .ev-title {
      display: flex;
      position: absolute;
      bottom: 36px;
      left: 35px;
      img {
        width: 87px;
      }
    }
  }
  &-right-content {
    flex: 1;
    padding: 36px 35px 0;
    .content-form {
      margin-top: 24px;

      :deep(.pix-common-search) {
        .el-form {
          .el-form-item {
            margin-bottom: 32px;
            .contact-info-type-wrapper {
              display: flex;
              margin-bottom: 12px;
              .contact-info-item {
                margin-right: 8px;
                display: flex;
                cursor: pointer;
                padding: 1px;
                border: 1.5px solid transparent;
                border-radius: 8px;
                transition: all 0.2s;
                img {
                  width: 28px;
                }
                &:nth-last-child(1) {
                  margin-right: 0;
                }
                &.contact-info-item-hover {
                  border: 1.5px solid #ffdf3c;
                }
              }
            }
            label {
              color: #000;
            }
            .el-form-item__label {
              color: #000;
              &::before {
                display: none;
              }
            }
            .el-form-item__content {
              .el-input__wrapper {
                border: none;
                box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset;
                &.is-focus {
                  box-shadow: 0 0 0 2px #ffe050 inset;
                }
                .el-input__inner {
                  height: 55px;
                  font-size: 16px;
                  &::placeholder {
                    color: rgba(0, 0, 0, 0.2);
                  }
                }
              }
              .country-select {
                line-height: 55px;
                height: 55px;
                .el-select {
                  .el-input__wrapper {
                    box-shadow: none;
                    &.is-focus {
                      box-shadow: none !important;
                      border: none !important;
                    }
                  }
                }
                > .el-input {
                  width: 100%;
                }
              }
              @include device-max-width-600 {
                .country-select {
                  > .el-input {
                    width: 100%;
                  }
                }
              }
              .el-select {
                .el-input__wrapper {
                  &.is-focus {
                    box-shadow: 0 0 0 2px #ffe050 inset !important;
                  }
                }
              }
              .el-textarea {
                .el-textarea__inner {
                  height: 108px !important;
                  border: none;
                  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.3) inset;
                  &:focus {
                    box-shadow: 0 0 0 2px #ffe050 inset;
                  }
                }
              }
            }
          }
          .el-form-item:nth-last-of-type(1) {
            margin-bottom: 24px;
          }

          .el-input__prefix-inner {
            > div {
              margin-right: 4px;
              .select-group_prefix_img {
                display: flex;
                img {
                  width: 26px;
                  height: 26px;
                }
              }
            }
          }

          .el-form-item {
            width: 100%;
            margin-right: 0;
          }
          .el-form-item {
            .el-input__suffix-inner {
              i {
                font-size: 16px;
              }
            }
            .el-select {
              .el-select__caret {
                transform: rotateZ(0);
              }
              .el-input__suffix {
                align-items: center;
                .el-input__suffix-inner {
                  i {
                    width: 24px;
                    height: 24px;
                    background-image: url("@/assets/images/pages/payment/select-icon2.png");
                    background-size: 100% 100%;
                    font-size: 0;
                    margin-left: 0;
                  }
                }
              }
            }
          }
        }
      }
    }
    .footer {
      position: sticky;
      bottom: 0;
      left: 0;
      z-index: 1001;
      background-color: #fff;
      padding-bottom: 40px;
      .cancel-button {
        margin-top: 12px;
      }
    }
  }
}
</style>
