<template>
  <div class="recovery-wrap">
    <div class="recovery-wrap_title">
      <span>{{ useTranslateI18n(`login.signIn.recovery`) }}</span>
    </div>
    <div class="recovery-wrap_form">
      <CommonForm
        ref="formRef"
        :form-inline="false"
        form-label-position="top"
        :form-model="formModel"
        :form-input-list="formInputList"
        :form-rules="formRules"
        @validate="validate"
      />
      <div class="recovery-wrap_form-footer">
        <CommonButton ref="btnRef" :button-style="saveBtnStyle" @btn-click="save">{{
          useTranslateI18n(`profile.confirm.ok`)
        }}</CommonButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import CommonButton from "@/components/common/Button.vue"
import { useTranslateI18n } from "@/composables/store/i18n-country"
import { EMAIL_RE } from "~/constant/regex"
import { IFormPropsInputList } from "~/ui/components/commonSearch/interface"

const formModel = reactive({
  email: ""
})
const formInputList = reactive<IFormPropsInputList<typeof formModel>>([
  {
    name: "email",
    type: "input",
    placeholder: useTranslateI18n(`login.signIn.recovery`),
    model: "email",
    enterClick: true,
    enterClickFn: () => save()
  }
])
const formRules = reactive({
  email: [
    {
      validator: validatorEmail as any,
      trigger: "blur"
    }
  ]
})

const formRef = ref()

const btnRef = ref<InstanceType<typeof CommonButton>>()

const emits = defineEmits(["close", "save"])

const saveBtnStyle = reactive({
  background: "#ffe050",
  color: `rgba(0, 0, 0, 0.85)`,
  borderRadius: "42px",
  height: "42px",
  fontSize: "14px",
  flex: "1"
})

const save = useDebounceFn(() => formRef.value && formRef.value.validate(), 300)
const validate = (v: boolean) => {
  if (v) {
    emits("save", formModel)
  }
}

async function validatorEmail(_rule: any, value: any, callback: any) {
  let regex = EMAIL_RE
  if (!regex.test(value)) {
    return callback(new Error(useTranslateI18n("login.signIn.error")))
  } else {
    return callback()
  }
}
</script>

<style scoped lang="scss">
.recovery-wrap {
  display: flex;
  flex-direction: column;
  gap: 40px;
  font-family: "SF Pro";
  font-style: normal;
  line-height: normal;
  font-size: 14px;
  font-weight: 400;

  &_title {
    color: #000;
    font-size: 24px;
    font-weight: 590;
  }

  &_form {
    &-label {
      margin-bottom: 16px;
      color: #000;
    }
    &-footer {
      display: flex;
      flex-direction: row;
      gap: 8px;
    }
    :deep(.pix-common-search) {
      .el-form-item {
        .el-form-item__content {
          .el-input__wrapper {
            .el-input__inner {
              height: 36px;
            }
          }
        }
      }

      .el-form-item--large:last-of-type {
        margin-bottom: 40px;
      }
    }
  }
}
</style>
