export enum paymentApi {
  Payment_Plan = "/v1/app/subscription/plan_list",
  Addition_Package = "/v1/api/subscription/addition_package_list",
  Payment_PassThrough = "/v1/api/subscription/generate_passthrough",
  No_Login_PassThrough = `/v1/web/get_passthrough`,
  Payment_AddDatePackage = "v1/api/subscription/buy_addition_package",
  Payment_UpdatePlan = "/v1/api/subscription/change_plan",
  Payment_ConfirmPlan = "/v1/api/subscription/confirm_change_plan",
  Payment_ConfirmBuyPackage = "/v1/api/subscription/confirm_buy_addition_package",
  Payment_Cancel = "/v1/api/subscription/cancel",
  GET_COUNTRY_LIST = "/v1/app/enum/get_country_list", // 获取国家列表
  CONSULTATION_ADD = "/v1/app/consultation/add",
  Read_Payment_Status = `/v1/api/subscription/read_payment_status`,
  PAYMENT_LOG = `/v1/api/trade/payment_log`,
  PostRedeemPaddlePassThrough = `/v1/api/redemption_code/get_passthrough` // 兑换码页面获取passThrough
}
