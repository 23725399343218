<script lang="tsx">
import { defineComponent, PropType } from "vue"
import { IFormInputListConfig, IFormPropsFormModel } from "@/types/components/common/form"
import CommonUpload from "@/components/common/Upload.vue"
import CommonCountrySelect from "@/components/common/CountrySelect.vue"
import VerificationCodeInput from "@/components/common/VerificationCodeInput.vue"
// TODO 避免使用 usePolicyCheckboxDialog 出现组件找不到渲染的问题
import {
  ElInput,
  ElSelect,
  ElRadio,
  ElRadioGroup,
  ElCheckbox,
  ElCheckboxGroup,
  ElInputNumber,
  ElOption,
  ElPopover
} from "element-plus"
export default defineComponent({
  name: "CommonChildItemRender",
  components: {
    CommonUpload,
    CommonCountrySelect,
    ElRadio,
    ElRadioGroup,
    ElCheckbox,
    ElCheckboxGroup,
    ElInput,
    ElSelect,
    ElOption,
    ElInputNumber,
    ElPopover
  },
  props: {
    config: {
      type: Object as PropType<IFormInputListConfig>,
      default: () => {
        return {}
      }
    },
    model: {
      type: Object as PropType<IFormPropsFormModel>,
      default() {
        return {}
      }
    }
  },
  setup() {
    let inputRef = ref()
    return {
      inputRef
    }
  },
  methods: {
    enterHandler(e, item) {
      if (e.keyCode === 13) {
        item.enterClickFn && item.enterClickFn()
      }
    },
    renderForm() {
      const model = this.model
      const config = this.config
      if (config.type === "input") {
        if (Object.prototype.hasOwnProperty.call(config, "inputRef")) {
          config.inputRef = this.inputRef
        }
      }
      const formElement = {
        input: (
          <el-input
            {...config}
            ref={"inputRef"}
            v-model={model[config.model]}
            type={config.itype}
            placeholder={config.placeholder}
            disabled={config.disabled}
            clearable={config.clearable || false}
            onKeyup={(e) => {
              config.enterClick && this.enterHandler(e, this.config)
            }}
            onBlur={(e) => {
              model[config.model] = (model[config.model] as string)?.trim()
              config?.onBlur && config?.onBlur()
            }}
          >
            {{
              suffix: () => {
                return config.suffixSlot ? config.suffixSlot(config) : ""
              }
            }}
          </el-input>
        ),
        textarea: (
          <el-input
            {...config}
            v-model={model[config.model]}
            type="textarea"
            autosize={config.autosize || { minRows: config.minRows || 14 }}
            disabled={config.disabled}
            clearable={config.clearable || false}
            placeholder={config.placeholder}
          ></el-input>
        ),
        select: (
          <el-select
            {...config}
            popper-class="contact-sales_s"
            v-model={model[config.model]}
            placeholder={config.placeholder}
            disabled={config.disabled}
            clearable={config.clearable || false}
          >
            {{
              prefix: () => {
                return config.selectPrefixFormatter ? config.selectPrefixFormatter(config) : null
              },
              default: () => {
                return config.selectData
                  ? config.selectData.map((item) => {
                      return (
                        <el-option label={item.label} value={item.value}>
                          {config.formatter ? config.formatter(item) : null}
                        </el-option>
                      )
                    })
                  : []
              }
            }}
          </el-select>
        ),
        upload: (
          // <el-upload
          //   {...config}
          //   drag={config.drag}
          //   action={config.action}
          //   multiple={config.multiple}
          //   auto-upload={config.autoUpload}
          //   v-model={model[config.model]}
          // >
          //   {{
          //     ...config.uploadSlots
          //   }}
          // </el-upload>
          <CommonUpload
            {...config}
            v-model={model[config.model]}
            typeList={config.typeList}
            uploadTitle={config.uploadTitle}
            uploadSizeLimit={config.uploadSizeLimit}
            uploadErrorTypeMessage={config.uploadErrorTypeMessage}
            uploadErrorLimitMessage={config.uploadErrorLimitMessage}
          >
            {{
              tip: () => {
                return config.slotFormatter ? config.slotFormatter(config) : null
              }
            }}
          </CommonUpload>
        ),
        radio: (
          <el-radio-group v-model={model[config.model]} disabled={config.disabled}>
            {config.options &&
              config.options.map((it, i) => {
                return (
                  <el-radio key={i} label={it.value} border={config.border}>
                    {it.label}
                  </el-radio>
                )
              })}
          </el-radio-group>
        ),
        checkbox: (
          <el-checkbox-group v-model={model[config.model]} disabled={config.disabled}>
            {config.options &&
              config.options.map((item, i) => {
                return (
                  <el-checkbox label={item.value} key={i} border={config.border}>
                    {item.formatter ? item.formatter() : item.label}
                  </el-checkbox>
                )
              })}
          </el-checkbox-group>
        ),
        countrySelect: (
          <CommonCountrySelect
            onKeyup={(e) => {
              config.enterClick && this.enterHandler(e, this.config)
            }}
            {...config}
            v-model={model[config.model]}
          ></CommonCountrySelect>
        ),
        inputNumber: (
          <el-input-number
            {...config}
            v-model={model[config.model]}
            min={config.min}
            max={config.max}
            step={config.step}
            placeholder={config.placeholder}
            controls-position={config.controlsPosition}
            controls={config.controls}
            size={config.size}
          />
        ),
        verCodeInput: (
          <VerificationCodeInput
            {...config}
            v-model={model[config.model]}
            placeholder={config.placeholder}
            email={model[config.emailModel]}
            disabled={config.disabled}
            scene={config.scene}
          />
        ),
        elementCustom: <>{this.renderSlots(config)}</>
      }
      return formElement[config.type]
    },
    renderSlots(item: IFormInputListConfig) {
      return item.slotsFormatter
        ? item.slotsFormatter(item)
        : item.slotsName
        ? this.$slots[item.slotsName]
          ? this.$slots[item.slotsName]!(item)
          : null
        : null
    }
  },
  render() {
    return this.renderForm()
  }
})
</script>

<style lang="scss">
.contact-sales_s {
  &.el-select__popper.el-popper[role="tooltip"] {
    background: #ffffff;
    border: 1px solid rgba(255, 255, 255, 0.15);
    box-shadow: 0px 36px 70px rgba(0, 0, 0, 0.08), 0px 0px 3px rgba(0, 0, 0, 0.33);
    backdrop-filter: blur(30px);
    /* Note: backdrop-filter has minimal browser support */
  }
  .el-select-dropdown__list {
    margin: 0 !important;
    padding: 4px;
    li {
      border-radius: 6px;
      margin-bottom: 4px;
      height: 25px;
      line-height: 25px;
      padding-left: 8px;
      padding-right: 8px;
      background-color: transparent;
      font-weight: 510;
      font-size: 12px;
      color: rgba(0, 0, 0, 0.85);
      min-width: 166px;
      display: flex;
      align-items: center;
      width: 100%;
      .country-select-li {
        justify-content: space-between;
        width: 100%;
      }
    }
    .el-select-dropdown__item:hover {
      font-size: 12px;
      color: rgba(255, 255, 255, 1);
      background-color: #3083ff;
    }
    .el-select-dropdown__item {
      &::before {
        content: "";
        background: url("@/assets/images/common/contact-success.png") no-repeat left top;
        background-size: 100% 100%;
        width: 16px;
        height: 16px;
        display: inline-block;
        margin-right: 2px;
        opacity: 0;
      }
      &.selected {
        &::before {
          opacity: 1;
        }
        &.hover {
          &::before {
          }
        }
      }
    }
  }
  border-radius: 10px;
  &.el-popper {
    .el-popper__arrow {
      display: none;
    }
  }
  .select-right-flag {
    width: 25px;
    height: 17px;
    background: #ff9533;
    color: #fff;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .el-select-dropdown__empty {
    min-width: 166px;
  }
}
</style>
