import { INavbarTheme } from "@/constant/navbar"
import { PAYMENT_COMBO_SUB, PAYMENT_PAGE_PATH } from "@/constant/route"
import { useOriginPath } from "~/constant/useLocalPathConfig"

export const useHallowsOpen = () => useState("use_hallows_open", () => false)

export const useHallowsOpenTimeLeft = () => useState("use_hallows_open_time_left", () => 0)

export const useAllActivitys = () => {
  const hallows_open = computed(() => {
    return useHallowsOpen().value
  })

  const resetHallowsOpen = () => {
    useHallowsOpen().value = false
  }

  const hallows_open_time_left = computed(() => {
    return useHallowsOpenTimeLeft().value
  })

  const setNavbarTheme = (theme: INavbarTheme) => {
    if (theme === INavbarTheme.Dark) {
      useSetNavbarTheme(INavbarTheme.Dark)
      useFixedNavbarTheme().value = true
      useNavbarCustomBg().value = "#000"
    } else if (theme === INavbarTheme.White) {
      useSetNavbarTheme(INavbarTheme.White)
      useFixedNavbarTheme().value = false
      useNavbarCustomBg().value = "#000"
    }
  }

  const setHallowOpenValue = async () => {
    const hallowsOpen = useHallowsOpen()
    const hallowsOpenTimeLeft = useHallowsOpenTimeLeft()
    const { time_left, is_discount } = await getAllActivity()
    hallowsOpen.value = is_discount
    hallowsOpenTimeLeft.value = time_left
  }

  return {
    hallows_open,
    hallows_open_time_left,
    setNavbarTheme,
    setHallowOpenValue,
    resetHallowsOpen
  }
}
