import Mitt from "mitt"

export default defineNuxtPlugin(() => {
const mitt = new Mitt()
  return {
    provide: {
      mitt:mitt
    }
  }
})
