import { CookiesKey } from "@/constant/cookies"

export const useLandingRecordUuid = () => {
  const setLandingRecordUuid = (uuid) => {
    const cookie = useCookie(CookiesKey.LANDING_UUID)
    cookie.value = uuid
  }
  const getLandingRecordUuid = () => {
    return useCookie(CookiesKey.LANDING_UUID).value
  }

  const removeLandingRecordUuid = () => {
    useCookie(CookiesKey.LANDING_UUID).value = null
  }

  return {
    setLandingRecordUuid,
    getLandingRecordUuid,
    removeLandingRecordUuid
  }
}
