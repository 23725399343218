import { LocalStorageKey } from "~~/constant/cache"
import { CookiesOptions } from "~~/constant/cookies"
import mixpanelInstance from "@/utils/mixpanel"
import { useUser } from "@/store/user"
import { useMp } from "@/composables/useMp"

// 是否开启 mixpanel注册
let enabledFlag = true

export function resetMixpanel() {
  mixpanelInstance.reset()
}

export async function registryMixpanel(changeEnabled?: boolean) {
  if (changeEnabled) {
    enabledFlag = changeEnabled
  }
  const { userInfo } = useUser()
  const { initMixPanel } = useMixpanel()
  Object.keys(userInfo).length
    ? await initMixPanel(userInfo.id, {
        name: userInfo.name
      })
    : await initMixPanel()
}

// mixpanel pv需要自己派发
export function registryMixpanelPageView(to) {
  const { useCommonMp } = useMp()
  const { _pointMpCommonPageView } = useCommonMp()
  const timeId = setTimeout(() => {
    _pointMpCommonPageView(to.path, to.query)
    clearTimeout(timeId)
  }, 100)
}

export default function useMixpanel() {
  const isEnabled = useIsPoint(CookiesOptions.Analytics)
  // 注册mixpanel 以及用户属性
  const initMixPanel = async (id?: any, properties?: Record<string, any>, callback?: (...args: any[]) => any) => {
    if (isEnabled && enabledFlag) {
      await mixpanelInstance.initMixPanel(id, properties, callback)
      enabledFlag = false
    }
  }

  return {
    initMixPanel
  }
}
